<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div
              class="grid-column"
              (click)="Load(1, Model.Filter, 1)"
              [ngClass]="Tab === 1 ? 'tab-selected' : ''"
              [ngStyle]="{
                cursor: Tab === 2 || Tab === 3 ? 'pointer' : 'default'
              }"
            >
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.AllMetadatas
              }}</span>
            </div>

            <div
              class="grid-column"
              (click)="Load(1, Model.Filter, 2)"
              [ngClass]="Tab === 2 ? 'tab-selected' : ''"
              [ngStyle]="{
                cursor: Tab === 1 || Tab === 3 ? 'pointer' : 'default'
              }"
            >
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.Enabled
              }}</span>
            </div>

            <div
              class="grid-column"
              (click)="Load(1, Model.Filter, 3)"
              [ngClass]="Tab === 3 ? 'tab-selected' : ''"
              [ngStyle]="{
                cursor: Tab === 2 || Tab === 1 ? 'pointer' : 'default'
              }"
            >
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.Disabled
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mx-0 p-0">
    <div class="search-create-container">
      <div
        class="p-0 d-flex align-items-center justify-content-center"
        style="width: 30px; margin-right: 15px"
      >
        <span
          class="filter-badge"
          [matBadgeHidden]="FilterCount === 0"
          matBadge="{{ FilterCount }}"
        >
          <img
            class="clickable-icon"
            (click)="OpenFiltersDialog()"
            src="../../../../../assets/icon/filter-icon.svg"
          />
        </span>
      </div>
      <div class="flex-grow-1 p-0">
        <app-search
          #search
          [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
          [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel"
          [HaveFilters]="false"
          [HaveActions]="false"
          (OnEnterPress)="Search($event)"
          (OnClearBtnPress)="Search($event)"
        ></app-search>
      </div>
      <div class="p-0" style="width: 120px">
        <button
          *ngIf="authService?.CurrentUser?.IsAdmin"
          (click)="Create()"
          mat-fab
          extended
          class="it-doc-btn-create create-button"
        >
          <img
            class="clickable-icon"
            src="../../../../../assets/icon/add.svg"
          />
          <span class="text">{{ navigatorService.Dictionary?.Create }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="form mt-3">
    <div class="it-doc-table-container">
      <table
        mat-table
        [dataSource]="DataSource"
        matSort
        *ngIf="navigatorService.Dictionary"
      >
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef class="checkbox-column">
            <mat-checkbox
              (change)="HeaderCheckbox($event)"
              [checked]="IsAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              (change)="CheckRow($event, element)"
              [(ngModel)]="element.Checked"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="{{ navigatorService.Dictionary?.TableColumnName }}"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnName }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.Name }}</span>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="{{
            navigatorService.Dictionary?.SelectMetadataCategoryPlaceholder
          }}"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.SelectMetadataCategoryPlaceholder }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.MetadataCategoryName }}</span>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="{{
            navigatorService.Dictionary?.SelectTypePlaceholder
          }}"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.SelectTypePlaceholder }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.FieldName }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.Creator }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.Creator }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.Creator }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="more-action">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <img
              *ngIf="authService?.CurrentUser?.IsAdmin"
              #m="matMenuTrigger"
              [matMenuTriggerFor]="moreAction"
              class="clickable-icon"
              src="../../../../../assets/icon/more-vert.svg"
              [ngClass]="{
                'open-menu-trigger-color': m.menuOpen,
                'disabled-img': !GetMenuMetadatas()
              }"
            />
            <mat-menu
              #moreAction="matMenu"
              xPosition="before"
              class="more-action-menu"
            >
              <button mat-menu-item (click)="DisableSelectedMetadatas()">
                {{ navigatorService.Dictionary?.Disable }}
              </button>
              <button mat-menu-item (click)="EnableSelectedMetadatas()">
                {{ navigatorService.Dictionary?.Enable }}
              </button>
            </mat-menu>
          </th>

          <td mat-cell *matCellDef="let element">
            <img
              *ngIf="authService?.CurrentUser?.IsAdmin"
              #m="matMenuTrigger"
              [matMenuTriggerFor]="moreAction"
              class="clickable-icon"
              src="../../../../../assets/icon/more-vert.svg"
              [ngClass]="{ 'open-menu-trigger-color': m.menuOpen }"
            />
            <mat-menu
              #moreAction="matMenu"
              xPosition="before"
              class="more-action-menu"
            >
              <button mat-menu-item (click)="Update(element)">
                {{ navigatorService.Dictionary?.Update }}
              </button>
              <button mat-menu-item (click)="DisableMetadata(element)">
                {{
                  element.Disabled
                    ? navigatorService.Dictionary?.Enable
                    : navigatorService.Dictionary?.Disable
                }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
          <td mat-cell *matCellDef="let element">
            <img
              class="clickable-icon"
              src="../../../../../assets/icon/forward.svg"
              (click)="Update(element)"
            />
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="[
            'checkbox',
            navigatorService.Dictionary?.TableColumnName,
            navigatorService.Dictionary?.SelectMetadataCategoryPlaceholder,
            navigatorService.Dictionary?.SelectTypePlaceholder,
            navigatorService.Dictionary?.Creator,
            'more-action',
            'filter'
          ]"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: [
              'checkbox',
              navigatorService.Dictionary?.TableColumnName,
              navigatorService.Dictionary?.SelectMetadataCategoryPlaceholder,
              navigatorService.Dictionary?.SelectTypePlaceholder,
              navigatorService.Dictionary?.Creator,
              'more-action',
              'filter'
            ]
          "
        ></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
      </div>
    </div>
    <div
      *ngIf="Model?.Dtos?.length <= 0 && !navigatorService.Loading"
      class="m-0 empty-table-box"
      style="padding-bottom: 200px"
    >
      <img src="../../../../../assets/icon/tipology-empty.svg" />
      <div class="description">
        {{ navigatorService.Dictionary?.EmptyMetadataViewMessage }}
      </div>
    </div>
  </div>
</div>
