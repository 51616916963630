import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { Constants } from '../doc.configuration';

export class SettingsModel extends BaseModel<
  SettingsEntity,
  SettingsDto,
  SettingsFilter
> {
  constructor() {
    super();
  }
}

export class SettingsEntity extends BaseEntity {
  Configkey: any;
  Configvalue: string | null;
  Appid: string;
  constructor() {
    super();
    this.Configkey = null;
    this.Configvalue = null;
    this.Appid = Constants.AppId;
  }
}

export class SettingsDto extends SettingsEntity {
  constructor() {
    super();
  }
}

export class SettingsFilter extends SettingsDto {}
