import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  DateFormat,
  DATE_FORMATS,
  DATE_FORMATS2,
} from '../../doc.configuration';
import moment from 'moment';

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class PickerComponent<TDto> implements OnInit {
  private dataValue: TDto = null;
  get DataValue() {
    return this.dataValue;
  }

  @Input() set DataValue(value: TDto) {
    if (value && value[this.Property]) {
      this.BindView(value);
    }
    this.dataValue = value;
  }

  @Input() Required = false;
  @Input() Property: string;
  @Input() Placeholder = '';
  @Input() Location = null;
  @Input() DatepickerFilter = null;
  @Input() ReadOnly = false;
  @Input() Disabled = false;
  @Input() Type = 'date';
  @Input() Culture = 'it';

  public Start: Date;
  public End: Date;
  @Input() FormatDate = DateFormat.ddMMyyyy;
  Value;
  // @Output() DataChange = new EventEmitter();

  constructor(
    @Inject(MAT_DATE_FORMATS) public data: any,
    public datepipe: DatePipe
  ) {
    this.Start = null;
    this.End = null;
  }

  ngOnInit() {
    if (this.Type === 'datetime') {
      this.data.display = DATE_FORMATS2.display;
      this.data.parse = DATE_FORMATS2.parse;
    }
  }

  AddEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.BindModel(event.value);
    if (this.Type === 'datetime') {
      this.BindView(this.DataValue);
    }
    // if (this.DataChange) {
    //   this.DataChange.emit(this.dataValue[this.Property]);
    // }
  }

  BindView(dto: TDto) {
    if (dto && dto[this.Property]) {
      if (this.IsInteger(dto[this.Property])) {
        this.Value = new Date(dto[this.Property] * 1000);
      } else {
        if (this.Type === 'datetime') {
          this.Value = this.GetDateTime(dto[this.Property]);
        } else {
          this.Value = this.GetDate(dto[this.Property]);
        }
      }
    }
  }
  IsInteger(value: any) {
    return !isNaN(value);
  }

  BindModel(value) {
    let dateString = null;
    let dateTime = null;
    if (value) {
      dateString = this.datepipe.transform(value, this.FormatDate);
      if (this.Type === 'datetime') {
        dateTime = this.GetDateTime(dateString);
        dateString = this.datepipe.transform(dateTime, this.FormatDate);
        const newDate = moment(dateTime);
        // Inject it into the initial moment object
        value.set(newDate.toObject());
      }
    }
    this.dataValue[this.Property] = dateString;
  }

  GetDate(value: string): Date {
    let splits = value.split('/');
    if (splits?.length === 1) {
      splits = value.split('-');
    }
    let dateString = value;
    if (splits[0].length === 2) {
      dateString =
        splits[2].substring(0, 4) + '/' + splits[1] + '/' + splits[0];
    }
    const date = new Date(dateString);
    return date;
  }

  GetDateTime(value: string): Date {
    let splits = value.split('/');
    if (splits?.length === 1) {
      splits = value.split('-');
    }
    let dateString = value;
    if (splits[0].length === 2) {
      dateString =
        splits[2].substring(0, 4) + '/' + splits[1] + '/' + splits[0];
    }
    const time = splits[2].split(' ');
    if (time.length === 2) {
      dateString = dateString + ' ' + time[1];
    }
    const date = new Date(dateString);
    if (this.Type === 'datetime') {
      const time = splits[2].split(' ');
      if (time[1] === '00:00') {
        const now = new Date();
        const minutes = now.getMinutes();
        const hours = now.getHours();
        date.setHours(hours, minutes);
      }
    }
    return date;
  }

  public Reset() {
    this.Value = null;
  }
}
