import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-mt-autocomplete',
  templateUrl: './autocomplete-ui.component.html',
  styleUrls: ['./autocomplete-ui.component.scss']
})
export class AutocompleteFilterUiComponent implements OnInit {
  @Input() SearchProperties: Array<string> = null;
  @Input() DisplayProperties: Array<string> = null;
  @Input() Placeholder: string = 'Text';
  @Input() LoaderType: string = 'Spinner'; // ["Progressbar", "Spinner"];
  @Input() DropDownMode: boolean = false;
  @Input() Name: string;
  @Input() ReadOnly: boolean = false;
  @Input() Required: boolean = false;
  @Input() Multiple: boolean = false;
  @Input() appearance: string = 'outline';
  @Input() width: string = null;
  @Output() OnChange: EventEmitter<any> = new EventEmitter();
  @Output() SelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() Cancel: EventEmitter<any> = new EventEmitter<any>();
  @Input() Icon: string = 'close';
  @Input() OptionIcon: string;
  ShowLoader: boolean = false;
  AutocompleteControl = new FormControl();
  FilteredOptions: Observable<object[]>;

  private LocalItems: Array<any> = null;

  private dataValue: any = null;
  get DataValue() {
    return this.dataValue;
  }

  @Input() set DataValue(value: any) {
    this.dataValue = value;
    if (value && (this.DisplayProperties.length>0 && value[this.DisplayProperties[0]]!=null )) {
      this.Items = (this.Items?.length <= 0 ? [value] : this.Items);
    }
  }

  private items: Array<any> = null;
  get Items() {
    return this.items;
  }

  @Input() set Items(value: Array<any>) {
    this.items = value;
  }

  //#region "HostListener"
  @HostListener('keyup', ['$event'])
  async onKeyUp(event: KeyboardEvent) {
    if (!this.ReadOnly) {
      if (event.key !== 'Enter' && event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowDown' && event.key !== 'ArrowRight' && event.key !== 'ArrowUp') {
        this.dataValue = null;
        if (!this.DropDownMode) {
          this.Items = null;
          if (this.TimerDelay) {
            clearTimeout(this.TimerDelay);
          }
          if (this.AutocompleteControl.value && this.AutocompleteControl.value.trim().length > 0 && !this.ShowLoader) {
            await this.Delay(2000);
            this.FilterCallback(this.AutocompleteControl.value);
          }
        } else {
          if (!this.LocalItems || (this.LocalItems && this.LocalItems.length <= 0)) {
            this.LocalItems = this.Items;
          }
          this.Items = this.LocalFilter(this.AutocompleteControl.value);
        }
      }
    }
  }

  @HostListener('keypress', ['$event'])
  async onKeyUpenter(event: KeyboardEvent) {
    if (!this.ReadOnly) {
      if (event.key === 'Enter' && !this.ShowLoader) {
        if (!this.DropDownMode && !this.dataValue) {
          this.Items = null;
          if (this.TimerDelay) {
            clearTimeout(this.TimerDelay);
          }
          if (this.AutocompleteControl.value && this.AutocompleteControl.value.trim().length > 0) {
            this.FilterCallback(this.AutocompleteControl.value);
          }
        } else {
          clearTimeout(this.TimerDelay);
        }
      }
    }
  }

  @HostListener('click', ['$event'])
  async onClick(event: KeyboardEvent) {
    if (!this.ReadOnly) {
      if (this.DropDownMode) {
        if (!this.Items || (this.Items && this.Items.length <= 0)) {
          if (this.TimerDelay) {
            clearTimeout(this.TimerDelay);
          }
          if (this.OnChange.observers && this.OnChange.observers.length > 0) {
            this.FilterCallback(this.AutocompleteControl.value);
          }
        }
      }
    }
  }
  //#endregion "HostListener"

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
    if (!this.DropDownMode) {
      this.FilteredOptions = this.AutocompleteControl.valueChanges.pipe(
        map(search => search && typeof search === 'string' ? this.Filter(search) : null));
    } else {
      this.FilteredOptions = this.AutocompleteControl.valueChanges
        .pipe(
          startWith(''),
          map(search => typeof search === 'string' ? search : search.DisplaySearch),
          map(search => search ? this.Filter(search) : this.Items.slice())
        );
    }
  }

  DisplayValue(instance: AutocompleteFilterUiComponent, item: object): string {
    let display = '';
    if (item) {
      if (instance.DisplayProperties && instance.DisplayProperties.length > 0) {
        for (const property of instance.DisplayProperties) {
          display += item[property];
          display += property !== instance.DisplayProperties[instance.DisplayProperties.length - 1] ? ' ' : '';
        }
      } else {
        const properties = Object.keys(item);
        display = item[properties[0]];
      }
    } else {
      instance.dataValue = null;
      instance.SelectionChange.emit(null);
      display = (instance.AutocompleteControl && instance.AutocompleteControl.value ? instance.AutocompleteControl.value : '');
    }
    return display;
  }

  DisplayValueInput(instance: AutocompleteFilterUiComponent, input: HTMLInputElement, item): void {
    if (item) {
      instance.DataValue = item;
      instance.AutocompleteControl.setValue(null);
      input.value = '';

    }
  }


  GetDataValue($event) {
    if ($event) {
      this.dataValue = $event.option.value;
      delete this.dataValue.DisplaySearch;
      if (!this.DropDownMode) {
        this.Items = [this.dataValue];
      }
    }
    if (this.Multiple) {
      this.dataValue.Checked = !this.dataValue.Checked;
      event.stopPropagation();
    }
    this.SelectionChange.emit(this.dataValue);
  }

  private Filter(search: string) {
    let result = [];
    if (this.OnChange.observers && this.OnChange.observers.length <= 0) {
      const filterValue = search.toLowerCase().trim();
      if (this.Items) {
        const items = [];
        this.Items.forEach(val => items.push(Object.assign({}, val)));
        for (const item of items) {
          let searchProperty = '';
          for (const property of this.SearchProperties) {
            searchProperty += item[property];
            searchProperty += property !== this.SearchProperties[this.SearchProperties.length - 1] ? ' ' : '';
          }
          item['DisplaySearch'] = searchProperty;
        }
        result = items.filter(option => option.DisplaySearch.toLowerCase().includes(filterValue));
      }
    }
    return result;
  }

  private TimerDelay: any = null;
  async Delay(ms: number) {
    return new Promise(resolve => this.TimerDelay = setTimeout(resolve, ms));
  }

  private LocalFilter(search: string) {
    let result = [];
    const filterValue = search?.trim().toLowerCase();
    if (this.LocalItems) {
      const items = [];
      this.LocalItems.forEach(val => items.push(Object.assign({}, val)));
      for (const item of items) {
        let searchProperty = '';
        for (const property of this.SearchProperties) {
          searchProperty += item[property];
          searchProperty += property !== this.SearchProperties[this.SearchProperties.length - 1] ? ' ' : '';
        }
        item['DisplaySearch'] = searchProperty;
      }
      result = items.filter(option => option.DisplaySearch.toLowerCase().includes(filterValue));
    }

    return result;
  }


  //#region "Emitter event"

  private FilterCallback(search: string) {
    if (search) {
      this.ShowLoader = true;
      const params = [];
      params['Search'] = search.toLowerCase().trim();
      params['Callback'] = this.Callback;
      params['Istance'] = this;
      params['DropDownMode'] = this.DropDownMode;
      this.OnChange.emit(params);
    }
  }

  Callback(result, instance) {
    instance.Items = result;
    instance.LocalItems = instance.Items;
    instance.ShowLoader = false;
  }
  //#endregion "Emitter event"



  GetOptionSelectedClass(option) {
    if (this.IsEquals(option, this.DataValue)) {
      return 'mat-active';
    }
    return '';
  }

  private IsEquals(source, target): boolean {
    if (target) {
      const properties = Object.keys(source);
      for (const property of properties) {
        if (target[property] !== source[property]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  Clear() {
    this.DataValue = null;
    this.AutocompleteControl.setValue(null);
    this.Cancel.emit();
    //this.InputSearch.value = '';
  }

  CheckItem($event, option) {
    if (option && this.Multiple) {
      option.Checked = !option.Checked;
      if (this.LocalItems?.length > 0) {
        this.LocalItems.find(q => q.Id === option.Id).Checked = option.Checked;
      }
      $event.stopPropagation();
    }
  }
}

