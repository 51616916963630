<div class="component-container">
  <span class="material-icons icon-drag icon-align-center" cdkDragHandle>
    drag_indicator
  </span>
  <div class="form">
    <div *ngIf="!UploadComplete" class="upload-file-container" (click)="multiUploadFile.click()" appDragDrop
      (FileDropped)="SelectFiles($event)">
      <input #multiUploadFile multiple (change)="SelectFiles($event.target.files)" type="file" class="d-none"
        accept="video/*|image/*|application/*|text/*" [disabled]="UploadInProgress" />
      <img src="../../../../assets/icon/note-2.svg" />
      <div class="drag">
        {{ navigatorService.Dictionary?.DragAttachments }}
      </div>
    </div>
  </div>

  <span class="material-icons icon icon-align-center" (click)="DeleteComponent()">
    delete
  </span>

</div>

<div class="toggle-container">
  <mat-slide-toggle [(ngModel)]="Properties.Required" labelPosition="before" class="required-toggle">
    {{ navigatorService?.Dictionary?.Required }}
  </mat-slide-toggle>
</div>

<div *ngIf="FileModel?.Dtos?.length > 0" class="it-doc-table-container max-heigth-100-overflow tiny-scrollbar">
  <table mat-table [dataSource]="DataSourceFiles" class="custom-table">
    <ng-container matColumnDef="Name">
      <td mat-cell *matCellDef="let element" class="name-column">
        <div class="file-name">
          <div class="d-flex align-items-center file-row" (click)="OpenObject(element); $event.stopPropagation()"
            title="{{ element.Name + (element.Type === 'file'? '.' + commonService.GetExtension(element.PhysicalName): '')}}">
            <span class="icon-text-right file-icon">
              <img src="{{ element.Icon }}" class="file-img" />
            </span>
            <div class="file-details">
              <div class="ellipsis-text">
                {{ element.Name }}
              </div>
            </div>
          </div>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Progressbar">
      <td mat-cell *matCellDef="let element" class="progress-column" style="width: 200px; font-size: 12px">
        <div class="row">
          <div class="col-9 progress-container mt-2">
            <mat-progress-bar *ngIf="element.Performed" mode="determinate" value="{{element.Progress}}"
              class="progress-bar">
            </mat-progress-bar>
            <p *ngIf="!element.Performed" class="error-message mt-2">
              {{ element.Message }}
            </p>
          </div>
          <div class="col-3 progress-container percentage" *ngIf="element.Performed">
            <span>{{ element.Progress }}%</span>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="Close">
      <td mat-cell *matCellDef="let element" style="width: 50px; font-size: 12px; text-overflow: clip">
        <button mat-icon-button (click)="DeleteFile(element)" *ngIf="element.Progress === 0">
          <img class="clickable-icon icon-delete" src="../../../../../assets/icon/delete.svg" />
        </button>
      </td>
    </ng-container>
    <tr mat-row [ngClass]="{ selected: row.Selected }" *matRowDef="let row; columns: ['Name', 'Progressbar', 'Close']"
      style="position: relative"></tr>
  </table>
</div>