import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogComponent } from '../../../Interface/doc.dialog.interface';
import { FormDto, FormModel } from '../../../models/FormModel';
import { FormController } from '../../../controllers/FormController';
import { FormBuilderComponent } from './form-builder/form-builder.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, IDialogComponent<FormModel, FormController> {
  Controller: FormController;
  Model: FormModel;
  Preview: boolean = false;
  @ViewChild('formBuilder') FormBuilder: FormBuilderComponent;

  constructor(
    dataService: DataService, public navigatorService: NavigatorService, public authenticationService: AuthService,
    public commonService: CommonService, public dialogRef: MatDialogRef<TemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.Controller = new FormController(dataService);
    this.Model = new FormModel();
    this.Model.Dto = data?.Dto;
    if (this.Model?.Dto == null) {
      this.Model.Dto = new FormDto();
    }
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {

  }

  public Close() {
    this.dialogRef.close(null);
  }

  public Next() {
    this.Preview = true
  }
  public Back() {
    this.Preview = false
  }
  public Save() {
    if (this.FormValidation()) {
      this.Model.Dto.Name = this.Model.Dto.Name.trim();
      this.Model.Dto.Form = this.FormBuilder.GetValue();
      this.dialogRef.close(this.Model.Dto);
    }
  }
  FormValidation() {
    this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.ValidatorMessage);
    if (this.Model.Dto.Name == null || this.Model?.Dto?.Name?.trim()?.length <= 0) {
      return false;
    }
    return true;
  }
}
