<div class="itdoc-page tiny-scrollbar ">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <span class="material-icons title-icon">
                        attach_file
                    </span>
                    <h2>{{navigatorService.Dictionary?.AttachedDocuments }}</h2>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <button mat-icon-button [mat-dialog-close]="null" [disabled]="UploadInProgress">
                <span class="material-icons icon-close">
                    close
                </span>
            </button>
        </div>
    </div>
    <p class="title-description">
        {{navigatorService.Dictionary?.ManageAttachements}}
    </p>

    <mat-dialog-content>
        <div class="form mb-5" style="min-height:400px;">
            <div *ngIf="EnableUpload" class="upload-file-container" (click)="multiUploadFile.click()" appDragDrop
                (FileDropped)="SelectFiles($event)">
                <input #multiUploadFile multiple (change)="SelectFiles($event.target.files)" type="file" class="d-none"
                    accept="video/*|image/*|application/*|text/*" [disabled]="this.UploadInProgress">
                <img src="../../../../assets/icon/upload.svg" style="margin-top: 18px;">
                <p class="title-description">{{navigatorService.Dictionary?.DragAttachments}}</p>
            </div>

            <div *ngIf="!navigatorService.Loading && FileModel?.Dtos?.length > 0"
                class="it-doc-table-container max-heigth-100-overflow tiny-scrollbar">
                <table mat-table [dataSource]="DataSourceFiles">
                    <ng-container matColumnDef="Name">
                        <td mat-cell *matCellDef="let element">
                            <span (click)="OpenObject(element);$event.stopPropagation();"
                                title="{{element.Name + commonService.GetExtension(element.PhysicalName)}}">
                                <div class="d-flex">
                                    <span class="icon-text-right">
                                        <img src="{{element.Icon}}">
                                    </span>
                                    <div class="ellipsis-text">
                                        {{element.Name}}
                                    </div>
                                </div>
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Progressbar">
                        <td mat-cell *matCellDef="let element" style="width: 200px;font-size: 12px;">
                            <div class="row">
                                <div class="col-9">
                                    <mat-progress-bar *ngIf="element.Performed" mode="determinate"
                                        value="{{ element.Progress}}" class="progressbar">
                                    </mat-progress-bar>
                                    <p *ngIf="!element.Performed" style="color: red;position: relative;top:5px">
                                        {{element.Message}}</p>
                                </div>
                                <div class="col-3" *ngIf="element.Performed">
                                    <span>{{ element.Progress}}%</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Close">
                        <td mat-cell *matCellDef="let element" style="width: 50px;font-size: 12px;">
                            <button mat-icon-button (click)="DeleteFile(element)"
                                [ngClass]="UploadInProgress?'d-none':''">
                                <span class="material-icons icon-close">
                                    close
                                </span>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-row [ngClass]="{'selected':row.Selected}" *matRowDef="let row;
                        columns: ['Name',
                            'Progressbar',
                             'Close',
                            ]" style="position: relative;">
                    </tr>

                </table>
            </div>
            <hr *ngIf="EnableUpload" style="width: 100%;background-color: #282828;">


            <div *ngIf="!navigatorService.Loading && Model?.Dtos?.length > 0"
                class="it-doc-table-container max-heigth-overflow tiny-scrollbar">
                <table mat-table [dataSource]="DataSource">
                    <ng-container matColumnDef="Name">
                        <td mat-cell *matCellDef="let element">
                            <span (click)="OpenObject(element);$event.stopPropagation();"
                                [ngClass]="!UploadInProgress?'clickable-icon':''" title="{{element.Name + '.' +
                            commonService.GetExtension(element.PhysicalName)}}">
                                <div class="d-flex">
                                    <span class="icon-text-right">
                                        <img src="{{element.Icon}}">
                                    </span>
                                    <div class="ellipsis-text">
                                        {{element.Name + '.' + commonService.GetExtension(element.PhysicalName)}}
                                    </div>
                                </div>
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Message">
                        <td mat-cell *matCellDef="let element" style="width: 200px;font-size: 12px;">
                            <div class="row">
                                <div class="col-12">

                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Close">
                        <td mat-cell *matCellDef="let element" style="width: 200px;font-size: 12px;text-align: end;">
                            <button mat-icon-button (click)="DownloadFile(element)">
                                <img class="clickable-icon" src="../../../../../assets/icon/download.svg">
                            </button>
                            <button *ngIf="EnableUpload" mat-icon-button (click)="Delete(element)"
                                [disabled]="element.Selected">
                                <span class="material-icons icon-close">
                                    close
                                </span>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-row [ngClass]="{'selected':row.Selected}" *matRowDef="let row;
                        columns: ['Name',
                            'Message',
                             'Close',
                            ]" style="position: relative;">
                    </tr>

                </table>
            </div>
            <div *ngIf="!UploadInProgress && Model?.Dtos?.length <= 0 && FileModel?.Dtos?.length<=0"
                class="m-0 mt-5 empty-table-box">
                <img src="../../../../../assets/icon/document-empty.svg">
                <div class="description">{{navigatorService.Dictionary?.EmptyDocumentMessage}}</div>
            </div>

        </div>
    </mat-dialog-content>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Cancel()">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button *ngIf="FileModel?.Dtos?.length>0 && EnableUpload" (click)="Upload()" mat-button
            class="it-doc-btn-primary" [disabled]="UploadInProgress">
            <span class="button-text">{{navigatorService.Dictionary?.Upload}}</span>

        </button>
        <button *ngIf="FileModel?.Dtos?.length<=0 || !EnableUpload" mat-button class="it-doc-btn-primary"
            (click)="Close()" [disabled]="UploadInProgress">
            <span class="button-text">{{navigatorService.Dictionary?.Close}}</span>
        </button>

    </div>





</div>
