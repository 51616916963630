import { ObjectDto, ObjectFilter, ObjectModel } from '../models/ObjectModel';
import { DataService } from '../../data.service';
import { DocumentState } from '../doc.configuration';
import { EncryptionModel } from '../../models/EncryptionModel';

export class ObjectController {
  constructor(private dataService: DataService) {}

  async CreateOrUpdate(model: ObjectModel): Promise<ObjectModel> {
    let response = model;
    if (model && model.Dto) {
      model.Dto.Size = 0;
    } else {
      model.Dtos.map((o) => (o.Size = 0));
    }
    response = await this.dataService.CreateOrUpdateObject(model);
    if (response && response.Performed) {
      if (response.Entity) {
        response.Dto.Id = response.Entity?.Id;
      }
    }
    return response;
  }

  async Read(model: ObjectModel): Promise<ObjectModel> {
    const response = await this.dataService.ReadObject(model);
    return response;
  }

  async Delete(model: ObjectModel): Promise<ObjectModel> {
    const response = await this.dataService.DeleteObject(model);
    return response;
  }

  async Get(model: ObjectModel): Promise<ObjectModel> {
    return await this.dataService.GetObject(model);
  }

  async Load(model: ObjectModel): Promise<ObjectModel> {
    return await this.dataService.LoadObject(model);
  }

  async GetFirst(model: ObjectModel): Promise<ObjectModel> {
    const response = await this.dataService.GetObject(model);
    if (response) {
      model.Performed = response.Performed;
      if (model?.Dtos?.length > 0) {
        model.Dto = model.Dtos[0];
        model.Dtos = new Array<ObjectDto>();
      }
    }
    return model;
  }

  async Protocol(model: ObjectModel): Promise<ObjectModel> {
    let response = model;
    if (model && model.Dto) {
      model.Dto.Size = 0;
    }
    response = await this.dataService.ProtocolObject(model);
    if (response && response.Performed) {
      if (response.Entity) {
        response.Dto.Id = response.Entity?.Id;
      }
    }
    return response;
  }

  async CountAll(
    accountId: number,
    state: Array<DocumentState>,
    trashed: boolean = false
  ) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.State = state;
    model.Filter.RoleName = 'Admin';
    model.Filter.AccountId = accountId;
    model.Filter.Trashed = trashed;
    model = await this.dataService.CountObject(model);
    if (model.Performed) {
      return model.Count;
    }
    return 0;
  }

  async Encrypt(model: EncryptionModel): Promise<EncryptionModel> {
    const response = await this.dataService.Encrypt(model);
    return response;
  }

  async Decrypt(model: EncryptionModel): Promise<EncryptionModel> {
    const response = await this.dataService.Decrypt(model);
    return response;
  }
}
