import { BaseEntity, BaseModel } from './BaseModel';

export class SignatureAccountModel extends BaseModel<SignatureAccountEntity, SignatureAccountDto, SignatureAccountFilter> {

    constructor() {
        super();
    }
}

export class SignatureAccountEntity extends BaseEntity {
    ServiceId: number;
    Email: string;
    UserId: number;
    Username: string;
    Password: string;
    MarkUsername: string;
    MarkPassword: string;
    Token: string;
    Identifier: string;
    Signature: string;
    RealSignature: string;
    constructor() {
        super();
        this.ServiceId = null;
        this.Email = null;
        this.UserId = null;
        this.Username = null;
        this.MarkPassword = null;
        this.MarkUsername = null;
        this.Password = null;
        this.Token = null;
        this.Identifier = null;
        this.Signature = null;
        this.RealSignature = null;
    }
}

export class SignatureAccountDto extends SignatureAccountEntity {
    MimeType: string;
    Document: string;
    Params: SignatureParams;
    FileId: number;
    UoId: number;
    Desktop: boolean;
    PhysicalName: string;
    Path: string;
    constructor() {
        super();
    }
}

export class SignatureAccountFilter extends SignatureAccountDto {
}

export class SignatureParams {
    format: string;
    fileName: string;
    otpRequested: AnnotationOtpRequested;
    imageOnly: boolean;
    invisibleSignature: boolean;
    annotations: Annotations;
    otp: string;
    mark: boolean;
    constructor() {
        this.otpRequested = new AnnotationOtpRequested();
    }
}

export class Annotations {
    contact: AnnotationObject;
    reason: AnnotationObject;
    location: AnnotationObject;
    image: ImageAnnotation;
    position: Array<PositionAnnotation>;
}

export class AnnotationObject {
    type: string;
    value: string;
}

export class ImageAnnotation {
    mimeType: string;
    data: string;
}
export class AnnotationOtpRequested {
    tokenRequested: boolean;
}
export class PositionAnnotation {
    posX: number;
    posY: number;
    width: number;
    height: number;
    page: number;
}
