import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CurrentUserDto,
  CurrentUserModel,
} from '../../../../models/CurrentUserModel';
import { SettingsController } from '../../../../controllers/SettingsController';
import { NavigatorService } from '../../../../navigator.services';
import { DataService } from '../../../../data.service';
import { AuthService } from '../../../../auth.service';
import { Language } from '../../../doc.configuration';
import {
  EncryptionDto,
  EncryptionModel,
} from '../../../../models/EncryptionModel';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnInit {
  CacheDto: CurrentUserDto;
  Dto: CurrentUserDto;
  Step: number;
  SettingController: SettingsController;
  ErrorMessageSurname: string;
  ErrorMessageName: string;
  ErrorMessagePassword: string;
  ErrorMessageEncryptionPassword: string;
  ErrorMessageEmail: string;
  ErrorMessagePhone: string;
  Languages: Array<LanguageDto>;
  LanguageDto: LanguageDto;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<ProfileInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {
    if (data && data.Dto && data.Step) {
      this.Dto = data.Dto;
      this.Dto.Password = null;
      this.CacheDto = Object.assign({}, data.Dto);
      this.CacheDto.Password = null;
      this.Step = data.Step;
      this.SettingController = new SettingsController(dataService);
      if (data.Step === 1) {
        this.Languages = new Array<LanguageDto>();
        const italian = new LanguageDto(
          1,
          navigatorService.Dictionary?.Italian,
          Language.IT
        );
        const english = new LanguageDto(
          2,
          navigatorService.Dictionary?.English,
          Language.EN
        );
        this.Languages.push(...[italian, english]);
        this.LanguageDto = this.Languages.find(
          (q) => q.Value === this.Dto.Language
        );
      }
    } else {
      dialogRef.close(null);
    }
  }

  ngOnInit(): void {}

  async Save() {
    if (this.FormValidation()) {
      this.navigatorService.StartLoading();
      const performed =
        this.Step === 3
          ? await this.CheckEncryptonPassword(this.Dto.EncryptionPassword)
          : true;
      if (performed) {
        let model = new CurrentUserModel();
        this.Dto.Displayname = this.Dto.Name + ' ' + this.Dto.Surname;
        if (this.Dto.Birthdate) {
          // Se Birthdate è una stringa, esegui lo split
          if (typeof this.Dto.Birthdate === 'string') {
            if (this.Dto.Birthdate !== this.CacheDto.Birthdate) {
              const splits = this.Dto.Birthdate.split('/');
              this.Dto.Birthdate = splits[2] + '/' + splits[1] + '/' + splits[0];
            }
          }
          else if (this.Dto.Birthdate instanceof Date) {
            const formattedDate = this.datepipe.transform(this.Dto.Birthdate, 'dd/MM/yyyy');
            if (formattedDate !== this.CacheDto.Birthdate) {
              const splits = formattedDate.split('/');
              this.Dto.Birthdate = splits[2] + '/' + splits[1] + '/' + splits[0];
            }
          }
        } else {
          this.Dto.Birthdate = null;
        }
        this.Dto.EncryptionPassword = this.CacheDto.EncryptionPassword;
        model.Dto = this.Dto;
        model.UpdateProperties = this.GetUpdateProperties();
        model = await this.SettingController.CreateOrUpdateUser(model);
        if (model?.Performed) {
          model.Dto.IsSetEncryptionPassword = model.Dto.IsSetEncryptionPassword;
          const message = this.navigatorService.Dictionary?.SaveSuccess;
          this.navigatorService.ShowSnackBar(message, this.Dto.Displayname);
          sessionStorage.setItem('user_language', model.Dto.Language);
          this.dialogRef.close(model);
        } else {
          const message = this.navigatorService.Dictionary?.MessageGenericError;
          this.navigatorService.ShowSnackBar(message);
        }
      } else {
        this.ErrorMessageEncryptionPassword =
          this.navigatorService.Dictionary?.WrongPassword;
      }
    }
    this.navigatorService.StopLoading();
  }

  async CheckEncryptonPassword(encryptionPassword: string) {
    let performed = false;
    if (this.Dto.IsSetEncryptionPassword) {
      let model = new EncryptionModel();
      model.Dto = new EncryptionDto();
      model.Dto.Password = encryptionPassword;
      model = await this.SettingController.CheckEncryptionPassword(model);
      performed = model?.Performed;
    } else {
      performed = true;
    }
    return performed;
  }

  GetUpdateProperties() {
    let updateProperties = null;
    if (this.Step === 1) {
      updateProperties = [
        'Name',
        'Surname',
        'Birthdate',
        'Displayname',
        'Language',
      ];
    } else if (this.Step === 2) {
      updateProperties = ['Password', 'Phone', 'Email'];
    } else if (this.Step === 3) {
      updateProperties = ['EncryptionPassword'];
    }
    return updateProperties;
  }

  FormValidation() {
    let validation = true;
    if (this.Step === 1) {
      const message = this.navigatorService.Dictionary?.ErrorRequired;
      if (!this.Dto.Name) {
        this.ErrorMessageName = message;
        validation = false;
      } else {
        this.ErrorMessageName = null;
      }
      if (!this.Dto.Surname) {
        this.ErrorMessageSurname = message;
        validation = false;
      } else {
        this.ErrorMessageSurname = null;
      }
    } else if (this.Step === 2) {
      if (
        this.Dto.Password?.length > 0 ||
        this.Dto.Email?.length > 0 ||
        this.Dto.Phone?.length > 0
      ) {
        if (
          this.Dto.Password?.length > 0 &&
          this.Dto.Password !== this.CacheDto.Password &&
          this.Dto.Password !== this.Dto.ConfirmPassword
        ) {
          const message = this.navigatorService.Dictionary?.ComparePassword;
          this.ErrorMessagePassword = message;
          validation = false;
        } else {
          this.ErrorMessagePassword = null;
        }
        if (
          this.Dto.Email?.length > 0 &&
          this.Dto.Email !== this.CacheDto.Email &&
          this.Dto.Email !== this.Dto.ConfirmMail
        ) {
          const message = this.navigatorService.Dictionary?.CompareEmail;
          this.ErrorMessageEmail = message;
          validation = false;
        } else {
          this.Dto.ConfirmMail = !(this.Dto.Email !== this.CacheDto.Email)
            ? null
            : this.Dto.ConfirmMail;
          this.ErrorMessageEmail = null;
        }
        if (
          this.Dto.Phone?.length > 0 &&
          this.Dto.Phone !== this.CacheDto.Phone &&
          this.Dto.Phone !== this.Dto.ConfirmPhone
        ) {
          const message = this.navigatorService.Dictionary?.ComparePhone;
          this.ErrorMessagePhone = message;
          validation = false;
        } else {
          this.Dto.ConfirmPhone = !(this.Dto.Phone !== this.CacheDto.Phone)
            ? null
            : this.Dto.ConfirmPhone;
          this.ErrorMessagePhone = null;
        }
      } else {
        validation = false;
      }
    } else if (this.Step === 3) {
      if (!this.CacheDto.EncryptionPassword) {
        const message = this.navigatorService.Dictionary?.PasswordLength;
        this.ErrorMessagePassword = message;
        validation = false;
      } else if (this.CacheDto.EncryptionPassword?.length < 6) {
        const message = this.navigatorService.Dictionary?.PasswordLength;
        this.ErrorMessagePassword = message;
        validation = false;
      } else if (
        this.CacheDto.EncryptionPassword !==
        this.CacheDto.ConfirmEncryptionPassword
      ) {
        const message = this.navigatorService.Dictionary?.ComparePassword;
        this.ErrorMessagePassword = message;
        validation = false;
      } else {
        this.ErrorMessagePassword = null;
      }
    }
    return validation;
  }

  SelectLanguage(id: number) {
    this.Dto.Language = this.Languages.find((q) => q.Id === id)?.Value;
  }
}

export class LanguageDto {
  Id: number;
  DisplayValue: string;
  Value: string;

  constructor(id: number, displayValue: string, value: string) {
    this.Id = id;
    this.DisplayValue = displayValue;
    this.Value = value;
  }
}
