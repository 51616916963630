import { DataService } from '../../data.service';
import { MetadataModel } from '../models/MetadataModel';

export class MetadataController {
  constructor(private dataService: DataService) {}

  async CreateOrUpdate(
    model: MetadataModel
  ): Promise<MetadataModel> {
    let response = null;
    if (model && model.Dto) {
      response = await this.dataService.CreateOrUpdateMetadata(model);
      if (response?.Performed) {
        response.Dto.Id = response.Entity?.Id;
      }
    }
    return response;
  }

  async Get(model: MetadataModel): Promise<MetadataModel> {
    const response = await this.dataService.GetMetadata(model);
    if (response) {
      model.Performed = response.Performed;
      if (response.Performed) {
        model.Dtos = response.Dtos;
      }
    }
    return model;
  }

  async Load(model: MetadataModel): Promise<MetadataModel> {
    const response = await this.dataService.LoadMetadata(model);
    if (response) {
      model.Performed = response.Performed;
      if (response.Performed) {
        model.Dtos = response.Dtos;
      }
    }
    return model;
  }

  async Read(model: MetadataModel): Promise<MetadataModel> {
    const response = await this.dataService.ReadMetadata(model);
    if (response) {
      model.Performed = response.Performed;
      if (response.Performed) {
        model.Dto = response.Dto;
      }
    }
    return model;
  }

  async Delete(
    model: MetadataModel
  ): Promise<MetadataModel> {
    const response = await this.dataService.DeleteMetadata(model);
    return response;
  }
}
