<ng-container *ngFor="let dto of DataSource; let i = index">
  <div
    [ngClass]="{'table-uo-row': !dto.Parent || (dto.ParentExpandRow % 2 ===0  && dto.Parent), 'table-child-row-odd-parent': dto.ParentExpandRow % 2 !==0  && dto.Parent}">
    <div class="row w-100 m-0" style="overflow-x: hidden; min-height: 50px; ">
      <div class="col-md-3" [ngClass]="Index>0?'padding-top-12':'uo-col-1'">
        <span class="uo-name" *ngIf="dto.Childs?.length<=0"
          [ngStyle]="{'margin-left.px': (Index===0?0:17*(Index+1))}">{{dto.Name ? dto.Name :
          Constants.ND}}</span>
        <span class="uo-name" *ngIf="dto.Childs?.length>0" [ngStyle]="{'margin-left.px': (Index===0?0:17*(Index+1))}"
          (click)="Expand(dto, i)">{{dto.Name ?
          dto.Name :
          Constants.ND}}</span>
        <span *ngIf="dto.Expand" [ngClass]="dto.Childs?.length>0?'icon-action':'icon-noaction'"
          [ngStyle]="{'margin-left.px': (Index===0?0:6)}" class="material-icons " style="vertical-align: sub;"
          (click)="Expand(dto, i)">
          arrow_drop_down
        </span>
        <span *ngIf="!dto.Expand" [ngClass]="dto.Childs?.length>0?'icon-action':'icon-noaction'" class="material-icons"
          [ngStyle]="{'margin-left.px': (Index===0?0:6)}" style="vertical-align: sub;" (click)="Expand(dto, i)">
          arrow_right
        </span>
      </div>
      <div class="col-md-2 uo-col-2">
        {{dto.OfficeCode ? dto.OfficeCode : Constants.ND}}
      </div>
      <div class="col-md-3 uo-col-3">{{dto.Description ? dto.Description : Constants.ND}}</div>
      <div class="col-md-2 uo-col-4">
        <span *ngIf="dto.Admins?.length>0">
          <img *ngIf="dto.Admins?.length>1" src="../../../../assets/icon/info-icon.svg" class="icon-action"
            [matMenuTriggerFor]="adminsMenu">
          {{dto.Admins[0].DisplayName}}
        </span>
        <mat-menu #adminsMenu="matMenu" class="more-action-menu">
          <div class="admins-container">
            <p class="admin-count">Admin(<strong>{{dto.Admins?.length-1}}</strong>)</p>
            <span *ngFor="let admin of dto.Admins">
              <span *ngIf="admin.Username!==dto.Admins[0].Username">{{admin.DisplayName}}</span>
            </span>
          </div>
        </mat-menu>
      </div>
      <div class="col-md-1 uo-col-5">{{dto.CountAccount ? dto.CountAccount : Constants.ND}}</div>
      <div class="col-md-1 uo-col-6">
        <span *ngIf="authenticationService.DocAccount?.Dto?.IsAdmin;" class="material-icons icon-action"
          [matMenuTriggerFor]="menu">
          more_vert
        </span>
        <mat-menu #menu="matMenu" class="more-action-menu">
          <button *ngIf="!IsTrashedView" mat-menu-item
            (click)="AddUser(dto)">{{navigatorService.Dictionary?.AddUser}}</button>
          <button *ngIf="!IsTrashedView" mat-menu-item
            (click)="Edit(dto)">{{navigatorService.Dictionary?.Modify}}</button>
          <button *ngIf="!IsTrashedView" mat-menu-item
            (click)="ExportAction(dto)">{{navigatorService.Dictionary?.Export}}</button>
          <button *ngIf="!IsTrashedView" mat-menu-item
            (click)="Delete(dto)">{{navigatorService.Dictionary?.Delete}}</button>
          <button *ngIf="IsTrashedView" mat-menu-item
            (click)="Restore(dto)">{{navigatorService.Dictionary?.Restore}}</button>
          <button *ngIf="IsTrashedView" mat-menu-item
            (click)="PermanentDelete(dto)">{{navigatorService.Dictionary?.PermanentlyDelete}}</button>
        </mat-menu>
      </div>

    </div>
    <app-tree class="w-100" [DataSource]="dto.Childs" [Tab]="Tab" [Index]="GetIndex(dto)"
      [ngStyle]="{'display': (!dto.Expand?'none':'inline')}"></app-tree>
  </div>
</ng-container>
