import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileComponent } from '../../../../../../custom-components/file/file.component';
import { NavigatorService } from '../../../../../../../navigator.services';
import { PropertyDto } from '../../../../../../models/FieldsModel';
import { MetadataDto } from '../../../../../../models/MetadataModel';
import { Column } from '../../Form';


@Component({
  selector: 'app-form-file',  
  templateUrl: './form-file.component.html',
  styleUrl: './form-file.component.scss',
})
export class FormFileComponent<TDto>  extends FileComponent<TDto> {
  @Input() Properties: PropertyDto;
  @Input() Data: any;

  public DeleteComponent() {
    const component: MetadataDto = this.Data.item;
    const column: Column = this.Data.column;
    column.RemoveComponent(component);
  }
}
