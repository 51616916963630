<div class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/signature.svg">
                    <h2>{{navigatorService.Dictionary?.Signature}}</h2>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <p class="title-description mb-1">
        {{navigatorService.Dictionary?.SignatureDescription2}}</p>
    <div class="required-label">
        <span>{{navigatorService.Dictionary?.Document}}: {{ObjectDto.Name + '.' +
            commonService.GetExtension(ObjectDto.PhysicalName) }}</span>
    </div>

    <mat-dialog-content *ngIf="Step===1">
        <div class="form mb-5" *ngIf="navigatorService.Loading === false">
            <div *ngIf="Step===1">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>{{navigatorService.Dictionary?.Signature}}</mat-label>
                            <mat-select (selectionChange)="SignatureAccountChange($event)" [value]="Model?.Dto?.Id"
                                required>
                                <mat-option *ngFor="let dto of SigatureAccountModel?.Dtos" [value]="dto?.Id">
                                    {{dto?.Identifier}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>{{navigatorService.Dictionary?.SignatureMode}}</mat-label>
                            <mat-select (selectionChange)="SignatureModeChange($event)" required [value]="GetMode()"
                                [disabled]="Extension !== 'docx' && Extension !== 'doc'  && Extension !== 'odt'  &&
                                    Extension !== 'rtf' && Extension!== 'txt'  && Extension !== 'pdf'">
                                <mat-option *ngFor="let mode of SignatureModes" [value]="mode?.Id">
                                    {{mode?.Display}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>{{navigatorService.Dictionary?.SignatureMode}}</mat-label>
                            <mat-select (selectionChange)="SignatureTypeChange($event)"
                                [disabled]="this.Model?.Dto?.Params?.format==='CADES'" required [value]="GetType()">
                                <mat-option *ngFor="let type of SignatureTypes" [value]="type?.Id">
                                    {{type?.Display}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3" *ngIf="this.Model?.Dto?.Params?.format==='PADES'">
                    <div class="col-md-6 col-sm-12 ">
                        <app-input [DataValue]='this.Model?.Dto?.Params?.annotations?.reason' [Property]="'value'"
                            [Type]="'text'" [Maxlength]="255" [Required]="true"
                            [Placeholder]="navigatorService.Dictionary?.SignaturePlace"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12 ">
                        <app-input [DataValue]='this.Model?.Dto?.Params?.annotations?.location' [Property]="'value'"
                            [Type]="'text'" [Maxlength]="255" [Required]="true"
                            [Placeholder]="navigatorService.Dictionary?.SignatureReason"></app-input>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="this.Model?.Dto?.MarkUsername">
                    <div class="col-md-6 col-sm-12 ">
                        <mat-checkbox [(ngModel)]="Model.Dto.Params.mark" class="value-text">
                            {{navigatorService.Dictionary?.TimestampDescription}}</mat-checkbox>
                    </div>
                    <div class="col-md-6 col-sm-12 ">

                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="Step===2" class="slider-scan-dialog">
        <div *ngIf="Step===2">
            <div class="row">
                <div class="row bottom-shadow"></div>
                <div class="col-md-1"></div>
                <div class="col-md-10 col-sm-12 ">
                    <div class="content-scan tiny-scrollbar">
                        <div id="{{'img_page_'+dto.PhysicalName}}" *ngFor="let dto of Preview"
                            style="height: fit-content;">
                            <img id="{{'img_'+dto.PhysicalName}}" [src]="'data:image/jpeg;base64,'+dto.File"
                                class="{{dto.CssClass}}" [ngClass]="dto.Selected? ' selected':''"
                                (click)="SelectedImage(dto)">
                            <app-resizable-draggable [width]="300" [height]="50" [left]="DragPosition.x"
                                [top]="DragPosition.y" [Image]="Model?.Dto?.RealSignature" *ngIf="dto.ShowSign"
                                [Page]="dto.PhysicalName" style="position: relative;"></app-resizable-draggable>
                        </div>
                    </div>
                    <div *ngIf="FileModel.Dtos.length>0" class="content-scan-paging ">
                        <div class="row">
                            <div class="col-md-6">

                                <button mat-icon-button (click)="SliderPagingClick('previous')" class="arrow-button"
                                    [disabled]="CurrentPage<=1">
                                    <mat-icon class="icon">arrow_upward</mat-icon>
                                </button>
                                <button mat-icon-button (click)="SliderPagingClick('next')" class="arrow-button"
                                    [disabled]="CurrentPage === Pages?.length">
                                    <mat-icon class="icon">arrow_downward</mat-icon>
                                </button>
                                <button class="it-doc-btn-small" (click)="AddGraphicSignature()"
                                    [disabled]="DisableAddSigButton()">
                                    <span class="btn-text">{{navigatorService.Dictionary?.AddSignature}}</span>
                                </button>
                            </div>
                            <div class="col-md-3 "></div>
                            <div class="col-md-3 ">
                                <div class="multipage text-end">
                                    <!-- <button mat-icon-button class="arrow-button" [disabled]="FileModel.Count<2"
                                        (click)="ChangeDisplayMode('MULTIPLE')">
                                        <img style="margin-right: 11px;"
                                            src="../../../../../assets/icon/double-page{{DisplayMode==='MULTIPLE'?'-selected.svg':'.svg'}}">
                                    </button>
                                    <button mat-icon-button class="arrow-button" (click)="ChangeDisplayMode('SINGLE')">
                                        <img style="margin-right: 11px;"
                                            src="../../../../../assets/icon/single-page{{DisplayMode==='SINGLE'?'-selected.svg':'.svg'}}">
                                    </button> -->
                                    <input matInput type="text"  #inputpage  (keydown.enter)="CurrentPageChange(inputpage.value)" [value]="CurrentPage">
                                    <span class="page-number">{{' / ' + Pages?.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="row top-shadow"></div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="Step===3">
        <div *ngIf="Step===3">
            <div class="row" *ngIf="Model?.Dto?.Params?.otpRequested?.tokenRequested">
                <div class="col-6">
                    <div class="otp-description">
                        <p>{{navigatorService.Dictionary?.TypeOtpCode}}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div *ngIf="Model?.Dto?.Params?.otpRequested?.tokenRequested" class="otp-link">
                        <button mat-button (click)="Sign()">{{navigatorService.Dictionary?.ResendOtp}}</button>
                    </div>
                </div>
            </div>
            <div class="row otp-box" *ngIf="Model?.Dto?.Params?.otpRequested?.tokenRequested">
                <div class="row  d-flex flex-wrap">
                    <div class="col-md-12 col-sm-12 ">
                        <app-input [DataValue]='Model?.Dto?.Params' [Property]="'otp'" [Type]="'text'" [Maxlength]="50"
                            [Placeholder]="'OTP'" [Disabled]="!Model?.Dto?.Params?.otpRequested?.tokenRequested">
                        </app-input>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <p ngClass="{{Model?.Dto?.Params?.otpRequested?.tokenRequested?'message-green':'error'}}">
                        {{Message}}</p>
                </div>
            </div>
        </div>
    </mat-dialog-content>


    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button *ngIf="Step>1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
            <span class="button-text">{{navigatorService.Dictionary?.Back}}</span>
        </button>


        <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button *ngIf="ShowButton('SIGN')" (click)="Sign()" mat-button class="it-doc-btn-primary"
            [disabled]="!ValidateForm()">
            <span class="button-text">{{navigatorService.Dictionary?.Sign}}</span>
        </button>
        <button *ngIf="ShowButton('NEXT')" (click)="Next()" mat-button class="it-doc-btn-primary"
            [disabled]="!ValidateForm()">
            <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
        </button>
    </div>
</div>
