import { DataService } from '../data.service';
import { LinkTokenDto, LinkTokenModel } from '../models/LinkToken';
import { SignatureAccountModel } from '../models/SignatureAccount';
import { SignatureServiceModel } from '../models/SignatureService';
import { FileModel } from '../documentary/models/FileModel';
import { EncryptionModel } from '../models/EncryptionModel';
import { CurrentUserModel } from '../models/CurrentUserModel';

export class SettingsController {
  constructor(private dataService: DataService) {}

  async GetUser(model: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.dataService.GetSettingsUser(model);
    return response;
  }

  async LoadUser(model: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.dataService.LoadSettingsUser(model);
    return response;
  }

  async ReadUser(model: CurrentUserModel): Promise<CurrentUserModel> {
    const response = await this.dataService.ReadSettingsUser(model);
    return response;
  }

  async CreateOrUpdateUser(model: CurrentUserModel): Promise<CurrentUserModel> {
    let response = model;
    if (model && model.Dto) {
      response = await this.dataService.CreateOrUpdateUser(model);
      if (response && response.Performed) {
        response.Dto.Id = response.Entity?.Id;
      }
    }
    return response;
  }

  async SendInvitation(model: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.dataService.SendInvitation(model);
    return response;
  }

  async GetInvitationFirst(model: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.dataService.GetInvitation(model);
    if (response) {
      model.Performed = response.Performed;
      if (model?.Dtos?.length > 0) {
        model.Dto = model.Dtos[0];
        model.Dtos = new Array<LinkTokenDto>();
      }
    }
    return model;
  }

  async AcceptInvitation(model: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.dataService.AcceptInvitation(model);
    return response;
  }

  async GetLinkTokenFirst(model: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.dataService.GetResetTokenFirst(model);
    if (response) {
      model.Performed = response.Performed;
      if (model?.Dtos?.length > 0) {
        model.Dto = model.Dtos[0];
        model.Dtos = new Array<LinkTokenDto>();
      }
    }
    return model;
  }

  async ResetPassword(model: LinkTokenModel): Promise<LinkTokenModel> {
    const response = await this.dataService.ResetPassword(model);
    return response;
  }

  async GetSignatureAccount(
    model: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.dataService.GetSignatureAccount(model);
    return response;
  }

  async GetSignatureService(
    model: SignatureServiceModel
  ): Promise<SignatureServiceModel> {
    const response = await this.dataService.GetSignatureService(model);
    return response;
  }

  async CreateOrUpdateSignatureAccount(
    model: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.dataService.CreateOrUpdateSignatureAccount(
      model
    );
    if (response && response.Performed) {
      model.Dto.Id = model.Entity?.Id;
    }
    return model;
  }

  async DeleteSignatureAccount(
    model: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.dataService.DeleteSignatureAccount(model);
    return response;
  }

  async ConvertOnlyOfficeFile(model: FileModel) {
    const response = await this.dataService.ConvertOnlyOfficeFile(model);
    return response;
  }

  async Sign(model: SignatureAccountModel) {
    const response = await this.dataService.Sign(model);
    return response;
  }
  async SignatureVerify(model: FileModel) {
    const response = await this.dataService.SignatureVerify(model);
    return response;
  }
  async ExtractP7M(model: FileModel) {
    const response = await this.dataService.ExtractP7M(model);
    return response;
  }

  async GetPreview(model: FileModel) {
    const response = await this.dataService.GetPreview(model);
    return response;
  }
  async GetPreviewFile(model: FileModel) {
    const response = await this.dataService.GetPreviewFile(model);
    return response;
  }
  async CheckEncryptionPassword(model: EncryptionModel) {
    const response = await this.dataService.CheckEncryptionPassword(model);
    return response;
  }
}
