import { Component, Input, OnInit } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_FORMATS } from '../../../../../../doc.configuration';
import { Column } from '../../Form';
import { MetadataDto } from '../../../../../../models/MetadataModel';
import { PropertyDto } from '../../../../../../models/FieldsModel';
import { NavigatorService } from '../../../../../../../navigator.services';

@Component({
  selector: 'te',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class FormDateComponent {
  @Input() Properties: PropertyDto;
  @Input() Data: any;

  constructor(public navigatorService: NavigatorService) {}

  public DeleteComponent() {
    const component: MetadataDto = this.Data.item;
    const column: Column = this.Data.column;
    column.RemoveComponent(component);
  }
}
