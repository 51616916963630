<div class="component-container">
  <span class="material-icons icon-drag icon-align-center" cdkDragHandle>
    drag_indicator
  </span>
  <mat-form-field class="form-field" appearance="fill">
    <mat-label>{{ Properties?.Placeholder }}</mat-label>
    <mat-select [required]="Properties?.Required" [multiple]="Properties?.Multiple">
      <mat-option *ngFor="let dto of Properties?.Items" [value]="dto?.Id">
        {{ dto?.Option }}
      </mat-option>
    </mat-select>
    <img matSuffix src="../../../../../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon"
      alt="Open select" />
  </mat-form-field>
  <span (click)="DeleteComponent()" class="material-icons icon icon-align-center">
    delete
  </span>
</div>
<div class="toggle-container">
  <mat-slide-toggle [(ngModel)]="Properties.Required" labelPosition="before" class="required-toggle">
    {{ navigatorService?.Dictionary?.Required }}
  </mat-slide-toggle>
</div>