import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../../../data.service';
import {
  OrganizationUnitDto,
  OrganizationUnitFilter,
  OrganizationUnitModel,
} from '../../../models/OrganizationUnitModel';
import { IDialogComponent } from '../../../Interface/doc.dialog.interface';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import {
  AccountDto,
  AccountFilter,
  AccountModel,
} from '../../../models/AccountModel';
import { UserModel } from '../../../../models/UserModel';
import { RoleModel } from '../../../models/RoleModel';
import { AccountController } from '../../../controllers/AccountController';
import { ItCoreController } from '../../../../controllers/ItCoreController';
import { RoleController } from '../../../controllers/RoleController';
import { AccountRoleController } from '../../../controllers/AccountRoleController';
import { AutocompleteFilterUiComponent } from '../../../custom-components/autocomplete-ui/autocomplete-ui.component';
import { NotificationController } from '../../../../controllers/NotificationController';
import { Constants, PageAction } from '../../../doc.configuration';
import { NavigatorService } from '../../../../navigator.services';
import {
  AccountRoleFilter,
  AccountRoleModel,
} from '../../../models/AccountRoleModel';

@Component({
  selector: 'app-organization-unit',
  templateUrl: './organization-unit.component.html',
  styleUrls: ['./organization-unit.component.scss'],
})
export class OrganizationUnitComponent
  implements
    OnInit,
    IDialogComponent<OrganizationUnitModel, OrganizationUnitController>
{
  Step = 1;
  Steps = 3;
  Model: OrganizationUnitModel;
  AccountModel: AccountModel;
  UserModel: UserModel;
  RoleModel: RoleModel;
  AccountDto: AccountDto;
  Controller: OrganizationUnitController;
  AccountController: AccountController;
  ItCoreController: ItCoreController;
  RoleController: RoleController;
  DataSource: MatTableDataSource<AccountDto>;
  AccountsToDelete: Array<AccountDto>;
  Pages: Array<number>;
  CurrentPage = 1;
  HierarchyModel: OrganizationUnitModel;
  UserLang: string;
  AccountRoleController: AccountRoleController;
  NotificationController: NotificationController;
  @ViewChild('autocompleteUser')
  AutoCompleteUser: AutocompleteFilterUiComponent;
  ND = Constants.ND.toString();
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<OrganizationUnitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.UserLang = sessionStorage.getItem('user_language');
    this.Model = new OrganizationUnitModel();
    this.Model.Dto = new OrganizationUnitDto();
    this.AccountModel = new AccountModel();
    this.HierarchyModel = new OrganizationUnitModel();

    this.UserModel = new UserModel();
    this.RoleModel = new RoleModel();
    this.AccountDto = new AccountDto();
    this.AccountsToDelete = new Array<AccountDto>();

    this.Controller = new OrganizationUnitController(dataService);
    this.ItCoreController = new ItCoreController(dataService);
    this.AccountController = new AccountController(dataService);
    this.RoleController = new RoleController(dataService);
    this.AccountRoleController = new AccountRoleController(dataService);
    this.NotificationController = new NotificationController(dataService);
    this.Pages = new Array<number>();
    this.Pages.push(1);
  }

  ngOnInit(): void {
    if (this.data?.Action === 'ADDUSER') {
      this.Step = 2;
    }
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.HierarchyModel = await this.Controller.GetHierarchy(
      this.HierarchyModel
    );
    if (this.navigatorService.PageAction === PageAction.Edit) {
      this.Model.Filter = new OrganizationUnitFilter();
      this.Model.Filter.Id = this.data.UoId;
      this.AccountModel.Filter = new AccountFilter();
      this.AccountModel.Filter.UoId = this.data.UoId;

      this.Model = await this.Controller.Read(this.Model);
      if (this.Model && this.Model.Performed) {
        this.Model.Dto.Parent = this.Model.Dto.Parent;
        this.Model.Dto = this.Model.Dto;
        this.AccountModel = await this.AccountController.Get(this.AccountModel);
        if (this.AccountModel.Performed) {
          this.Paging();
        } else {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.MessageGenericError
          );
        }
      }
    }

    this.Model.Filter = new OrganizationUnitFilter();
    this.Model = await this.Controller.Get(this.Model);
    this.SetParentName();
    this.RoleModel = await this.RoleController.Get(this.RoleModel);
    this.navigatorService.StopLoading();
  }

  SelectUo($event: OrganizationUnitDto) {
    this.Model.Dto.Parent = $event ? $event.Id : null;
    this.SetParentName();
  }

  SetParentName() {
    if (this.Model.Dto.Parent && this.Model.Dto.Parent > 0) {
      const uo = this.Model.Dtos.find((x) => x.Id === this.Model.Dto.Parent);
      if (uo) {
        this.Model.Dto.ParentName = uo.Name;
      }
    }
  }

  SelectRole($event, dto: AccountDto) {
    dto.RoleId = $event;
    const roleName = this.RoleModel?.Dtos?.find(
      (r) => r.Id === dto.RoleId
    )?.Name;
    dto.RoleName = roleName ? roleName : '';
  }

  RemoveUser(account: AccountDto) {
    if (account) {
      const index = this.AccountModel.Dtos.findIndex(
        (x) => x.Username === account.Username
      );
      if (index >= 0) {
        const userdelete = this.AccountModel.Dtos.splice(index, 1);
        if (userdelete && userdelete.length > 0) {
          this.AccountModel.Count -= 1;
          if (account.Id > 0) {
            this.AccountsToDelete.push(userdelete[0]);
          }
        }
        this.Paging(this.CurrentPage);
      }
    }
  }

  async SearchUser($event) {
    const search = $event.Search;
    if (search) {
      this.UserModel.Search = encodeURI(search.trim());
      this.UserModel.Dtos = [];
      const response = await this.ItCoreController.SearchUserDoc(
        this.UserModel
      );
      if ($event.Callback) {
        $event.Callback(response.Dtos, $event.Istance);
      }
    }
  }

  LdapUsersChange($event, dto: AccountDto) {
    if ($event) {
      dto.DisplayName = $event.Displayname;
      dto.Username = $event.Username;
      dto.Avatar = $event.Avatar;
      this.AddUser();
    }
  }

  AddUser() {
    if (this.AccountDto && this.AccountDto.Username) {
      const search = this.AccountModel.Dtos.find(
        (x) => x.Username === this.AccountDto.Username
      );
      if (!search) {
        this.AccountModel.Dtos.push(this.AccountDto);
        this.AccountDto = new AccountDto();
        this.AccountModel.Count += 1;
        this.Paging(this.Pages.length);
        this.AutoCompleteUser.Clear();
      }
    }
  }

  Paging(page: number = 1) {
    let dtos = new Array<AccountDto>();
    if (this.AccountModel.Count > 0) {
      const totalPages = Math.ceil(
        this.AccountModel.Count / this.AccountModel.Take
      );
      this.Pages = new Array<number>();
      for (let i = 0; i < totalPages; i++) {
        this.Pages.push(i + 1);
      }
      if (page > this.Pages.length) {
        page = this.Pages.length;
      }
      this.CurrentPage = page;

      this.AccountModel.Skip = this.AccountModel.Take * (page - 1);
      dtos = this.AccountModel.Dtos.slice(
        this.AccountModel.Skip,
        this.AccountModel.Skip + this.AccountModel.Take
      );
    }
    this.RefreshTable(dtos);
  }

  PagingBack() {
    const page = this.CurrentPage - 1 > 0 ? this.CurrentPage - 1 : 1;
    this.Paging(page);
  }

  PagingNext() {
    const page =
      this.CurrentPage + 1 <= this.Pages.length
        ? this.CurrentPage + 1
        : this.Pages.length;
    this.Paging(page);
  }

  RefreshTable(dtos: Array<AccountDto>) {
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<AccountDto>(dtos);
  }

  async Save() {
    this.navigatorService.StartLoading();
    this.Model.Performed = false;
    const performed = await this.DeleteAccounts();
    if (performed) {
      this.Model.Dto.FolderName =
        !this.Model.Dto.FolderName || this.Model.Code === 409
          ? this.Model.Dto.Name.replace(/[^a-z0-9]/gi, '_').toUpperCase()
          : this.Model.Dto.FolderName;
      this.Model.Code = null;
      this.Model = await this.Controller.CreateOrUpdate(this.Model);
      if (this.Model && this.Model.Performed && this.Model.Code !== 409) {
        this.AccountModel.Dtos.map((x) => (x.UoId = this.Model.Dto.Id));
        this.AccountModel.Performed = false;
        await this.AccountController.CreateOrUpdate(this.AccountModel);
        if (this.navigatorService.PageAction === PageAction.New) {
          await this.NotificationController.NewUo(this.Model.Dto.Id);
        }
        const message =
          this.navigatorService.PageAction === PageAction.New
            ? this.navigatorService.Dictionary?.ToCreateSuccessF
            : this.navigatorService.Dictionary?.ToEditSuccessF;
        this.navigatorService.ShowSnackBar(message, this.Model.Dto?.Name);
        this.dialogRef.close({
          Performed: true,
          UO: this.Model.Entity,
          Accounts: this.AccountModel.Dtos,
        });
      } else if (
        this.Model &&
        this.Model.Performed &&
        this.Model.Code === 409
      ) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.UoExist,
          this.Model.Dto?.Name
        );
      }
    }

    if (!this.Model.Performed) {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.MessageGenericError
      );
    }
    this.navigatorService.StopLoading();
  }

  async DeleteAccounts() {
    if (this.AccountsToDelete && this.AccountsToDelete.length > 0) {
      let accountRoleModel = new AccountRoleModel();
      accountRoleModel.Filter = new AccountRoleFilter();
      accountRoleModel.Filter.Id = this.AccountsToDelete.map(
        (x) => x.AccountRoleId
      );
      accountRoleModel = await this.AccountRoleController.Delete(
        accountRoleModel
      );
    }
    return true;
  }

  Next() {
    const isValid = this.ValidateForm(this.Step);
    if (isValid) {
      this.Step += 1;
    }
  }

  Back() {
    this.Step -= 1;
    if (this.Step <= 0) {
      this.Step = 0;
      this.dialogRef.close(null);
    }
  }

  ValidateForm(step: number, showMessage = true): boolean {
    if (step === 1) {
      if (
        this.Model.Dto.Name === null ||
        this.Model.Dto.Description === null ||
        this.Model.Dto.OfficeCode === null
      ) {
        if (showMessage) {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.ValidatorMessage
          );
        }
        return false;
      }
    } else if (step === 2) {
      if (this.AccountModel.Dtos?.length > 0) {
        const roles = this.AccountModel.Dtos.find((x) => x.RoleId === null);
        const admins = this.AccountModel.Dtos.find(
          (x) =>
            x.RoleId === this.RoleModel.Dtos.find((x) => x.Name === 'Admin')?.Id
        );
        if (roles) {
          if (showMessage) {
            this.navigatorService.ShowSnackBar(
              this.navigatorService.Dictionary?.ValidatorMessage
            );
          }
          return false;
        } else if (!admins) {
          if (showMessage) {
            this.navigatorService.ShowSnackBar(
              this.navigatorService.Dictionary?.ValidatorMessage
            );
          }
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  RemoveAccounts() {
    for (const account of this.AccountModel.Dtos) {
      this.AccountModel.Count -= 1;
      if (account.Id > 0) {
        const accountToDelete = this.AccountsToDelete.find(
          (x) => x === accountToDelete
        );
        if (!accountToDelete) {
          this.AccountsToDelete.push(account);
        }
      }
    }
    this.AccountModel.Dtos = new Array<AccountDto>();
    this.Paging();
  }

  async GetUO(items: Array<OrganizationUnitDto>) {
    if (items && items.length > 0) {
      const uos = this.ClearChildsUo(items);
      const ids = this.FilterUo(uos);
      this.Model.Dtos = this.Model.Dtos.filter((x) => ids.includes(x.Id));
    }
    return [];
  }

  private ClearChildsUo(items: Array<OrganizationUnitDto>) {
    for (const dto of items) {
      if (this.Model.Dto.Id === dto.Id) {
        dto.Childs = [];
        return items;
      } else {
        if (dto.Childs.length > 0) {
          this.ClearChildsUo(dto.Childs);
        }
      }
    }
    return items;
  }

  private FilterUo(items: Array<OrganizationUnitDto>, ids: Array<number> = []) {
    for (const dto of items) {
      ids.push(dto.Id);
      if (dto.Childs.length > 0) {
        this.FilterUo(dto.Childs, ids);
      }
    }
    return ids;
  }
}
