import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DateFormat, DATE_FORMATS2 } from '../../doc.configuration';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
@Component({
  selector: 'app-rangepicker',
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.scss'],
})
export class RangepickerComponent implements OnInit {
  private dataValue: any = null;
  get DataValue() {
    return this.dataValue;
  }

  @Input() set DataValue(value: any) {
    if (value && value[this.Property]) {
      this.BindView(value);
    }
    this.dataValue = value;
  }

  @Input() Required = false;
  @Input() Property: string;
  @Input() Placeholder = '';
  @Input() Minimize = false;
  @Input() Type = 'date';

  public Start;
  public End;
  FormatDate = DateFormat.ddMMyyyy;
  @ViewChild('calendaricon') calendaricon: ElementRef;
  constructor(
    @Inject(MAT_DATE_FORMATS) public data: any,
    public datepipe: DatePipe
  ) {
    this.Start = null;
    this.End = null;
  }
  Settings: boolean;

  ngOnInit() {
    if (this.Type === 'datetime') {
      this.FormatDate = DateFormat.ddMMyyyyHHmm;
      this.data.display = DATE_FORMATS2.display;
      this.data.parse = DATE_FORMATS2.parse;
    }
  }

  Change(event, type) {
    if (type === 'start') {
      this.Start = event.value;
    } else {
      this.End = event.value;
    }
    if (this.Start && this.End) {
      this.BindModel();
    }
  }

  BindView(dto: any) {
    if (dto && dto[this.Property]) {
      const splits = dto[this.Property].split('-');
      if (splits.length === 2) {
        const dal = this.GetDate(splits[0].trim());
        const al = this.GetDate(splits[1].trim());
        this.Start = dal;
        this.End = al;
      }
    }
  }

  BindModel() {
    if (this.Start && this.End) {
      const dal = this.datepipe.transform(this.Start, this.FormatDate);
      const al = this.datepipe.transform(this.End, this.FormatDate);
      this.dataValue[this.Property] = dal + ' - ' + al;
    } else {
      this.dataValue[this.Property] = null;
    }
  }

  GetDate(value: string): Date {
    const splits = value.split(' ');
    const date = splits[0].split('/');
    const dateString = date[2].substring(0, 4) + '/' + date[1] + '/' + date[0];
    const dataValue = new Date(dateString);
    return dataValue;
  }

  GetDisplayValue() {
    if (this.dataValue && this.dataValue[this.Property]) {
      const splits = this.dataValue[this.Property].split('-');
      if (splits.length == 2) {
        return this.End;
      } else {
        return this.Start;
      }
    }
    return null;
  }

  Open(picker) {
    picker.open();
  }
}
