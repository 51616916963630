import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DictionaryInterface } from './dictionary/dictionary.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from './documentary/custom-components/snackbar/snackbar.component';
import { PageAction } from './documentary/doc.configuration';
import { FileDto } from './documentary/models/FileModel';
import { ObjectDto } from './documentary/models/ObjectModel';
import { SystemInfoModel } from './documentary/models/SystemInfoModel';

export interface IBreadCrumb {
  Label: string;
  Url: string;
  Visible: boolean;
}

@Injectable()
export class NavigatorService {
  LoadComplete: boolean;

  Token: string;
  CurrentPage = null;
  PageAction: PageAction;
  ObjectDto: ObjectDto;
  SystemInfoModel: SystemInfoModel;
  SignFile: FileDto;
  NotificationTargetId: number;

  private LoadingTimer = null;
  public Dictionary: DictionaryInterface;
  public Loading = false;
  AppConfig;
  public DashboardMenu: any;
  public DocMenu: Array<any>;
  public SettingsMenu: any;
  public UserProfileMenu: any;
  NotificationTargetDossierId: number;
  static Dictionary: any;
  constructor(
    public router: Router,
    private location: Location,
    public activatedRoute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.LoadComplete = false;
    this.Token = null;
  }

  public GoTo(
    link: string,
    extras?: NavigationExtras,
    container: string = 'v1/',
    blank: boolean = false
  ) {
    if (!blank) {
      this.router.navigate([container + link], extras);
    } else {
      let querystring = '';
      if (extras) {
        const properties = Object.getOwnPropertyNames(extras.queryParams);
        for (const property of properties) {
          querystring += property + '=' + extras.queryParams[property] + '&';
        }
        querystring = querystring.substring(0, querystring?.length - 1);
      }
      window.open(
        container + link + (extras ? '?' + querystring : ''),
        '_blank'
      );
    }
  }

  public GoToExternal(link: string, extras?: NavigationExtras) {
    this.router.navigate([link], extras);
  }

  public GoBack() {
    this.location.back();
  }

  public StartLoading(millisends = 180000) {
    this.spinner.show();
    this.Loading = true;
    this.LoadingTimer = setTimeout(() => {
      if (this.Loading) {
        this.StopLoading();
      }
    }, millisends);
  }

  public StopLoading() {
    this.spinner.hide();
    this.Loading = false;
    clearTimeout(this.LoadingTimer);
  }

  ShowDialog(
    component,
    data,
    width: string,
    height: string,
    minHeight: string = '200px',
    callback = null,
    minWidth: string = '700px',
    cssCalss: string = null
  ) {
    const dialogRef = this.dialog.open(component, {
      width,
      minWidth,
      height,
      minHeight,
      disableClose: true,
      panelClass: cssCalss,
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (callback) {
        callback(result);
      }
    });
  }

  ShowSnackBar(message: string, objectName: string = null) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { Message: message, ObjectName: objectName },
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  private TimerDelay: any = null;
  async Delay(ms: number) {
    return new Promise(
      (resolve) => (this.TimerDelay = setTimeout(resolve, ms))
    );
  }

  OpenOnlyOffice(id: number, name: string) {
    const navigate = this.router.serializeUrl(
      this.router.createUrlTree([
        `/onlyoffice/${'id=' + id + '&name=' + name + '&shareToken='}`,
      ])
    );
    window.open(navigate, '_blank');
  }

  public GetQueryParameter(parameter: string): string {
    if (this.activatedRoute != null) {
      const snapshot = this.activatedRoute.snapshot;
      if (snapshot != null) {
        const queryParameters = snapshot.queryParamMap;
        if (queryParameters != null) {
          const value = queryParameters.get(parameter);
          return value;
        }
      }
    }
    return null;
  }

  IsRefresh(): boolean {
    const urlFragments = window.location.pathname.split('/');
    if (urlFragments && urlFragments?.length > 2) {
      return true;
    }
    return false;
  }

  Decode(value: string): string {
    return decodeURIComponent(escape(value));
  }

  async Wait() {
    if (!this.LoadComplete || !this.Dictionary) {
      await this.Delay(100);
      await this.Wait();
    }
  }
}
