<div class="itdoc-page">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <!-- <h2 *ngIf="navigatorService.PageAction === 'New' && IsScanDocument()">
                  {{navigatorService.Dictionary?.NewDocumentPdf}}</h2> -->
        <h2 *ngIf="navigatorService.PageAction === 'New'">
          {{ navigatorService.Dictionary?.NewDocument }}
        </h2>
        <h2 *ngIf="navigatorService.PageAction === 'Edit'">
          {{ navigatorService.Dictionary?.EditDocument }}
        </h2>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div>
    <p class="title-description">
      {{ navigatorService.Dictionary?.NewDocumentDescription }}
    </p>

    <div class="form mb-5" style="min-height: 200px">
      <div
        *ngIf="!UploadComplete"
        class="upload-file-container"
        (click)="multiUploadFile.click()"
        appDragDrop
        (FileDropped)="SelectFiles($event)"
      >
        <input
          #multiUploadFile
          multiple
          (change)="SelectFiles($event.target.files)"
          type="file"
          class="d-none"
          accept="video/*|image/*|application/*|text/*"
          [disabled]="this.UploadInProgress"
        />
        <img src="../../../../assets/icon/note-2.svg" />
        <div class="drag">
          {{ navigatorService.Dictionary?.DragAttachments }}
        </div>
      </div>

      <div
        *ngIf="FileModel?.Dtos?.length > 0"
        class="it-doc-table-container max-heigth-100-overflow tiny-scrollbar"
      >
        <table mat-table [dataSource]="DataSourceFiles" class="custom-table">
          <ng-container matColumnDef="Name">
            <td mat-cell *matCellDef="let element" class="name-column">
              <div class="file-name">
                <div
                  class="d-flex align-items-center file-row"
                  (click)="OpenObject(element); $event.stopPropagation()"
                  title="{{
                    element.Name +
                    (element.Type === 'file'
                    ? '.' + commonService.GetExtension(element.PhysicalName)
                    : '')
                  }}"
                >
                  <span class="icon-text-right file-icon">
                    <img src="{{ element.Icon }}" class="file-img" />
                  </span>
                  <div class="file-details">
                    <div class="ellipsis-text">
                      {{ element.Name }}
                    </div>
                    <!-- <span class="file-size">
                      {{ element.Size }}
                    </span> -->
                  </div>
                </div>

              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Progressbar">
            <td mat-cell *matCellDef="let element" class="progress-column" style="width: 200px; font-size: 12px">
                <div class="row">
                  <div class="col-9 progress-container">
                    <mat-progress-bar
                      *ngIf="element.Performed"
                      mode="determinate"
                      value="{{element.Progress}}"
                      class="progress-bar"
                    >
                    </mat-progress-bar>
                    <p *ngIf="!element.Performed" class="error-message">
                      {{ element.Message }}
                    </p>
                  </div>
                  <div class="col-3 progress-container percentage" *ngIf="element.Performed">
                    <span>{{ element.Progress }}%</span>
                  </div>

                </div>
            </td>
          </ng-container>



          <ng-container matColumnDef="Close">
            <td
              mat-cell
              *matCellDef="let element"
              style="width: 50px; font-size: 12px; text-overflow: clip"
            >
              <button mat-icon-button (click)="DeleteFile(element)"  *ngIf="element.Progress === 0">
                <img
                  class="clickable-icon icon-delete"
                  src="../../../../../assets/icon/delete.svg"
                />
              </button>
            </td>
          </ng-container>

          <tr
            mat-row
            [ngClass]="{ selected: row.Selected }"
            *matRowDef="let row; columns: ['Name', 'Progressbar', 'Close']"
            style="position: relative"
          ></tr>
        </table>
      </div>
      <!-- <div
        *ngIf="!navigatorService.Loading && Model?.Dtos?.length > 0"
        class="it-doc-table-container max-heigth-overflow tiny-scrollbar"
      >
        <table mat-table [dataSource]="DataSource">
          <ng-container matColumnDef="Name">
            <td mat-cell *matCellDef="let element">
              <span
                (click)="OpenObject(element); $event.stopPropagation()"
                [ngClass]="!UploadInProgress ? 'clickable-icon' : ''"
                title="{{
                  element.Name +
                    '.' +
                    commonService.GetExtension(element.PhysicalName)
                }}"
              >
                <div class="d-flex">
                  <span class="icon-text-right">
                    <img src="{{ element.Icon }}" />
                  </span>
                  <div class="ellipsis-text">
                    {{
                      element.Name +
                        "." +
                        commonService.GetExtension(element.PhysicalName)
                    }}
                  </div>
                </div>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Message">
            <td
              mat-cell
              *matCellDef="let element"
              style="width: 200px; font-size: 12px"
            >
              <div class="row">
                <div class="col-12"></div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Close">
            <td
              mat-cell
              *matCellDef="let element"
              style="width: 200px; font-size: 12px; text-align: end"
            >
              <button mat-icon-button (click)="DownloadFile(element)">
                <img
                  class="clickable-icon"
                  src="../../../../../assets/icon/download.svg"
                />
              </button>
              <button
                *ngIf="EnableUpload"
                mat-icon-button
                (click)="Delete(element)"
                [disabled]="element.Selected"
              >
                <span class="material-icons icon-close"> close </span>
              </button>
            </td>
          </ng-container>

          <tr
            mat-row
            [ngClass]="{ selected: row.Selected }"
            *matRowDef="let row; columns: ['Name', 'Message', 'Close']"
            style="position: relative"
          ></tr>
        </table>
      </div> -->
    </div>
  </div>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button
      class="it-doc-btn-cancel btn-doc"
      style="margin-inline-end: auto"
      [mat-dialog-close]="null"
    >
      <span class="button-text">{{ navigatorService.Dictionary?.Cancel }}</span>
    </button>
    <button
      *ngIf="
        FileModel?.Dtos?.length > 0 && !UploadInProgress && !UploadComplete
      "
      (click)="Save()"
      mat-button
      class="it-doc-btn-primary"
      [disabled]="UploadInProgress && EnableUpload"
    >
      <span class="button-text">{{ navigatorService.Dictionary?.Upload }}</span>
    </button>
    <button
      *ngIf="UploadComplete"
      mat-button
      class="it-doc-btn-primary"
      (click)="Close()"
      [disabled]="UploadInProgress"
    >
      <span class="button-text">{{ navigatorService.Dictionary?.Close }}</span>
    </button>
  </div>
</div>

<input type="file" class="d-none" accept="*.*" #uploadFile />
<input type="file" class="d-none" accept="*" multiple #multiUploadFile />
