import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignatureServiceModel } from '../../../models/SignatureService';
import {
  SignatureAccountDto,
  SignatureAccountModel,
} from '../../../models/SignatureAccount';
import { SettingsController } from '../../../controllers/SettingsController';
import { CommonService } from '../../../common.service';
import { NavigatorService } from '../../../navigator.services';
import { DataService } from '../../../data.service';
import { AuthService } from '../../../auth.service';
import { PageAction } from '../../../documentary/doc.configuration';

@Component({
  selector: 'app-signature-account',
  templateUrl: './signature-account.component.html',
  styleUrls: ['./signature-account.component.scss'],
})
export class SignatureAccountComponent implements OnInit {
  @ViewChild('uploadFile') fileUpload: ElementRef;
  ServiceModel: SignatureServiceModel;
  Model: SignatureAccountModel;
  SettingsController: SettingsController;
  Hide = true;
  Image: string;
  constructor(
    public navigatorService: NavigatorService,
    public commonService: CommonService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<SignatureAccountComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Model = new SignatureAccountModel();
    this.Model.Dto = data;
    this.ServiceModel = new SignatureServiceModel();
    this.SettingsController = new SettingsController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }
  async Load() {
    this.Model.Dto = this.Model.Dto
      ? this.Model.Dto
      : new SignatureAccountDto();
    this.Model.Dto.UserId = this.authService.CurrentUser.Id;
    this.ServiceModel = await this.SettingsController.GetSignatureService(
      this.ServiceModel
    );
    this.Model.Dto.ServiceId =
      this.Model.Dto.ServiceId ??
      (this.ServiceModel?.Dtos?.length > 0
        ? this.ServiceModel?.Dtos[0].Id
        : null);
    this.Model.Dto.Signature = this.authService.CurrentUser.Displayname;
  }

  async Save() {
    if (this.ValidateForm()) {
      this.navigatorService.StartLoading();
      this.Model = await this.SettingsController.CreateOrUpdateSignatureAccount(
        this.Model
      );
      if (this.Model.Performed) {
        if (this.navigatorService.PageAction === PageAction.Edit) {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.ToEditSuccess,
            this.Model.Entity?.Identifier
          );
        } else {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.ToCreateSuccess,
            this.Model.Entity?.Identifier
          );
        }
        this.dialogRef.close(this.Model?.Entity?.Id);
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
      this.navigatorService.StopLoading();
    }
  }

  SignatureServiceChange(event) {
    if (event) {
      this.Model.Dto.ServiceId = event.value;
    }
  }

  ValidateForm() {
    const regex=/[a-zA-Z0-9.-_]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,10}$/;
    
    return (
      this.Model?.Dto?.ServiceId &&
      this.Model?.Dto?.Identifier &&
      this.Model?.Dto?.Username &&
      this.Model?.Dto?.Password &&
      this.Model?.Dto?.Email &&  regex.test(this.Model?.Dto?.Email)
    );
  }

  FileSelected() {
    const fileUpload = this.fileUpload.nativeElement;
    const self = this;

    fileUpload.onchange = () => {
      if (fileUpload.files?.length > 0) {
        const file = fileUpload.files[0];
        const isValid = file.type.includes('image');
        if (isValid) {
          this.commonService.BlobToBase64(file, async (result) => {
            this.Model.Dto.RealSignature = result;
          });
        } else {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.InvalidFormat
          );
        }
      }
    };
    fileUpload.click();
  }

  async FileDelete() {
    this.Image = this.Model.Dto.RealSignature;
    this.Model.Dto.RealSignature = null;
  }

  Close() {
    this.Model.Dto.RealSignature = this.Image
      ? this.Image
      : this.Model.Dto.RealSignature;
    this.dialogRef.close(null);
  }
}
