import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../data.service';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { TipologyComponent } from '../../dialog-menu/tipology/tipology.component';
import { MatTableDataSource } from '@angular/material/table';
import { TipologyDetailComponent } from './tipology-detail/tipology-detail.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import {
  TipologyDto,
  TipologyFilter,
  TipologyModel,
} from '../../../models/TipologyModel';
import { TipologyController } from '../../../controllers/TipologyController';
import { MetadataTipologyModel } from '../../../models/MetadataTipologyModel';
import { MetaDataTipologyController } from '../../../controllers/MetaDataTipologyController';
import { MimeTypeModel } from '../../../models/MimeTypeModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { MimeTypeController } from '../../../controllers/MimeTypeController';
import { TipologyUoController } from '../../../controllers/TipologyUoController';
import { TemplateController } from '../../../controllers/TemplateController';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { GenericDialogAction, PageAction } from '../../../doc.configuration';
import { GenericDialogModel } from '../../../models/GenericDialogModel';

@Component({
  selector: 'app-tipology-view',
  templateUrl: './tipology-view.component.html',
  styleUrls: ['./tipology-view.component.scss'],
})
export class TipologyViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  @ViewChild('tipologydetail') TipologyDetail: TipologyDetailComponent;

  Model: TipologyModel;
  Controller: TipologyController;
  MetaDataTipologyModel: MetadataTipologyModel;
  MetaDataTipologyController: MetaDataTipologyController;
  MimeTypeModel: MimeTypeModel;
  MimeTypeController: MimeTypeController;
  Mimetypes: string;
  ObjectController: ObjectController;
  TipologyUoController: TipologyUoController;
  TemplateController: TemplateController;
  ShowTipologyDetail = false;
  TipologyDtoDetail: TipologyDto;

  DataSource: MatTableDataSource<TipologyDto>;
  Pages: Array<number>;
  CurrentPage = 1;

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authService: AuthService,
    commonService: CommonService
  ) {
    commonService.CurrentPageComponent = this;
    this.Model = new TipologyModel();
    this.Controller = new TipologyController(dataService);
    this.MetaDataTipologyModel = new MetadataTipologyModel();
    this.MetaDataTipologyController = new MetaDataTipologyController(
      dataService
    );
    this.MimeTypeModel = new MimeTypeModel();
    this.MimeTypeController = new MimeTypeController(dataService);
    this.ObjectController = new ObjectController(dataService);
    this.TipologyUoController = new TipologyUoController(dataService);
    this.TemplateController = new TemplateController(dataService);
    this.TipologyDtoDetail = new TipologyDto();
  }

  ngOnInit() {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    if (this.navigatorService.NotificationTargetId > 0) {
      const targetId = this.navigatorService.NotificationTargetId;
      this.navigatorService.NotificationTargetId = null;
      this.MimeTypeModel = await this.MimeTypeController.Get(
        this.MimeTypeModel
      );
      await this.ShowTargetNotification(targetId);
    } else {
      this.SearchComponent?.ClearInputValue(false);
      await this.GetTipologies();
      if (this.Model.Performed) {
        this.MimeTypeModel = await this.MimeTypeController.Get(
          this.MimeTypeModel
        );
      }
    }
    this.navigatorService.StopLoading();
  }

  async ShowTargetNotification(targetId: number) {
    let model = new TipologyModel();
    model.Filter = new TipologyFilter();
    model.Filter.Id = targetId;
    model = await this.Controller.Read(model);
    if (model.Performed) {
      if (model.Dto) {
        await this.ToggleTipologyDetail(model.Dto, true);
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.TipologyNotFound,
          model.Dto?.Name
        );
      }
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.TipologyNotFound
      );
    }
  }

  async GetTipologies(page = 1) {
    this.Model.Search = this.SearchComponent?.GetSearchValue();
    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Name';
    this.Model.Order.Direction = 'asc';
    this.Model = await this.Controller.Load(this.Model);
    this.Paging(page);
  }

  Create() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(
      TipologyComponent,
      null,
      '45%',
      'fit-content',
      '200px',
      async (result) => {
        if (result) {
          this.navigatorService.StartLoading();
          this.Model.Skip = 0;
          await this.GetTipologies();
          this.navigatorService.StopLoading();
        }
      }
    );
  }

  Update(dto: TipologyDto) {
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(
      TipologyComponent,
      dto.Id,
      '45%',
      'fit-content',
      '200px',
      async (result) => {
        if (result) {
          this.navigatorService.StartLoading();
          await this.GetTipologies(this.CurrentPage);
          this.navigatorService.StopLoading();
        }
      }
    );
  }

  async DeleteTipology(dto: TipologyDto) {
    if (dto) {
      const data = new GenericDialogModel();
      data.Name = dto?.Name;
      data.Icon = '../../../../../assets/icon/tipology-black.svg';
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToDelete +
        '<br>' +
        dto.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new TipologyModel();
            model.Filter = new TipologyFilter();
            model.Filter.Id = dto.Id;
            model = await this.Controller.Delete(model);
            if (model?.Performed) {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.DeleteSuccess,
                data.Name
              );
              await this.GetTipologies(this.CurrentPage);
            } else {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.MessageGenericError
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    }
  }

  GetDisplayTypes(dto: TipologyDto) {
    if (dto) {
      const mimetipesArray = dto?.MimeTypes.split(',');
      return this.MimeTypeModel.Dtos.filter((x) =>
        mimetipesArray?.includes(x.MimeType)
      )?.map((e) => e.Extension?.replace(/./, ''));
    }
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    await this.GetTipologies();
    this.navigatorService.StopLoading();
  }

  OpenFiltersDialog(value: boolean) {}

  async ToggleTipologyDetail(
    dto?: TipologyDto,
    redirectNotification: boolean = false
  ) {
    if (this.ShowTipologyDetail && !redirectNotification) {
      this.navigatorService.StartLoading();
      this.ShowTipologyDetail = !this.ShowTipologyDetail;
      await this.GetTipologies(this.CurrentPage);
      this.navigatorService.StopLoading();
    } else if (this.ShowTipologyDetail && redirectNotification) {
      this.TipologyDtoDetail = dto;
      this.ShowTipologyDetail = dto != null;
      await this.TipologyDetail.Refresh(dto);
    } else {
      this.TipologyDtoDetail = dto;
      this.ShowTipologyDetail = dto != null;
    }
  }

  // #region Paginazione
  RefreshTable(dtos: Array<TipologyDto>) {
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<TipologyDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.CurrentPage = page;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    this.CurrentPage = page;
    await this.GetTipologies(page);
    this.navigatorService.StopLoading();
  }
  // #endregion Paginazione
}
