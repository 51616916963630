import { Component, HostListener, OnInit } from '@angular/core';
import { AppConfigService } from './app.config.service';
import { CommonService } from './common.service';
import { AppConfigController } from './controllers/AppConfigController';
import { DataService } from './data.service';
import { Constants } from './documentary/doc.configuration';
import {
  SettingsFilter,
  SettingsModel,
} from './documentary/models/SettingsModel';
import { WhiteLabelService } from './white-label.service';

export let browserRefresh = false;

export function setBrowserRefresh(value: boolean) {
  browserRefresh = value;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  AppConfigController: AppConfigController;
  SettingsModel: SettingsModel;
  constructor(
    private configAppService: AppConfigService,
    private commonService: CommonService,
    public whiteLabelService: WhiteLabelService,
    dataService: DataService
  ) {
    this.SettingsModel = new SettingsModel();
    this.AppConfigController = new AppConfigController(dataService);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeunload() {
    if (!this.commonService.IsRefresh()) {
      localStorage.clear();
    }
  }

  ngOnInit(): void {
    this.Inizialize();
  }

  async Inizialize() {
    this.SettingsModel.Filter = new SettingsFilter();
    this.SettingsModel.Filter.Appid = Constants.AppId;
    this.SettingsModel = await this.AppConfigController.Get(this.SettingsModel);
    if (this.SettingsModel.Performed && this.SettingsModel.Dtos) {
      this.whiteLabelService.Inizialize(this.SettingsModel);
    }
  }
}
