<mat-label class="slider-label">
  {{Placeholder }}
  <span *ngIf="Required" class="required">*</span>
</mat-label>

<div class="row-container">
  <mat-slider class="custom-slider" [min]="Min" [max]="Max" discrete>
    <input value="2" matSliderStartThumb>
    <input value="20" matSliderEndThumb>
  </mat-slider>
</div>
