<div class="profile-sidebar">

  <div class="header">
    <img *ngIf="authenticationService?.CurrentUser?.Avatar" class="profile-picture"
      [src]="'data:image/png;base64,' + authenticationService?.CurrentUser?.Avatar" alt="User Avatar" />
    <div class="close-button-container">
      <span class="material-icons icon-close" (click)="closeSidebar()">close</span>
    </div>
  </div>

  <div class="profile-content">
    <div class="user-status">
      <div class="user-status-top">
        <div class="username">{{ authenticationService?.CurrentUser?.Displayname || "-" }}</div>
        <span class="status online">Online</span>
      </div>
      <div class="user-info">
        <span class="usertag">{{ Tag }}</span>
        <div class="separator">|</div>
        <span class="role">{{ UserRole }}</span>
      </div>
    </div>

    <div class="contact-info">
      <div class="contact-container">
        <div><img title="mail" src="../../../../assets/icon/directbox-notif.svg" class="icon"> {{
          authenticationService?.CurrentUser?.Email || "-" }}</div>
        <div><img title="phone" src="../../../../assets/icon/call.svg" class="icon"> {{
          authenticationService?.CurrentUser?.Phone || "-" }}</div>
      </div>
    </div>

    <!-- <div class="company-info">
      <div><img title="calendar" src="../../../../assets/icon/directbox-notif.svg">  {{ authenticationService?.CurrentUser?.Company || "-" }}</div>
      <div><img title="calendar" src="../../../../assets/icon/directbox-notif.svg">  {{ authenticationService?.CurrentUser?.Location || "-" }}</div>
      <div><img title="calendar" src="../../../../assets/icon/directbox-notif.svg">  {{ authenticationService?.CurrentUser?.Location || "-" }}</div>
    </div> -->
  </div>

  <div class="profile-footer">
    <div class="menu-links">
      <div class="menu-item-container" (click)="GoToProfile()">
        <ng-container *ngIf="navigatorService?.UserProfileMenu?.length > 0">
          <img title="{{navigatorService.UserProfileMenu[0]?.Title}}"
            src="../../../../assets/icon/{{navigatorService.UserProfileMenu[0]?.Icon}}.svg" class="icon">
          <span>{{navigatorService.UserProfileMenu[0]?.Label}}</span>
        </ng-container>
      </div>
      <div class="menu-item-container" (click)="GoToSettings()">
        <img title="{{navigatorService?.SettingsMenu?.Title}}" src="../../../../assets/icon/{{navigatorService?.SettingsMenu?.Icon}}.svg" class="icon">
        <span>{{ navigatorService?.SettingsMenu?.Label }}</span>
      </div>
      <div class="menu-item-container" (click)="Logout()">
        <img title="logout" src="../../../../assets/icon/out.svg" class="icon">
        <span>{{ navigatorService.Dictionary?.Logout }}</span>
      </div>
    </div>
  </div>
