<div *ngIf="!navigatorService.Loading" id="filterMetadata" class="itdoc-page">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <div class="valign-icon-text">
          <img
            style="margin-right: 11px"
            src="../../../../../assets/icon/filter-icon.svg"
          />
          <h2>{{ navigatorService.Dictionary?.Filter }}</h2>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="reset-filters-btn pointer"
            (click)="InitializeFilters()"
            style="margin-right: 20px"
          >
            <span>{{ navigatorService.Dictionary?.ResetFilters }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <mat-dialog-content class="default-scrollbar">
    <div class="form">
      <div class="row mb-2">
        <div class="col-md-6 col-sm-12">
          <app-chips-multiselect
            #categorySelect
            [Elements]="MetadataCategoryModel?.Dtos"
            (ChipsSelectedEmit)="SelectCategory($event)"
            (ChipsDeletedEmit)="RemoveCategory($event)"
            [Chips]="ChipsMetadataCategory"
            [NameProperty]="'Name'"
            [Placeholder]="
              navigatorService.Dictionary?.SelectMetadataCategoryPlaceholder
            "
            [Required]="false"
          >
          </app-chips-multiselect>
        </div>
        <div class="col-md-6 col-sm-12">
          <app-chips-multiselect
            #fieldSelect
            [Elements]="FieldsModel?.Dtos"
            (ChipsSelectedEmit)="SelectField($event)"
            (ChipsDeletedEmit)="RemoveField($event)"
            [Chips]="ChipsField"
            [NameProperty]="'Name'"
            [Placeholder]="navigatorService.Dictionary?.SelectTypePlaceholder"
            [Required]="false"
          >
          </app-chips-multiselect>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 col-sm-12">
          <app-mt-autocomplete
            #autocompleteUser
            [appearance]="'fill'"
            [Items]="UserModel?.Dtos"
            [DisplayProperties]="['Displayname']"
            [Placeholder]="navigatorService.Dictionary?.Creator"
            [SearchProperties]="['Displayname']"
            [LoaderType]="'Spinner'"
            [DropDownMode]="false"
            [DataValue]="UserModel.Dto"
            (SelectionChange)="SelectUserChange($event, AccountDto)"
            [Multiple]="false"
            (OnChange)="SearchUser($event)"
            [OptionIcon]="null"
          ></app-mt-autocomplete>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button
      class="it-doc-btn-cancel"
      style="margin-inline-end: auto"
      [mat-dialog-close]="null"
    >
      <span class="button-text">{{ navigatorService.Dictionary?.Cancel }}</span>
    </button>
    <button
      (click)="ApplyFilters()"
      mat-button
      class="it-doc-btn-primary"
      cdkFocusInitial
    >
      <span class="button-text">{{ navigatorService.Dictionary?.Apply }}</span>
    </button>
  </div>
</div>
