<div class="itdoc-page ">
    <div class="row">
        <div class="col-md-11">
            <h2>{{navigatorService.Dictionary?.AddToDossier}}</h2>
            <p *ngIf="data?.length===1" class="title-description">
                {{navigatorService.Dictionary?.AddToDossierDescription}} <b>{{data[0].PhysicalName}}</b></p>
                <p *ngIf="data?.length>1" class="title-description">
                    {{navigatorService.Dictionary?.AddToDossierDescriptions}}</p>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>
    <div class="row form">
        <div class="col-md-12">
            <ng-container *ngIf="Model?.Dtos?.length > 0 && !navigatorService.Loading">
                <app-chips-multiselect #dossierMultiselect [Elements]="Model?.Dtos"
                    (ChipsSelectedEmit)="SelectDossier($event)" (ChipsDeletedEmit)="RemoveDossier($event)"
                    [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.DossierNamePlaceholder"
                    [Required]="true">
                </app-chips-multiselect>
            </ng-container>
            <ng-container *ngIf="Model?.Dtos?.length <= 0 && !navigatorService.Loading">
                <div class="m-0 empty-table-box" style="margin-top: 20px !important;">
                    <img src="../../../../../assets/icon/dossier-empty.svg">
                    <div class="description">{{navigatorService.Dictionary?.EmptyDossierMessage}}</div>
                </div>
            </ng-container>
        </div>
    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button (click)="Save()" mat-button class="it-doc-btn-primary" cdkFocusInitial
            [disabled]="!SelectedDossierIds || SelectedDossierIds?.length <= 0">
            <span class="button-text">{{navigatorService.Dictionary?.AddToDossier}}</span>
        </button>
    </div>
</div>
