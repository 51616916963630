<div class="search-container">
  <div class="input-container">
    <input #searchInput matInput class="form-control" placeholder="{{ Placeholder }}"
      (keydown.enter)="SearchEvent(searchInput.value)" (keyup)="InteractiveSearch(searchInput.value)"
      [(ngModel)]="searchValue" />
    <mat-icon *ngIf="searchValue" class="clear-icon" mat-icon-button matSuffix (click)="ClearInputValue()"
      [attr.aria-label]="'Clear'">close</mat-icon>
    <img class="search-icon" matSuffix src="../../../../assets/icon/search.svg"
      (click)="SearchEvent(searchInput.value)" />
  </div>
  <div *ngIf="HaveActions" class="btn-container">
    <div *ngIf="ActionsLabelBtn">{{ ActionsLabelBtn }}</div>
    <button mat-menu-item [disabled]="ItemsCount <= 0" class="p-0">
      <mat-icon class="material-icons btn-icon" [matMenuTriggerFor]="MoreAction">more_vert</mat-icon>
    </button>
    <mat-menu #MoreAction="matMenu">
      <button *ngFor="let item of TriggerMenuItems" mat-menu-item (click)="ItemActionsMenuClick(item)"
        [disabled]="!item.Enabled" class="menu-item">
        {{ item?.Label }}
      </button>
    </mat-menu>
  </div>
</div>
