import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../data.service';
import { DossiersComponent } from '../../dialog-menu/dossiers/dossiers.component';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { FilterDossierComponent } from '../../dialog-menu/filter-dossier/filter-dossier.component';
import { DossierDetailComponent } from '../dossier-view/dossier-detail/dossier-detail.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import {
  DossierDto,
  DossierFilter,
  DossierModel,
} from '../../../models/DossierModel';
import { DossierController } from '../../../controllers/DossierController';
import { AccountDossierController } from '../../../controllers/AccountDossierController';
import { NavigatorService } from '../../../../navigator.services';
import {
  Constants,
  DossierState,
  GenericDialogAction,
  PageAction,
} from '../../../doc.configuration';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { GenericDialogModel } from '../../../models/GenericDialogModel';

@Component({
  selector: 'app-dossier-view',
  templateUrl: './dossier-archived-view.component.html',
  styleUrls: ['./dossier-archived-view.component.scss'],
})
export class DossierArchivedViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  @ViewChild('dossierdetail') DossierDetail: DossierDetailComponent;

  Model: DossierModel;
  Controller: DossierController;
  AccountDossierController: AccountDossierController;
  Tab = 1;
  ShowDossierDetail = false;
  DossierDtoDetail: DossierDto;
  DossierDocumentId: number;
  DataSource: MatTableDataSource<DossierDto>;
  CurrentPage: number;
  ND = Constants.ND;
  Filter: DossierFilter;
  FilterCount = 0;
  constructor(
    public dataService: DataService,
    public navigatorService: NavigatorService,
    private authorizationService: AuthService,
    public commonService: CommonService
  ) {
    commonService.CurrentPageComponent = this;
    this.Model = new DossierModel();
    this.DossierDtoDetail = new DossierDto();

    this.Controller = new DossierController(dataService);
    this.AccountDossierController = new AccountDossierController(dataService);
  }

  ngOnInit() {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1) {
    this.Tab = tab;
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.SearchComponent?.ClearInputValue(false);
    if (this.navigatorService.NotificationTargetId > 0) {
      const targetId = this.navigatorService.NotificationTargetId;
      const documentDossierId =
        this.navigatorService.NotificationTargetDossierId;
      this.navigatorService.NotificationTargetId = null;
      this.navigatorService.NotificationTargetDossierId = null;
      await this.ShowTargetNotification(targetId, documentDossierId);
    } else {
      await this.GetDossiers();
      if (!this.Model?.Performed) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
    }

    this.navigatorService.StopLoading();
  }

  async GetDossiers(page = 1, filter = null) {
    this.Model.Search = this.SearchComponent?.GetSearchValue();
    if (filter) {
      this.Model.Filter = filter;
    } else {
      this.Model.Filter = new DossierFilter();
      this.Model.Filter.State = DossierState.CLOSE;
    }

    this.Model.Filter.Creator = this.authorizationService.CurrentUser.Username;
    this.Model.Filter.AccountId = this.authorizationService.DocAccount.Dto.Id;
    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';
    this.Model = await this.Controller.Load(this.Model);
    this.Paging(page);
  }

  async ShowTargetNotification(targetId: number, dossierId: number) {
    let model = new DossierModel();
    model.Filter = new DossierFilter();
    model.Filter.Id = !dossierId ? targetId : dossierId;
    model.Filter.State = DossierState.CLOSE;
    model = await this.Controller.Read(model);
    if (model.Performed && model?.Dto) {
      if (model.Dto && model.Dto.State === DossierState.CLOSE) {
        const fileId = !dossierId ? null : targetId;
        await this.ToggleDossierDetail(model.Dto, fileId, true);
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.DossierNotFound,
          model.Dto?.Name
        );
      }
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.DossierNotFound
      );
    }
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    await this.GetDossiers(1, this.Filter);
    this.navigatorService.StopLoading();
  }

  Create() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(
      DossiersComponent,
      null,
      '45%',
      'fit-content',
      '200px',
      async (result) => {
        if (result) {
          this.navigatorService.StartLoading();
          this.Model.Skip = 0;
          await this.GetDossiers();
          this.navigatorService.StopLoading();
        }
      }
    );
  }

  async Delete(dto: DossierDto) {
    if (dto) {
      const data = new GenericDialogModel();
      data.Name = dto.Name;
      data.Icon = '../../../../../assets/icon/dossier-black.svg';
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToDelete +
        '<br>' +
        dto.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new DossierModel();
            model.Filter = new DossierFilter();
            model.Filter.Id = dto.Id;
            model = await this.Controller.Delete(model);
            if (model?.Performed) {
              await this.GetDossiers(this.Pager.CurrentPage);
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.DossierDeleteMessage,
                data.Name
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    }
  }

  async ToggleDossierDetail(
    dto?: DossierDto,
    fileId: number = null,
    redirectNotification: boolean = false
  ) {
    if (this.ShowDossierDetail && !redirectNotification) {
      this.navigatorService.StartLoading();
      this.ShowDossierDetail = !this.ShowDossierDetail;
      await this.GetDossiers(this.CurrentPage);
      this.navigatorService.StopLoading();
    } else if (this.ShowDossierDetail && redirectNotification) {
      this.DossierDtoDetail = dto;
      this.DossierDocumentId = fileId;
      this.ShowDossierDetail = dto != null;
      await this.DossierDetail.Refresh(dto);
    } else {
      this.DossierDtoDetail = dto;
      this.DossierDocumentId = fileId;
      this.ShowDossierDetail = dto != null;
    }
  }

  GetDossierDisplayState(state: string) {
    let displayState = Constants.ND.toString();
    if (state) {
      if (state === DossierState.OPEN) {
        displayState = this.navigatorService.Dictionary?.Open;
      } else if (state === DossierState.CLOSE) {
        displayState = this.navigatorService.Dictionary?.Archived;
      }
    }
    return displayState;
  }

  // #region Paginazione
  RefreshTable(dtos: Array<DossierDto>) {
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<DossierDto>(dtos);
  }

  Paging(page: number = 1) {
    if (this.Pager) {
      this.Pager.Model = this.Model;
      this.CurrentPage = page;
      this.Pager.Paging(page);
      this.RefreshTable(this.Model.Dtos);
    }
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    this.CurrentPage = page;
    await this.GetDossiers(page, this.Filter);
    this.navigatorService.StopLoading();
  }
  // #endregion Paginazione

  SelectRow(dto: DossierDto) {
    if (dto && dto.Id > 0) {
      this.Model.Dtos.map((o) => (o.Selected = false));
      dto.Selected = true;
    }
  }

  OpenFiltersDialog() {
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(
      FilterDossierComponent,
      this.Filter,
      '45%',
      'fit-content',
      '200px',
      async (response: any) => {
        if (response) {
          this.navigatorService.StartLoading();
          this.FilterCount = response.FilterCount;
          this.Filter = response.Filter;
          this.Model.Skip = 0;
          if (this.FilterCount > 0) {
            await this.GetDossiers(1, response.Filter);
          } else {
            this.Model = new DossierModel();
            await this.GetDossiers();
          }
          this.navigatorService.StopLoading();
        }
      }
    );
  }
}
