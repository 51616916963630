import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardDataModel } from '../../../models/CardDataModel';
import { DashboardCardType } from '../../../doc.configuration';
import { NavigatorService } from '../../../../navigator.services';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() CardTitle: string;
  @Input() CardType: string;
  @Input() DatasetModel: CardDataModel[];
  @Input() DataModel: CardDataModel;
  @Input() IsEmpty: boolean;
  @Output() MoreActionCard = new EventEmitter();

  DashboardCardType = DashboardCardType;

  constructor(public navigatorService: NavigatorService) {}

  ngOnInit(): void {
    this.Load();
  }

  Load() {}

  MoreAction() {
    this.MoreActionCard.emit();
  }

  onWheel(event: WheelEvent): void {
    document.getElementsByClassName('overflow-x-scroll')[0].scrollLeft +=
      event.deltaY / 5;
    event.preventDefault();
  }
}
