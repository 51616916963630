import { Component, Input, OnInit } from '@angular/core';
import { NavigatorService } from '../../../navigator.services';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent<TDto> implements OnInit {
  @Input() Property: string;

  private dataValue: TDto = null;
  public get DataValue() {
    return this.dataValue;
  }

  @Input() set DataValue(value: TDto) {
    this.dataValue = value;
  }

  @Input() Placeholder = '';
  @Input() Type = 'text';
  @Input() Min = 0;
  @Input() Max = '';
  @Input() Maxlength = 50;
  @Input() Required = false;
  @Input() Description = '';
  @Input() Disabled = false;
  @Input() Regex = null;

  max = 100;
  min = 0;

  constructor(public navigatorService: NavigatorService) {}
  ngOnInit(): void {}
}
