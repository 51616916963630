<div *ngIf="!navigatorService.Loading" id="filterDossier" class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/filter-icon.svg">
                    <h2>{{navigatorService.Dictionary?.Filter}}</h2>
                </div>
                <div class="d-flex align-items-center">
                    <div class="reset-filters-btn pointer" (click)="InitializeFilters()" style="margin-right: 20px;">
                        <span>{{navigatorService.Dictionary?.ResetFilters}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" [mat-dialog-close]="null">
                close
            </span>
        </div>
    </div>

    <mat-dialog-content class="default-scrollbar">
        <div class="form">
            <div class="row mb-2">
                <div class="col-md-6 col-sm-12">
                    <app-chips-multiselect #uoSelect [Elements]="OrganizationUnitModel?.Dtos"
                        (ChipsSelectedEmit)="SelectUo($event)" (ChipsDeletedEmit)="RemoveUo($event)" [Chips]="ChipsUo"
                        [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.UoPlaceholder"
                        [Required]="false">
                    </app-chips-multiselect>
                </div>
                <div class="col-md-6 col-sm-12">
                    <app-chips-multiselect #statusSelect [Elements]="Statuses"
                        (ChipsSelectedEmit)="SelectStatus($event)" (ChipsDeletedEmit)="RemoveStatus($event)"
                        [Chips]="ChipsState" [NameProperty]="'Name'"
                        [Placeholder]="navigatorService.Dictionary?.StatusPlaceholder" [Required]="false">
                    </app-chips-multiselect>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-6 col-sm-12">
                    <app-input [DataValue]='Filter' [Property]="'Label'" [Type]="'text'" [Required]='false'
                        [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.DossierLabelPlaceholder">
                    </app-input>
                </div>
                <div class="col-md-6 col-sm-12">
                    <app-picker #creationDate [Placeholder]="navigatorService.Dictionary?.CreationDatePlaceholder"
                        [Property]="'CreationDateString'" [DataValue]='Filter'>
                    </app-picker>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button (click)="ApplyFilters()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Apply}}</span>
        </button>
    </div>
</div>
