import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NotificationFilter,
  NotificationModel,
} from '../../../models/NotificationModel';
import { ItCoreController } from '../../../controllers/ItCoreController';
import { PagerComponent } from '../../../documentary/custom-components/pager/pager.component';
import { NavigatorService } from '../../../navigator.services';
import { DataService } from '../../../data.service';
import { CommonService } from '../../../common.service';
import { DocumentComponent } from '../../../documentary/pages/dialog-menu/document/document.component';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-notification-history',
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.scss'],
})
export class NotificationHistoryComponent implements OnInit {
  Model: NotificationModel;
  ItCoreController: ItCoreController;
  @ViewChild('pager') Pager: PagerComponent;
  Pages: Array<number>;
  CurrentPage = 1;

  constructor(
    private dataService: DataService,
    public navigatorService: NavigatorService,
    public commonService: CommonService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<DocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Model = new NotificationModel();
    this.ItCoreController = new ItCoreController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.LoadNotificationHistory();
    this.navigatorService.StopLoading();
  }

  async LoadNotificationHistory(page = 1) {
    this.Model.Filter = new NotificationFilter();
    this.Model.Filter.Username = this.authService.CurrentUser.Username;
    this.Model = await this.ItCoreController.LoadNotificationHistory(
      this.Model
    );
    if (this.Model?.Performed) {
      for (const dto of this.Model.Dtos) {
        this.commonService.ParseNotification(
          dto,
          this.authService.CurrentUser.Username,
          this.navigatorService.Dictionary
        );
      }
      this.Paging(page);
    }
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.LoadNotificationHistory(page);
    this.navigatorService.StopLoading();
  }
}
