<div *ngIf="!ShowDossierDetail" class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div class="grid-column" [ngClass]="Tab === 1 ? 'tab-selected' : ''">
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.YourDossier
                }}</span>
            </div>
            <!-- <div class="grid-column" (click)='Load(2)' [ngClass]="Tab===2?'tab-selected':''">
                            <h2>{{navigatorService.Dictionary?.AllDossier}}</h2>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mx-0 p-0">
    <div class="search-create-container">
      <div class="p-0 d-flex align-items-center justify-content-center" style="width: 30px;margin-right: 15px;">
        <span class="filter-badge" [matBadgeHidden]="FilterCount === 0" matBadge="{{ FilterCount }}">
          <img class="clickable-icon" (click)="OpenFiltersDialog()" src="../../../../../assets/icon/filter-icon.svg" />
        </span>
      </div>

      <div class="flex-grow-1 p-0">
        <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
          [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
          [HaveActions]="false" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)"></app-search>
      </div>
    </div>
  </div>

  <div class="form mt-3">
    <div class="it-doc-table-container">
      <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnName }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnName }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="icon-text-right"><img src="../../../../../assets/icon/dossier-black.svg" /></span>
            <span>{{ element.Name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{
            navigatorService.Dictionary?.TableColumnDossierIdentifier
          }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnDossierIdentifier }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.Key }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnStatus }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnStatus }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="material-icons" [ngClass]="'icon-circle-' + element.State?.toLowerCase()">
              circle</span>
            <span>{{ GetDossierDisplayState(element.State) }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{
            navigatorService.Dictionary?.DossierLabelPlaceholder
          }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.DossierLabelPlaceholder }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.Label }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{
            navigatorService.Dictionary?.TableColumnResponsible
          }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnResponsible }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container>
              <span>-</span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnTeam }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnTeam }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              <ng-container *ngIf="
                  element?.AccountDossiers?.length > 0 &&
                  element?.AccountDossiers?.length <= 3
                ">
                <img *ngFor="
                    let operatore of element?.AccountDossiers;
                    let i = index
                  " class="profile-picture" [ngClass]="i > 0 ? 'profile-picture-over' : ''"
                  matTooltip="{{ operatore.DisplayName }}" src="data:image/png;base64,{{ operatore.Avatar }}" />
              </ng-container>
              <ng-container *ngIf="
                  element?.AccountDossiers?.length > 0 &&
                  element?.AccountDossiers?.length > 3
                ">
                <span *ngFor="
                    let operatore of element?.AccountDossiers;
                    let i = index
                  ">
                  <img *ngIf="i <= 3" class="profile-picture" [ngStyle]="{
                      'left.px':
                        i > 0 && i <= 3 && i > 0 && element?.Team[i - 1].Avatar
                          ? i * -8
                          : 0
                    }" matTooltip="{{ operatore.DisplayName }}" src="data:image/png;base64,{{ operatore.Avatar }}" />
                </span>
                <div *ngIf="element?.AccountDossiers?.length - 4 > 0" class="profile-picture-circle"
                  [ngStyle]="{ 'left.px': 4 * -8 }">
                  +{{ element?.AccountDossiers?.length - 4 }}
                </div>
              </ng-container>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnUpdate }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnUpdate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ element.ModificationDateString }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="more-action">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <!-- <img src="../../../../../assets/icon/more-vert.svg"> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
              src="../../../../../assets/icon/more-vert.svg" [ngClass]="{ 'open-menu-trigger-color': m.menuOpen }" />
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)="Delete(element)">
                {{ navigatorService.Dictionary?.Delete }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
          </th>
          <td mat-cell *matCellDef="let element">
            <img class="clickable-icon" src="../../../../../assets/icon/forward.svg"
              (click)="ToggleDossierDetail(element)" />
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="[
            navigatorService.Dictionary?.TableColumnName,
            navigatorService.Dictionary?.TableColumnDossierIdentifier,
            navigatorService.Dictionary?.TableColumnStatus,
            navigatorService.Dictionary?.DossierLabelPlaceholder,
            navigatorService.Dictionary?.TableColumnResponsible,
            navigatorService.Dictionary?.TableColumnTeam,
            navigatorService.Dictionary?.TableColumnUpdate,
            'more-action',
            'filter'
          ]"></tr>
        <tr mat-row (click)="SelectRow(row)" [ngClass]="{ selected: row.Selected }" *matRowDef="
            let row;
            columns: [
              navigatorService.Dictionary?.TableColumnName,
              navigatorService.Dictionary?.TableColumnDossierIdentifier,
              navigatorService.Dictionary?.TableColumnStatus,
              navigatorService.Dictionary?.DossierLabelPlaceholder,
              navigatorService.Dictionary?.TableColumnResponsible,
              navigatorService.Dictionary?.TableColumnTeam,
              navigatorService.Dictionary?.TableColumnUpdate,
              'more-action',
              'filter'
            ]
          "></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
      </div>
      <div *ngIf="Model?.Dtos?.length <= 0 && !navigatorService.Loading" class="m-0 empty-table-box"
        style="padding-bottom: 200px">
        <img src="../../../../../assets/icon/dossier-empty.svg" />
        <div class="description">
          {{ navigatorService.Dictionary?.EmptyDossierMessage }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ShowDossierDetail">
  <app-dossier-detail #dossierdetail [DossierDto]="DossierDtoDetail" [DossierDocumentId]="DossierDocumentId"
    (GoBackEmit)="ToggleDossierDetail($event)">
  </app-dossier-detail>
</div>
