import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigatorService } from '../../../navigator.services';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent<TDto> implements OnInit {
  @Input() Property: string;

  private dataValue: TDto = null;
  public get DataValue() {
    return this.dataValue;
  }

  @Input() set DataValue(value: TDto) {
    this.dataValue = value;
  }

  @Input() Placeholder = '';
  @Input() Type = 'text';
  @Input() Min = 0;
  @Input() Max = '';
  @Input() Maxlength = 50;
  @Input() Required = false;
  @Input() Description = '';
  @Input() Disabled = false;
  @Input() Regex = null;
  @Output() Confirm = new EventEmitter();
  @Output() Add = new EventEmitter();

  IsValid = true;
  constructor(public navigatorService: NavigatorService) {}

  ngOnInit(): void {
    if (this.Type === 'CF') {
      this.Maxlength = 16;
    } else if (this.Type === 'PIVA') {
      this.Maxlength = 11;
    } else if (this.Type === 'PIVACF') {
      this.Maxlength = 16;
    }
  }

  ConfirmPassword() {
    this.Confirm.emit(this.dataValue);
  }

  AddButton() {
    this.Add.emit(this.dataValue);
  }

  ValidateNumberInput($evt) {
    const theEvent = $evt;
    let key = null;
    // Handle paste
    if (theEvent.type === 'paste') {
      key = $evt.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  onPasteNumber(event: ClipboardEvent) {
    // let clipboardData = event.clipboardData ;
    const pastedText = event.clipboardData.getData('text');
    const regex = /[0-9]/;
    if (!regex.test(pastedText)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  ValidateCFInput($evt) {
    const theEvent = $evt;
    let key = null;
    // Handle paste
    if (theEvent.type === 'paste') {
      key = $evt.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[a-zA-Z0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  ValidateCF($evt) {
    const theEvent = $evt;
    const value = theEvent.currentTarget.value;
    if (value && value.length > 0) {
      const regex =
        /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
      if (!regex.test(value)) {
        theEvent.returnValue = false;
        this.IsValid = false;
        if (theEvent.preventDefault) {
          theEvent.preventDefault();
        }
      }
    } else {
      this.IsValid = true;
    }
  }

  onPasteCF(event: ClipboardEvent) {
    // let clipboardData = event.clipboardData ;
    const pastedText = event.clipboardData.getData('text');
    if (pastedText && pastedText.length > 0) {
      const regex =
        /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
      if (!regex.test(pastedText)) {
        event.returnValue = false;
        this.IsValid = false;
        if (event.preventDefault) {
          event.preventDefault();
        }
      }
    } else {
      this.IsValid = true;
    }
  }

  ValidatePIVACF($evt) {
    const theEvent = $evt;
    const value = theEvent.currentTarget.value;
    if (value && value.length > 0) {
      const regexCF =
        /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
      const regex = /^[0-9]{11}$/;
      if (!regex.test(value) && !regexCF.test(value)) {
        theEvent.returnValue = false;
        this.IsValid = false;
        if (theEvent.preventDefault) {
          theEvent.preventDefault();
        }
      } else {
        this.IsValid = true;
      }
    } else {
      this.IsValid = true;
    }
  }

  onPastePIVACF(event: ClipboardEvent) {
    // let clipboardData = event.clipboardData ;
    const pastedText = event.clipboardData.getData('text');
    if (pastedText && pastedText.length > 0) {
      const regexCF =
        /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
      const regex = /^[0-9]{11}$/;
      if (!regex.test(pastedText) && !regexCF.test(pastedText)) {
        event.returnValue = false;
        this.IsValid = false;
        if (event.preventDefault) {
          event.preventDefault();
        }
      } else {
        this.IsValid = true;
      }
    } else {
      this.IsValid = true;
    }
  }
}
