import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigatorService } from '../navigator.services';
import {
  ApplicationSidebar as DocApplicationSidebar,
  Pages,
} from '../documentary/doc.configuration';
import { MatDialog } from '@angular/material/dialog';
import { DialogMenuComponent } from '../documentary/pages/dialog-menu/dialog-menu.component';
import { CommonService } from '../common.service';
import { WhiteLabelService } from '../white-label.service';
import { WizardComponent } from '../documentary/custom-components/wizard/wizard.component';
import { I } from '@angular/cdk/keycodes';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  @Input() MenuItem: any;
  isCollapsed: boolean = false;
  isSidebarProfileOpen = false;

  constructor(
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService,
    public dialog: MatDialog,
    public whiteLabelService: WhiteLabelService,
    ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.Redirect();
  }

  ngOnInit() {
    if (this.authenticationService.IsAuthenticated()) {
      this.Load();
    }
  }

  async Load() {
    this.navigatorService.LoadComplete = false;
    await this.authenticationService.Initialize();
    const user = this.authenticationService?.DocAccount?.Dto;
    const docApplicationSidebar = new DocApplicationSidebar(this.navigatorService.Dictionary,user);
    this.navigatorService.DashboardMenu = docApplicationSidebar.GetDashboardMenu();
    this.navigatorService.DocMenu = docApplicationSidebar.GetMenu();
    this.navigatorService.SettingsMenu = docApplicationSidebar.GetSettingsMenu();
    this.navigatorService.UserProfileMenu = docApplicationSidebar.GetUserProfileMenu();
    this.Redirect();
    this.navigatorService.LoadComplete = true;
  }

  SidebarMenuClick(menuItem) {
    this.commonService.SidebarMenuClick(menuItem.Link, this.navigatorService);
  }

  toggleSidebarProfile() {
    this.isSidebarProfileOpen = !this.isSidebarProfileOpen;
  }

  closeSidebar() {
    this.isSidebarProfileOpen = false;
  }

  ShowDialog() {
    this.navigatorService.ShowDialog(
      DialogMenuComponent,
      null,
      '45%',
      'fit-content'
    );
  }

  private Redirect() {
    if (!this.navigatorService.IsRefresh()) {
      this.navigatorService.DashboardMenu.Selected = true;
      this.navigatorService.CurrentPage = this.navigatorService.DashboardMenu;
      this.navigatorService.GoTo(Pages.Dashboard);
    } else {
      const index = window.location.pathname.indexOf('/v1/');
      if (index >= 0) {
        const page = window.location.pathname.substring(
          index + 4,
          window.location.pathname?.length
        );
        if (
          page !== Pages.Dashboard &&
          page !== Pages.Settings &&
          page !== Pages.UserProfile
        ) {
          const item = this.navigatorService.DocMenu?.find((itemMenu) => {
            let currentItem = itemMenu.Link === page ? itemMenu : null;
            if (!currentItem && itemMenu?.Childs?.length > 0) {
              currentItem = itemMenu.Childs.find((c) => c.Link === page);
            }
            if (currentItem) {
              this.ResetMenu();
              currentItem.Selected = true;
              return currentItem;
            }
          });
          if (item && item.Childs?.find((c) => c.Selected)) {
            item.Expanded = true;
            item.Selected = true;
            this.navigatorService.CurrentPage = item.Childs.find((c) => c.Selected );
            if (!this.navigatorService.CurrentPage?.Visible) {
              this.ResetMenu();
              item.Selected = true;
            }
          } else {
            if (item) {
              this.ResetMenu();
              item.Selected = true;
            }
            this.navigatorService.DashboardMenu.Selected = false;
            this.navigatorService.CurrentPage = item;
          }
        } else {
          if (page === Pages.Dashboard) {
            this.navigatorService.DashboardMenu.Selected = true;
            this.navigatorService.CurrentPage =
              this.navigatorService.DashboardMenu;
            this.navigatorService.GoTo(Pages.Dashboard);
          } else if (page === Pages.Settings) {
            this.navigatorService.SettingsMenu.Selected = true;
            this.navigatorService.CurrentPage =
              this.navigatorService.SettingsMenu;
            this.navigatorService.GoTo(Pages.Settings);
          } else if (page === Pages.UserProfile) {
            this.navigatorService.UserProfileMenu.Selected = true;
            this.navigatorService.CurrentPage =
              this.navigatorService.UserProfileMenu;
            this.navigatorService.GoTo(Pages.UserProfile);
          }
        }
      }
    }
  }

  ProfileMenuItemClick(item) {
    this.navigatorService.DashboardMenu.Selected = false;
    if (this.navigatorService.SettingsMenu) {
      this.navigatorService.SettingsMenu.Selected = false;
    }
    this.navigatorService.DocMenu?.map((itemMenu) => {
      itemMenu.Selected = false;
      if (itemMenu.Childs) {
        itemMenu.Childs.map((i) => (i.Selected = false));
      }
    });
    this.navigatorService.UserProfileMenu.map((q) => (q.Selected = false));
    if (item.Name !== 'wizard') {
      item.Selected = true;
      this.navigatorService.CurrentPage = item;
      this.navigatorService.GoTo(Pages.UserProfile);
    } else {
      const data = {
        BrandLogo: this.whiteLabelService.BrandLogo,
        IsAdmin: this.authenticationService.CurrentUser.IsAdmin,
        User: this.authenticationService?.DocAccount?.Dto,
      };
      this.navigatorService.ShowDialog(
        WizardComponent,
        data,
        '100%',
        '100%',
        '100%',
        null,
        '100%',
        'wizard'
      );
    }
  }

  private ResetMenu() {
    this.navigatorService.DashboardMenu.Selected = false;
    this.navigatorService.UserProfileMenu.Selected = false;
    if (this.navigatorService.SettingsMenu) {
      this.navigatorService.SettingsMenu.Selected = false;
    }
    this.navigatorService.DocMenu?.map((itemMenu) => {
      itemMenu.Selected = false;
      if (itemMenu.Childs) {
        itemMenu.Childs.map((i) => (i.Selected = false));
      }
    });
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.closeAllExpandedMenus();
    }
  }

  private closeAllExpandedMenus() {
    this.navigatorService.DashboardMenu.Expanded = false;

    this.navigatorService.DocMenu?.forEach(menu => {
      menu.Expanded = false;
      menu.Childs?.forEach(child => (child.Expanded = false));
    });

    this.navigatorService.UserProfileMenu?.forEach(menu => {
      menu.Expanded = false;
      menu.Childs?.forEach(child => (child.Expanded = false));
    });

    this.navigatorService.SettingsMenu.Expanded = false;
    this.navigatorService.SettingsMenu.Childs?.forEach(child => (child.Expanded = false));
  }
}
