<mat-form-field class="form-field" appearance="fill">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <div *ngIf="!Required">
    <div *ngIf="!MultiSelect">
      <mat-select [(value)]="DataValue" [disabled]="Disabled">
        <mat-option class="option" *ngFor="let dto of Items" [value]="dto.Id">{{dto[Property]}}</mat-option>
      </mat-select>
      <img matSuffix src="../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon" alt="Open select">
    </div>
    <div *ngIf="MultiSelect">
      <mat-select multiple  [(ngModel)]="DataValue" [disabled]="Disabled">
        <mat-option class="option" *ngFor="let dto of Items" [value]="dto.Id" (onSelectionChange)="OnChangeItems($event,dto)">{{dto[Property]}}</mat-option>
      </mat-select>
      <img matSuffix src="../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon" alt="Open select">
    </div>
  </div>
  <div *ngIf="Required">
    <div *ngIf="!MultiSelect">
      <mat-select [(value)]="DataValue" required [disabled]="Disabled">
        <mat-option class="option" *ngFor="let dto of Items" [value]="dto.Id" >{{dto[Property]}}</mat-option>
      </mat-select>
      <img matSuffix src="../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon" alt="Open select">

    </div>
    <div *ngIf="MultiSelect">
      <mat-select required multiple [(ngModel)]="DataValue" [disabled]="Disabled">
        <mat-option  class="option"  *ngFor="let dto of Items" [value]="dto.Id" (onSelectionChange)="OnChangeItems($event,dto)">{{dto[Property]}}</mat-option>
      </mat-select>
      <img matSuffix src="../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon" alt="Open select">
    </div>
  </div>
</mat-form-field>
