<div class="component-container">
  <mat-label class="slider-label">
    {{ Properties?.Placeholder }}
    <span *ngIf="Properties?.Required" class="required">*</span>
  </mat-label>

  <div class="row-container">
    <span class="material-icons icon-drag icon-align-center" cdkDragHandle>
      drag_indicator
    </span>

    <mat-slider class="custom-slider" [min]="Properties?.Minlength" [max]="Properties?.Maxlength" discrete>
      <input value="2" matSliderStartThumb>
      <input value="20" matSliderEndThumb>
    </mat-slider>
    <span (click)="DeleteComponent()" class="material-icons icon icon-align-center">
      delete
    </span>
  </div>
</div>

<div class="toggle-container">

  <mat-slide-toggle [(ngModel)]="Properties.Required" labelPosition="before" class="required-toggle">
    {{ navigatorService?.Dictionary?.Required }}
  </mat-slide-toggle>
</div>
