import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DossiersComponent } from '../../../dialog-menu/dossiers/dossiers.component';
import { FilterDocumentComponent } from '../../../dialog-menu/filter-document/filter-document.component';
import { GenericConfirmDialogComponent } from '../../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { EncryptComponent } from '../../../dialog-menu/encrypt/encrypt.component';
import { PagerComponent } from '../../../../custom-components/pager/pager.component';
import {
  DossierDto,
  DossierFilter,
  DossierModel,
} from '../../../../models/DossierModel';
import {
  ObjectDossierDto,
  ObjectDossierFilter,
  ObjectDossierModel,
} from '../../../../models/ObjectDossierModel';
import { ObjectDossierController } from '../../../../controllers/ObjectDossierController';
import { FileManagerController } from '../../../../controllers/FileManagerController';
import { DossierController } from '../../../../controllers/DossierController';
import { SearchComponent } from '../../../../custom-components/search/search.component';
import {
  Constants,
  DossierState,
  GenericDialogAction,
  PageAction,
  Pages,
} from '../../../../doc.configuration';
import { CommonService } from '../../../../../common.service';
import { NavigatorService } from '../../../../../navigator.services';
import { DataService } from '../../../../../data.service';
import { ObjectDto } from '../../../../models/ObjectModel';
import { GenericDialogModel } from '../../../../models/GenericDialogModel';
import { WorkflowUserDto } from '../../../../../workflow/models/WorkflowUserModel';

@Component({
  selector: 'app-dossier-detail',
  templateUrl: './dossier-detail.component.html',
  styleUrls: ['./dossier-detail.component.scss'],
})
export class DossierDetailComponent implements OnInit {
  @ViewChild('pager') Pager: PagerComponent;
  @Input() DossierDto: DossierDto;
  @Input() DossierDocumentId: number;
  @Output() GoBackEmit = new EventEmitter<any>();
  CurrentFolder: ObjectDossierDto;
  ObjectDossierModel: ObjectDossierModel;
  ObjectDossierController: ObjectDossierController;
  FileManagerController: FileManagerController;
  Controller: DossierController;
  DataSource: MatTableDataSource<ObjectDossierDto>;
  @ViewChild('search') SearchComponent: SearchComponent;
  ND = Constants.ND.toString();

  Pages: Array<number>;
  CurrentPage = 1;
  FilterCount = 0;
  Filter: ObjectDossierFilter;

  constructor(
    public navigatorService: NavigatorService,
    public commonService: CommonService,
    public dataService: DataService
  ) {
    this.ObjectDossierModel = new ObjectDossierModel();
    this.ObjectDossierController = new ObjectDossierController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.Controller = new DossierController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    if (this.DossierDocumentId) {
      await this.ReadObjectsDossier(this.DossierDocumentId);
    } else {
      await this.GetObjectsDossier();
    }
    this.navigatorService.StopLoading();
  }

  async Refresh(dto) {
    this.navigatorService.StartLoading();
    this.ObjectDossierModel = new ObjectDossierModel();
    this.DossierDocumentId = null;
    this.CurrentFolder = null;
    this.DossierDto = dto;
    await this.GetObjectsDossier();
    this.navigatorService.StopLoading();
  }

  async GetObjectsDossier(page = 1, filter = null) {
    this.ObjectDossierModel.Search = this.SearchComponent?.GetSearchValue();
    if (filter) {
      this.ObjectDossierModel.Filter = filter;
    } else {
      this.ObjectDossierModel.Filter = new ObjectDossierFilter();
    }
    this.ObjectDossierModel.Filter.DossierId = this.DossierDto.Id;
    this.ObjectDossierModel = await this.ObjectDossierController.Load(
      this.ObjectDossierModel
    );
    this.Paging(page);
  }

  async ReadObjectsDossier(id: number) {
    this.ObjectDossierModel.Search = null;
    this.ObjectDossierModel.Filter = new ObjectDossierFilter();
    this.ObjectDossierModel.Filter.Id = id;
    this.ObjectDossierModel = await this.ObjectDossierController.Read(
      this.ObjectDossierModel
    );
    if (this.ObjectDossierModel?.Performed && this.ObjectDossierModel?.Dto) {
      this.CurrentFolder = this.ObjectDossierModel?.Dto;
      this.ObjectDossierModel.Dtos = [];
      const dto = this.ObjectDossierModel.Dto;
      this.ObjectDossierModel.Dtos.push(dto);
      this.ObjectDossierModel.Count = 1;
      this.ObjectDossierModel.Dto = null;
      this.Paging();
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.DocumentNotFound
      );
    }
  }

  SelectRow(dto: ObjectDossierDto) {
    if (dto && dto.Id > 0) {
      this.ObjectDossierModel.Dtos.map((o) => (o.Selected = false));
      dto.Selected = true;
    }
  }

  async OpenObject(row: ObjectDossierDto) {
    if (row.Type === 'file') {
      this.ObjectDossierModel.Dtos.map((x) => (x.Selected = false));
      row.Selected = true;
      const dto = new ObjectDto();
      dto.Id = row.ObjectId;
      dto.UoId = row.UoId;
      dto.MimeType = row.MimeType;
      dto.PhysicalName = row.PhysicalName;
      dto.Path = row.Path;
      dto.OriginalExtension = row.OriginalExtension;
      await this.commonService.OpenObject(dto, this.navigatorService);
    }
  }

  async Search() {
    this.navigatorService.StartLoading();
    this.ObjectDossierModel.Skip = 0;
    await this.GetObjectsDossier(1, this.Filter);
    this.navigatorService.StopLoading();
  }

  GoBack() {
    this.CurrentFolder = null;
    this.GoBackEmit.emit();
  }

  Update() {
    if (this.DossierDto) {
      this.navigatorService.PageAction = PageAction.Edit;
      this.navigatorService.ShowDialog(
        DossiersComponent,
        this.DossierDto.Id,
        '800px',
        'fit-content',
        '200px',
        async (result) => {
          if (result) {
            this.navigatorService.StartLoading();
            let model = new DossierModel();
            model.Filter = new DossierFilter();
            model.Filter.Id = this.DossierDto.Id;
            model = await this.Controller.Read(model);
            if (model?.Performed) {
              this.DossierDto = model.Dto;
              await this.Load();
            }
          }
        },
        '800px'
      );
    }
  }

  async Archives(dto: DossierDto) {
    if (dto) {
      const data = new GenericDialogModel();
      data.Name = dto.Name;
      data.Icon = '../../../../../assets/icon/dossier-black.svg';
      data.Title = this.navigatorService.Dictionary?.Archive;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToArchive +
        '<br>' +
        dto.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Confirm;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            dto.State = DossierState.CLOSE;
            let model = new DossierModel();
            model.Dto = dto;
            model.UpdateProperties = ['Modifier', 'ModificationDate', 'State'];
            model = await this.Controller.CreateOrUpdate(model);
            if (model?.Performed) {
              this.navigatorService.GoTo(Pages.ViewDossiersArchived);
              this.commonService.SidebarMenuClick(
                Pages.ViewDossiersArchived,
                this.navigatorService
              );
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.DossierArchivedMessage,
                dto.Name
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    }
  }

  async Delete() {
    if (this.DossierDto) {
      const data = new GenericDialogModel();
      data.Name = this.DossierDto.Name;
      data.Icon = '../../../../../assets/icon/dossier-black.svg';
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToDelete +
        '<br>' +
        this.DossierDto.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new DossierModel();
            model.Filter = new DossierFilter();
            model.Filter.Id = this.DossierDto.Id;
            model = await this.Controller.Delete(model);
            if (model?.Performed) {
              this.GoBack();
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.DossierDeleteMessage,
                data.Name
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    }
  }

  async RemoveObjectFromDossier(element: ObjectDossierDto) {
    if (element) {
      const data = new GenericDialogModel();
      data.Name =
        element.Name +
        '.' +
        this.commonService.GetExtension(element.PhysicalName);
      data.Icon = '../../../../../assets/icon/dossier-black.svg';
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToDelete +
        '<br>' +
        data.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new ObjectDossierModel();
            model.Filter = new ObjectDossierFilter();
            model.Filter.Id = element.Id;
            model = await this.ObjectDossierController.Delete(model);
            if (model?.Performed) {
              await this.GetObjectsDossier();
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    }
  }

  Cryptography(dto: ObjectDossierDto) {
    if (dto.Type === 'file') {
      const data = { Dto: dto, SoftDescrypt: false };
      this.navigatorService.ShowDialog(
        EncryptComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (response) => {
          if (response?.Performed) {
            dto = response.Dto;
          }
        }
      );
    }
  }
  // #region Paginazione
  RefreshTable(dtos: Array<ObjectDossierDto>) {
    for (const dto of dtos) {
      this.commonService.GetIcon(dto);
      if (
        dto?.WorkflowData?.WorkflowSteps &&
        dto?.WorkflowData?.WorkflowSteps?.length > 0
      ) {
        dto.Team = new Array<WorkflowUserDto>();
        for (const step of dto?.WorkflowData?.WorkflowSteps) {
          for (const user of step.WorkflowStepUsers) {
            const find = dto.Team.find((u) => u.AccountId === user.AccountId);
            if (!find) {
              dto.Team.push(user);
            }
          }
        }
      }
    }

    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDossierDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.ObjectDossierModel;
    this.Pager.Paging(page);
    this.RefreshTable(this.ObjectDossierModel.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjectsDossier(page, this.Filter);
    this.navigatorService.StopLoading();
  }

  // #endregion Paginazione

  OpenFiltersDialog() {
    const data = { View: Pages.Dossiers, Filter: this.Filter };
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(
      FilterDocumentComponent,
      data,
      '45%',
      'fit-content',
      '200px',
      async (response: any) => {
        if (response) {
          this.navigatorService.StartLoading();
          this.FilterCount = response.FilterCount;
          this.Filter = response.Filter;
          this.ObjectDossierModel.Skip = 0;
          if (this.FilterCount > 0) {
            await this.GetObjectsDossier(null, response.Filter);
          } else {
            this.ObjectDossierModel = new ObjectDossierModel();
            await this.GetObjectsDossier();
          }
          this.navigatorService.StopLoading();
        }
      }
    );
  }
}
