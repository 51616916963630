import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PagerComponent } from '../../../../custom-components/pager/pager.component';
import {
  Children,
  SystemInfoDto,
  SystemInfoModel,
} from '../../../../models/SystemInfoModel';
import { DocFlyController } from '../../../../controllers/DocFlyController';
import { Pages, PDAStatus } from '../../../../doc.configuration';
import { NavigatorService } from '../../../../../navigator.services';
import { AuthService } from '../../../../../auth.service';
import { DataService } from '../../../../../data.service';
import { CommonService } from '../../../../../common.service';
import {
  ViewRepositoryDto,
  ViewRepositoryModel,
} from '../../../../models/SystemInfoModel copy';

@Component({
  selector: 'app-pda-view',
  templateUrl: './pda-view.component.html',
  styleUrls: ['./pda-view.component.scss'],
})
export class PdaViewComponent implements OnInit {
  @ViewChild('pager') Pager: PagerComponent;
  Model: SystemInfoModel;
  DataSource: MatTableDataSource<Children>;
  Controller: DocFlyController;
  NodeRef: string;
  ArchiveName: string;
  PdaStatus = PDAStatus;
  RefreshTimer;
  Resfresh = false;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService
  ) {
    this.NodeRef = this.navigatorService.GetQueryParameter('node');
    this.Model = this.navigatorService.SystemInfoModel;
    this.Controller = new DocFlyController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
    this.RefreshTimer = setInterval(() => {
      if (!this.Resfresh && !this.navigatorService.Loading) {
        if (window.location.href.includes(Pages.ViewPda)) {
          this.Resfresh = true;
          this.GetPda(this.Pager.CurrentPage);
        } else {
          clearInterval(this.RefreshTimer);
        }
      }
    }, 30000);
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    await this.GetPda();
    this.navigatorService.StopLoading();
  }

  async GetPda(page: number = 1) {
    this.Model = new SystemInfoModel();
    this.Model.Dto = new SystemInfoDto();
    this.Model.Dto = this.authenticationService.DocflyUser;
    this.Model.Dto.Page = page;
    this.Model = await this.Controller.GetArchivesInfo(this.Model);
    if (this.Model?.Code === 500) {
      // this.Model.Message.includes('Token valido inesistente');
      this.Model = null;
      this.authenticationService.DocflyUser = null;
      this.navigatorService.GoTo(Pages.Conservation);
    }
    // }
    this.navigatorService.SystemInfoModel = this.Model;
    const archive = this.Model?.Dto?.ArchivesInfo?.find(
      (q) => q.NodeRef === this.NodeRef
    );
    if (archive) {
      this.ArchiveName = archive.Name;
      if (this.Model?.Performed) {
        this.Model.Count = archive?.ViewRepository?.NumberOfElements;
        this.Model.Take = archive?.ViewRepository?.PageSize;
        this.Model.Dtos = archive?.ViewRepository?.Children;
      }
    }
    this.Paging(page);
    this.Resfresh = false;
  }

  async DownloadPDD(pda: Children) {
    let model = new ViewRepositoryModel();
    model.Dto = new ViewRepositoryDto();
    model.Dto.Ticket = this.authenticationService.DocflyUser.Ticket;
    model.Dto.NodeRef = pda.NodeRef;
    model = await this.Controller.DownloadPDD(model);
    if (model?.Performed) {
      const blob = new Blob([model.Dto.File], { type: 'application/zip' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = pda.Title;
      element.click();

      // const blob = this.commonService.Base64toBlob(model.Dto.File, 'application/zip');
      // const element = document.createElement('a');
      // element.href = URL.createObjectURL(blob);
      // element.download = pda.Title;
      // // start download
      // element.click();
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary.PDDNotAvailable
      );
    }
  }

  async DeletePda(pda: Children) {
    let model = new ViewRepositoryModel();
    model.Dto = new ViewRepositoryDto();
    model.Dto.Ticket = this.authenticationService.DocflyUser.Ticket;
    model.Dto.NodeRef = pda.NodeRef;
    model = await this.Controller.DeletePda(model);
    if (model?.Performed) {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary.DeletedPda,
        pda.Title
      );
      await this.GetPda(this.Pager.CurrentPage);
    } else {
      this.navigatorService.ShowSnackBar(model.Message, pda.Title);
    }
  }

  CreatePda() {
    const archive = this.Model?.Dto?.ArchivesInfo?.find(
      (q) => q.NodeRef === this.NodeRef
    );
    this.navigatorService.SystemInfoModel = this.Model;
    this.commonService.SidebarMenuClick(Pages.Pda, this.navigatorService, {
      queryParams: { node: archive.NodeRef },
      queryParamsHandling: 'merge',
    });
  }

  RefreshTable(dtos: Array<Children>) {
    this.DataSource = null;
    dtos.map((q) => (q.State = q.State.toUpperCase()));
    this.DataSource = new MatTableDataSource<Children>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetPda(page);
    this.navigatorService.StopLoading();
  }

  PdaClick(children: Children) {
    this.navigatorService.SystemInfoModel = this.Model;
    this.commonService.SidebarMenuClick(
      Pages.DetailPda,
      this.navigatorService,
      { queryParams: { pda: children.NodeRef }, queryParamsHandling: 'merge' }
    );
  }
}
