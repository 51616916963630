import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigatorService } from '../../../navigator.services';
@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss']
})
export class MediaViewerComponent implements OnInit {
  FileName: string;

  constructor(
    public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<MediaViewerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data?.Stream) {
      this.FileName = data.FileName;
    } else {
      this.dialogRef.close();
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    if (this.data.Stream) {

      if (this.data?.MimeType.includes('image')) {
        const element = document.getElementById('mediaImg');
        if (element) {
          const url = 'data:' + this.data.MimeType + ';base64,' + this.data.Stream;
          element.setAttribute('src', url);
        }
      } else if (this.data?.MimeType?.includes('video')) {
        const element = document.getElementById('mediaVideo');
        if (element) {
          const newResponse = new Response(this.data.Stream);
          const blob = await newResponse.blob();
          const url = URL.createObjectURL(blob);
          element.setAttribute('src', url);
        }
      } else if (this.data?.MimeType?.includes('audio')) {
        const element = document.getElementById('mediaAudio') as HTMLAudioElement;
        if (element) {
          const newResponse = new Response(this.data.Stream);
          const blob = await newResponse.blob();
          const url = URL.createObjectURL(blob);
          element.setAttribute('src', url);
          // element.play();
        }
      }
    }
    //this.StreamBase64 = 'data:' + this.data.MimeType + ';base64,' + this.data.Stream;
  }
}
