<div class="itdoc-page">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <div class="valign-icon-text">
          <img style="margin-right: 11px" src="../../../../../assets/icon/code.svg" />
          <h2>{{ navigatorService.Dictionary?.MetadataDefinition }}</h2>
        </div>
        <div>
          <span class="current-step">{{Step}}</span><span class="step-separator">|</span><span
            class="steps">{{Steps}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div *ngIf="Step===1">
    <p *ngIf="navigatorService.PageAction !== 'Edit'" class="title-description mb-1">
      {{ navigatorService.Dictionary?.TipologyDescription }}
    </p>
    <p *ngIf="navigatorService.PageAction === 'Edit'" class="title-description mb-1">
      {{ navigatorService.Dictionary?.ModifyTipologyDescription }}
    </p>
    <div class="required-label">
      <span class="star">*</span>
      <span>{{ navigatorService.Dictionary?.RequiredFields }}</span>
    </div>
    <mat-dialog-content>
      <div class="form">
        <div class="row mb-3">
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]="Model?.Dto" [Property]="'Creator'" [Type]="'text'" [Required]="true"
              [Placeholder]="navigatorService.Dictionary?.Creator" [Disabled]="true">
            </app-input>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]="Model?.Dto" [Property]="'Name'" [Type]="'text'" [Required]="true" [Maxlength]="200"
              [Placeholder]="
                navigatorService.Dictionary?.MetadataNamePlaceholder
              ">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <app-select [Placeholder]="
                navigatorService.Dictionary?.SelectMetadataCategoryPlaceholder
              " [Required]="true" [DataValue]="Model?.Dto?.MetadataCategoryId" [Property]="'Name'"
              (OnChange)="SelectCategory($event)" [Items]="MetadataCategoryModel.Dtos" [MultiSelect]="false">
            </app-select>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-select [Placeholder]="navigatorService.Dictionary?.SelectTypePlaceholder" [Required]="true"
              [DataValue]="Model?.Dto?.FieldId" [Property]="'Name'" (OnChange)="SelectField($event)"
              [Items]="FieldsModel.Dtos" [MultiSelect]="false">
            </app-select>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div *ngIf="Step===2">
    <p *ngIf="navigatorService.PageAction !== 'Edit'" class="title-description mb-1">
      {{ navigatorService.Dictionary?.TipologyDescription }}
    </p>
    <p *ngIf="navigatorService.PageAction === 'Edit'" class="title-description mb-1">
      {{ navigatorService.Dictionary?.ModifyTipologyDescription }}
    </p>
    <div class="required-label">
      <span class="star">*</span>
      <span>{{ navigatorService.Dictionary?.RequiredFields }}</span>
    </div>
    <mat-dialog-content>
      <div class="form">
        <div class="row mb-3">
          <div class="col-md-12 col-sm-12">
            <app-input [DataValue]="Model?.Dto.Properties" [Property]="'Placeholder'" [Type]="'text'" [Required]="true"
              [Maxlength]="200" [Placeholder]="
                navigatorService.Dictionary?.FieldNamePlaceholder
              ">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="toggle-container">
              <mat-slide-toggle [(ngModel)]="Model.Dto.Properties.Required" labelPosition="before"
                class="required-toggle">
                {{ navigatorService?.Dictionary?.Required }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div *ngIf="Model?.Dto.Properties.Type != 'date'" class="row mb-3">
          <div class="col-md-12 col-sm-12">
            <app-input [DataValue]="Model?.Dto.Properties" [Property]="'Maxlength'" [Type]="'Integer'" [Required]="true"
              [Placeholder]="
                navigatorService.Dictionary?.MaxLengthSet
              ">
            </app-input>
          </div>
        </div>

        <div class="mt-4">
          <div class="mb-2">{{navigatorService.Dictionary?.TipologyFieldPreview}}</div>
          <div class="mb-3">
            <app-picker *ngIf="Model.Dto.Properties.Type === 'date'" [ReadOnly]="true"
              [Placeholder]="Model.Dto.Properties?.Placeholder" [Required]="Model.Dto.Properties?.Required">
            </app-picker>
            <app-input *ngIf="Model.Dto.Properties.Type === 'text'" [DataValue]="Model?.Dto.Properties"
              [Property]="'Value'" [Type]="'text'" [Placeholder]="Model.Dto.Properties?.Placeholder"
              [Required]="Model.Dto.Properties?.Required" [Maxlength]="Model.Dto.Properties.Maxlength">
            </app-input>
            <app-input *ngIf="Model.Dto.Properties.Type === 'textarea'" [DataValue]="Model?.Dto.Properties"
              [Property]="'Value'" [Type]="'text-area'" [Placeholder]="Model.Dto.Properties?.Placeholder"
              [Required]="Model.Dto.Properties?.Required" [Maxlength]="Model.Dto.Properties.Maxlength">
            </app-input>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
      <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
    </button>
    <button *ngIf="Step>1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
      <span class="button-text">{{navigatorService.Dictionary?.Back}}</span>
    </button>

    <button *ngIf="Step<2" (click)="Next()" mat-button class="it-doc-btn-primary"
      [disabled]="!ValidateForm(this.Step,false)">
      <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
    </button>

    <button *ngIf="Step===2" (click)="Save()" mat-button class="it-doc-btn-primary"
      [disabled]="!ValidateForm(this.Step,false)">
      <span *ngIf="!data" class="button-text">{{navigatorService.Dictionary?.Create}}</span>
      <span *ngIf="data" class="button-text">{{navigatorService.Dictionary?.Update}}</span>
    </button>
  </div>
</div>
