<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <h2 *ngIf="navigatorService.PageAction === 'New' && IsScanDocument()">
          {{navigatorService.Dictionary?.NewDocumentPdf}}</h2>
        <h2 *ngIf="navigatorService.PageAction === 'New' && !IsScanDocument()">
          {{navigatorService.Dictionary?.NewDocument}}</h2>
        <h2 *ngIf="navigatorService.PageAction === 'Edit'">{{navigatorService.Dictionary?.EditDocument}}</h2>
        <div>
          <span class="current-step">{{Step}}</span><span class="step-separator">|</span><span
            class="steps">{{Steps}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div *ngIf="Step===1">
    <p class="title-description">{{navigatorService.Dictionary?.NewDocumentDescription}}</p>
    <mat-dialog-content style="display:inline;">
      <div class="form mb-1" [ngClass]="'grid-three'">
        <div *ngIf="navigatorService.CurrentPage?.Link !='protocols/view'" class="grid-container">
          <div class="grid-column" *ngFor="let item of DocumentMenuItems" title="{{item.Label}}">
            <div class="item" (click)="ItemClick(item)" [ngClass]="item.Selected?'selected':''">
              <img class="grid-icon" src="../../../../assets/icon/{{item.Icon}}.svg">
              <div class="title">{{item.Title}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="navigatorService.CurrentPage?.Link ==='protocols/view'">
          <div *ngFor="let item of DocumentMenuItems">
            <div *ngIf="!UploadComplete" class="upload-file-container" (click)="ItemClick(item)">
              <input type="file" class="d-none" accept="video/*|image/*|application/*|text/*"
                [disabled]="this.UploadInProgress" />
              <img src="../../../../assets/icon/note-2.svg" />
              <div class="drag">
                {{ navigatorService.Dictionary?.SelectFile }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true' [Maxlength]="50"
              [Placeholder]="navigatorService.Dictionary?.DocumentName"
              [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-select [Placeholder]="navigatorService.Dictionary?.TipologyPlaceholder" [Required]='true'
              [DataValue]='Model?.Dto?.TipologyId' [Property]="'Name'" [Items]="Tipologies"
              (OnChange)="SelectTipology($event)" [Disabled]="navigatorService.PageAction === 'Edit'">
            </app-select>
          </div>
        </div>
      </div>

    </mat-dialog-content>
  </div>

  <div *ngIf="Step===2">
    <p class="title-description" *ngIf="navigatorService.PageAction === 'New'">
      {{navigatorService.Dictionary?.NewDocumentDescription2}}</p>
    <p class="title-description" *ngIf="navigatorService.PageAction === 'Edit'">
      {{navigatorService.Dictionary?.EditDocumentDescription2}}</p>
    <mat-dialog-content>
      <div class="form mb-5">
        <div class="row mb-3" *ngIf="navigatorService.PageAction === 'Edit'">
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true' [Maxlength]="50"
              [Placeholder]="navigatorService.Dictionary?.DocumentName"
              [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-select [Placeholder]="navigatorService.Dictionary?.TipologyPlaceholder" [Required]='true'
              [DataValue]='Model?.Dto?.TipologyId' [Property]="'Name'" [Items]="TipologyModel.Dtos"
              (OnChange)="SelectTipology($event)" [Disabled]="!Model?.Dto?.Authorization?.Authorized">
            </app-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6 col-sm-12">
            <app-select [Placeholder]="navigatorService.Dictionary?.OrganizationalUnit" [Required]='true'
              [DataValue]='Model?.Dto?.UoId' [Property]="'Name'" [Items]="OrganizationUnitModel.Dtos"
              [Disabled]="!Model?.Dto?.Authorization?.Authorized" (OnChange)="SelectUo($event)">
            </app-select>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-select [Placeholder]="navigatorService.Dictionary?.Workflow" [Required]='true'
              [DataValue]='Model?.Dto?.WorkflowId' [Property]="'Name'" [Items]="WorkflowModel.Dtos"
              [Disabled]="!(Model?.Dto?.State === 'PROGRESS' || !Model?.Dto?.State)"
              (OnChange)="SelectWorkflow($event)">
            </app-select>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div *ngIf="Step===3">
    <p class="title-description">{{navigatorService.Dictionary?.NewDocumentDescription3}}</p>
    <mat-dialog-content style="min-height: 26vh;overflow: hidden ">
      <div>
        <div class="row mb-3 mt-2 d-flex align-items-center">
          <div class="col-md-12 m-0">
            <app-search #search [Placeholder]="navigatorService.Dictionary?.FolderSearchPlaceholder"
              [HaveFilters]="false" [HaveActions]="false" [ModalStyle]="true" (OnEnterPress)="Search($event)"
              (OnClearBtnPress)="Search($event)">
            </app-search>
          </div>
        </div>

        <div class="row d-flex align-items-center new-dossier-input">

          <!-- Colonna input, visibile solo quando FolderNewDto è presente -->
          <div class="col-md-10 col-sm-12 text-end p-0" *ngIf="FolderNewDto">
            <app-input [DataValue]='FolderNewDto' [Property]="'Name'" [Type]="'text'"
                       [Required]='true' [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.FolderName">
            </app-input>
          </div>

          <!-- Colonna pulsante, cambia dimensione in base alla presenza di FolderNewDto -->
          <div class="text-center p-0 ms-auto"
               [ngClass]="FolderNewDto ? 'col-md-2' : 'col-md-3'">
            <button *ngIf="FolderNewDto" class="it-doc-btn-confirm-small" (click)="SaveFolder()">
              <mat-icon class="icon-text-left">done</mat-icon>
            </button>
            <button *ngIf="FolderNewDto" class="it-doc-btn-cancel-small" (click)="FolderNewDto = null">
              <mat-icon class="icon-text-left">close</mat-icon>
            </button>

            <!-- Pulsante aggiungi cartella, visibile solo quando FolderNewDto è nullo -->
            <button *ngIf="!FolderNewDto" mat-fab extended class="it-doc-btn-add-folder-small"
                    (click)="ShowCreateFolder()">
              <img class="clickable-icon" src="../../../../../assets/icon/add.svg" />
              <span class="text">{{navigatorService.Dictionary?.AddFolder}}</span>
            </button>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12 col-sm-12 mt-4">
            <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder?.Deep>=1">
              <div class="col-md-4 ">
                <button mat-icon-button (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder">
                  <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
                  <span>{{navigatorService.Dictionary?.Back}}
                  </span>
                </button>
              </div>
              <div class="col-md-4 text-center current-folder">
                <span>{{CurrentFolder?.Name}}</span>
              </div>
              <div class="col-md-4"></div>
            </div>

            <div class="it-doc-table-container default-scrollbar" style="height: 26vh; overflow: auto">
              <table mat-table [dataSource]="DataSource" matSort>
                <ng-container matColumnDef="FileIcon">
                  <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                  <td mat-cell *matCellDef="let element">
                    <img src=" {{element.Icon}}" (click)="ObjectsClick(element)" class="{{element.ClassIcon}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnName}} </th>
                  <td mat-cell *matCellDef="let element">
                    <span (click)="ObjectsClick(element)" class="clickable-icon">
                      {{element.Name + (element.Type==='file'?'.' +
                      commonService.GetExtension(element.PhysicalName):'')}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnCreate}}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnCreate}} </th>
                  <td mat-cell *matCellDef="let element">
                    <span>
                      {{element.DisplayName}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.Deep>=0">
                      {{element.ModificationDateString}}
                    </span>
                    <span *ngIf="!element.Deep">
                      {{element.UpdateDate | date:'dd/MM/yyyy'}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ArrowIcon">
                  <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                  <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                    <mat-icon class="clickable-icon" *ngIf="element.Type==='folder'">
                      arrow_forward_ios</mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row
                  *matHeaderRowDef="['FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon']">
                </tr>
                <tr mat-row (click)="SelectRow(row)" [ngClass]="{'selected':row.Selected}"
                  *matRowDef="let row; columns: ['FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon'];">
                </tr>

              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 ">
            <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>


  <div *ngIf="Step===4">

    <p class="title-description">{{navigatorService.Dictionary?.NewDocumentDescription4}}</p>
    <p *ngIf="Model?.Dto?.Format === 'docx' && ExistTemplate">
      <mat-checkbox [(ngModel)]="Model.Dto.Template" class="value-text">
        {{navigatorService.Dictionary?.CreateFromTemplate}}</mat-checkbox>
    </p>
    <mat-dialog-content class="default-scrollbar">
      <div class="form mb-5" style="min-height: 400px;">
        <div class="row mb-3" *ngFor="let dto of MetaDataTipologyModel.Dtos;let i = index">
          <div class="col-md-6 col-sm-12">
            <app-input *ngIf="MetaDataTipologyModel.Dtos[i].Type ==='text' && (i % 2===0)" [Property]="'Value'"
              [Type]="'text'" [Required]='MetaDataTipologyModel.Dtos[i].PropertiesJson.Required' [Maxlength]="50"
              [Placeholder]="MetaDataTipologyModel.Dtos[i].PropertiesJson.Placeholder" [Description]=""
              [DataValue]='MetaDataTipologyModel.Dtos[i].MetadataTipologyValue'>
            </app-input>

            <app-input *ngIf="MetaDataTipologyModel.Dtos[i].Type ==='text-area'  && (i % 2===0)" [Property]="'Value'"
              [Type]="'text-area'" [Required]='MetaDataTipologyModel.Dtos[i].PropertiesJson.Required' [Maxlength]="250"
              [Placeholder]="MetaDataTipologyModel.Dtos[i].PropertiesJson.Placeholder" [Description]=""
              [DataValue]='MetaDataTipologyModel.Dtos[i].MetadataTipologyValue'>
            </app-input>

            <app-picker *ngIf="MetaDataTipologyModel.Dtos[i].Type ==='date'  && (i % 2===0)"
              [Placeholder]="MetaDataTipologyModel.Dtos[i].PropertiesJson.Placeholder"
              [Required]='MetaDataTipologyModel.Dtos[i].PropertiesJson.Required' [Property]="'Value'"
              [DataValue]='MetaDataTipologyModel.Dtos[i].MetadataTipologyValue'>
            </app-picker>
            <app-select *ngIf="MetaDataTipologyModel.Dtos[i].Type ==='combo'  && (i % 2===0)"
              [Placeholder]="MetaDataTipologyModel.Dtos[i].PropertiesJson.Placeholder"
              [Required]='MetaDataTipologyModel.Dtos[i].PropertiesJson.Required' [Property]="'Value'"
              [Items]="MetaDataTipologyModel.Dtos[i].PropertiesJson.Items"
              (OnChange)="ComboItemSelected($event,MetaDataTipologyModel.Dtos[i])"
              [MultiSelect]="MetaDataTipologyModel.Dtos[i].PropertiesJson.Multiple"
              [DataValue]='MetaDataTipologyModel.Dtos[i].MetadataTipologyValue'>
            </app-select>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-input
              *ngIf="MetaDataTipologyModel.Dtos.length-1>=i+1 ?MetaDataTipologyModel.Dtos[i+1].Type ==='text' && ((i +1)% 2 !==0):''"
              [Property]="'Value'" [Type]="'text'" [Required]='MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Required'
              [Maxlength]="50" [Placeholder]="MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Placeholder"
              [Description]="" [DataValue]='MetaDataTipologyModel.Dtos[i+1].MetadataTipologyValue'>
            </app-input>

            <app-input
              *ngIf="MetaDataTipologyModel.Dtos.length-1>=i+1 ? MetaDataTipologyModel.Dtos[i+1].Type ==='text-area'  && ((i +1)% 2 !==0):''"
              [Property]="'Value'" [Type]="'text-area'" [Required]='dto.PropertiesJson.Required' [Maxlength]="250"
              [Placeholder]="MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Placeholder" [Description]=""
              [DataValue]='MetaDataTipologyModel.Dtos[i+1].MetadataTipologyValue'>
            </app-input>

            <app-picker
              *ngIf="MetaDataTipologyModel.Dtos.length-1>=i+1 ?MetaDataTipologyModel.Dtos[i+1].Type ==='date'  &&((i +1)% 2 !==0):''"
              [Placeholder]="MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Placeholder"
              [Required]='MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Required' [Property]="'Value'"
              [DataValue]='MetaDataTipologyModel.Dtos[i+1].MetadataTipologyValue'>
            </app-picker>
            <app-select
              *ngIf="MetaDataTipologyModel.Dtos.length-1>=i+1 ?MetaDataTipologyModel.Dtos[i+1].Type ==='combo'  && ((i +1)% 2 !==0):''"
              [Placeholder]="MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Placeholder"
              [Required]='MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Required' [Property]="'Value'"
              [Items]="MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Items"
              (OnChange)="ComboItemSelected($event,MetaDataTipologyModel.Dtos[i+1])"
              [MultiSelect]="MetaDataTipologyModel.Dtos[i+1].PropertiesJson.Multiple"
              [DataValue]='MetaDataTipologyModel.Dtos[i+1].MetadataTipologyValue'>
            </app-select>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <div *ngIf="Step===5">

    <mat-dialog-content class="slider-scan-dialog">
      <div class="form mb-2">
        <div class="row ">
          <div class="row bottom-shadow"></div>
          <div class="col-md-3 col-sm-3 sidebar-scan" cdkDropList [cdkDropListData]="FileModel?.Dtos"
            (cdkDropListDropped)="drop($event)">
            <div *ngFor="let dto of FileModel?.Dtos" cdkDrag [cdkDragData]="dto">
              <div *cdkDragPlaceholder
                style="background-color: grey !important;height: 100px;width: 100px; border:1px dotted black">
                &nbsp;</div>
              <img *cdkDragPreview [src]="dto.BlobUrl" style="width: 100px;">
              <div class="container-img-scan">
                <img [src]="dto.BlobUrl" class="image">
                <div class="overlay" (click)="SelectSliderImage(dto)">
                  <div class="row">
                    <div class="col-md-2 col-sm-2">
                      <mat-icon *ngIf="FileModel?.Dtos.length>1" (click)="DeleteSliderImage(dto)"
                        class="icon pointer">delete_outline</mat-icon>
                    </div>
                    <div class="col-md-7 col-sm-7"></div>
                    <div class="col-md-2 col-sm-2">
                      <mat-icon class="icon drag">drag_indicator</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <span matTooltip="{{dto.PhysicalName}}">{{dto.PhysicalName?.length>26?dto.PhysicalName?.substring(0,
                23) + '...':dto.PhysicalName}}</span>
            </div>
          </div>
          <div class="col-md-9 col-sm-9 content-scan">
            <img [src]="GetSliderImageSelected()" class="image">
            <div class="content-scan-paging">
              <div class="row">
                <div class="col-md-1 col-sm-1">
                  <button mat-icon-button (click)="SliderPagingClick('previous')" [disabled]="SliderCurrentPage<=1">
                    <mat-icon class="icon">arrow_upward</mat-icon>
                  </button>
                </div>
                <div class="col-md-2 col-sm-2">
                  <button mat-icon-button (click)="SliderPagingClick('next')"
                    [disabled]="SliderCurrentPage === FileModel.Dtos?.length">
                    <mat-icon class="icon">arrow_downward</mat-icon>
                  </button>
                </div>
                <div class="col-md-6 col-sm-6"></div>
                <div class="col-md-3 col-sm-3 pt-2"><span class="page-number">{{SliderCurrentPage + ' /
                    ' +
                    FileModel.Dtos?.length}}</span></div>
              </div>
            </div>
          </div>
          <div class="row top-shadow"></div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button *ngIf="Step===1 || (this.navigatorService.PageAction === 'Edit' && Step===2)" class="it-doc-btn-cancel"
      style="margin-inline-end: auto;" [mat-dialog-close]="null">
      <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
    </button>
    <button
      *ngIf="(Step>1 && this.navigatorService.PageAction !== 'Edit') || (Step>2 && this.navigatorService.PageAction === 'Edit')"
      class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
      <span class="button-text">{{navigatorService.Dictionary?.Back}}</span>
    </button>
    <button *ngIf="Step<Steps" (click)="Next()" mat-button class="it-doc-btn-primary" cdkFocusInitial
      [disabled]="!EnableDisableButtonNext(Step)">
      <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
    </button>
    <button *ngIf="Step===Steps" (click)="Save()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
      <span class="button-text"
        *ngIf="navigatorService.PageAction === 'New'">{{navigatorService.Dictionary?.Create}}</span>
      <span class="button-text"
        *ngIf="navigatorService.PageAction === 'Edit'">{{navigatorService.Dictionary?.Update}}</span>
    </button>
  </div>
</div>

<input type="file" class="d-none" accept="*.*" #uploadFile>
<input type="file" class="d-none" accept=".jpg,.jpeg,.png,.tiff" multiple #multiUploadFile>
