import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignatureComponent } from '../signature.component';
import { SignatureVerifyModel } from '../../../../../models/SignatureVerify';
import { ObjectDto } from '../../../../models/ObjectModel';
import { SettingsController } from '../../../../../controllers/SettingsController';
import { NavigatorService } from '../../../../../navigator.services';
import { DataService } from '../../../../../data.service';
import { CommonService } from '../../../../../common.service';
import { AuthService } from '../../../../../auth.service';
import { FileDto, FileModel } from '../../../../models/FileModel';
import { Constants } from '../../../../doc.configuration';

@Component({
  selector: 'app-verify-signature',
  templateUrl: './verify-signature.component.html',
  styleUrls: ['./verify-signature.component.scss']
})
export class VerifySignatureComponent implements OnInit {

  Model: SignatureVerifyModel;
  ObjectDto: ObjectDto;
  SettingsController: SettingsController;
  constructor(
    public navigatorService: NavigatorService,
    private dataService: DataService, public commonService: CommonService,
    public dialogRef: MatDialogRef<SignatureComponent>,
    public authenticationService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.ObjectDto = data.Dto;
    this.SettingsController = new SettingsController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    const fileModel = new FileModel();
    fileModel.Dto = new FileDto();
    fileModel.Dto.Path = Constants.AppId + '/' + this.ObjectDto.Path;
    fileModel.Dto.PhysicalName = this.ObjectDto.PhysicalName;
    this.Model = await this.SettingsController.SignatureVerify(fileModel);
    if (!this.Model?.Performed) {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
    this.navigatorService.StopLoading();
  }
}


