import { Authorization } from '../../documentary/models/ObjectModel';
import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { WorkflowDataStepDto } from './WorkflowDataStepModel';
import { WorkflowStepDto } from './WorkflowStepModel';

export class WorkflowDataModel extends BaseModel<
  WorkflowDataEntity,
  WorkflowDataDto,
  WorkflowDataFilter
> {
  constructor() {
    super();
  }
}

export class WorkflowDataEntity extends BaseEntity {
  WorkflowId: number;
  ObjectId: number;
  State: string;

  constructor() {
    super();
  }
}

export class WorkflowDataDto extends WorkflowDataEntity {
  WorkflowName: string;
  WorkflowRelevantSector: string;
  WorkflowTimeLimit: number;
  Action: string;
  WorkflowDataSteps: Array<WorkflowDataStepDto>;
  WorkflowSteps: Array<WorkflowStepDto>;
  Authorization: Authorization;
  constructor() {
    super();
    this.WorkflowName = null;
    this.WorkflowRelevantSector = null;
    this.WorkflowTimeLimit = 0;
    this.State = null;
  }
}

export class WorkflowDataFilter extends WorkflowDataDto {}
