import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConservationLoginComponent } from '../../../dialog-menu/conservation-login/conservation-login.component';
import { DocumentListComponent } from '../../../dialog-menu/document-list/document-list.component';
import { MessageComponent } from '../../../dialog-menu/message/message.component';
import { UploadquequeComponent } from '../../../dialog-menu/uploadqueque/uploadqueque.component';
import { PagerComponent } from '../../../../custom-components/pager/pager.component';
import {
  ViewRepositoryDto,
  ViewRepositoryModel,
} from '../../../../models/SystemInfoModel copy';
import {
  Children,
  SystemInfoDto,
  SystemInfoModel,
} from '../../../../models/SystemInfoModel';
import { DocFlyController } from '../../../../controllers/DocFlyController';
import { Pages, PDAStatus } from '../../../../doc.configuration';
import { DocClassDto, DocClassModel } from '../../../../models/DocClassModel';
import { NavigatorService } from '../../../../../navigator.services';
import { AuthService } from '../../../../../auth.service';
import { CommonService } from '../../../../../common.service';
import { DataService } from '../../../../../data.service';
import { ObjectDto } from '../../../../models/ObjectModel';

@Component({
  selector: 'app-pda-detail',
  templateUrl: './pda-detail.component.html',
  styleUrls: ['./pda-detail.component.scss'],
})
export class PdaDetailComponent implements OnInit {
  @ViewChild('pager') Pager: PagerComponent;
  Model: ViewRepositoryModel;
  DataSource: MatTableDataSource<Children>;
  Controller: DocFlyController;
  NodeRef: string;
  Pda: string;
  ArchiveName: string;
  PdaName: string;
  PdaStatus = PDAStatus;
  Resfresh = false;
  StatePDA: string;
  RefreshTimer;
  DocCalssModel: DocClassModel;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService
  ) {
    this.NodeRef = this.navigatorService.GetQueryParameter('node');
    this.Pda = this.navigatorService.GetQueryParameter('pda');
    this.Controller = new DocFlyController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
    this.RefreshTimer = setInterval(async () => {
      if (!this.Resfresh && !this.navigatorService.Loading) {
        if (window.location.href.includes(Pages.DetailPda)) {
          this.Resfresh = true;
          await this.GetArchives();
          await this.GetFiles(this.Pager.CurrentPage);
        } else {
          clearInterval(this.RefreshTimer);
        }
      }
    }, 30000);
  }

  async Load() {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    if (!this.authenticationService.IsAuthenticatedDocFly()) {
      this.navigatorService.StopLoading();
      const autoLogin = await this.AutoLogin();
      if (!autoLogin) {
        this.navigatorService.ShowDialog(
          ConservationLoginComponent,
          null,
          '300px',
          '330px',
          '330px',
          async (model) => {
            this.navigatorService.StartLoading();
            if (model?.Performed && model?.Code === 200) {
              this.authenticationService.DocflyUser = {
                Username: model.Username,
                Ticket: model.Dto.Ticket,
              };
              const performed = await this.GetArchives();
              if (performed) {
                await this.GetFiles();
                this.navigatorService.StopLoading();
              } else {
                return;
              }
            } else {
              this.navigatorService.GoTo(Pages.Conservation);
            }
          }
        );
      } else {
        const performed = await this.GetArchives();
        if (performed) {
          await this.GetFiles();
          this.navigatorService.StopLoading();
        } else {
          return;
        }
      }
    } else {
      const performed = await this.GetArchives();
      await this.GetFiles();
      this.navigatorService.StopLoading();
    }
  }

  async GetArchives() {
    this.navigatorService.SystemInfoModel = new SystemInfoModel();
    this.navigatorService.SystemInfoModel.Dto = new SystemInfoDto();
    this.navigatorService.SystemInfoModel.Dto =
      this.authenticationService.DocflyUser;
    this.navigatorService.SystemInfoModel.Dto.Page = this.Pager
      ? this.Pager.CurrentPage
      : 1;
    this.navigatorService.SystemInfoModel =
      await this.Controller.GetArchivesInfo(
        this.navigatorService.SystemInfoModel
      );
    if (this.navigatorService.SystemInfoModel?.Code === 500) {
      // this.navigatorService.SystemInfoModel.Message.includes('Token valido inesistente');
      this.navigatorService.SystemInfoModel = null;
      this.authenticationService.DocflyUser = null;
      this.navigatorService.GoTo(Pages.Conservation);
      return false;
    } else {
      const archive =
        this.navigatorService.SystemInfoModel.Dto.ArchivesInfo.find(
          (q) => q.NodeRef === this.NodeRef
        );
      if (archive) {
        this.ArchiveName = archive.Name;
        const pda = archive?.ViewRepository.Children.find(
          (q) => q.NodeRef === this.Pda
        );
        this.StatePDA = pda.State;
        if (this.StatePDA === this.PdaStatus.CONSERVATO) {
          //clearInterval(this.RefreshTimer);
        }
        this.PdaName = pda ? pda.Title : '';
      }
    }
    return true;
  }

  async AutoLogin() {
    const model = await this.Controller.Login(null, null, false, true);
    if (model?.Performed && model?.Code === 200) {
      this.authenticationService.DocflyUser = {
        Username: model.Username,
        Ticket: model.Dto.Ticket,
      };
      return true;
    }
    return false;
  }

  async GetFiles(page: number = 1) {
    this.Model = new ViewRepositoryModel();
    this.Model.Dto = new ViewRepositoryDto();
    this.Model.Dto.Ticket = this.authenticationService.DocflyUser.Ticket;
    this.Model.Dto.NodeRef = this.Pda;
    this.Model.Dto.Page = page;
    this.Model = await this.Controller.GetRepositoryView(this.Model);
    if (this.Model?.Code === 500) {
      // this.Model.Message.includes('Token valido inesistente');
      this.Model.Dto = null;
      this.authenticationService.DocflyUser = null;
      this.navigatorService.GoTo(Pages.Conservation);
    } else if (this.Model?.Performed) {
      this.Model.Count = this.Model.Dto?.NumberOfElements;
      this.Model.Take = this.Model.Dto?.PageSize;
      this.Model.Dtos = this.Model.Dto?.Children;
      this.CheckError();
    }
    this.Paging(page);
    this.Resfresh = false;
  }

  CheckError() {
    const ipdvs = this.Model?.Dto?.Children?.filter(
      (q) => q.Ipdv === true && q.State === this.PdaStatus.ERRORE
    );
    if (ipdvs && ipdvs.length <= 0) {
      return false;
    }
    return true;
  }

  async DownloadFile(file: Children) {
    let model = new ViewRepositoryModel();
    model.Dto = new ViewRepositoryDto();
    model.Dto.Ticket = this.authenticationService.DocflyUser.Ticket;
    model.Dto.NodeRef = file.NodeRef;
    model = await this.Controller.DownloadFile(model);
    if (model?.Performed) {
      const blob = new Blob([model.Dto.File]);
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = file.Title;
      element.click();
    }
  }

  async EditIPDV(file: Children) {
    this.commonService.SidebarMenuClick(Pages.Pda, this.navigatorService, {
      queryParams: { pda: this.Pda, pdv: file.Title },
      queryParamsHandling: 'merge',
    });
  }

  async ShowDisposedLog(file: Children) {
    let model = new ViewRepositoryModel();
    model.Dto = new ViewRepositoryDto();
    model.Dto.Ticket = this.authenticationService.DocflyUser.Ticket;
    model.Dto.PdaName = this.PdaName;
    model.Dto.ArchiveName = this.ArchiveName;
    model = await this.Controller.GetDisposedLog(model);
    if (model?.Performed) {
      const data = {
        Title: this.navigatorService.Dictionary.ShowLog,
        Message: model.Message,
        Placeholder: this.navigatorService.Dictionary.ShowLog,
      };
      this.navigatorService.ShowDialog(
        MessageComponent,
        data,
        '30%',
        '50%',
        '200px'
      );
    } else {
      const data = {
        Title: this.navigatorService.Dictionary.ShowLog,
        Message: model.Message,
        Placeholder: this.navigatorService.Dictionary.ShowLog,
      };
      this.navigatorService.ShowDialog(
        MessageComponent,
        data,
        '30%',
        '50%',
        '200px'
      );
    }
  }

  async AddDocuments() {
    this.DocCalssModel = await this.GetPDVXml(this.PdaName);
    if (this.DocCalssModel) {
      for (const file of this.DocCalssModel.Dto.Files) {
        const objectDto = new ObjectDto();
        objectDto.Id = file.File.ObjectId;
        this.DocCalssModel.Dtos.push(objectDto);
      }
    }
    const data = {
      FileEnabled: this.GetFilesEnabled(this.DocCalssModel.Dto.DocClass),
      Documents: this.DocCalssModel.Dto.Files
        ? this.DocCalssModel.Dtos
        : new Array<File>(),
      IsUpload: true,
    };
    this.navigatorService.ShowDialog(
      DocumentListComponent,
      data,
      '45%',
      'fit-content',
      '200px',
      async (objectDtos) => {
        if (objectDtos) {
          this.DocCalssModel.Dtos = objectDtos.filter((q) => q.Hash);
          await this.UploadQueque();
        }
      }
    );
  }

  CanAddDocuments() {
    if (this.StatePDA === this.PdaStatus.APERTO && !this.CheckError()) {
      return true;
    }
    return false;
  }

  async UploadQueque() {
    this.navigatorService.ShowDialog(
      ConservationLoginComponent,
      { Fake: true },
      '300px',
      '330px',
      '330px',
      async (user) => {
        if (user && user?.Username && user?.Password) {
          this.navigatorService.StartLoading();
          this.DocCalssModel.Message = null;
          this.DocCalssModel.Code = null;
          this.DocCalssModel.Dto.Username = user.Username;
          this.DocCalssModel.Dto.Password = user.Password;
          this.DocCalssModel.Dto.Owner =
            this.navigatorService.SystemInfoModel.Dto.ArchivesInfo.find(
              (q) => q.NodeRef === this.NodeRef
            ).Name;
          this.navigatorService.StopLoading();
          this.navigatorService.ShowDialog(
            UploadquequeComponent,
            { Model: this.DocCalssModel, IsUploadFilesOnly: true },
            '45%',
            'fit-content',
            '200px',
            async (performed) => {
              if (performed === true) {
                this.navigatorService.StopLoading();
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary.UploadCompleted,
                  this.DocCalssModel.Dto.PdaName
                );
                this.commonService.SidebarMenuClick(
                  Pages.ViewPda,
                  this.navigatorService,
                  { queryParams: { node: this.NodeRef } }
                );
              } else if (performed === false) {
                //  this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary.MessageGenericError);
              }
            }
          );
        }
      }
    );
  }

  async GetPDVXml(pdaName: string) {
    let model = new DocClassModel();
    model.Dto = new DocClassDto();
    model.Dto.PdaName = pdaName;
    model = await this.Controller.GetDocumentModel(model);
    if (model.Performed) {
      return model;
    }
    return null;
  }

  GetFilesEnabled(docClass: string): Array<string> {
    if (docClass) {
      const docClassNode =
        this.navigatorService.SystemInfoModel.Dto.SubClasses.find(
          (q) => (q.Name = docClass)
        );
      if (docClassNode) {
        return docClassNode.FilesEnabled;
      }
    }
    return [];
  }

  RefreshTable(dtos: Array<Children>) {
    dtos = dtos ? dtos : [];
    this.DataSource = null;
    dtos.map((q) => (q.State = q.State.toUpperCase()));
    this.DataSource = new MatTableDataSource<Children>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetFiles(page);
    this.navigatorService.StopLoading();
  }
}
