<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <div class="valign-icon-text">
          <img style="margin-right: 11px;" src="../../../../../assets/icon/tipology-black.svg">
          <h2>{{navigatorService.Dictionary?.TipologyTitle}}</h2>
        </div>
        <div class="d-flex align-items-center">
          <div *ngIf="Step===1" style="margin-right: 20px;">
            <app-upload [DataValue]='TemplateModel?.Dto' [Property]="'Name'" [Required]='false'
              [ButtonText]="navigatorService.Dictionary?.ButtonUpload" (Open)="OpenTemplate()"
              (OnChange)="FileSelected($event)">
            </app-upload>
          </div>
          <span class="current-step">{{Step}}</span>
          <span class="step-separator">|</span>
          <span class="steps">{{Steps}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div *ngIf="Step===1">
    <p *ngIf="navigatorService.PageAction !== 'Edit'" class="title-description mb-1">
      {{navigatorService.Dictionary?.TipologyDescription}}</p>
    <p *ngIf="navigatorService.PageAction === 'Edit'" class="title-description mb-1">
      {{navigatorService.Dictionary?.ModifyTipologyDescription}}</p>
    <div class="required-label">
      <span class="star">*</span>
      <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
    </div>
    <mat-dialog-content>
      <div class="form mb-5">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]='Model?.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true' [Maxlength]="50"
              [Placeholder]="navigatorService.Dictionary?.Name"
              [Description]="navigatorService.Dictionary?.Maxlength50"></app-input>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-chips-multiselect #uoMultiselect [Elements]="OrganizationUnitModel?.Dtos"
              (ChipsSelectedEmit)="SelectUO($event)" (ChipsDeletedEmit)="RemoveUo($event)" [Chips]="GetUoSelected()"
              [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.SelectOrganizationUnitPlaceholder"
              [Required]="true">
            </app-chips-multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <app-chips-multiselect (ChipsSelectedEmit)="SelectMimeTypes($event)"
              (ChipsDeletedEmit)="RemoveMimeTypesChips($event)" [Elements]="MimeTypeModel?.Dtos"
              [Chips]="SelectedMimetypesDtos" [NameProperty]="'Name'"
              [Placeholder]="navigatorService.Dictionary?.DocumentTipologyFileType" [Required]="true">
            </app-chips-multiselect>
          </div>
          <div class="col-md-6 col-sm-12">
            <!-- todo: signature input -->
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div *ngIf="Step===2">
    <p class="title-description" *ngIf="!PersonalizeFields">{{navigatorService.Dictionary?.TipologyDescription2}}</p>
    <p class="title-description" *ngIf="PersonalizeFields">{{navigatorService.Dictionary?.TipologyDescription3}}</p>
    <mat-dialog-content class="default-scrollbar" *ngIf="!PersonalizeFields">
      <div class="form mb-5">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <app-chips-multiselect *ngIf="MetaDataModel && MetaDataModel.Dtos"
              (ChipsSelectedEmit)="SelectGenericFields($event)" (ChipsDeletedEmit)="DeleteGenericFields($event.Id)"
              [Elements]="MetaDataModel?.Dtos" [Chips]="SelectedGenericFieldsDtos" [NameProperty]="'Name'"
              [LengthProperty]="'Lenght'" [PropertiesJson]="'Properties'"
              [Placeholder]="navigatorService.Dictionary?.Type" [AllowDuplicates]="false">
            </app-chips-multiselect>
          </div>
          <!-- <div class="col-md-6 col-sm-12">
                        <app-chips-multiselect *ngIf="FieldsModel && FieldsModel.Dtos"
                            (ChipsSelectedEmit)="SelectDefaultFields($event)"
                            (ChipsDeletedEmit)="DeleteDefaultFields($event.Id)" [Elements]="FieldsDefaultModel?.Dtos"
                            [Chips]="SelectedDefaultFieldsDtos" [NameProperty]="'Name'"
                            [Placeholder]="'Pre-set metadata'" [AllowDuplicates]="false" [disabled]="true" >
                        </app-chips-multiselect>
                    </div> -->
        </div>
      </div>
    </mat-dialog-content>
    <!-- <mat-dialog-content class="default-scrollbar" *ngIf="PersonalizeFields">
      <div class="page-field-container d-flex justify-content-between">
        <div>
          <ng-container *ngFor="let dto of MetaDataTipologyModel?.Dtos; let isLast = last; let index = index">
            <span (click)="PersonalizeFieldsPage(index)" class="page-field-text"
              [ngClass]="{'selected-field-page': CurrentIndexField === index}">{{dto?.Properties?.Placeholder ||
              dto?.Key}}</span>
            <span *ngIf="!isLast" class="page-field-separator"> | </span>
          </ng-container>
        </div>
        <div *ngIf="!CurrentField?.Properties?.Default"
          class="d-flex justify-content-center align-items-center delete-field-tab" (click)="DeleteTab()">
          <span style="margin-right: 5px;">{{navigatorService.Dictionary?.DeleteField}}</span>
          <img width="16" height="16" src="../../../../../assets/icon/trash.svg"
            style="filter: invert(100%) sepia(0%) saturate(4287%) hue-rotate(122deg) brightness(111%) contrast(101%);">
        </div>
      </div>
      <hr class="page-field-bottom-hr">
      <div class="form mb-5">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]='CurrentField?.Properties' [Property]="'Placeholder'" [Type]="'text'"
              [Required]='true' [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.TipologyPlaceholderField"
              [Description]="navigatorService.Dictionary?.Maxlength50"
              [Disabled]="CurrentField?.Properties.Default"></app-input>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-mt-autocomplete #autocompleteUser [appearance]="'fill'" [Items]="AccountModel?.Dtos"
              [DisplayProperties]="['DisplayName']" [Placeholder]="navigatorService.Dictionary?.OperatorWorkPlaceholder"
              [SearchProperties]="['DisplayName']" [LoaderType]="'Spinner'" [DropDownMode]="true" [Multiple]="false"
              (SelectionChange)="LdapUsersChange($event)" [Icon]="'arrow_drop_down'">
            </app-mt-autocomplete>
            <div class="row" *ngFor="let dto of GetMetadataAccounts()" style="height: 25px;">
              <div class="col-md-5 col-sm-11" style="height: 25px;">
                <div class="d-flex">
                  <img *ngIf="dto.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + dto.Avatar"
                    style="margin-right: 10px;">
                  <div *ngIf="!dto.Avatar" class="avatar-circle" style="margin-right: 10px;">{{dto.DisplayName |
                    acronym}}</div>
                  <span class="value-text">{{dto.DisplayName}}</span>
                </div>
              </div>
              <div class="col-md-1 col-sm-1" style="height: 25px;text-align: right;">
                <button mat-icon-button class="icon-button" (click)="RemoveUser(dto)" *ngIf="dto.Id>=0"
                  style="position: relative;top:-8px">
                  <mat-icon class="icon">close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row m-0">
          <p class="title-description-2">{{navigatorService.Dictionary?.TipologyFieldPreview}}</p>
          <div class="col-md-6 col-sm-12 preview-box">
            <app-input *ngIf="CurrentField.Type ==='text'" [DataValue]='CurrentField.Properties' [Property]=""
              [Type]="'text'" [Required]='CurrentField.Properties.Required' [Maxlength]="50"
              [Placeholder]="CurrentField.Properties.Placeholder" [Description]="">
            </app-input>

            <app-input *ngIf="CurrentField.Type ==='text-area'" [DataValue]='CurrentField.Properties' [Property]=""
              [Type]="'text-area'" [Required]='CurrentField.Properties.Required' [Maxlength]="250"
              [Placeholder]="CurrentField.Properties.Placeholder" [Description]="">
            </app-input>

            <app-picker *ngIf="CurrentField.Type ==='date'" [Placeholder]="CurrentField.Properties.Placeholder"
              [Required]='CurrentField.Properties.Required' [DataValue]='CurrentField.Properties' [Property]="">
            </app-picker>


            <app-select *ngIf="CurrentField.Type ==='combo'" [Placeholder]="CurrentField.Properties.Placeholder"
              [Required]='CurrentField.Properties.Required' [DataValue]='CurrentField.Properties' [Property]="'Value'"
              [Items]="CurrentField.Properties.Items" [MultiSelect]="CurrentField.Properties.Multiple">
            </app-select>
          </div>
          <div class="col-md-6 col-sm-12">
            <app-input [DataValue]='CurrentField.Properties' [Property]="'Token'" [Type]="'text'" [Required]='false'
              [Maxlength]="50" [Placeholder]="navigatorService.Dictionary?.TokenPlaceholder"
              [Description]="navigatorService.Dictionary?.Maxlength50">
            </app-input>
          </div>
        </div>

        <div class="row m-0  mt-4">
          <div class="col-md-6 col-sm-12">
            <div *ngIf="CurrentField.Type ==='combo'">
              <p class="title-description-2">{{navigatorService.Dictionary?.SelectedItems}}</p>
              <mat-form-field class="form-field">
                <mat-label class="placeholder">{{navigatorService.Dictionary?.AddItem}}</mat-label>
                <input class="input-text" matInput required [(ngModel)]="Option">
                <button matSuffix mat-icon-button (click)="AddOption()">
                  <span class="material-icons icon-action">
                    add
                  </span>
                </button>

              </mat-form-field>
              <div class="row" *ngFor="let item of this.CurrentField.Properties.Items">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between">
                    <span> {{item.Value}}</span>
                    <div>
                      <button matSuffix mat-icon-button (click)="EditOption(item)" class="float-right">
                        <span class="material-icons icon-action align-top">
                          edit
                        </span>
                      </button>
                      <button matSuffix mat-icon-button (click)="DeleteOption(item)" class="float-right">
                        <span class="material-icons icon-action align-top">
                          delete
                        </span>
                      </button>
                    </div>
                  </div>
                  <hr class="w-100">
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="CurrentField.Type ==='combo'" class="col-md-6 col-sm-12" style="margin-top: 25px;">
            <mat-checkbox [(ngModel)]="CurrentField.Properties.Required">
              <span class="value-text">{{navigatorService.Dictionary?.FieldRequired}}</span>
            </mat-checkbox>
          </div>
        </div>

        <div *ngIf="CurrentField.Type !=='combo'" class="row m-0  mt-4">
          <div class="col-md-6 col-sm-12">
            <mat-checkbox [(ngModel)]="CurrentField.Properties.Required">
              <span class="value-text">{{navigatorService.Dictionary?.FieldRequired}}</span>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </mat-dialog-content> -->
  </div>

  <!-- <div *ngIf="Step===3">
    <p class="title-description">{{navigatorService.Dictionary?.TipologySummaryDescription}}</p>
    <mat-dialog-content class="default-scrollbar">
      <div class="form mb-5">
        <div class="row">
          <p class="title-preview-box">{{navigatorService.Dictionary?.Name}}</p>
          <div class="col-md-6 col-sm-12">
            <div class="preview-box d-flex align-items-center">
              <span class="value-in-preview-box">{{Model.Dto.Name}}</span>
            </div>
          </div>
        </div>
        <p class="title-description-2 mt-4">{{navigatorService.Dictionary?.Preview}}</p>
        <ng-container *ngIf="GetNoPreSetMetadata(); let noPreSetDtos">
          <div *ngIf="noPreSetDtos.length > 0" class="row mb-3 preview-box d-flex flex-wrap">
            <div *ngFor="let dto of noPreSetDtos;let i = index" class="col-md-6 col-sm-12 mb-3">
              <app-input *ngIf="dto.Type ==='text'" [DataValue]='dto' [Property]="" [Type]="'text'"
                [Required]='dto.Properties.Required' [Maxlength]="50" [Placeholder]="dto.Properties.Placeholder"
                [Description]="">
              </app-input>

              <app-input *ngIf="dto.Type ==='text-area'" [DataValue]='dto.Properties' [Property]="" [Type]="'text-area'"
                [Required]='dto.Properties.Required' [Maxlength]="250" [Placeholder]="dto.Properties.Placeholder"
                [Description]="">
              </app-input>

              <app-picker *ngIf="dto.Type ==='date'" [Placeholder]="dto.Properties.Placeholder"
                [Required]='dto.Properties.Required' [DataValue]='dto.Properties' [Property]="">
              </app-picker>
              <app-select *ngIf="dto.Type ==='combo'" [Placeholder]="dto.Properties.Placeholder"
                [Required]='dto.Properties.Required' [DataValue]='dto.Properties' [Property]="'Value'"
                [Items]="dto.Properties.Items" [MultiSelect]="dto.Properties.Multiple">
              </app-select>
            </div>
            <div>
              <hr class="metadata-separator">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="GetPreSetMetadata(); let preSetDtos">
          <div *ngIf="preSetDtos.length > 0" class="row preview-box d-flex flex-wrap">
            <div *ngFor="let dto of preSetDtos;let i = index" class="col-md-6 col-sm-12 mb-3">
              <app-input *ngIf="dto.Type ==='text'" [DataValue]='dto' [Property]="" [Type]="'text'"
                [Required]='dto.Properties.Required' [Maxlength]="50" [Placeholder]="dto.Properties.Placeholder"
                [Description]="">
              </app-input>

              <app-input *ngIf="dto.Type ==='text-area'" [DataValue]='dto.Properties' [Property]="" [Type]="'text-area'"
                [Required]='dto.Properties.Required' [Maxlength]="250" [Placeholder]="dto.Properties.Placeholder"
                [Description]="">
              </app-input>

              <app-picker *ngIf="dto.Type ==='date'" [Placeholder]="dto.Properties.Placeholder"
                [Required]='dto.Properties.Required' [DataValue]='dto.Properties' [Property]="">
              </app-picker>
              <app-select *ngIf="dto.Type ==='combo'" [Placeholder]="dto.Properties.Placeholder"
                [Required]='dto.Properties.Required' [DataValue]='dto.Properties' [Property]="'Value'"
                [Items]="dto.Properties.Items" [MultiSelect]="dto.Properties.Multiple">
              </app-select>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-dialog-content>
  </div> -->

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button *ngIf="Step===1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
      <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
    </button>
    <button *ngIf="Step>1" class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Back()">
      <span class="button-text">{{navigatorService.Dictionary?.Back}}</span>
    </button>
    <button *ngIf="Step<2" (click)="Next()" mat-button class="it-doc-btn-primary" [disabled]="DisableNextButton(Step)"
      cdkFocusInitial>
      <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
    </button>
    <button *ngIf="Step===2" (click)="Save()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
      <span *ngIf="navigatorService.PageAction ==='New'"
        class="button-text">{{navigatorService.Dictionary?.Create}}</span>
      <span *ngIf="navigatorService.PageAction ==='Edit'"
        class="button-text">{{navigatorService.Dictionary?.Update}}</span>
    </button>
  </div>
</div>
