<div class="component-container">
    <span class="material-icons icon-drag icon-align-center" cdkDragHandle>
        drag_indicator
    </span>
    <mat-form-field appearance="fill" *ngIf="!Minimize">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{Properties?.Placeholder  + (Properties?.Required ?' *':'')}}">
            <input matEndDate formControlName="end" placeholder="{{Properties?.Placeholder  + (Properties?.Required ?' *':'')}}">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <span class="material-icons icon icon-align-center" (click)="DeleteComponent()">
        delete
    </span>
</div>

<div class="toggle-container">
    <mat-slide-toggle [(ngModel)]="Properties.Required" labelPosition="before" class="required-toggle">
        {{ navigatorService?.Dictionary?.Required }}
    </mat-slide-toggle>
</div>

<!-- <div *ngIf="Minimize" class="data-container">
    <div class="row">
        <div class="col-md-2">
            <div #calendaricon>
                <button title="calendar" mat-icon-button (click)="Open(picker)" style="top:-3px;left:-8px;">
                    <img title="calendar" src="../../../../assets/icon/calendar.svg">
                </button>
            </div>
        </div>
        <div class="col-md-6">
            <span class="value" >{{End | date: FormatDate}}</span>
        </div>
    </div>

    <mat-form-field class="form-field-hidden">
        <mat-date-range-input   [rangePicker]="picker" style="display: none;">
            <input matStartDate  placeholder="{{Placeholder + (Required ?' *':'')}}" [value]="Start" (dateChange)="Change($event,'start')" >
            <input matEndDate   placeholder="{{Placeholder + (Required ?' *':'')}}" [value]="End"  (dateChange)="Change($event,'end')">
        </mat-date-range-input>        
        <mat-date-range-picker #picker ></mat-date-range-picker>
    </mat-form-field>
</div> -->