<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <h2>
          Aggiungi documenti al pacchetto di versamento</h2>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div>
    <p class="title-description">{{navigatorService.Dictionary?.DossierDescription3}}</p>
    <mat-dialog-content style="min-height: 26vh;overflow: hidden ">
      <div>
        <div class="row">
          <div class="col-md-12 col-sm-12 ">
            <app-search #search [Placeholder]="navigatorService.Dictionary?.DossierSearchPlaceholder"
              [HaveFilters]="false" [HaveActions]="false" [ModalStyle]="true" (OnEnterPress)="Search()"
              (OnClearBtnPress)="Search()">
            </app-search>
          </div>
          <div class="col-md-6 col-sm-12">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder">
              <div class="col-md-4">
                <button mat-icon-button (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder">
                  <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
                  <span>{{navigatorService.Dictionary?.Back}}
                  </span>
                </button>
              </div>
              <div class="col-md-4 text-center current-folder">
                <span>{{CurrentFolder?.Name}}</span>
              </div>
              <div class="col-md-4"></div>
            </div>

            <div class="it-doc-table-container default-scrollbar" style="height: 26vh; overflow: auto">
              <table mat-table [dataSource]="DataSource" matSort>
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef class="checkbox-column"></th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox *ngIf="element.Type === 'file'" [checked]="element.Checked"
                      [disabled]="!element.Enabled" (change)="element.Checked = $event.checked;"></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="FileIcon">
                  <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                  <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                    <img src=" {{element.Icon}}" class="{{element.ClassIcon}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnName}} </th>
                  <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                    <span>
                      {{element.Name + (element.Type==='file'?'.' +
                      commonService.GetExtension(element.PhysicalName):'')}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnCreate}}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnCreate}} </th>
                  <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                    <span>
                      {{element.DisplayName}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
                  <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                    <span *ngIf="element.Deep>=0">
                      {{element.ModificationDateString}}
                    </span>
                    <span *ngIf="!element.Deep">
                      {{element.UpdateDate | date:'dd/MM/yyyy'}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ArrowIcon">
                  <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
                  <td mat-cell *matCellDef="let element" (click)="ObjectsClick(element)">
                    <mat-icon *ngIf="element.Type==='folder'">arrow_forward_ios</mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row
                  *matHeaderRowDef="['checkbox','FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon']">
                </tr>
                <tr mat-row class="clickable-icon" [ngClass]="!row.Enabled?'disabled-row':''"
                  [matTooltip]="row.EnabledMessage" [matTooltipDisabled]="row.Enabled"
                  *matRowDef="let row; columns: ['checkbox','FileIcon', navigatorService.Dictionary?.TableColumnName,navigatorService.Dictionary?.TableColumnCreate,navigatorService.Dictionary?.TableColumnUpdate,'ArrowIcon'];">
                </tr>

              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 ">
            <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>




  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
      <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
    </button>

    <button (click)="Confirm()" mat-button class="it-doc-btn-primary" [disabled]="false">
      <span class="button-text">{{navigatorService.Dictionary?.Confirm}}</span>
    </button>

  </div>
</div>

<input type="file" class="d-none" accept="*.*" #uploadFile>
<input type="file" class="d-none" accept=".jpg,.jpeg,.png,.tiff" multiple #multiUploadFile>
