import { Component, Input, OnInit } from '@angular/core';
import { SliderComponent } from '../../../../../../custom-components/slider/slider.component';
import { PropertyDto } from '../../../../../../models/FieldsModel';
import { MetadataDto } from '../../../../../../models/MetadataModel';
import { Column } from '../../Form';

@Component({
  selector: 'app-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss'],
})
export class FormSliderComponent<TDto> extends SliderComponent<TDto> {
  @Input() Properties: PropertyDto;
  @Input() Data: any;

  public DeleteComponent() {
    const component: MetadataDto = this.Data.item;
    const column: Column = this.Data.column;
    column.RemoveComponent(component);
  }
}
