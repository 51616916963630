import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { WorkflowDataDto } from "../../workflow/models/WorkflowDataModel";
import { WorkflowUserDto } from "../../workflow/models/WorkflowUserModel";
import { AccountDto } from './AccountModel';
import { FavouriteEntity } from './FavouriteModel';

export class ObjectModel extends BaseModel<ObjectEntity, ObjectDto, ObjectFilter> {
  DestinationFolderId: number;
  Action: string;
  constructor() {
    super();
    this.Take = 10;
  }
}

export class ObjectEntity extends BaseEntity {
  TipologyUoId: number;
  Type: string; // 10 = Folder/File
  Description: string; // 500
  Trashed: boolean;
  Name: string; // 500
  PhysicalName: string; // 500
  Path: string; // 4000
  Creator: string; // 500
  CreationDate: number;
  Modifier: string; // 500
  ModificationDate: number;
  ProgressiveString: string; // 200
  Progressive: number;
  Lock: boolean;
  Size: any;
  Version: number;
  Current: boolean;
  Parent: number;
  Deep: number;
  Hash: string;
  State: any;
  WorkflowId: number;
  Input: boolean;
  Sign: boolean;
  Crypt: boolean;
  Desktop:boolean;
  constructor() {
    super();
    this.TipologyUoId = null;
    this.Type = null;
    this.Description = null;
    this.Trashed = false;
    this.Name = null;
    this.PhysicalName = null;
    this.Path = null;
    this.Creator = null;
    this.CreationDate = null;
    this.Modifier = null;
    this.ModificationDate = null;
    this.ProgressiveString = null;
    this.Progressive = null;
    this.Lock = false;
    this.Size = null;
    this.Version = null;
    this.Current = true;
    this.Parent = null;
    this.Deep = null;
    this.Hash = null;
    this.State = null;
    this.WorkflowId = null;
    this.Input = false;
    this.Sign = false;
    this.Crypt = false;
    this.Desktop=false;
  }
}

export class ObjectDto extends ObjectEntity {
  TipologyId: number;
  UoId?: number;
  UoName: string;
  Format: string;
  AccountId: number;
  Icon: string;
  ClassIcon: string;
  Checked?: boolean;
  Template: boolean;
  CreationDateString: string;
  ModificationDateString: string;
  MimeType: string;
  Selected: boolean;
  Share: boolean;
  Avatar: string;
  Admins: Array<AccountDto>;
  TipologyName: string;
  UpdateDate: string;
  Favourite: FavouriteEntity;
  IsFavourite: boolean;
  Authorization: Authorization;
  WorkflowData: WorkflowDataDto;
  Team: Array<WorkflowUserDto>;
  OriginalExtension: string;
  CountAttachment: number;
  IsSignatory: boolean;
  IsRedirect: boolean;
  EnabledMessage: string;
  Enabled: boolean;
 
  constructor() {
    super();
    this.TipologyId = null;
    this.UoId = null;
    this.UoName = null;
    this.Format = null;
    this.AccountId = null;
    this.Icon = null;
    this.ClassIcon = null;
    this.Checked = false;
    this.Template = false;
    this.CreationDateString = null;
    this.ModificationDateString = null;
    this.MimeType = null;
    this.Selected = false;
    this.Share = false;
    this.Avatar = null;
    this.Admins = null;
    this.TipologyName = null;
    this.Favourite = null;
    this.IsFavourite = (this.Favourite !== null);
    this.Authorization = new Authorization();
    this.Team = new Array<WorkflowUserDto>();
    this.OriginalExtension = null;
    this.IsSignatory = false;
    this.IsRedirect = false;
    this.EnabledMessage = null;
    this.Enabled = true;
  }
}

export class ObjectFilter extends ObjectDto {
  SearchUo: any;
  SearchFileExtension: any;
  SearchTipology: any;
  RoleName: string;
  YourRequest: boolean;

  Title: string;
  Recipient: string;
  Sender: string;
  constructor() {
    super();
    this.Input = null;
  }
}

export class Authorization {
  Authorized: boolean;
  AuthorizedView: boolean;
  Action: string;
  RequestEditing: boolean;
  NextAction: string;
  constructor() {
    this.Authorized = false;
    this.AuthorizedView = false;
    this.Action = null;
    this.RequestEditing = false;
  }
}
