<div class="itdoc-page tiny-scrollbar ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <div class="valign-icon-text">
          <span class="material-icons title-icon">
            upload
          </span>
          <h2>{{navigatorService.Dictionary?.UploadPDA }}</h2>
        </div>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <button mat-icon-button [mat-dialog-close]="null" [disabled]="UploadInProgress">
        <span class="material-icons icon-close">
          close
        </span>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <div class="form mb-5  tiny-scrollbar" [ngStyle]="{'min-height':Message?.length>0?'400px':'200px'}">
      <span class="title-description">Indici da caricare (PDV): {{Model.PdvModels?.length}}</span>
      <br>
      <span class="title-description">File da caricare: {{Model.Dtos?.length}}</span>

      <div class="row mt-4">
        <div class="col-11">
          <span class="title-description">{{FileUploading}}</span>
          <mat-progress-bar mode="determinate" value="{{ProgressUpload}}" class="progressbar">
          </mat-progress-bar>

          <button *ngIf="!UploadInProgress && Message?.length>0" (click)="ExportTXTLog()" mat-button
            class="mt-3 it-doc-btn-add-folder-small">
            <span class="button-text">{{navigatorService.Dictionary?.ExportLog}}</span>
          </button>

          <p *ngIf="Message" style="color: red;margin-top: 10px;" [innerHtml]="Message">
          </p>
        </div>
        <div class="col-1">
          <span style="position: relative;"
            [ngStyle]="{'top':Message?.length>0?'26px':'2px'}">{{ProgressUpload}}%</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button *ngIf="UploadCompleted === 0" (click)="Upload()" mat-button class="it-doc-btn-primary"
      [disabled]="UploadInProgress">
      <span class="button-text">{{navigatorService.Dictionary?.Upload}}</span>

    </button>
    <button mat-button class="it-doc-btn-primary" (click)="Close()" [disabled]="UploadInProgress">
      <span class="button-text">{{navigatorService.Dictionary?.Close}}</span>
    </button>
  </div>
</div>
