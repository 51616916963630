<div id="ItDocDashboard" class="container-fluid">
    <div *ngIf="authService.CurrentUser.IsAdmin" class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 mx-0 mb-3 mt-4 p-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 m-0 px-4 py-4">
            <app-card (MoreActionCard)="OpenRequestModal(YourRequest,'YOURREQUEST',DashboardCardType.Chart,navigatorService.Dictionary?.YourRequest)"
             [CardTitle]="navigatorService.Dictionary?.YourRequest" [CardType]="DashboardCardType.Chart" [DatasetModel]="YourRequest" 
             [IsEmpty]="IsEmptyYourRequest"></app-card>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 m-0 px-4 py-4">
            <app-deliberations-card [DatasetModel]="AllDocument"></app-deliberations-card>
        </div>
    </div>
    <div class="row row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 mx-0 mb-3 mt-0 p-0">
        <div class="col-3 px-4 py-4">
            <app-card [CardType]="DashboardCardType.MinimalCounter" [DataModel]="RevisionDocs" [IsEmpty]="StatisticsModel?.Dto?.YourDocsInRevisionCount <= 0"
            (MoreActionCard)="OpenRequestModal(RevisionDocs,'YOURDOCS',DashboardCardType.Chart,RevisionDocs.Name)"></app-card>
        </div>
        <div class="col-3 px-4 py-4">
            <app-card (MoreActionCard)="OpenRequestModal(InApprovalDocs,'YOURDOCS',DashboardCardType.Chart,InApprovalDocs.Name)" [CardType]="DashboardCardType.MinimalCounter" [DataModel]="InApprovalDocs" [IsEmpty]="StatisticsModel?.Dto?.YourDocsInApprovalCount <= 0"></app-card>
        </div>
        <div class="col-3 px-4 py-4">
            <app-card (MoreActionCard)="OpenRequestModal(ApprovedDocs,'YOURDOCS',DashboardCardType.Chart,ApprovedDocs.Name)" [CardType]="DashboardCardType.MinimalCounter" [DataModel]="ApprovedDocs" [IsEmpty]="StatisticsModel?.Dto?.YourDocsApprovedCount <= 0"></app-card>
        </div>
        <div class="col-3 px-4 py-4">
            <app-card (MoreActionCard)="OpenRequestModal(ArchivedDocs,'YOURDOCS',DashboardCardType.Chart,ArchivedDocs.Name)" [CardType]="DashboardCardType.MinimalCounter" [DataModel]="ArchivedDocs" [IsEmpty]="StatisticsModel?.Dto?.YourDocsArchivedCount <= 0"></app-card>
        </div>
    </div>
    <div *ngIf="authService.CurrentUser.IsAdmin" class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 mx-0 mb-3 mt-0 p-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 m-0 px-4 py-4" >
            <app-protocols-card [DatasetModel]="AllProtocolsDossiers"></app-protocols-card>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 m-0 px-4 py-4">
            <app-activities-card [DataValue]="ActivityModel?.Dtos"></app-activities-card>
        </div>
    </div>
    <div *ngIf="!authService.CurrentUser.IsAdmin" class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 mx-0 mb-3 mt-4 p-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 m-0 px-4 py-4">
            <app-card (MoreActionCard)="OpenRequestModal(YourRequest,'YOURREQUEST',DashboardCardType.ProgressBar,navigatorService.Dictionary?.YourRequest)" [CardTitle]="navigatorService.Dictionary?.YourRequest" [CardType]="DashboardCardType.ProgressBar" [DatasetModel]="YourRequest" [IsEmpty]="IsEmptyYourRequest"></app-card>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 m-0 px-4 py-4">
            <app-activities-card [DataValue]="ActivityModel?.Dtos"></app-activities-card>
        </div>
    </div>
    <!-- <div class="mx-0 mb-3 mt-0 p-0" *ngIf="authService.DocAccount?.Dto?.UoId>0">
        <app-document-view *ngIf="ShowComponentDocumentView" [DashboardView]="true" ></app-document-view>
    </div>
    <div class="mx-0 mb-3 mt-0 p-0" *ngIf="authService.DocAccount?.Dto?.UoId==null">
        <app-my-documents-view *ngIf="ShowComponentDocumentView" [DashboardView]="true" ></app-my-documents-view>
    </div> -->
</div>
