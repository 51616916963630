import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WhiteLabelService } from '../../white-label.service';
import { AuthService } from '../../auth.service';
import { NavigatorService } from '../../navigator.services';
import { Pages, ApplicationSidebar } from '../../documentary/doc.configuration';
import { LoginController } from '../../controllers/Login';
import { CurrentUserDto } from '../../models/CurrentUserModel';
import { ChatService } from '../../chat.service';


@Component({
  selector: 'app-sidebar-profile',
  templateUrl: './sidebar-profile.component.html',
  styleUrls: ['./sidebar-profile.component.scss']
})
export class SidebarProfileComponent implements OnInit {
  @Output() closeSidebarEvent = new EventEmitter<void>();
  LoginController: LoginController;
  UserRole: string;
  Tag: string;
  SettingsMenu: any;
  UserProfileMenu: any;

  constructor(
    public whiteLabel: WhiteLabelService,
    public authenticationService: AuthService,
    public navigatorService: NavigatorService,
    public chatService: ChatService,
  ) {
    this.LoginController = new LoginController(authenticationService, chatService);
  }

  ngOnInit(): void {
    this.Load();
  }

  public Load() {
    this.UserProfileMenu = this.navigatorService.UserProfileMenu;
    this.SettingsMenu = this.navigatorService.SettingsMenu;
    this.UserRole = this.GetUserRole(this.authenticationService.CurrentUser);
    this.Tag = '@' + this.authenticationService.CurrentUser.Name + this.authenticationService.CurrentUser.Surname.toLowerCase();
  }

  GetUserRole(user: CurrentUserDto) {
    if (user) {
      const find = user.Groups.find((g) => g.Name === 'admin');
      if (user.IsOwner && find) {
        return 'Owner, Admin';
      } else if (find) {
        return 'Admin';
      } else if (user.IsOwner) {
        return 'Owner';
      } else {
        return 'Worker';
      }
    }
    return null;
  }

  closeSidebar() {
    this.closeSidebarEvent.emit();
  }

  GoToProfile() {

    this.closeSidebar();
    this.navigatorService.GoTo(Pages.UserProfile);
  }

  GoToSettings() {
    this.closeSidebar();
    this.navigatorService.GoTo(Pages.Settings);
  }



  async Logout(): Promise<void> {
    const response = await this.LoginController.Logout();
    this.navigatorService.GoToExternal(Pages.Login);
  }

}
