import { BaseEntity, BaseModel } from '../../models/BaseModel';

export class FieldsModel extends BaseModel<
  FieldsEntity,
  FieldsDto,
  FieldsFilter
> {
  constructor() {
    super();
  }
}

export class FieldsEntity extends BaseEntity {
  Name?: string;
  Type?: string;
  Properties: PropertyDto;
  Default: boolean;
  constructor() {
    super();
    this.Name = null;
    this.Type = null;
    this.Properties = null;
    this.Default = false;
  }
}

export class FieldsDto extends FieldsEntity {
  constructor() {
    super();
  }
}

export class FieldsFilter extends FieldsDto {}

export class PropertyDto {
  Placeholder: string;
  Minlength: number;
  Maxlength: number;
  Multiple: boolean;
  Items: Array<FieldItem>;
  Required: boolean;
  Token: string;
  Disabled: boolean;
  Type: string;
  Regex: string;

  constructor() {
    this.Placeholder = null;
    this.Minlength = null;
    this.Maxlength = null;
    this.Multiple = false;
    this.Items = new Array<FieldItem>();
    this.Required = false;
    this.Token = null;
    this.Type = null;
    this.Regex = null;
  }
}

export class FieldItem {
  Id: number;
  Value: string;
  Checked: boolean;
  constructor() {
    this.Id = 0;
    this.Value = null;
    this.Checked = false;
  }
}
