<div id="itdoc">
  <div id="sidebar" [ngClass]="{'collapsed': isCollapsed}">
    <div class="logo-collapse-container d-flex align-items-center">
      <div class="menu-item-container collapse-toggle" (click)="toggleCollapse()">
        <img *ngIf="isCollapsed || !isCollapsed" class="icon-navbar"
     src="../../assets/icon/{{ isCollapsed ? 'menu-closed' : 'menu-opened' }}.svg"
     alt="Toggle Menu"
     [style]="isCollapsed ? whiteLabelService.SVGIconHover : whiteLabelService.SVGIcon">
      </div>
      <div class="sidebar-logo" *ngIf="!isCollapsed">
        <img [src]="whiteLabelService.BrandLogo" alt="Brand Logo" />
      </div>
    </div>

    <div class="menu default-scrollbar">
      <div>
        <mat-list *ngIf="navigatorService?.DashboardMenu" class="pb-0">
          <app-sidebar-menu
            [MenuItem]="navigatorService?.DashboardMenu"
            [isCollapsed]="isCollapsed"
            (ItemClick)="SidebarMenuClick($event)"
          >
          </app-sidebar-menu>
        </mat-list>

        <mat-list *ngIf="navigatorService.DocMenu" class="pt-0">
          <app-sidebar-menu
            *ngFor="let menu of navigatorService?.DocMenu"
            [MenuItem]="menu"
            [isCollapsed]="isCollapsed"
            (ItemClick)="SidebarMenuClick($event)"
            class="item-menu"
          ></app-sidebar-menu>
        </mat-list>
        <div class="d-flex justify-content-center">
          <hr />
        </div>
        <mat-list *ngIf="navigatorService.UserProfileMenu">
          <app-sidebar-menu
            *ngFor="let menu of navigatorService?.UserProfileMenu"
            [MenuItem]="menu"
            [isCollapsed]="isCollapsed"
            (ItemClick)="ProfileMenuItemClick($event)"
            class="item-menu"
          ></app-sidebar-menu>
        </mat-list>
      </div>
      <div>
        <!-- <mat-list *ngIf="navigatorService?.SettingsMenu">
          <app-sidebar-menu
            [MenuItem]="navigatorService?.SettingsMenu"
            [isCollapsed]="isCollapsed"
            (ItemClick)="SidebarMenuClick($event)"
          >
          </app-sidebar-menu>
        </mat-list> -->
        <div class="left-logo-container row m-0 p-0">
          <div class="logo-col col-12 mt-auto mb-0 px-3 py-0">
            <img
              *ngIf="whiteLabelService.LogoPoweredLeft"
              class="bottom-logo"
              [src]="whiteLabelService.LogoPoweredLeft"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-itdoc">
    <div class="container-header">
      <app-header (userIconClick)="toggleSidebarProfile()"></app-header>
    </div>
    <div class="itdoc-app-container default-scrollbar">
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.1)"
        size="medium"
        color="#0671f2"
        type="ball-spin-clockwise"
        [fullScreen]="true"
      >
      </ngx-spinner>
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- Aggiungi mat-sidenav per la sidebar del profilo -->
  <mat-sidenav-container class="example-container">
    <!-- Sidebar del profilo -->
    <mat-sidenav #profileSidebar mode="over" position="end" [(opened)]="isSidebarProfileOpen">
      <app-sidebar-profile (closeSidebarEvent)="closeSidebar()"></app-sidebar-profile> 
    </mat-sidenav>
  </mat-sidenav-container>
</div>
