<div class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/signature.svg">
                    <h2>{{navigatorService.Dictionary?.Signature}}</h2>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" (click)="Close()">
                close
            </span>
        </div>
    </div>

    <div>
        <p class="title-description mb-1">
            {{navigatorService.Dictionary?.SignatureDescription}}</p>
        <div class="required-label">
            <span class="star">*</span>
            <span>{{navigatorService.Dictionary?.RequiredFields}}</span>
        </div>
        <mat-dialog-content>
            <div class="form mb-5">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>{{navigatorService.Dictionary?.SignatureService}}</mat-label>
                            <mat-select (selectionChange)="SignatureServiceChange($event)"
                                [value]="Model?.Dto?.ServiceId" required>
                                <mat-option *ngFor="let dto of ServiceModel?.Dtos" [value]="dto?.Id">
                                    {{dto?.ServiceName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Identifier'" [Type]="'text'" [Required]='true'
                            [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.ProfileName"></app-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Username'" [Type]="'text'" [Required]='true'
                            [Maxlength]="255" [Placeholder]="'Username'"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field class="form-field">
                            <mat-label class="placeholder">Password</mat-label>
                            <input class="input-text" matInput [type]="Hide ? 'password' : 'text'"
                                [(ngModel)]="Model.Dto.Password" maxlength="200" required>
                            <mat-icon matSuffix (click)="Hide = !Hide" style="font-size: 18px;
                            top: 4px;position: relative;">
                                {{Hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'Email'" [Type]="'text'" [Required]='true'
                            [Maxlength]="200" [Placeholder]="navigatorService.Dictionary?.Email"
                            [Regex]="'[a-zA-Z0-9.-_]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,10}$'">
                        </app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field class="form-field">
                            <mat-label class="placeholder">Pin/Token</mat-label>
                            <input class="input-text" matInput [type]="Hide ? 'password' : 'text'"
                                [(ngModel)]="Model.Dto.Token" maxlength="200">
                            <mat-icon matSuffix (click)="Hide = !Hide" style="font-size: 18px;
                            top: 4px;position: relative;">
                                {{Hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <app-input [DataValue]='Model?.Dto' [Property]="'MarkUsername'" [Type]="'text'"
                            [Required]='false' [Maxlength]="255" [Placeholder]="navigatorService.Dictionary?.TimestampUsername"></app-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <mat-form-field class="form-field">
                            <mat-label class="placeholder">{{navigatorService.Dictionary?.TimestampPassword}}</mat-label>
                            <input class="input-text" matInput [type]="Hide ? 'password' : 'text'"
                                [(ngModel)]="Model.Dto.MarkPassword" maxlength="200">
                            <mat-icon matSuffix (click)="Hide = !Hide" style="font-size: 18px;
                            top: 4px;position: relative;">
                                {{Hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <p class="title-description mb-1">
                            {{navigatorService.Dictionary?.GraphicSign + ' ' + navigatorService.Dictionary?.Sign}}</p>
                        <div class="graphic-box">
                            <img *ngIf="Model.Dto?.RealSignature" [src]="Model.Dto?.RealSignature?.startsWith('data')?Model.Dto?.RealSignature
                                :'data:image/jpeg;base64,'+Model.Dto?.RealSignature">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <button class="it-doc-btn-small">
                            <span class="btn-text" (click)="FileDelete()">{{navigatorService.Dictionary?.Delete}}</span>
                        </button>
                        <button (click)="FileSelected()" class="it-doc-btn-small">
                            <span class="btn-text">{{navigatorService.Dictionary?.Upload}}</span>
                        </button>
                        <input type="file" class="d-none" accept="image/*" #uploadFile>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Close()">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button (click)="Save()" mat-button class="it-doc-btn-primary" [disabled]="!ValidateForm()" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Confirm}}</span>
        </button>
    </div>
</div>
