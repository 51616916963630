import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { PropertyDto } from './FieldsModel';
import { MetadataTipologyValueDto } from './MetadataTipologyValueModel';
import { PermissionDto } from './PermissionModel';

export class MetadataTipologyModel extends BaseModel<
  MetadataTipologyEntity,
  MetadataTipologyDto,
  MetadataTipologyFilter
> {
  constructor() {
    super();
  }
}

export class MetadataTipologyEntity extends BaseEntity {
  TipologyId: number;
  Key: string;
  Indexed: boolean;
  Properties: PropertyDto;
  MetadataId: number;
  constructor() {
    super();
    this.TipologyId = null;
    this.Key = null;
    this.Indexed = false;
    this.Properties = null;
    this.MetadataId = null;
  }
}

export class MetadataTipologyDto extends MetadataTipologyEntity {
  Type?: string;
  Name: string;
  Index: number;
  Permissions: Array<PermissionDto>;
  PermissionsToDelete: Array<PermissionDto>;
  MetadataTipologyValue: MetadataTipologyValueDto;

  constructor() {
    super();
    this.Type = null;
    this.Name = null;
    this.Index = -1;
    this.Permissions = new Array<PermissionDto>();
    this.PermissionsToDelete = new Array<PermissionDto>();
  }
}

export class MetadataTipologyFilter extends MetadataTipologyDto {
  AccountId: number;
}
