import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NavigatorService } from '../../../navigator.services';

@Component({
  selector: 'app-chips-multiselect',
  templateUrl: './chips-multiselect.component.html',
  styleUrls: ['./chips-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsMultiselectComponent implements OnInit {
  @Input() set Elements(value: any[]) {
    if (value && value.length > 0) {
      this.ElementsView = [...value];
      this.Load();
    }
  }
  @Input() set Chips(value: any[]) {
    if (value && value.length > 0) {
      this.ChipsView = [...value];
      this.Load();
    }
  }

  @Input() NameProperty: string;
  @Input() LengthProperty: string;
  @Input() PropertiesJson: string;
  @Input() MatLabel: string;
  @Input() Placeholder = '';
  @Input() Required = false;
  @Input() AllowDuplicates = false;
  @Input() disabled: boolean = false;
  @Output() ChipsSelectedEmit = new EventEmitter<any[]>();
  @Output() ChipsDeletedEmit = new EventEmitter<any[]>();
  @ViewChild('elementInput') elementInput: ElementRef<HTMLInputElement>;

  Selectable = true;
  Removable = true;
  ChipsView: any[] = [];
  ElementsView: any[] = [];
  SeparatorKeysCodes: number[] = [ENTER, COMMA];
  FilteredElements: Observable<string[]>;
  InputCtrl = new FormControl();

  constructor(
    public navigatorService: NavigatorService
  ) {
  }

  ngOnInit(): void {
    this.Load();
  }

  Load() {
    // tslint:disable-next-line:max-line-length
    this.FilteredElements = this.InputCtrl.valueChanges.pipe(startWith(null), map((elem: string | null) => elem ? this.Filter(elem) : this.ElementsView.slice()));
  }

  Remove(chip: any): void {
    const index = this.ChipsView.indexOf(chip);
    if (index >= 0) {
      this.ChipsView.splice(index, 1);
    }
    this.ChipsDeletedEmit.emit(chip);
  }

  Selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.AllowDuplicates) {
      const index = this.ChipsView.indexOf(event.option.value);
      if (index === -1) {
        this.ChipsView.push(event.option.value);
        this.ChipsSelectedEmit.emit(event.option.value);
      }
    } else {
      this.ChipsView.push(event.option.value);
      this.ChipsSelectedEmit.emit(event.option.value);
    }
    this.elementInput.nativeElement.value = '';
    this.elementInput.nativeElement.blur();
    this.InputCtrl.setValue(null);
  }

  private Filter(value: string): string[] {
    if (value) {
      // const filterValue = value.toLowerCase();
      return this.ElementsView.filter(elem => elem[this.NameProperty].toLowerCase().includes(value));
    }return [];
  }

  Reset() {
    this.ChipsView = [];
  }

  ShowRemovableIcon(chip: any) {
    if (chip && chip.PropertiesJson) {
      if (chip.PropertiesJson.hasOwnProperty('Removable')) {
        if (chip.PropertiesJson.Removable) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }
}
