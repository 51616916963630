import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileDto, FileModel } from '../../models/FileModel';
import { NavigatorService } from '../../../navigator.services';
import { CommonService } from '../../../common.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-file',  
  templateUrl: './file.component.html',
  styleUrl: './file.component.scss',
})
export class FileComponent<TDto>  implements OnInit {
  @ViewChild('multiUploadFile') multiFileUpload: ElementRef;
  UploadInProgress = false;
  UploadComplete = false;
  EnableUpload = false;  
  @Input() FileModel: FileModel;
  DataSourceFiles: MatTableDataSource<FileDto>;

  constructor(public navigatorService: NavigatorService,private commonService:CommonService) {}

  ngOnInit(): void {    
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.SelectFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) { }

  SelectFiles(fileList: FileList) {
    if (!this.FileModel) {
      this.FileModel = new FileModel();
    }
    if (!this.FileModel.Dtos) {
      this.FileModel.Dtos = new Array<FileDto>();
    }

    Array.from(fileList).forEach((file) => {
      const exist = this.FileModel.Dtos.findIndex(q => q.Name == file.name);
      let fileDto = new FileDto();
      fileDto.File = file;
      fileDto.PhysicalName = file.name.replace(/[^a-z0-9.]/gi, '_');
      fileDto.Path = '';
      fileDto.Performed = true;
      fileDto.Icon = this.commonService.GetFileIcon(fileDto.PhysicalName);
      fileDto.Size = file.size;
      fileDto.Progress = 0;
      fileDto.Name = file.name;
      this.FileModel.Dtos.push(fileDto);
      fileDto.Message = exist >= 0 ? this.navigatorService.Dictionary?.FileAlreadyExists : null;
      fileDto.Performed = exist < 0;
      this.EnableUpload = this.FileModel?.Dtos.filter((q) => q.Performed === true).length > 0;
    });
    this.multiFileUpload.nativeElement.value = null;
    this.RefreshTableFile(this.FileModel.Dtos);
  }

  DeleteFile(dto: FileDto) {
    const index = this.FileModel.Dtos.findIndex((q) => q.Name === dto.Name);
    this.FileModel.Dtos.splice(index, 1);
    this.RefreshTableFile(this.FileModel.Dtos);
  }

  RefreshTableFile(dtos: Array<FileDto>) {
    for (const dto of dtos) {
      if (!dto.Icon && !dto.Icon) {
        this.commonService.GetFileIcon(dto.PhysicalName);
      }
    }
    this.DataSourceFiles = null;
    this.DataSourceFiles = new MatTableDataSource<FileDto>(dtos);
  }

  GetValue(){
    return this.FileModel;
  }
}
