<div class="itdoc-page">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <div class="valign-icon-text">
          <img style="margin-right: 11px;" src="../../../../../assets/icon/work.svg">
          <h2>{{navigatorService.Dictionary?.DocClass}}</h2>
        </div>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div>
    <p class="title-description">{{navigatorService.Dictionary?.DocClassDescription}}</p>
    <mat-dialog-content class="default-scrollbar">
      <div class="form">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <app-select [Items]="Model.Dtos" [Required]="true" [Placeholder]="navigatorService.Dictionary?.DocClass"
              [Property]="'Title'" [DataValue]="Model?.Dto?.Id" (OnChange)="ClassSelected($event)"></app-select>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
      <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
    </button>

    <button (click)="Next()" mat-button class="it-doc-btn-primary" [disabled]="!Model.Dto?.Name">
      <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
    </button>
  </div>
</div>
