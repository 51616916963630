<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-11 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div class="grid-column" (click)="Load(1)" [ngClass]="Tab === 1 ? 'tab-selected' : ''" [ngStyle]="{
                cursor: Tab === 2 || Tab === 3 ? 'pointer' : 'default'
              }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.AllProtocols }}
              </span>
            </div>
            <div class="grid-column text-center" (click)="Load(2)" [ngClass]="Tab === 2 ? 'tab-selected' : ''"
              [ngStyle]="{
                cursor: Tab === 1 || Tab === 3 ? 'pointer' : 'default'
              }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.Input }}
              </span>
            </div>
            <div class="grid-column" (click)="Load(3)" [ngClass]="Tab === 3 ? 'tab-selected' : ''" [ngStyle]="{
                cursor: Tab === 1 || Tab === 2 ? 'pointer' : 'default'
              }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.Output }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 m-0 p-0">
      <div class="search-create-container">
        <div class="p-0 d-flex align-items-center justify-content-center" style="width: 30px;margin-right: 15px;">
          <span class="filter-badge" [matBadgeHidden]="FilterCount === 0" matBadge="{{ FilterCount }}">
            <img class="clickable-icon" (click)="OpenFiltersDialog()"
              src="../../../../../assets/icon/filter-icon.svg" />
          </span>
        </div>
        <div class="flex-grow-1 p-0">
          <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
            [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
            [HaveActions]="false" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)"></app-search>
        </div>

        <div class="p-0 d-flex justify-center" style="width: 60px;">
          <button mat-button class="it-doc-btn-primary-circle"
            (click)="UploadInputProtocol(); $event.stopPropagation()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="form mt-3">
    <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder">
      <div class="col-md-4">
        <button mat-icon-button (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder">
          <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
          <span *ngIf="!CurrentFolder.IsRedirect">{{ navigatorService.Dictionary?.Back }}
          </span>
          <span *ngIf="CurrentFolder.IsRedirect">{{ navigatorService.Dictionary?.AllProtocols }}
          </span>
        </button>
      </div>
      <div class="col-md-4 text-center current-folder">
        <span>{{ CurrentFolder?.Name }}</span>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="it-doc-table-container">
      <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef class="checkbox-column">
            <mat-checkbox (change)="HeaderCheckbox($event)" [checked]="IsAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox *ngIf="element.PhysicalName && element.Parent" (change)="CheckRow($event, element)"
              [(ngModel)]="element.Checked">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnName }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnName }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span (click)="OpenObject(element); $event.stopPropagation()" class="clickable-icon" title="{{
                element.Name +
                  (element.Type === 'file'
                    ? '.' + commonService.GetExtension(element.PhysicalName)
                    : '')
              }}">
              <div class="d-flex">
                <span class="icon-text-right">
                  <img src="{{ element.Icon }}" />
                  <span *ngIf="element.Sign" class="material-icons sign-icon">draw</span>
                </span>
                <div class="ellipsis-text">
                  {{
                  element.Name +
                  (element.Type === "file"
                  ? "." + commonService.GetExtension(element.PhysicalName)
                  : "")
                  }}
                </div>
              </div>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnNumber }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnNumber }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ element.ProgressiveString }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnState }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnState }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Input" class="valign-icon-text">
              <img src="../../../../../assets/icon/input-arrow.svg" />
              <span>Input</span>
            </div>
            <div *ngIf="!element.Input" class="valign-icon-text">
              <img src="../../../../../assets/icon/output-arrow.svg" />
              <span>Output</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnSource }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnSource }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{
              element.Input
              ? "-"
              : this.navigatorService.Dictionary?.Documents
              }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.Admin }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.Admin }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Admins?.length > 0">
              <img *ngIf="element.Admins?.length > 1" src="../../../../assets/icon/info-icon.svg" class="icon-action"
                [matMenuTriggerFor]="adminsMenu" />
              {{ element.Admins[0].DisplayName }}
            </span>
            <mat-menu #adminsMenu="matMenu" class="more-action-menu">
              <div class="admins-container">
                <p class="admin-count">
                  Admin(<strong>{{ element.Admins?.length - 1 }}</strong>)
                </p>
                <span *ngFor="let admin of element.Admins">
                  <span *ngIf="admin.Username !== element.Admins[0].Username">{{
                    admin.DisplayName
                    }}</span>
                </span>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnCreate }}">
          <th mat-header-cell *matHeaderCellDef>
            {{ navigatorService.Dictionary?.TableColumnCreate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Deep >= 0">
              {{ element.CreationDateString }}
            </span>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef class="icon-column">
                        <img src="../../../../../assets/icon/download.svg">
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <img class="clickable-icon" (click)="DownloadFile(element)"
                            src="../../../../../assets/icon/download.svg">
                    </td>
                </ng-container> -->

        <ng-container matColumnDef="moreAction">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <button mat-icon-button (click)="GetMenuFiles()" [disabled]="GetMoreActionFilesDisabled()" class="w-auto"
              #m="matMenuTrigger" [matMenuTriggerFor]="moreAction">
              <img src="../../../../../assets/icon/more-vert.svg"
                [ngClass]="{ 'open-menu-trigger-color': m.menuOpen }" />
            </button>
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)="ActionFilesMenu(itemMenu.Name)" *ngFor="let itemMenu of FilesMenu"
                [disabled]="!itemMenu.Enabled">
                {{ itemMenu.Label }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element">
            <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
              [ngClass]="{ 'open-menu-trigger-color': m.menuOpen }" (click)="GetMenuFile(element)"
              src="../../../../../assets/icon/more-vert.svg" />
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)="ActionMenu(itemMenu.Name, element)" *ngFor="let itemMenu of FileMenu">
                {{ itemMenu.Label }}
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
          </th>
          <td mat-cell *matCellDef="let element">
            <img class="clickable-icon" *ngIf="!element.Crypt" (click)="Download(element)"
              src="../../../../../assets/icon/download.svg" />
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="[
            'checkbox',
            navigatorService.Dictionary?.TableColumnName,
            navigatorService.Dictionary?.TableColumnNumber,
            navigatorService.Dictionary?.TableColumnState,
            navigatorService.Dictionary?.TableColumnSource,
            navigatorService.Dictionary?.Admin,
            navigatorService.Dictionary?.TableColumnCreate,

            'moreAction',
            'filter'
          ]"></tr>
        <tr mat-row (click)="SelectRow(row)" [ngClass]="{ selected: row.Selected }" *matRowDef="
            let row;
            columns: [
              'checkbox',
              navigatorService.Dictionary?.TableColumnName,
              navigatorService.Dictionary?.TableColumnNumber,
              navigatorService.Dictionary?.TableColumnState,
              navigatorService.Dictionary?.TableColumnSource,
              navigatorService.Dictionary?.Admin,
              navigatorService.Dictionary?.TableColumnCreate,

              'moreAction',
              'filter'
            ]
          " style="position: relative"></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
      </div>
    </div>
    <div *ngIf="Model?.Dtos?.length <= 0 && !navigatorService.Loading" class="m-0 empty-table-box"
      style="padding-bottom: 200px">
      <img src="../../../../../assets/icon/protocols-empty.svg" />
      <div class="description">
        {{ navigatorService.Dictionary?.EmptyProtocolsMessage }}
      </div>
    </div>
  </div>
</div>
