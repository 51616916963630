import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WhiteLabelService } from '../../white-label.service';


@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  @Input() MenuItem: any;
  @Input() EnableExpandClick = true;
  @Input() IsChild = false;
  @Output() ItemClick = new EventEmitter();
  @Input() isCollapsed: boolean = false;

  constructor(public whiteLabel: WhiteLabelService) { }

  ngOnInit(): void {
  }

  ClickItem(item: any) {    
    this.ItemClick.emit(item);
    if (!item.Link && !this.isCollapsed) {
      this.ClickExpand(item);
    }
  }

  ClickExpand(item: any) {
    if (item.Childs && item.Childs?.length > 0 && this.EnableExpandClick) {
      item.Expanded = !item.Expanded;
    }
  }

  ShowExpand() {
    if (this.MenuItem.Childs && this.MenuItem.Childs?.length > 0) {
      let countVisibleItems = 0;
      for (const child of this.MenuItem.Childs) {
        if (child.Visible) {
          countVisibleItems += 1;
        }
      }
      if (countVisibleItems === 0) {
        return false;
      }
    }
    return true;
  }

  MouseOver($event, menuItem) {
    menuItem.Hover = true;
  }

  MouseLeave($event, menuItem) {
    menuItem.Hover = false;
  }

}
