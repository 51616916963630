import { DataService } from '../../data.service';
import { MetadataCategoryModel } from '../models/MetadataCategoryModel';

export class MetadataCategoryController {


  constructor(private dataService: DataService) { }

  async CreateOrUpdate(model: MetadataCategoryModel
  ): Promise<MetadataCategoryModel> {
    let response = model;
    if (model && (model.Dto || model.Dtos.length > 0)) {
      response = await this.dataService.CreateOrUpdateMetadataCategory(model);
    }
    return response;
  }

  async Read(model: MetadataCategoryModel): Promise<MetadataCategoryModel> {
    const response = await this.dataService.ReadMetadataCategory(model);
    return response;
  }

  async Get(model: MetadataCategoryModel): Promise<MetadataCategoryModel> {
    const response = await this.dataService.GetMetadataCategory(model);
    return response;
  }

  async Load(model: MetadataCategoryModel): Promise<MetadataCategoryModel> {
    const response = await this.dataService.LoadMetadataCategory(model);
    return response;
  }

  async Delete(model: MetadataCategoryModel): Promise<MetadataCategoryModel> {
    let response = model;
    if (model) {
      if (model.Dtos?.length > 0) {
        response = await this.dataService.DeleteMetadataCategory(model);
      } else {
        response.Performed = true;
      }
    }
    return response;
  }

}
