<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container ">
          <div class="grid-container">
            <div class="grid-column" (click)='Load(1)' [ngClass]="Tab===1?'tab-selected':''"
              [ngStyle]="{'cursor':Tab===2?'pointer':'default'}">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.YourUoDeleted}}
              </span>
            </div>
            <div class="grid-column" (click)='Load(2)' [ngClass]="Tab===2?'tab-selected':''"
              [ngStyle]="{'cursor':Tab===1?'pointer':'default'}">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.AllUoDeleted}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="search-create-container d-flex align-items-center"
      >
        <div class="col-12 p-0">
          <app-search
            #search
            [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
            [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel"
            [HaveFilters]="false"
            [HaveActions]="false"
            (OnEnterPress)="Search($event, Model.Dtos)"
            (OnClearBtnPress)="ClearSearchInput($event)"
          ></app-search>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="height: 100%;">
    <div class="col-md-12">
      <div class="form mt-3">
        <div *ngIf="Model?.Dtos?.length > 0" class="row w-100 table-uo-header">
          <div class="col-md-3 uo-col-1">
            <div class="valign-icon-text">
              <div>{{navigatorService.Dictionary?.Name}}</div>
            </div>
          </div>
          <div class="col-md-2 uo-col-2">
            <div class="valign-icon-text">
              <div>{{navigatorService.Dictionary?.OfficeCodePlaceHolder}}</div>
            </div>
          </div>

          <div class="col-md-3 uo-col-3 no-sort-column">{{navigatorService.Dictionary?.Description}}</div>
          <div class="col-md-2 uo-col-4 no-sort-column">{{navigatorService.Dictionary?.Admin}}</div>
          <div class="col-md-1 uo-col-5 no-sort-column">{{navigatorService.Dictionary?.Users}}</div>
          <div class="col-md-1 uo-col-6"></div>
        </div>
        <app-tree #uotree [DataSource]="FilteredUo" [Tab]="Tab" [Index]="0" [IsTrashedView]="true"
          (Action)="Action($event)">
        </app-tree>
        <div *ngIf="Model?.Dtos?.length <=0 && !navigatorService.Loading" class="empty-table-box">
          <img src="../../../../../assets/icon/uo-empty-icon.svg">
          <div class="description">{{navigatorService.Dictionary?.NoDeletedUoMessage}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
