<form>
    <mat-form-field appearance="{{this.appearance}}" style="width : 100%" class="form-autocomplete">
        <mat-label class="label">{{this?.Placeholder}}<span *ngIf="Required" class="star">*</span></mat-label>
        <input class="mt-input" #editInput name="inputSearch" type="text" matInput [formControl]="AutocompleteControl"
            [matAutocomplete]="auto" [readonly]="this.ReadOnly" value="{{this.DisplayValue(this,this.DataValue)}}">
        <mat-spinner matSuffix *ngIf="this.ShowLoader && this.LoaderType==='Spinner'" [diameter]="20"
            style="float: right; position: relative; top: 5px;"></mat-spinner>
        <img matSuffix *ngIf="!ShowLoader && DropDownMode" class="custom-arrow-icon" src="../../../../assets/icon/select-arrow.svg">
        <mat-autocomplete #auto="matAutocomplete"
            [displayWith]="this?.DisplayValue.bind(this?.AutocompleteControl?.value, this)"
            (optionSelected)='this.GetDataValue($event);'>
            <mat-option *ngFor="let option of this.Items" [value]="option"
                class="{{this.GetOptionSelectedClass(option)}}">
                <div class="row">
                    <div class="col-md-10">
                        <div class="option-content" (click)=" this.CheckItem($event,option);">
                            <img *ngIf="option.Avatar" class="avatar-circle" [src]="'data:image/png;base64,' + option.Avatar" style="margin-right: 10px;">
                            <div *ngIf="!option.Avatar" class="avatar-circle" style="margin-right: 10px;">{{this.DisplayValue(this, option) | acronym}}</div>
                            <span class="value-text"
                                [title]="this.DisplayValue(this,option)">{{this.DisplayValue(this,option)}}</span>
                            <mat-checkbox *ngIf="this.Multiple" (click)="$event.stopPropagation();"
                                [checked]="option.Checked" style="right: 15px;position: absolute;"></mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-2 text-end">
                        <mat-icon *ngIf="OptionIcon" class="icon" matSuffix>{{OptionIcon}}</mat-icon>
                    </div>
                </div>
            </mat-option>
        </mat-autocomplete>
        <mat-progress-bar class="mt-autocomplete-progressbar" mat-progress-bar-height="1px"
            *ngIf="this.ShowLoader && this.LoaderType==='Progressbar'" mode="indeterminate"> </mat-progress-bar>
    </mat-form-field>
</form>
