<div id="cardComponent">
    <!-- Card with chart style -->
    <div *ngIf="CardType === DashboardCardType.Chart && DatasetModel" class="card-container">
        <div class="row m-0">
            <div class="col-md-12">
               <div  class="d-flex justify-content-between align-content-center align-item-center">
                    <div class="card-title ml-0">{{CardTitle}}</div>
                    <button mat-icon-button (click)="MoreAction()" [disabled]="IsEmpty" class="button-more" style="position: relative;top: 10px;">
                        <mat-icon> more_vert</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-6 col-sm-6 d-flex justify-content-left mt-2">
                <app-doughnut-chart [Width]="135" [DatasetModel]="DatasetModel" [IsEmpty]="IsEmpty">
                </app-doughnut-chart>
            </div>
            <div class="col-md-5 col-sm-5 mt-1 p-0">
                <div class="legend-column tiny-scrollbar">
                    <div *ngFor="let data of DatasetModel; let i = index;let isFirst = first;"
                        class="legend-element-chart" [ngStyle]="{'margin-top': !isFirst ? '10px' : '0px'}">
                        <div style="width: 100%;">
                            <div class="d-flex align-items-center text-start">
                                <span class="circle" [ngStyle]="{'background-color': data.PercentageColor}"></span>
                                <div [matTooltip]="data.Name" class="legend-name ms-2">{{data.Name}}</div>
                            </div>
                            <div class="d-flex justify-content-between" style="margin-top: 3px;">
                                <span class="legend-percentage">{{data.Percentage}}% </span>
                                <span class="legend-percentage-late justify-content-end">{{data.PercentageInLate}}%
                                    {{navigatorService.Dictionary?.InLate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Card with minimal counter style -->
    <div *ngIf="CardType === DashboardCardType.MinimalCounter && DataModel" class="minimal-card-container">
        <div class="row m-0">
            <div class="col-md-12">
                <div  class="d-flex justify-content-between align-content-center align-item-center">
                    <div class="name-txt ml-0">{{DataModel.Name}}</div>
                    <button mat-icon-button (click)="MoreAction()" [disabled]="IsEmpty" class="button-more">
                        <mat-icon> more_vert</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-4">
                <app-doughnut-chart [Width]="70" [DataModel]="DataModel" [IsEmpty]="IsEmpty"></app-doughnut-chart>
            </div>
            <div class="col-md-3">
                <div class="h-100 d-flex flex-column justify-content-center">
                    <div class="count-txt mt-2">{{DataModel.Count}}</div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="h-100 d-flex flex-column justify-content-center">
                    <div>
                        <span class="circle" [ngStyle]="{'background-color': DataModel.PercentageInTimeColor}"></span>
                        <span class="legend-value ms-2"><b>{{DataModel.PercentageInTime}}%</b> {{navigatorService.Dictionary?.InTime}}</span>
                    </div>
                    <div>
                        <span class="circle" [ngStyle]="{'background-color': DataModel.PercentageInLateColor}"></span>
                        <span class="legend-value ms-2"><b>{{DataModel.PercentageInLate}}%</b> {{navigatorService.Dictionary?.InLate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="CardType === DashboardCardType.ProgressBar && DatasetModel" id="progressContainer"
        class="card-container">
        <div class="row m-0">
            <div class="col-md-12">
                <div  class="d-flex justify-content-between align-content-center align-item-center">
                    <div class="card-title ml-0">{{CardTitle}}</div>
                    <button mat-icon-button (click)="MoreAction()" [disabled]="IsEmpty" class="button-more">
                        <mat-icon> more_vert</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0 mt-2">
            <div class="col-md-12">
                <app-progress-bar-chart [DatasetModel]="DatasetModel"></app-progress-bar-chart>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-12 ">
                <div class="d-flex mt-5 overflow-x-scroll default-scrollbar" (wheel)="onWheel($event)">
                    <div *ngFor="let data of DatasetModel; let i = index; let isLast = last;">
                        <div class="legend-element-progress-chart mb-2"
                            [ngStyle]="{'margin-right': !isLast ? '5px' : '0px'}">
                            <div style="width: 100%;">
                                <div class="d-flex align-items-center text-start pt-1">
                                    <span class="circle" [ngStyle]="{'background-color': data.PercentageColor}"></span>
                                    <div [matTooltip]="data.Name" class="legend-name ms-2">{{data.Name}}</div>
                                </div>
                                <div class="d-flex justify-content-between" style="margin-top: 3px;">
                                    <span class="legend-percentage">{{data.Percentage}}% </span>
                                </div>
                                <div class="d-flex justify-content-between" style="margin-top: 3px;">
                                    <span class="legend-percentage-late">{{data.PercentageInLate}}% {{navigatorService.Dictionary?.InLate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
