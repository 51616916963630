<div class="component-container">
  <span class="material-icons icon-drag icon-align-center" cdkDragHandle>
    drag_indicator
  </span>
  <!-- type text -->
  <mat-form-field class="form-field" *ngIf="Type !== 'text-area'" >
    <mat-label class="placeholder">{{ Properties?.Placeholder }}</mat-label>
    <input [required]="Properties?.Required" class="input-text" matInput type="{{ Properties?.Type }}" 
    pattern="{{Properties?.Regex}}" [maxLength]="Properties?.Maxlength" autocomplete="off" />    
  </mat-form-field>
  <span (click)="DeleteComponent()" class="material-icons icon icon-align-center">
    delete
  </span>

<mat-form-field class="form-field" *ngIf="Type === 'text-area'" appearance="outline">
  <mat-label class="placeholder">{{Properties?.Placeholder}}</mat-label>
  <div>
    <textarea matInput class="input-text"  rows="4"
      [maxLength]="Properties?.Maxlength" [required]="Properties?.Required"></textarea>
  </div>  
</mat-form-field>
</div>
<div class="toggle-container">
  <mat-slide-toggle [(ngModel)]="Properties.Required" labelPosition="before" class="required-toggle">
    {{ navigatorService?.Dictionary?.Required }}
  </mat-slide-toggle>
</div>