<div class="itdoc-page ">
    <div>
        <div class="row">
            <div class="col-11">
                <h2>{{navigatorService.Dictionary?.NewUpload}}</h2>
                <p class="title-description">{{navigatorService.Dictionary?.MenuDialogDescription}}</p>
            </div>
            <div class="col-1 text-end">
                <img class="icon-close" [mat-dialog-close]="null" src="../../../../assets/icon/close-icon.svg">
            </div>
            <mat-dialog-content class="tiny-scrollbar" style="display: table-row;">
                <div class="form grid-two">
                    <div class=" grid-container">
                        <div class="grid-column" *ngFor="let item of DocumentaryMenuItems" title="{{item.Label}}">
                          <div class="item" (click)="ItemClick(item)" [ngClass]="item.Selected ? 'selected' : ''">
                            <div class="icon-title-container">
                              <img class="grid-icon" src="../../../../assets/icon/{{item.Icon}}.svg">
                              <div class="title">{{item.Title}}</div>
                            </div>
                            <div class="description">{{item.Description}}</div>
                          </div>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
        </div>

        <div mat-dialog-actions align="end" class="dialog-buttons-container">
            <button mat-button class="it-doc-btn-cancel" style="margin-inline-end: auto;" [mat-dialog-close]="null">
                <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
            </button>
            <button (click)="Next()" mat-button class="it-doc-btn-primary" [disabled]="!MenuItemSelected"
                cdkFocusInitial>
                <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
            </button>
        </div>
    </div>
