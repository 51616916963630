<div id="header">
  <div class="container-column pe-4">
    <div class="row m-0 header-row">
      <div class="col-md-6 p-0">
        <h1>{{navigatorService?.CurrentPage?.Title}}</h1>
      </div>
      <div class="col-md-6 p-0 text-end">
        <div class="d-flex justify-content-end">

          <!-- <iframe src="https://vaideskull1.itvalues.site/iframe-chat/32" allow="microphone; camera" loading="lazy"></iframe> -->

          <div class="notifications-icon mx-3">
            <span class="material-icons-outlined pointer" matBadge="{{commonService.NotificationModel?.Dtos?.length}}"
              matBadgeColor="warn" [matBadgeHidden]="commonService.NotificationModel?.Dtos?.length === 0"
              (click)="toggleNotificationList()" #toggleButtonNotif>notifications</span>
          </div>
          <div #dropdownNotif class="dropdown">
            <div class="dropdown-content py-2 px-0" *ngIf="Open" style="z-index: 1000;">
              <div class="scrollable-notif-cont"
                [ngClass]="{'noNotifications': commonService.NotificationModel?.Dtos?.length === 0}">
                <div *ngFor="let dto of commonService.NotificationModel?.Dtos; let isLast = last;"
                  class="container m-0 p-0">
                  <div class="row notif-item-list m-0 p-0">
                    <div class="col-11 m-0 p-0">
                      <div class="notificationTitle row m-0 p-0">
                        <div class="col-1 m-0 p-0 notificationIcon">
                          <img width="24" height="24" src="{{dto.Icon}}" />
                        </div>
                        <div class="col-11 m-0 p-0 notificationSubject">
                          <div (click)="RedirectNotification(dto)"
                            [ngStyle]="{'cursor':dto.data?.Id && dto.data?.Id>0 ?'pointer':''}">
                            {{dto.Subject}}
                          </div>
                        </div>
                      </div>
                      <div class="notificationMessage row m-0 pb-1 pt-0 px-0">
                        <div class="col-12 m-0 p-0">
                          <!-- <div [innerHTML]="truncateText(notification.message, 82)"></div> -->
                          <div class="messageText" [innerHTML]="dto.Message"></div>
                        </div>
                      </div>
                      <div class="notificationDate row m-0 p-0">
                        <div class="col-12 m-0 p-0">
                          {{dto.created_at | date:'dd/MM/yyyy' }}
                        </div>
                      </div>
                    </div>
                    <div class="col-1 d-flex justify-content-end m-0 p-0">
                      <span (click)="DeleteNotification(dto.id)" id="delete-single-notification"
                        class="notificationDeleteIcon material-icons">
                        close
                      </span>
                    </div>
                  </div>
                  <hr class="notificationSeparator" *ngIf="!isLast">
                  <div class="last-margin" *ngIf="isLast"></div>
                </div>
                <div *ngIf="commonService.NotificationModel?.Dtos?.length === 0" class="noNotificationsContainer">
                  <span class="material-icons-outlined">notifications_off</span>
                  <p class="noNotifications">{{navigatorService.Dictionary?.NotificationEmpty}}</p>
                </div>
              </div>
              <div class="mark-all-as-read">
                <span class="pointer" *ngIf="commonService.NotificationModel?.Dtos?.length > 0"
                  (click)="MarkAllAsRead()">{{navigatorService.Dictionary?.MarkAllAsRead}}</span>
                <span class="pointer"
                  (click)="GetNotificationHistory()">{{navigatorService.Dictionary?.NotificationHistory}}</span>
              </div>

            </div>
          </div>

          <!-- <div *ngFor="let item of navigatorService.UserProfileMenu">
                      <div
                        *ngIf="item.Name === 'user-profile'"
                        class="user-menu-container"
                        [ngClass]="{ selected: item?.Selected }"
                        (click)="ProfileMenuItemClick(item)"
                      >
                        <img
                          *ngIf="authService?.CurrentUser?.Avatar"
                          class="profile-picture"
                          src="data:image/png;base64,{{
                            authService?.CurrentUser?.Avatar
                          }}"
                        />
                      </div>
                    </div> -->

          <div *ngFor="let item of navigatorService.UserProfileMenu">
            <div *ngIf="item.Name === 'user-profile'" class="user-menu-container"
              [ngClass]="{ selected: item?.Selected }" (click)="onUserIconClick(item)">
              <img *ngIf="authService?.CurrentUser?.Avatar" class="profile-picture" src="data:image/png;base64,{{
                            authService?.CurrentUser?.Avatar
                          }}" />
            </div>
          </div>



          <!-- Chat button -->
          <div *ngIf="configAppService.enableChat" class="mx-3 my-0 p-0">
            <span id="chatIcon" class="material-icons-outlined" style="cursor: pointer;font-size: 22px;"
              matBadgeColor="warn" matBadge="{{chatService.chatBadge}}"
              [matBadgeHidden]="chatService.chatBadge <= 0 || !chatService.showChatBadge"
              (click)="openCloseChat()">chat</span>
          </div>
          <!-- <span (click)="Logout()" class="material-icons ms-3" style="cursor: pointer;font-size:22px;"
                        matTooltip="logout">logout</span> -->
        </div>
      </div>
    </div>
  </div>
</div>
