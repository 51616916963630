import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../../../data.service';
import { DialogMenuComponent } from '../../dialog-menu/dialog-menu.component';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { SignatureComponent } from '../../dialog-menu/signature/signature.component';
import { VerifySignatureComponent } from '../../dialog-menu/signature/verify-signature/verify-signature.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import {
  ObjectDto,
  ObjectFilter,
  ObjectModel,
} from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { SettingsController } from '../../../../controllers/SettingsController';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { FavouritesController } from '../../../controllers/FavouritesController';
import {
  Constants,
  DocumentState,
  FileMenu,
  FilesMenu,
  GenericDialogAction,
  PageAction,
  Pages,
} from '../../../doc.configuration';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { GenericDialogModel } from '../../../models/GenericDialogModel';
import { EncryptComponent } from '../../dialog-menu/encrypt/encrypt.component';
import {
  FavouriteDto,
  FavouriteFilter,
  FavouriteModel,
} from '../../../models/FavouriteModel';
import { MatSort } from '@angular/material/sort';
import { FilterDocumentComponent } from '../../dialog-menu/filter-document/filter-document.component';

@Component({
  selector: 'app-my-documents-view',
  templateUrl: './my-documents-view.component.html',
  styleUrls: ['./my-documents-view.component.scss'],
})
export class MyDocumentsViewComponent implements OnInit, AfterViewInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  @Input() DashboardView = false;
  Model: ObjectModel;
  Controller: ObjectController;
  DataSource: MatTableDataSource<ObjectDto>;
  @ViewChild(MatSort) sort: MatSort;

  SettingsController: SettingsController;
  FileManagerController: FileManagerController;
  FavouritesController: FavouritesController;
  CurrentFolder: ObjectDto;
  CountAllObject: number = null;
  ND = Constants.ND.toString();
  Tab = 1;
  FileMenu: Array<any>;
  FilesMenu: Array<any>;
  FilterCount = 0;
  Filter: ObjectFilter;
  IsAllFavouritesSelected: boolean;
  columns: string[] = [];

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {
    commonService.CurrentPageComponent = this;

    this.Controller = new ObjectController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.FavouritesController = new FavouritesController(dataService);
    this.SettingsController = new SettingsController(dataService);
  }

  ngOnInit(): void {
    // if (!this.navigatorService.Loading) {
    //   this.Load();
    // }
  }

  ngAfterViewInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load(this.Tab);
    }
    this.cdr.detectChanges();
  }

  async Load(tab = 1) {
    try {
      this.navigatorService.StartLoading();
      await this.navigatorService.Wait();

      this.Model = new ObjectModel();
      this.FilterCount = 0;
      this.Filter = null;
      this.SearchComponent?.ClearInputValue(false);

      this.Tab = tab;

      if (this.navigatorService.NotificationTargetId > 0) {
        this.navigatorService.ObjectDto = await this.ReadObject(
          this.navigatorService.NotificationTargetId
        );
        if (this.navigatorService.ObjectDto) {
          this.navigatorService.ObjectDto.IsRedirect = true;
        } else {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.DocumentNotFound
          );
        }
        this.navigatorService.NotificationTargetId = null;
      }

      if (tab === 1) {
        await this.GetObjects();
      } else if (tab === 2) {
        await this.GetFavouriteObjects();
      } else if (tab === 3) {
        await this.GetTrashedObjects(this.Pager.CurrentPage);
      }

      this.setColumns(tab);
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Errore durante il caricamento:', error);
    } finally {
      this.navigatorService.StopLoading();
    }
  }

  setColumns(tabIndex: number) {
    switch (tabIndex) {
      case 1:
      case 2:
        this.columns = [
          'checkbox',
          'favourites-column',
          this.navigatorService.Dictionary?.TableColumnName,
          this.navigatorService.Dictionary?.TableColumnVersion,
          this.navigatorService.Dictionary?.TableColumnSize,
          this.navigatorService.Dictionary?.TableColumnShared,
          this.navigatorService.Dictionary?.TableColumnUpdate,
          'more-action',
          'filter',
        ].filter((column) => column !== undefined);
        break;

      case 3:
        this.columns = [
          'checkbox',
          this.navigatorService.Dictionary?.TableColumnName,
          this.navigatorService.Dictionary?.TableColumnVersion,
          this.navigatorService.Dictionary?.TableColumnSize,
          this.navigatorService.Dictionary?.TableColumnShared,
          this.navigatorService.Dictionary?.TableColumnUpdate,
          'more-action',
        ].filter((column) => column !== undefined);
        break;

      default:
        this.columns = [];
        break;
    }
  }

  async GetObjects(
    dto: ObjectDto = null,
    search: string = null,
    page = 1,
    filter = null
  ) {
    if (
      this.authenticationService.DocAccount.Dto.Id &&
      this.authenticationService.DocAccount.Dto.Id > 0
    ) {
      this.Model.Search = this.SearchComponent?.GetSearchValue();
      if (filter) {
        this.Model.Filter = filter;
        this.Model.Filter.Desktop = true;
      } else {
        this.Model.Filter = new ObjectFilter();
        this.Model.Filter.Desktop = true;
        if (!search) {
          search = this.SearchComponent?.GetSearchValue();
          if (!dto && search) {
            this.Model.Search = search;
          } else if (!dto) {
            this.Model.Filter.Deep = 1;
          } else if (dto && dto.Type === 'folder') {
            this.Model.Filter.Parent = dto.Id;
            this.Model.Filter.Deep = dto.Deep + 1;
          } else if (dto && dto.Type === 'file') {
            if (dto.Deep > 1) {
              this.Model.Filter.Parent = dto.Parent;
              this.Model.Filter.Deep = dto.Deep;
            } else {
              this.Model.Filter.Deep = 1;
            }
          }
        } else {
          this.Model.Search = search;
        }
      }
      this.CurrentFolder =
        dto?.Type === 'folder' ? dto : await this.ReadObject(dto?.Parent);
      this.Model.Order = new BaseOrder();
      this.Model.Order.Name = 'Id';
      this.Model.Order.Direction = 'desc';
      this.Model = await this.Controller.Load(this.Model);
      if (dto && this.Model.Performed && this.Model.Dtos.length > 0) {
        if (dto.Type === 'file') {
          this.Model.Dtos.find((q) => q.Id === dto.Id).Selected = true;
        }
      }
      this.Paging(page);
    }
  }

  async GetTrashedObjects(page = 1) {
    this.Model.Search = null;
    this.Model.Filter = new ObjectFilter();
    this.Model.Filter.Trashed = true;
    this.Model.Filter.Desktop = true;
    this.Model.Search = this.SearchComponent?.GetSearchValue();
    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';
    this.Model = await this.Controller.Load(this.Model);
    this.Paging(page);
  }

  async OpenObject(row: ObjectDto) {
    if (!row.Trashed) {
      if (row.Type === 'folder') {
        this.navigatorService.StartLoading();
        this.Model.Skip = 0;
        if (this.Tab == 1) {
          await this.GetObjects(row);
        } else if (this.Tab == 2) {
          await this.GetFavouriteObjects(row);
        }
        this.navigatorService.StopLoading();
      } else if (row.Type === 'file') {
        this.Model.Dtos.map((x) => (x.Selected = false));
        await this.commonService.OpenObject(row, this.navigatorService);
      }
    }
  }

  CheckRow(event: any, dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      dto.Checked = event.checked;
    }
  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      this.Model.Dtos.map((o) => (o.Selected = false));
      dto.Selected = true;
    }
  }

  async ReadObject(parent) {
    if (parent && parent > 0) {
      let model = new ObjectModel();
      model.Filter = new ObjectFilter();
      model.Filter.AccountId = this.authenticationService.DocAccount.Dto.Id;
      model.Filter.Id = parent;
      model = await this.Controller.Read(model);
      return model?.Dto ?? null;
    }
    return null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    if (this.Tab == 1) {
      await this.GetObjects(null, searchText, 1, this.Filter);
    } else if (this.Tab < 2) {
      await this.GetFavouriteObjects(null, searchText, 1, this.Filter);
    }
    this.navigatorService.StopLoading();
  }

  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (folder.IsRedirect) {
      this.CurrentFolder = null;
      await this.GetObjects();
    } else {
      if (this.CurrentFolder?.Deep > 1) {
        this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
        folder = this.CurrentFolder;
      } else {
        this.CurrentFolder = null;
        folder = null;
      }
      if (this.Tab == 1) {
        await this.GetObjects(folder, null, this.Pager.CurrentPage);
      } else if (this.Tab === 2) {
        await this.GetFavouriteObjects(folder, null, this.Pager.CurrentPage);
      }
    }
    this.navigatorService.StopLoading();
  }

  HeaderCheckbox(event: any) {
    if (event) {
      const dtos = this.Model.Dtos;
      dtos?.map((o) => (o.Checked = event.checked));
    }
  }

  IsAllSelected() {
    const dtosSelected = this.Model?.Dtos;
    const selection = new SelectionModel<ObjectDto>(
      true,
      this.Model?.Dtos?.filter((t) => t.Checked && t.Type === 'file')
    );
    if (selection.selected.length > 0) {
      return selection.selected.length === dtosSelected?.length;
    }
    return false;
  }

  async Create() {
    let rootfolder =
      this.CurrentFolder == null
        ? await this.ReadRootFolder()
        : this.CurrentFolder;
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(
      DialogMenuComponent,
      { IsMyDocument: true },
      '45%',
      'fit-content',
      '200px',
      (response) => {
        this.navigatorService.PageAction = PageAction.New;
        if (response) {
          this.navigatorService.ShowDialog(
            response.MenuItemSelected.Component,
            {
              DocumentMenuItems: response.DocumentMenuItems,
              CurrentFolder: rootfolder,
              IsMyDocument: true,
            },
            '45%',
            'fit-content',
            '200px',
            async (result) => {
              if (result) {
                this.Tab = 1;
                this.Model.Skip = 0;
                this.navigatorService.StartLoading();
                await this.GetObjects(this.CurrentFolder);
                this.navigatorService.StopLoading();
              }
            }
          );
        }
      }
    );
  }

  async ReadRootFolder() {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.Deep = 0;
    model.Filter.Desktop = true;
    model.Filter.Type = 'folder';
    model.Filter.PhysicalName = this.authenticationService.CurrentUser.Id;
    model = await this.Controller.GetFirst(model);
    return model?.Dto ?? null;
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    if (dtos) {
      for (const dto of dtos) {
        if (!dto.ClassIcon && !dto.Icon) {
          this.commonService.GetIcon(dto);
        }
      }
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
    this.SetAllFavouritesSelected();
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    if (this.Tab == 1) {
      await this.GetObjects(this.CurrentFolder, null, page, this.Filter);
    } else if (this.Tab == 2) {
      await this.GetFavouriteObjects(
        this.CurrentFolder,
        null,
        page,
        this.Filter
      );
    } else if (this.Tab == 3) {
      await this.GetTrashedObjects(this.Pager.CurrentPage);
    }
    this.navigatorService.StopLoading();
  }

  GetMenuFile(dto: ObjectDto) {
    const menu = new FileMenu(
      this.navigatorService.Dictionary,
      dto,
      true,
      dto.Trashed
    );
    this.FileMenu = menu.GetMenu();
  }

  async ActionMenu(action: string, dto: ObjectDto) {
    if (action === 'sign') {
      if (dto.IsSignatory) {
        this.navigatorService.ShowDialog(
          SignatureComponent,
          dto,
          '45%',
          'fit-content',
          '200px',
          async (response) => {
            if (response) {
              this.navigatorService.StartLoading();
              if (this.Tab == 1) {
                await this.GetObjects(response, null, this.Pager.CurrentPage);
              } else if (this.Tab == 2) {
                await this.GetFavouriteObjects(
                  response,
                  null,
                  this.Pager.CurrentPage
                );
              }
              this.navigatorService.StopLoading();
            }
          }
        );
      }
    } else if (action === 'sign-verify') {
      if (dto.IsSignatory) {
        const data = { Dto: dto };
        this.navigatorService.ShowDialog(
          VerifySignatureComponent,
          data,
          '45%',
          'fit-content',
          '200px'
        );
      }
    } else if (action === 'encrypt' || action === 'decrypt') {
      if (dto.Type === 'file') {
        const data = { Dto: dto, SoftDescrypt: false };
        this.navigatorService.ShowDialog(
          EncryptComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (response) => {
            if (response?.Performed) {
              dto = response.Dto;
            }
          }
        );
      }
    } else if (action === 'delete') {
      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/big-doc.svg';
      data.Name =
        dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToDelete +
        '<br>' +
        data.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new ObjectModel();
            dto.Trashed = true;
            model.Dto = dto;
            model.Action = 'TRASHED';
            model.UpdateProperties = ['Trashed'];
            model = await this.Controller.CreateOrUpdate(model);
            if (model?.Performed) {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.TrashedFile,
                dto.Name
              );
              if (this.Tab == 1) {
                await this.GetObjects(
                  this.CurrentFolder,
                  null,
                  this.Pager.CurrentPage
                );
              } else if (this.Tab == 2) {
                await this.GetFavouriteObjects(
                  this.CurrentFolder,
                  null,
                  this.Pager.CurrentPage
                );
              }
            } else {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.MessageGenericError
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    } else if (action === 'permanent_delete') {
      if (dto && !Array.isArray(dto)) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name =
          dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Delete;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.PermanentlyDelete +
          '<br>' +
          dto.Name +
          '.' +
          this.commonService.GetExtension(dto.PhysicalName) +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              model.Filter = new ObjectFilter();
              model.Filter.Trashed = true;
              model.Filter.Desktop = true;
              model.Filter.Id = dto.Id;
              model = await this.Controller.Delete(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.DeleteSuccess
                );
                await this.GetTrashedObjects(this.Pager.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      } else if (!dto) {
        const dtos = this.Model?.Dtos?.filter((o) => o.Checked === true);
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Title = this.navigatorService.Dictionary?.Delete;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.PermanentlyDelete +
          '<br>' +
          this.navigatorService.Dictionary?.AllFileSelected +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              model.Filter = new ObjectFilter();
              model.Filter.Trashed = true;
              model.Filter.Desktop = true;
              model.Filter.Id = dtos.map((o) => o.Id);
              model = await this.Controller.Delete(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.DeleteSuccess,
                  data.Name
                );
                await this.GetTrashedObjects(this.Pager.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      }
    } else if (action === 'restore') {
      if (dto && !Array.isArray(dto)) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name =
          dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Restore;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.ToRestore +
          '<br>' +
          dto.Name +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              dto.Trashed = false;
              model.Dto = dto;
              model.Action = 'RESTORE';
              model.UpdateProperties = ['Trashed'];
              model = await this.Controller.CreateOrUpdate(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.RestoreSuccess,
                  data.Name
                );
                await this.GetTrashedObjects(this.Pager.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      } else if (!dto) {
        const dtos = this.Model?.Dtos?.filter((o) => o.Checked === true);
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Title = this.navigatorService.Dictionary?.Restore;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.ToRestore +
          '<br>' +
          this.navigatorService.Dictionary?.AllFileSelected +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              dtos.map((o) => (o.Trashed = false));
              model.Dtos = dtos;
              model.Action = 'RESTORE';
              model.UpdateProperties = ['Trashed'];
              model = await this.Controller.CreateOrUpdate(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.RestoreSuccess
                );
                await this.GetTrashedObjects(this.Pager.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      }
    }
  }
  // #region Tab Favourite
  async GetFavouriteObjects(
    dto: ObjectDto = null,
    search: string = null,
    page = 1,
    filter: ObjectFilter = null
  ) {
    this.Model.Search = null;
    if (filter) {
      this.Model.Filter = filter;
      this.Model.Filter.Desktop = true;
    } else {
      this.Model.Filter = new ObjectFilter();
      this.Model.Filter.Desktop = true;
    }
    let favouriteModel = new FavouriteModel();
    favouriteModel.Filter = new FavouriteFilter();
    favouriteModel.Filter.Uid =
      this.authenticationService.CurrentUser?.Username;
    favouriteModel = await this.FavouritesController.Get(favouriteModel);
    if (favouriteModel?.Performed) {
      if (favouriteModel?.Dtos?.length > 0) {
        const ids = favouriteModel?.Dtos?.map((f) => f.ObjectId);
        this.Model.Filter.Id = ids;
      }
    }
    this.CurrentFolder = dto?.Type === 'folder' ? dto : null;

    this.Model.Search = this.SearchComponent?.GetSearchValue();
    if (!dto && search) {
      this.Model.Search = search;
    } else if (!dto) {
      this.Model.Filter.Deep = 1;
    } else if (dto && dto.Type === 'folder') {
      this.Model.Filter.Parent = dto.Id;
    } else if (dto && dto.Type === 'file') {
      this.Model.Filter.Parent = dto.Parent;
    }

    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';

    this.Model = await this.Controller.Load(this.Model);
    if (this.Model.Dtos) {
      this.Model.Dtos = this.Model.Dtos.filter((dto) => dto.Favourite !== null);
    }
    if (
      (!favouriteModel?.Performed || favouriteModel?.Dtos?.length <= 0) &&
      !dto
    ) {
      this.Model.Dtos = [];
      this.Model.Count = 0;
    }
    if (dto && dto.Type === 'file') {
      this.Model.Dtos.find((q) => q.Id === dto.Id).Selected = true;
    }
    this.Paging(page);
  }

  async HeaderFavourites(selectAll: boolean) {
    const dtos = this.Model.Dtos;
    // this.navigatorService.StartLoading();

    if (dtos?.length > 0) {
      if (selectAll) {
        const favouriteDtos = new Array<FavouriteDto>();
        for (const dto of dtos) {
          const favouriteDto = new FavouriteDto();
          if (!dto.Favourite) {
            favouriteDto.Uid = this.authenticationService.CurrentUser.Username;
            favouriteDto.ObjectId = dto.Id;
          } else {
            favouriteDto.Id = dto.Favourite?.Id;
            favouriteDto.Uid = dto.Favourite?.Uid;
            favouriteDto.ObjectId = dto.Favourite?.ObjectId;
          }
          favouriteDtos.push(favouriteDto);
        }
        const responseDtos = await this.CheckAllFavourite(
          favouriteDtos,
          selectAll
        );
        dtos.map(
          (o) => (o.Favourite = responseDtos.find((e) => e.ObjectId === o.Id))
        );
      } else {
        const ids = dtos.map((o) => o.Favourite?.Id);
        await this.CheckAllFavourite(new Array<FavouriteDto>(), selectAll, ids);
        dtos.map((o) => (o.Favourite = null));
        if (this.Tab === 2) {
          if (!this.CurrentFolder || this.CurrentFolder?.Deep <= 0) {
            this.Model.Dtos = this.Model.Dtos?.filter(
              (o) => o.Favourite !== null
            );
            this.Paging(this.Pager.CurrentPage);
          }
        }
      }
      this.SetAllFavouritesSelected();
    }
    // this.navigatorService.StopLoading();
  }

  async CheckAllFavourite(
    dtos: Array<FavouriteDto>,
    selectAll: boolean,
    ids = null
  ) {
    let model = new FavouriteModel();
    if (selectAll) {
      model.Dtos = dtos;
      model = await this.FavouritesController.CreateOrUpdate(model);
      if (!model?.Performed) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
    } else if (!selectAll && ids?.length > 0) {
      model.Filter = new FavouriteFilter();
      model.Filter.Id = ids;
      model = await this.FavouritesController.Delete(model);
      if (!model?.Performed) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      } else {
        this.Model.Count -= dtos?.length;
      }
    }
    return model.Dtos;
  }

  async CheckFavourite(dto: ObjectDto) {
    // this.navigatorService.StartLoading();
    if (dto && dto.Id > 0) {
      let model = new FavouriteModel();
      if (dto.Favourite) {
        model.Filter = new FavouriteFilter();
        model.Filter.Id = dto.Favourite.Id;
        model = await this.FavouritesController.Delete(model);
        if (model?.Performed) {
          dto.Favourite = null;
          this.Model.Count -= 1;
        }
        if (this.Tab === 2) {
          if (!this.CurrentFolder || this.CurrentFolder?.Deep <= 0) {
            this.Model.Dtos = this.Model.Dtos.filter(
              (o) => o.Favourite !== null
            );
            this.Paging(this.Pager.CurrentPage);
          }
        }
      } else {
        model.Dto = new FavouriteDto();
        model.Dto.Uid = this.authenticationService.CurrentUser.Username;
        model.Dto.ObjectId = dto.Id;
        model = await this.FavouritesController.CreateOrUpdate(model);
        if (model?.Performed) {
          dto.Favourite = model.Entity;
        }
      }
      if (!model?.Performed) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      } else {
        this.SetAllFavouritesSelected();
      }
    }
    // this.navigatorService.StopLoading();
  }

  SetAllFavouritesSelected() {
    if (this.Model) {
      const dtoscurrentPage = this.Model.Dtos;
      const dtosFavourite = dtoscurrentPage?.filter(
        (o) => o.Favourite !== null
      );
      if (this.Tab < 2) {
        this.IsAllFavouritesSelected =
          dtosFavourite?.length === this.Model.Dtos?.length;
      } else {
        this.IsAllFavouritesSelected =
          dtosFavourite?.length === this.Model.Dtos?.length &&
          this.Model.Dtos?.length > 0;
      }
    }
  }
  // #endregion Favourite

  GetMenuFiles() {
    const dtos = this.Model?.Dtos?.filter((t) => t.Checked);
    const isAnyTrashed = dtos.some((dto) => dto.Trashed);

    const menuDelete =
      dtos.length > 0 &&
      dtos.filter(
        (q) =>
          q.State === DocumentState.PROGRESS ||
          q.State === DocumentState.REJECTED
      ).length === 0;

    const menuSign = false;

    const menu = new FilesMenu(
      this.navigatorService.Dictionary,
      menuDelete,
      menuSign,
      false,
      isAnyTrashed
    );

    this.FilesMenu = menu.GetMenu();
  }

  ActionFilesMenu(action) {
    const dtos = this.Model.Dtos.filter((q) => q.Checked);
    if (action === 'delete') {
      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/big-doc.svg';
      data.Name = '';
      for (const dto of dtos) {
        data.Name +=
          dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        if (dto !== dtos[dtos?.length - 1]) {
          data.Name += ',';
        }
      }
      data.Title = this.navigatorService.Dictionary?.Delete;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        this.navigatorService.Dictionary?.ToDelete +
        '<br>' +
        data.Name +
        '?';
      data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (option) => {
          if (option === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            const models = Array<ObjectModel>();
            for (const dto of dtos) {
              let model = new ObjectModel();
              dto.Trashed = true;
              model.Dto = dto;
              model.Action = 'TRASHED';
              model.UpdateProperties = ['Trashed'];
              model = await this.Controller.CreateOrUpdate(model);
              models.push(model);
            }
            const performed = models.filter((q) => !q.Performed)?.length <= 0;
            if (performed) {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.TrashedFiles
              );
              if (this.Tab < 2) {
                await this.GetObjects(null, null, this.Pager.CurrentPage);
              } else {
                await this.GetFavouriteObjects(
                  null,
                  null,
                  this.Pager.CurrentPage
                );
              }
            } else {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.TrashedFileNotCmplete
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    } else if (action === 'permanent_delete') {
      if (dtos && dtos.length > 0) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Title = this.navigatorService.Dictionary?.Delete;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.PermanentlyDelete +
          '<br>' +
          this.navigatorService.Dictionary?.AllFileSelected +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              model.Filter = new ObjectFilter();
              model.Filter.Trashed = true;
              model.Filter.Id = dtos.map((o) => o.Id);
              model = await this.Controller.Delete(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.DeleteSuccess,
                  data.Name
                );
                await this.GetTrashedObjects(this.Pager.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      }
    } else if (action === 'restore') {
      if (dtos && dtos.length > 0) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Title = this.navigatorService.Dictionary?.Restore;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.ToRestore +
          '<br>' +
          this.navigatorService.Dictionary?.AllFileSelected +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              dtos.map((o) => (o.Trashed = false));
              model.Dtos = dtos;
              model.Action = 'RESTORE';
              model.UpdateProperties = ['Trashed'];
              model = await this.Controller.CreateOrUpdate(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.RestoreSuccess
                );
                await this.GetTrashedObjects(this.Pager.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      }
    }
  }

  GetMoreActionFilesDisabled() {
    return this.Model?.Dtos?.filter((t) => t.Checked)?.length <= 0;
  }

  OpenFiltersDialog() {
    const data = {
      View: Pages.Documents,
      Filter: this.Filter,
      IsMyDocument: true,
    };
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(
      FilterDocumentComponent,
      data,
      '45%',
      'fit-content',
      '200px',
      async (response: any) => {
        if (response) {
          this.navigatorService.StartLoading();
          this.FilterCount = response.FilterCount;
          this.Filter = response.Filter;
          this.Model.Skip = 0;
          if (this.Tab == 1) {
            if (this.FilterCount > 0) {
              await this.GetObjects(null, null, 1, response.Filter);
            } else {
              this.Model = new ObjectModel();
              await this.GetObjects();
            }
          } else if (this.Tab == 2) {
            if (this.FilterCount > 0) {
              await this.GetFavouriteObjects(null, null, 1, response.Filter);
            } else {
              this.Model = new ObjectModel();
              await this.GetFavouriteObjects();
            }
          } else if (this.Tab == 3) {
            if (this.FilterCount > 0) {
              await this.GetTrashedObjects(1);
            } else {
              this.Model = new ObjectModel();
              await this.GetTrashedObjects();
            }
          }
          this.navigatorService.StopLoading();
        }
      }
    );
  }
}
