<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <h2>{{navigatorService.Dictionary?.ConservationLoginTitle}}</h2>
      </div>
    </div>
    <div class="col-md-1 text-end">
      <span class="material-icons icon-close" [mat-dialog-close]="null">
        close
      </span>
    </div>
  </div>

  <div>
    <p class="title-description">{{navigatorService.Dictionary?.ConservationLoginDescription}}</p>
    <mat-dialog-content style="display:inline;padding-left: 39px">
      <div class="row mb-3">
        <div class="col-md-12 col-sm-12">
          <app-input [DataValue]='Model.Dto' [Property]="'Username'" [Type]="'text'" [Required]='true'
            [Placeholder]="navigatorService.Dictionary?.Username"></app-input>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12 col-sm-12">
          <app-input [DataValue]='Model.Dto' [Property]="'Password'" [Type]="'password'" [Required]='true'
            [Placeholder]="navigatorService.Dictionary?.Password"></app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <mat-checkbox class="example-margin" [(ngModel)]="Model?.Dto.SaveCredential">Salva credenziali</mat-checkbox>

        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button (click)="Login()" mat-button class="it-doc-btn-primary"
      [disabled]="!Model.Dto.Username || !Model.Dto.Password">
      <span class="button-text">{{navigatorService.Dictionary?.DocFlyLogin}}</span>
    </button>
  </div>
</div>
