<mat-form-field id="ChipsMultiselect" class="example-chip-list">
  <mat-label *ngIf="MatLabel">{{MatLabel}}</mat-label>

  <input placeholder="{{Placeholder}}" #elementInput [formControl]="InputCtrl" [matAutocomplete]="auto"
    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="SeparatorKeysCodes" [disabled]="disabled" class="form-input">

  <img matSuffix src="../../../../assets/icon/select-arrow.svg" class="custom-arrow-icon">

  <mat-chip-grid  #chipList >
  </mat-chip-grid >

  <div class="custom-select-wrapper">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="Selected($event)" class= "custom-select">
      <mat-option *ngFor="let element of FilteredElements | async" [value]="element">
        {{PropertiesJson && LengthProperty && element[PropertiesJson][LengthProperty] > 0 ?
        element[NameProperty] + ' ' +
        navigatorService.Dictionary?.MaxPlaceHolder + ' ' +
        element[PropertiesJson][LengthProperty] + ' ' +
        navigatorService.Dictionary?.Characters
        : element[NameProperty]}}
      </mat-option>
    </mat-autocomplete>
  </div>
</mat-form-field>

<ng-container *ngFor="let chip of ChipsView">
  <mat-chip [ngClass]="{'disabled-chip': !ShowRemovableIcon(chip)}" [selectable]="Selectable"
    [removable]="Removable" (removed)="Remove(chip)">
    {{PropertiesJson && LengthProperty && chip[PropertiesJson][LengthProperty] > 0 ?
    chip[NameProperty] + ' ' +
    navigatorService.Dictionary?.MaxPlaceHolder + ' ' +
    chip[PropertiesJson][LengthProperty] + ' ' +
    navigatorService.Dictionary?.Characters
    : chip[NameProperty]}}
    <button matChipRemove *ngIf="ShowRemovableIcon(chip) && !disabled">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-chip>
</ng-container>


