<div class="itdoc-page itdoc-app-container-box">

  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div class="grid-column" (click)="Load(1)" [ngClass]="Tab === 1 ? 'tab-selected' : ''" [ngStyle]="{
                cursor: Tab === 2 || Tab === 3 ? 'pointer' : 'default'
              }">
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.AllDocument
                }}</span>
            </div>

            <div class="grid-column" (click)="Load(2)" [ngClass]="Tab === 2 ? 'tab-selected' : ''" [ngStyle]="{
                cursor: Tab === 1 || Tab === 3 ? 'pointer' : 'default'
              }">
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.Favourites
                }}</span>
            </div>

            <div class="grid-column" (click)="Load(3)" [ngClass]="Tab === 3 ? 'tab-selected' : ''" [ngStyle]="{
                cursor: Tab === 2 || Tab === 1 ? 'pointer' : 'default'
              }">
              <span class="grid-column-title">{{
                navigatorService.Dictionary?.Trashed
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mx-0 p-0">
    <div class="search-create-container">
      <div *ngIf="Tab!=3" class="p-0 d-flex align-items-center justify-content-center" style="width: 30px;margin-right: 15px;">
        <span class="filter-badge" [matBadgeHidden]="FilterCount === 0" matBadge="{{ FilterCount }}">
          <img class="clickable-icon" (click)="OpenFiltersDialog()" src="../../../../../assets/icon/filter-icon.svg" />
        </span>
      </div>
      <div class="flex-grow-1 p-0">
        <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
          [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
          [HaveActions]="false" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)"></app-search>
      </div>
      <div class="p-0" style="width: 120px;">
        <button *ngIf="!DashboardView" (click)="Create()" mat-fab extended class="it-doc-btn-create create-button">
          <img class="clickable-icon" src="../../../../../assets/icon/add.svg" />
          <span class="text">{{ navigatorService.Dictionary?.Upload }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="form mt-3">
    <div class="row breadcrumb-lite" *ngIf="CurrentFolder && CurrentFolder.Deep > 0">
      <div class="col-md-4">
        <button mat-fab extended (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder" class="it-doc-btn-back">
          <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
          <span *ngIf="!CurrentFolder.IsRedirect">{{ navigatorService.Dictionary?.Back }}
          </span>
          <span *ngIf="CurrentFolder.IsRedirect">{{ navigatorService.Dictionary?.AllDocument }}
          </span>
        </button>
      </div>
      <div class="col-md-4 text-center current-folder">
        <span>{{ CurrentFolder?.Name }}</span>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div *ngIf="!this.navigatorService.Loading" class="it-doc-table-container">
      <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef class="checkbox-column">
            <mat-checkbox (change)="HeaderCheckbox($event)" [checked]="IsAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (change)="CheckRow($event, element)" [(ngModel)]="element.Checked">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="favourites-column">
          <th mat-header-cell *matHeaderCellDef class="favourites-column text-center">
            <img src="../../../../../assets/icon/tick-empty.svg" class="clickable-icon" (click)="HeaderFavourites(true)"
              *ngIf="!IsAllFavouritesSelected && Tab < 3" />
            <img src="../../../../../assets/icon/tick-full.svg" class="clickable-icon" (click)="HeaderFavourites(false)"
              *ngIf="IsAllFavouritesSelected && Tab < 3" />
            <!-- </ng-container> -->
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <!-- <ng-container *ngIf="!CurrentFolder || CurrentFolder?.Deep <= 0"> -->
            <img *ngIf="!element?.Favourite && Tab < 3" (click)="CheckFavourite(element)"
              src="../../../../../assets/icon/tick-empty.svg" class="clickable-icon" />
            <img *ngIf="element?.Favourite && Tab < 3" (click)="CheckFavourite(element)"
              src="../../../../../assets/icon/tick-full.svg" class="clickable-icon" />
            <!-- </ng-container> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnName }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ navigatorService.Dictionary?.TableColumnName }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span (click)="OpenObject(element); $event.stopPropagation()" class="clickable-icon" title="{{
                element.Name +
                  (element.Type === 'file'
                    ? '.' + commonService.GetExtension(element.PhysicalName)
                    : '')
              }}">
              <div class="d-flex">
                <span class="icon-text-right">
                  <img src="{{ element.Icon }}" />
                  <span *ngIf="element.Sign && !element.Crypt" class="material-icons sign-icon">draw</span>
                </span>
                <div class="ellipsis-text">
                  {{
                  element.Name +
                  (element.Type === "file"
                  ? "." + commonService.GetExtension(element.PhysicalName)
                  : "")
                  }}
                </div>
              </div>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnVersion }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ navigatorService.Dictionary?.TableColumnVersion }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{
              element.Version && element.Version > 0
              ? "v." + element.Version
              : ND
              }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnSize }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ navigatorService.Dictionary?.TableColumnSize }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ element.Size }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnShared }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ navigatorService.Dictionary?.TableColumnShared }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              <ng-container *ngIf="element?.Team?.length > 0 && element?.Team?.length <= 3">
                <span *ngFor="let user of element?.Team; let i = index">
                  <img *ngIf="user.Avatar" class="profile-picture" [ngClass]="i > 0 ? 'profile-picture-over' : ''"
                    matTooltip="{{ user.Name }}" [ngStyle]="{
                      'left.px':
                        i > 0 && i <= 3 && i > 0 && element?.Team[i - 1].Avatar
                          ? i * -8
                          : 0
                    }" src="data:image/png;base64,{{ user.Avatar }}" /></span>
              </ng-container>
              <ng-container *ngIf="element?.Team?.length > 0 && element?.Team?.length > 3">
                <span *ngFor="let user of element?.Team; let i = index">
                  <img *ngIf="i <= 3 && user.Avatar" class="profile-picture" [ngStyle]="{
                      'left.px':
                        i > 0 && i <= 3 && i > 0 && element?.Team[i - 1].Avatar
                          ? i * -8
                          : 0
                    }" matTooltip="{{ user.Name }}" src="data:image/png;base64,{{ user.Avatar }}" />
                </span>
                <div *ngIf="element?.Team?.length - 4 > 0" class="profile-picture-circle"
                  [ngStyle]="{ 'left.px': 4 * -8 }">
                  +{{ element?.Team?.length - 4 }}
                </div>
              </ng-container>
              <ng-container *ngIf="!element?.Team || element?.Team?.length === 0">-</ng-container>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ navigatorService.Dictionary?.TableColumnUpdate }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ navigatorService.Dictionary?.TableColumnUpdate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Deep >= 0">
              {{ element.ModificationDateString }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="more-action">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <button mat-icon-button (click)="GetMenuFiles()" [disabled]="GetMoreActionFilesDisabled()" class="w-auto"
              #m="matMenuTrigger" [matMenuTriggerFor]="moreAction">
              <img src="../../../../../assets/icon/more-vert.svg"
                [ngClass]="{ 'open-menu-trigger-color': m.menuOpen }" />
            </button>
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)="ActionFilesMenu(itemMenu.Name)" *ngFor="let itemMenu of FilesMenu"
                [disabled]="!itemMenu.Enabled">
                {{ itemMenu.Label }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element">
            <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
              (click)="GetMenuFile(element)" src="../../../../../assets/icon/more-vert.svg"
              [ngClass]="{ 'open-menu-trigger-color': m.menuOpen }" />
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)="ActionMenu(itemMenu.Name, element)" *ngFor="let itemMenu of FileMenu">
                {{ itemMenu.Label }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
          </th>
          <td mat-cell *matCellDef="let element">
            <img *ngIf="element.Type==='folder'" (click)="OpenObject(element);$event.stopPropagation();"
              class="clickable-icon" src="../../../../../assets/icon/forward.svg">
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" (click)="SelectRow(row)"
          [ngClass]="{ selected: row.Selected }"></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
      </div>
    </div>
    <div *ngIf="!navigatorService.Loading && Model?.Dtos?.length <= 0" class="m-0 empty-table-box"
      style="padding-bottom: 200px">
      <img src="../../../../../assets/icon/document-empty.svg" />
      <div class="description">
        {{ navigatorService.Dictionary?.EmptyDocumentMessage }}
      </div>
    </div>
  </div>
</div>
