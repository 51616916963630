import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../../data.service';
import { NavigatorService } from '../../../../navigator.services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ProgressBarPopupComponent } from '../progress-bar-popup/progress-bar-popup.component';
import { IDialogComponent } from '../../../Interface/doc.dialog.interface';
import {
  ObjectDto,
  ObjectFilter,
  ObjectModel,
} from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { AutocompleteFilterUiComponent } from '../../../custom-components/autocomplete-ui/autocomplete-ui.component';
import { FileDto, FileModel } from '../../../models/FileModel';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { MimeTypeModel } from '../../../models/MimeTypeModel';
import { MimeTypeController } from '../../../controllers/MimeTypeController';
import { Constants, PageAction } from '../../../doc.configuration';
import { CommonService } from '../../../../common.service';
import { UploadService } from '../../../../upload.service';
import { forEach } from 'jszip';

@Component({
  selector: 'app-my-document',
  templateUrl: './my-document.component.html',
  styleUrls: ['./my-document.component.scss'],
})
export class MyDocumentComponent
  implements
  OnInit,
  AfterViewInit,
  IDialogComponent<ObjectModel, ObjectController> {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  Model: ObjectModel;
  ObjectModel: ObjectModel;
  Controller: ObjectController;
  ReadOnly = true;
  DocumentMenuItems: Array<any>;
  MenuItemSelected: any;

  DataSource: MatTableDataSource<ObjectDto>;
  DataSourceFiles: MatTableDataSource<FileDto>;

  @ViewChild('autocompleteUser')
  AutoCompleteUser: AutocompleteFilterUiComponent;
  @ViewChild(MatSort) sort: MatSort;
  Pages: Array<number>;
  CurrentPage = 1;
  SliderCurrentPage = 1;
  CurrentFolder: ObjectDto;
  FolderModel: ObjectModel;
  FolderNewDto: ObjectDto;
  ShowNewFolder: boolean;
  FileModel: FileModel;
  FileManagerController: FileManagerController;
  @ViewChild('uploadFile') fileUpload: ElementRef;
  @ViewChild('multiUploadFile') multiFileUpload: ElementRef;
  ExistTemplate = false;
  ND = Constants.ND.toString();
  ShowProgressBar = true;
  UploadInProgress = false;
  UploadComplete = false;
  EnableUpload = false;
  constructor(
    private dataService: DataService,
    public navigatorService: NavigatorService,
    public uploadService: UploadService,
    public commonService: CommonService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<MyDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (navigatorService.PageAction === PageAction.New) {
      this.DocumentMenuItems = data.DocumentMenuItems;
      this.CurrentFolder = data.CurrentFolder;
    }
    this.Model = new ObjectModel();
    this.Model.Dto = new ObjectDto();
    this.ObjectModel = new ObjectModel();
    if (this.navigatorService.PageAction === PageAction.New) {
      this.Model.Dto.Authorization.Authorized = true;
      this.Model.Action = 'CREATE';
    }
    this.FolderModel = new ObjectModel();
    this.Controller = new ObjectController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.Pages = new Array<number>();
    this.Pages.push(1);
  }
  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    // if (this.navigatorService.PageAction === PageAction.Edit) {
    //   this.Step = 2;
    //   this.Model.Filter = new ObjectFilter();
    //   this.Model.Filter.Id = this.data;
    //   this.Model = await this.Controller.Read(this.Model);
    //   if (!this.Model.Performed) {
    //     this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    //     this.dialogRef.close(true);
    //   }
    // }
    this.navigatorService.StopLoading();
  }

  Close() {
    this.dialogRef.close(true);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.SelectFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) { }

  SelectFiles(fileList: FileList) {
    if (!this.FileModel) {
      this.FileModel = new FileModel();
    }
    if (!this.FileModel.Dtos) {
      this.FileModel.Dtos = new Array<FileDto>();
    }

    Array.from(fileList).forEach((file) => {
      const exist = this.FileModel.Dtos.findIndex(q => q.Name == file.name);
      let fileDto = new FileDto();
      fileDto.File = file;
      fileDto.PhysicalName = file.name.replace(/[^a-z0-9.]/gi, '_');
      fileDto.Path = this.CurrentFolder.Path;
      fileDto.Performed = true;
      fileDto.Icon = null;
      fileDto.Size = file.size;
      fileDto.Progress = 0;
      fileDto.Name = file.name;
      this.FileModel.Dtos.push(fileDto);
      fileDto.Message = exist >= 0 ? this.navigatorService.Dictionary?.FileAlreadyExists : null;
      fileDto.Performed = exist < 0;
      this.EnableUpload = this.FileModel?.Dtos.filter((q) => q.Performed === true).length > 0;
    });
    this.multiFileUpload.nativeElement.value = null;
    this.RefreshTableFile(this.FileModel.Dtos);
  }

  RefreshTableFile(dtos: Array<FileDto>) {
    for (const dto of dtos) {
      if (!dto.Icon && !dto.Icon) {
        this.GetIcon(dto);
      }
    }
    this.DataSourceFiles = null;
    this.DataSourceFiles = new MatTableDataSource<FileDto>(dtos);
  }

  async Save() {
    const folder = this.CurrentFolder;
    const deep=folder.Deep + 1;
    for (const fileDto of this.FileModel.Dtos) {
      this.UploadComplete=false;
      this.UploadInProgress=true;
      this.Model.Dto = new ObjectDto();
      this.Model.Dto.PhysicalName = fileDto.PhysicalName;
      this.Model.Dto.Path = fileDto.Path;
      this.Model.Dto.Parent = folder.Id && folder.Id === 0 ? null : folder.Id;
      this.Model.Dto.Deep = this.Model.Dto.Parent ? deep : 1;
      this.Model.Dto.Version = 1;
      this.Model.Dto.Desktop = true;
      this.Model.Dto.Name = fileDto.Name.replace('.' + this.commonService.GetExtension(fileDto.PhysicalName),'');
      this.Model.Dto.Type = 'file';
      this.Model = await this.Controller.CreateOrUpdate(this.Model);
      if (this.Model.Performed) {
        if (this.Model.Code && this.Model.Code === 409) {
          this.Model.Code = null;
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.FileExist,
            this.Model.Dto.Name
          );
          this.navigatorService.StopLoading();
        } else {
          const response = await this.uploadService.Upload(fileDto.File, fileDto.PhysicalName, fileDto.Path, fileDto);
          if (response.Performed && response.Completed) {

          } else if (response.Error) {
            fileDto.Message = this.navigatorService.Dictionary?.UploadError
          }
        }
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        this.navigatorService.StopLoading();
      }
    }
    this.UploadComplete=true;
    this.UploadInProgress=false;
    this.navigatorService.StopLoading();
  }

  private GetIcon(element: FileDto): string {
    let icon = '../../assets/icon/doc_doc.svg';
    const ext = this.commonService.GetExtension(element.PhysicalName);
    switch (ext) {
      case 'ppt':
      case 'pptx':
      case 'odp':
        icon = '../../assets/icon/doc_presentation.svg';
        element.Icon = 'presentation';
        break;
      case 'html':
        icon = '../../assets/icon/doc_doc.svg';
        element.Icon = 'html';
        break;
      case 'txt':
        icon = '../../assets/icon/doc_txt.svg';
        element.Icon = 'txt';
        break;
      case 'jpg':
      case 'png':
        icon = '../../assets/icon/doc_image.svg';
        element.Icon = 'image';
        break;
      case 'xls':
      case 'xlsx':
      case 'ods':
        icon = '../../assets/icon/doc_xls.svg';
        element.Icon = 'xls';
        break;
      case 'doc':
      case 'docx':
      case 'odt':
        icon = '../../assets/icon/doc_doc.svg';
        element.Icon = 'doc';
        break;
      case 'pdf':
        icon = '../../assets/icon/doc_pdf.svg';
        element.Icon = 'pdf';
        break;
      default:
        icon = '../../assets/icon/doc_txt.svg';
        element.Icon = 'default';
        break;
    }
    element.Icon = icon;
    return icon;
  }

  DeleteFile(dto: FileDto) {
    const index = this.FileModel.Dtos.findIndex((q) => q.Name === dto.Name);
    this.FileModel.Dtos.splice(index, 1);
    this.RefreshTableFile(this.FileModel.Dtos);
  }
}
