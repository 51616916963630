<div class="itdoc-page ">
  <div class="row">
    <div class="col-md-11">
      <div class="title-container">
        <h2>{{navigatorService?.Dictionary?.NewTemplate}}</h2>
      </div>
      <p class="title-description">{{navigatorService?.Dictionary?.NewTemplateDescription}}</p>
    </div>
    <div class="col-md-1 text-end">
      <button mat-icon-button [mat-dialog-close]="null">
        <span class="material-icons icon-close">
          close
        </span>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <div class="row mb-3">
      <div class="col-md-10">
        <app-input [DataValue]='Model.Dto' [Property]="'Name'" [Type]="'text'" [Required]='true'
          [Placeholder]="navigatorService?.Dictionary?.TemplateName"></app-input>
      </div>
      <div class="col-md-2"></div>
    </div>
    <app-form-builder #formBuilder [Preview]="Preview" [FormDto]="Model?.Dto"></app-form-builder>
  </mat-dialog-content>

  <div mat-dialog-actions align="end" class="dialog-buttons-container">
    <button *ngIf="!Preview" mat-button (click)="Close()" class="it-doc-btn-cancel" style="margin-inline-end: auto;">
      <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
    </button>
    <button *ngIf="!Preview" mat-button class="it-doc-btn-primary" (click)="Next()">
      <span class="button-text">{{navigatorService.Dictionary?.Next}}</span>
    </button>
    <button *ngIf="Preview" mat-button class="it-doc-btn-primary" style="margin-inline-end: auto;" (click)="Back()">
      <span class="button-text">{{navigatorService.Dictionary?.Back}}</span>
    </button>
    <button *ngIf="Preview" mat-button class="it-doc-btn-primary" (click)="Save()">
      <span class="button-text">{{navigatorService.Dictionary?.Save}}</span>
    </button>
  </div>
</div>