import { BaseEntity, BaseModel } from "../../models/BaseModel";
import { ObjectDto } from './ObjectModel';

export class FavouriteModel extends BaseModel<FavouriteEntity, FavouriteDto, FavouriteFilter> {

    constructor() {
        super();
        this.Take = 10;
    }
}

export class FavouriteEntity extends BaseEntity {
    Uid: string;
    ObjectId: number;
    constructor() {
        super();
        this.Uid = null;
        this.ObjectId = null;
    }
}

export class FavouriteDto extends FavouriteEntity {
     Object: ObjectDto;
    constructor() {
        super();
        this.Object = null;
    }
}

export class FavouriteFilter extends FavouriteDto {
  desktop: boolean;
}
