<div *ngIf="!navigatorService.Loading" id="filterDocument" class="itdoc-page">
    <div class="row">
        <div class="col-md-11">
            <div class="title-container">
                <div class="valign-icon-text">
                    <img style="margin-right: 11px;" src="../../../../../assets/icon/filter-icon.svg">
                    <h2>{{navigatorService.Dictionary?.Filter}}</h2>
                </div>
                <div class="d-flex align-items-center">
                    <div class="reset-filters-btn pointer" (click)="InitializeFilters()" style="margin-right: 20px;">
                        <span>{{navigatorService.Dictionary?.ResetFilters}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1 text-end">
            <span class="material-icons icon-close" (click)="Close()">
                close
            </span>
        </div>
    </div>

    <mat-dialog-content class="default-scrollbar">
        <div class="form">
            <div class="row mb-2">
                <div class="col-md-6 col-sm-12">
                    <app-chips-multiselect #uoSelect *ngIf="!IsMyDocument" [Elements]="OrganizationUnitModel?.Dtos"
                    (ChipsSelectedEmit)="SelectUo($event)" (ChipsDeletedEmit)="RemoveUo($event)"
                    [Chips]="ChipsUo"
                    [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.UoPlaceholder"
                    [Required]="false" [disabled]="false">
                </app-chips-multiselect>
                </div>
                <div class="col-md-6 col-sm-12">
                    <app-chips-multiselect #statusSelect *ngIf="!IsMyDocument" [Elements]="Statuses"
                        (ChipsSelectedEmit)="SelectStatus($event)" (ChipsDeletedEmit)="RemoveStatus($event)"
                        [Chips]="ChipsState"
                        [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.StatusPlaceholder"
                        [Required]="false" [disabled]="StatusFilterDisabled">
                    </app-chips-multiselect>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-6 col-sm-12">
                    <app-chips-multiselect #extensionSelect [Elements]="MimeTypeModel?.Dtos"
                        (ChipsSelectedEmit)="SelectExtension($event)" (ChipsDeletedEmit)="RemoveExtension($event)"
                        [Chips]="ChipsFileExtension"
                        [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.FileExtensionPlaceholder"
                        [Required]="false">
                    </app-chips-multiselect>
                </div>
                <div class="col-md-6 col-sm-12">
                    <app-picker #creationDate
                        [Placeholder]="navigatorService.Dictionary?.CreationDatePlaceholder"
                        [Property]="'CreationDateString'"
                        [DataValue]='Filter'>
                    </app-picker>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-6 col-sm-12">
                    <app-chips-multiselect #tipologySelect *ngIf="!IsMyDocument" [Elements]="TipologyModel?.Dtos"
                        (ChipsSelectedEmit)="SelectTipology($event)" (ChipsDeletedEmit)="RemoveTipology($event)"
                        [Chips]="ChipsTipology"
                        [NameProperty]="'Name'" [Placeholder]="navigatorService.Dictionary?.MetadataStructurePlaceholder"
                        [Required]="false">
                    </app-chips-multiselect>
                </div>
                <div class="col-md-6 col-sm-12">
                    <app-input *ngIf="!IsMyDocument" [DataValue]='Filter' [Property]="'Title'" [Type]="'text'" [Required]='false'
                    [Maxlength]="500" [Placeholder]="navigatorService.Dictionary?.Title"></app-input>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-6 col-sm-12">
                    <app-input *ngIf="!IsMyDocument" [DataValue]='Filter' [Property]="'Sender'" [Type]="'text'" [Required]='false'
                    [Maxlength]="500" [Placeholder]="navigatorService.Dictionary?.Sender"></app-input>
                </div>
                <div class="col-md-6 col-sm-12">
                    <app-input *ngIf="!IsMyDocument" [DataValue]='Filter' [Property]="'Recipient'" [Type]="'text'" [Required]='false'
                    [Maxlength]="500" [Placeholder]="navigatorService.Dictionary?.Recipient"></app-input>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <div mat-dialog-actions align="end" class="dialog-buttons-container">
        <button class="it-doc-btn-cancel" style="margin-inline-end: auto;" (click)="Close()">
            <span class="button-text">{{navigatorService.Dictionary?.Cancel}}</span>
        </button>
        <button  (click)="ApplyFilters()" mat-button class="it-doc-btn-primary" cdkFocusInitial>
            <span class="button-text">{{navigatorService.Dictionary?.Apply}}</span>
        </button>
    </div>
</div>
