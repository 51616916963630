import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../../data.service';
import { IDialogComponent } from '../../../Interface/doc.dialog.interface';
import { FieldsModel, PropertyDto } from '../../../models/FieldsModel';
import { FieldsController } from '../../../controllers/FieldsController';
import { CommonService } from '../../../../common.service';
import { NavigatorService } from '../../../../navigator.services';
import {
  MetadataDto,
  MetadataFilter,
  MetadataModel,
} from '../../../models/MetadataModel';
import { MetadataController } from '../../../controllers/MetadataController';
import { MetadataCategoryModel } from '../../../models/MetadataCategoryModel';
import { MetadataCategoryController } from '../../../controllers/MetadataCategoryController';
import { AuthService } from '../../../../auth.service';
import { PageAction } from '../../../doc.configuration';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss'],
})
export class MetadataComponent
  implements OnInit, IDialogComponent<MetadataModel, MetadataController>
{
  Step = 1;
  Steps = 2;
  Model: MetadataModel;
  FieldsModel: FieldsModel;
  MetadataCategoryModel: MetadataCategoryModel;
  Controller: MetadataController;
  FieldsController: FieldsController;
  MetadataCategoryController: MetadataCategoryController;

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authService: AuthService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<MetadataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Model = new MetadataModel();
    this.Model.Dto = data?.Dto ?? new MetadataDto();
    this.FieldsModel = new FieldsModel();
    this.FieldsModel.Filter = new MetadataFilter();
    this.FieldsModel.Filter.Default = false;
    this.MetadataCategoryModel = new MetadataCategoryModel();
    this.Controller = new MetadataController(dataService);
    this.FieldsController = new FieldsController(dataService);
    this.MetadataCategoryController = new MetadataCategoryController(
      dataService
    );
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    if (this.navigatorService.PageAction === PageAction.New) {
      this.Model.Dto.Creator = this.authService.CurrentUser.Displayname;
      this.Model.Dto.UserId = this.authService.CurrentUser.Id;
    }
    this.MetadataCategoryModel = await this.MetadataCategoryController.Get(
      this.MetadataCategoryModel
    );
    this.FieldsModel = await this.FieldsController.Get(this.FieldsModel);

    this.navigatorService.StopLoading();
  }

  async Save() {
    if (this.ValidateForm(2, true)) {
      this.navigatorService.StartLoading();
      this.Model.Performed = false;
      this.Model = await this.Controller.CreateOrUpdate(this.Model);
      if (!this.Model.Performed) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary.MessageGenericError
        );
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary.SaveSuccess
        );

        this.dialogRef.close(true);
      }
      this.navigatorService.StopLoading();
    }
  }
  SelectCategory($event) {
    this.Model.Dto.MetadataCategoryId = $event;
  }

  SelectField($event) {
    const field = this.FieldsModel.Dtos.find((q) => q.Id == $event);
    if (field) {
      this.Model.Dto.Properties = field.Properties;
    }
    this.Model.Dto.FieldId = $event;
  }

  async Next() {
    if (this.Model) {
      const isValid = this.ValidateForm(this.Step);
      if (isValid) {
        this.Step += 1;
      }
    }
  }

  Back() {
    this.Step -= 1;
    if (this.Step <= 0) {
      this.Step = 1;
    }
  }

  ValidateForm(step: number, showMessage = true) {
    if (step === 1) {
      if (
        this.Model?.Dto?.Name &&
        this.Model?.Dto?.MetadataCategoryId &&
        this.Model?.Dto?.FieldId
      ) {
        return true;
      }
    } else if (step === 2) {
      if (
        (this.Model?.Dto?.Properties?.Placeholder !=null && this.Model?.Dto?.Properties?.Maxlength!=null )
        || (this.Model?.Dto?.Properties?.Placeholder !=null  && (this.Model?.Dto?.Properties?.Maxlength ==null && this.Model?.Dto?.Properties?.Type!='date' ))
      ) {
        return true;
      }
    }
    return false;
  }
}
