import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../../../data.service';
import { AddToDossierComponent } from '../../dialog-menu/add-to-dossier/add-to-dossier.component';
import { FilterDocumentComponent } from '../../dialog-menu/filter-document/filter-document.component';
import { AttachedDocumentComponent } from '../../dialog-menu/attached-document/attached-document.component';
import { EncryptComponent } from '../../dialog-menu/encrypt/encrypt.component';
import { VerifySignatureComponent } from '../../dialog-menu/signature/verify-signature/verify-signature.component';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import {
  ObjectDto,
  ObjectFilter,
  ObjectModel,
} from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import { WorkflowDataController } from '../../../../workflow/controllers/WorkflowDataController';
import { FileManagerController } from '../../../controllers/FileManagerController';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import {
  Constants,
  DocumentState,
  FileMenu,
  FilesMenu,
  Pages,
} from '../../../doc.configuration';
import { BaseOrder } from '../../../../models/BaseModel';
import { WorkflowUserDto } from '../../../../workflow/models/WorkflowUserModel';

@Component({
  selector: 'app-archive-document-view',
  templateUrl: './archive-document-view.component.html',
  styleUrls: ['./archive-document-view.component.scss'],
})
export class ArchiveDocumentViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;

  Model: ObjectModel;
  Controller: ObjectController;
  DataSource: MatTableDataSource<ObjectDto>;

  WorkflowDataController: WorkflowDataController;

  FileManagerController: FileManagerController;
  Pages: Array<number>;
  CurrentPage = 1;
  CurrentFolder: ObjectDto;
  Filter: ObjectFilter;
  FilterCount = 0;
  CountAllObject: number = null;
  ND = Constants.ND.toString();
  Tab = 1;
  FileMenu: Array<any>;
  FilesMenu: Array<any>;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService
  ) {
    commonService.CurrentPageComponent = this;
    this.Model = new ObjectModel();
    this.Controller = new ObjectController(dataService);
    this.FileManagerController = new FileManagerController(dataService);
    this.WorkflowDataController = new WorkflowDataController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1) {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Model = new ObjectModel();
    this.SearchComponent?.ClearInputValue(false);
    if (this.CountAllObject === null) {
      this.CountAllObject = await this.CountAll();
    }
    this.Tab = tab;
    if (this.navigatorService.NotificationTargetId > 0) {
      this.navigatorService.ObjectDto = await this.ReadObject(
        this.navigatorService.NotificationTargetId
      );
      if (this.navigatorService.ObjectDto) {
        this.navigatorService.ObjectDto.IsRedirect = true;
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.DocumentNotFound
        );
      }
      this.navigatorService.NotificationTargetId = null;
    }
    if (
      this.navigatorService.ObjectDto &&
      this.navigatorService.ObjectDto.IsRedirect
    ) {
      this.CurrentFolder = this.navigatorService.ObjectDto;
      this.Model.Dtos.push(this.CurrentFolder);
      this.Model.Count = 1;
      this.navigatorService.ObjectDto = null;
      this.Paging();
    } else {
      await this.GetObjects();
    }

    this.navigatorService.StopLoading();
  }

  async CountAll() {
    const state = [DocumentState.ARCHIVED];
    return await this.Controller.CountAll(
      this.authenticationService.DocAccount.Dto.Id,
      state
    );
  }

  async GetObjects(
    dto: ObjectDto = null,
    search: string = null,
    page = 1,
    filter = null
  ) {
    this.Model.Search = this.SearchComponent?.GetSearchValue();
    if (filter) {
      this.Model.Filter = filter;
    } else {
      this.Model.Filter = new ObjectFilter();
      this.Model.Filter.State = DocumentState.ARCHIVED;
      this.Model.Filter.Type = 'file';
    }
    this.Model.Filter.Type = 'file';
    this.Model.Filter.RoleName = this.Tab === 2 ? 'Admin' : null;
    this.Model.Filter.Creator =
      this.Tab === 1 ? this.authenticationService.CurrentUser.Username : null;
    this.Model.Filter.AccountId = this.authenticationService.DocAccount.Dto.Id;
    this.CurrentFolder = dto?.Type === 'folder' ? dto : null;

    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';

    this.Model = await this.Controller.Load(this.Model);
    this.Paging(page);
  }

  async DownloadFile(dto: ObjectDto) {
    this.navigatorService.StartLoading(200000);
    try {
      const model = await this.commonService.Download(dto);
      if (model.Performed) {
        const blob = new Blob([model.Dto.File], { type: dto.MimeType });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = dto.PhysicalName;
        // start download
        element.click();
      }
    } catch (error) {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.MessageGenericError
      );
    }
    this.navigatorService.StopLoading();
  }

  GetDisplayState(state: string) {
    if (state) {
      if (state === DocumentState.PROGRESS) {
        return this.navigatorService.Dictionary?.StateInProgress;
      } else if (state === DocumentState.REVISION) {
        return this.navigatorService.Dictionary?.StateInRevision;
      } else if (state === DocumentState.APPROVAL) {
        return this.navigatorService.Dictionary?.StateInApproval;
      } else if (state === DocumentState.APPROVED) {
        return this.navigatorService.Dictionary?.StateApproved;
      } else if (state === DocumentState.ARCHIVED) {
        return this.navigatorService.Dictionary?.StateArchived;
      }
      return state;
    }
    return this.ND;
  }

  async OpenObject(row: ObjectDto) {
    this.Model.Dtos.map((x) => (x.Selected = false));
    await this.commonService.OpenObject(row, this.navigatorService);
  }

  CheckRow(event: any, dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      dto.Checked = event.checked;
    }
  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      this.Model.Dtos.map((o) => (o.Selected = false));
      dto.Selected = true;
    }
  }

  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.AccountId = this.authenticationService.DocAccount.Dto.Id;
    model.Filter.Id = parent;
    model = await this.Controller.Read(model);
    return model?.Dto ?? null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    await this.GetObjects(null, searchText, 1, this.Filter);
    this.navigatorService.StopLoading();
  }

  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (folder.IsRedirect) {
      this.CurrentFolder = null;
      await this.GetObjects();
    } else {
      if (this.CurrentFolder?.Deep > 1) {
        this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
        folder = this.CurrentFolder;
      } else {
        this.CurrentFolder = null;
        folder = null;
      }
      await this.GetObjects(folder, null, this.CurrentPage);
    }
    this.navigatorService.StopLoading();
  }

  HeaderCheckbox(event: any) {
    if (event) {
      const dtos = this.Model.Dtos.slice(
        this.Model.Skip,
        this.Model.Skip + this.Model.Take
      );
      dtos?.map((o) => (o.Checked = event.checked));
    }
  }

  IsAllSelected() {
    const dtosSelected = this.Model.Dtos.slice(
      this.Model.Skip,
      this.Model.Skip + this.Model.Take
    );
    const selection = new SelectionModel<ObjectDto>(
      true,
      this.Model?.Dtos?.filter((t) => t.Checked)
    );
    if (selection.selected.length > 0) {
      return selection.selected.length === dtosSelected?.length;
    }
    return false;
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      if (!dto.ClassIcon && !dto.Icon) {
        this.commonService.GetIcon(dto);
      }

      if (
        dto?.WorkflowData?.WorkflowSteps &&
        dto?.WorkflowData?.WorkflowSteps?.length > 0
      ) {
        dto.Team = new Array<WorkflowUserDto>();
        for (const step of dto?.WorkflowData?.WorkflowSteps) {
          for (const user of step.WorkflowStepUsers) {
            const find = dto.Team.find((u) => u.AccountId === user.AccountId);
            if (!find) {
              dto.Team.push(user);
            }
          }
        }
      }
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjects(this.CurrentFolder, null, page, this.Filter);
    this.navigatorService.StopLoading();
  }

  GetMenuFile(dto: ObjectDto) {
    // const dto = this.Model.Dtos.find(o => o.Selected);
    const menu = new FileMenu(this.navigatorService.Dictionary,dto);
    this.FileMenu = menu.GetMenu();
  }

  async ActionMenu(action: string, dto: ObjectDto) {
    if (action === 'collate') {
      this.navigatorService.ShowDialog(
        AddToDossierComponent,
        [dto],
        '45%',
        'fit-content',
        '200px'
      );
    } else if (action === 'attached') {
      const data = { Dto: dto };
      this.navigatorService.ShowDialog(
        AttachedDocumentComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (response: any) => {
          if (response) {
          }
        }
      );
    } else if (action === 'sign-verify') {
      if (dto.IsSignatory) {
        const data = { Dto: dto };
        this.navigatorService.ShowDialog(
          VerifySignatureComponent,
          data,
          '45%',
          'fit-content',
          '200px'
        );
      }
    } else if (action === 'encrypt' || action === 'decrypt') {
      if (dto.Type === 'file') {
        const data = { Dto: dto, SoftDescrypt: false };
        this.navigatorService.ShowDialog(
          EncryptComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (response) => {
            if (response?.Performed) {
              dto = response.Dto;
            }
          }
        );
      }
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.NotAuthorizedMessage
      );
    }
  }

  OpenFiltersDialog() {
    const data = { View: Pages.Documents, Filter: this.Filter };
    // tslint:disable-next-line:max-line-length
    this.navigatorService.ShowDialog(
      FilterDocumentComponent,
      data,
      '45%',
      'fit-content',
      '200px',
      async (response: any) => {
        if (response) {
          this.navigatorService.StartLoading();
          this.FilterCount = response.FilterCount;
          this.Filter = response.Filter;
          this.Model.Skip = 0;
          if (this.FilterCount > 0) {
            await this.GetObjects(null, null, 1, response.Filter);
          } else {
            this.Model = new ObjectModel();
            await this.GetObjects();
          }
          this.navigatorService.StopLoading();
        }
      }
    );
  }

  GetMenuFiles() {
    const dtos = this.Model?.Dtos?.filter((t) => t.Checked);
    const menuDelete =
      dtos.filter(
        (q) =>
          q.State !== DocumentState.PROGRESS &&
          q.State !== DocumentState.REJECTED
      )?.length <= 0;
    const menuSign = false;
    const menuCollate =
      dtos.filter(
        (q) =>
          q.State !== DocumentState.PROTOCOLLED &&
          q.State !== DocumentState.ARCHIVED
      )?.length <= 0;
    const menu = new FilesMenu(
      this.navigatorService.Dictionary,
      menuDelete,
      menuSign,
      menuCollate
    );
    this.FilesMenu = menu.GetMenu();
    const c = true;
  }

  ActionFilesMenu(action) {
    const dtos = this.Model.Dtos.filter((q) => q.Checked);
    if (action === 'collate') {
      this.navigatorService.ShowDialog(
        AddToDossierComponent,
        dtos,
        '45%',
        'fit-content',
        '200px'
      );
    }
  }

  GetMoreActionFilesDisabled() {
    return this.Model?.Dtos?.filter((t) => t.Checked)?.length <= 0;
  }
}
