import { Component, Input, OnInit, signal } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Column, COMPONENTTYPE, Form, Row, SECTION } from './Form';
import { MetadataDto, MetadataFilter, MetadataModel } from '../../../../models/MetadataModel';
import { MetadataController } from '../../../../controllers/MetadataController';
import { DataService } from '../../../../../data.service';
import { FormDto } from '../../../../models/FormModel';
import { CommonService } from '../../../../../common.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilderComponent implements OnInit {
  @Input() FormDto: FormDto;
  @Input() Preview: boolean;
  Form!: Form;
  Components: Array<MetadataDto>;
  readonly panelOpenState = signal(false);
  LayoutSelected: number = 1;
  RowSelected: Row = null;
  SECTION = SECTION;
  COMPONENTTYPE = COMPONENTTYPE;
  MetadataModel: MetadataModel;
  MetadataController: MetadataController;
  SampleModel:MetadataModel;
  constructor(dataService: DataService,private commonService:CommonService) {
    this.MetadataModel = new MetadataModel();
    this.MetadataController = new MetadataController(dataService);
    this.SampleModel= new MetadataModel();
    this.SampleModel.Dto=new MetadataDto();
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.Form = new Form();
    if (this.FormDto != null && this.FormDto.Form != null) {
      const json = JSON.parse(this.FormDto.Form);
      this.Form.Parse(json);
    } else {
      this.FormDto = new FormDto();
      this.LayoutSelected = 1;
    }
    if (this.Form?.Rows?.length >= 0) {
      this.RowSelected = this.Form.FirstRow();
    }    
    this.MetadataModel.Filter=new MetadataFilter();
    this.MetadataModel.Filter.Default=false;
    this.MetadataModel = await this.MetadataController.Get(this.MetadataModel);

    if (this.MetadataModel.Performed) {
      this.SetComponentIcon(this.MetadataModel.Dtos);
      this.Form.SetMetadata(this.MetadataModel.Dtos);
      this.Components = this.Form.GetComponents();
    }
  }
  SetComponentIcon(dtos: Array<MetadataDto>) {
    for (const dto of dtos) {      
      dto.Icon=this.commonService.GetMetaDataComponentIcon(dto.Properties.Type);
    }
  }

  //#region FormBuilder
  DropRow(event: CdkDragDrop<Row[]>) {
    moveItemInArray(this.Form.Rows, event.previousIndex, event.currentIndex);
    this.Form.DropRow(event.previousIndex, event.currentIndex);
  }

  // DropColumn(event: CdkDragDrop<Column[]>, row: Row | null = null) {
  //   if (event.previousContainer === event.container && row != null) {
  //     moveItemInArray(row.Columns, event.previousIndex, event.currentIndex);
  //     row.DropColumn(event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //   }
  // }

  DropComponent(event: CdkDragDrop<MetadataDto[]>, column: Column) {
    if (event.previousContainer === event.container && column != null) {
      moveItemInArray(
        column.Components,
        event.previousIndex,
        event.currentIndex
      );
      column.MoveComponent(event.previousIndex, event.currentIndex);
    } else {
      const itemCopy = this.Form.CloneComponent(event.item.data);
      column.Components.splice(event.currentIndex, 0, itemCopy);
      column.DropComponent(event.currentIndex, itemCopy);
    }
  }

  AddRow() {
    this.Form.AddRow();
    this.Form.SetColumnsGuids();
    this.RowSelected = this.Form.LastRow();
  }

  AddColumn(row: Row) {
    row.AddColumn();
    this.Form.SetColumnsGuids();
  }

  DeleteRow(row: Row) {
    this.Form.DeleteRow(row.Index);
    this.Form.SetColumnsGuids();
    this.RowSelected = this.Form.LastRow();
    console.log(this.RowSelected);
  }

  DeleteColumn(row: Row, column: Column) {
    row.DeleteColumn(column.Index);
    this.Form.SetColumnsGuids();
  }

  public SelectRow(row: Row) {
    this.RowSelected = row;
    this.LayoutSelected = row.Columns.length;
  }
  print() {
    console.log(this.Form);
  }
  //#endregion form builder

  //#region Widget
  public SetLayout(columns: number) {
    if (this.RowSelected) {
      this.LayoutSelected = columns;
      this.RowSelected.SetColumns(columns);
      this.Form.SetColumnsGuids();
    }
  }
  //#endregion Widget

  public GetValue() {
    console.log(JSON.stringify(this.Form.Rows));
    return JSON.stringify(this.Form.Rows);

  }
}
