import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { PermissionDto } from './PermissionModel';

export class MetadataCategoryModel extends BaseModel<
  MetadataCategoryEntity,
  MetadataCategoryDto,
  MetadataCategoryFilter
> {
  constructor() {
    super();
  }
}

export class MetadataCategoryEntity extends BaseEntity {
  CategoryId: number;
  Name: string;
  Description: string;

  constructor() {
    super();
    this.CategoryId = null;
    this.Name = null;
    this.Description = null;
  }
}

export class MetadataCategoryDto extends MetadataCategoryEntity {
  Permissions: Array<PermissionDto>;
  PermissionsToDelete: Array<PermissionDto>;
  constructor() {
    super();
    this.Permissions = new Array<PermissionDto>();
    this.PermissionsToDelete = new Array<PermissionDto>();
  }
}

export class MetadataCategoryFilter extends MetadataCategoryDto {
  AccountId: number;
}
