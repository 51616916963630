import { BaseEntity, BaseModel } from './BaseModel';
import { UserAppsDto } from './UserApps';

export class CurrentUserModel extends BaseModel<CurrentUserEntity, CurrentUserDto, CurrentUserFilter> {

  constructor() {
    super();
    this.Take = 10;
    this.Dto = new CurrentUserDto();
  }
}

export class CurrentUserEntity extends BaseEntity {
  Name: string;
  Surname: string;
  Displayname: string;
  Username: string;
  Avatar: string;
  Email: string;
  Phone: string;
  Office: string;
  Birthdate: string | Date;
  Attempt: number;
  Enabled: boolean;
  Lock: boolean;
  Type: string;
  Language: string;
  IsOwner: boolean;
  Department: string;
  Password: string;
  EncryptionPassword: string;
  Jid?: string;
  OpenfireUsername?: string;
  constructor() {
    super();
    this.Name = null;
    this.Surname = null;
    this.Displayname = null;
    this.Username = null;
    this.Birthdate = null;
    this.Email = null;
    this.Type = null;
    this.Language = null;
    this.Avatar = null;
    this.IsOwner = false;
    this.EncryptionPassword = null;
  }
}

export class CurrentUserDto extends CurrentUserEntity {
  IsAdmin: boolean;
  Apps: Array<UserAppsDto>;
  Groups: Array<Group>;
  // GUI
  SettingsRole: string;
  Trashed: boolean;
  ConfirmPassword: string;
  ConfirmMail: string;
  ConfirmPhone: string;
  ConfirmEncryptionPassword: string;
  IsSetEncryptionPassword: boolean;
  constructor() {
    super();
    this.SettingsRole = null;
    this.IsAdmin = false;
   }
}

export class CurrentUserFilter extends CurrentUserDto {
}

export class Group {
  Id: number;
  Name: string;
  Type: string;
  constructor() {
    this.Id = 0;
    this.Name = null;
    this.Type = null;
  }
}

