import { BaseEntity, BaseModel } from '../../models/BaseModel';
import { PropertyDto } from './FieldsModel';

export class MetadataModel extends BaseModel<
  MetadataEntity,
  MetadataDto,
  MetadataFilter
> {
  constructor() {
    super();
    this.Take = 10;
  }

  IsValid() {
    return true;
  }
}
export class MetadataEntity extends BaseEntity {
  MetadataCategoryId: number;
  Name: string; // 500
  UserId: number;
  FieldId: number;
  Properties: PropertyDto;
  Disabled: boolean;

  constructor() {
    super();
  }
}
export class MetadataDto extends MetadataEntity {
  Creator: string;
  Checked: boolean;
  MetadataCategoryName: string;
  FieldName: string;
  Icon: string;
  Index:number;
  Default: boolean;
  constructor() {
    super();
    this.Checked = false;
  }
}

export class MetadataFilter extends MetadataDto {
  SelectedCategoryIds: number[];
  SelectedFieldIds: number[];

  constructor() {
    super();
    this.SelectedCategoryIds = [];
    this.SelectedFieldIds = [];
  }
}
