import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../auth.service';
import { CommonService } from '../common.service';
import { DataService } from '../data.service';
import { Dictionary } from '../dictionary/dictionary';
import { OrganizationUnitController } from '../documentary/controllers/OrganizationUnitController';
import {
  OrganizationUnitDto,
  OrganizationUnitFilter,
  OrganizationUnitModel,
} from '../documentary/models/OrganizationUnitModel';
import { BaseOrder } from '../models/BaseModel';
import { CurrentUserDto, CurrentUserModel } from '../models/CurrentUserModel';
import { NavigatorService } from '../navigator.services';
import { ApplicationSidebar as DocApplicationSidebar, Language } from '../documentary/doc.configuration';
import { WhiteLabelService } from '../white-label.service';
import { EncryptionDto, EncryptionModel } from '../models/EncryptionModel';
import { SettingsController } from '../controllers/SettingsController';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'DD/MM/YYYY', monthYearLabel: 'MMMM YYYY', dateA11yLabel: 'DD/MM/YYYY' } } },
  ],
})
export class UserProfileComponent implements OnInit {
  Tab = 1;
  isEditing: boolean = false;
  Dto: CurrentUserDto;
  SettingController: SettingsController;
  ErrorMessageSurname: string;
  ErrorMessageName: string;
  ErrorMessagePassword: string;
  ErrorMessageEncryptionPassword: string;
  ErrorMessageEmail: string;
  ErrorMessagePhone: string;
  ErrorMessageLanguage: string;
  Languages: Array<LanguageDto>;
  LanguageDto: LanguageDto;
  hide = true;

  UOModel: OrganizationUnitModel;
  UOController: OrganizationUnitController;
  UODataSource: MatTableDataSource<OrganizationUnitDto>;

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public commonService: CommonService,
    public datepipe: DatePipe,
    public authenticationService: AuthService,
    public whiteLabelService: WhiteLabelService

  ) {
    if (this.authenticationService.CurrentUser) {
      this.Dto = { ...this.authenticationService.CurrentUser };
      this.Dto.Password = this.authenticationService.CurrentUser.Password;
      this.Dto.EncryptionPassword = null;
      this.UOController = new OrganizationUnitController(dataService);
      this.SettingController = new SettingsController(dataService);
    }
  }

  ngOnInit(): void {
    this.Load();
  }


  async Load(tab = 1) {
    await this.navigatorService.Wait();
    this.BindViewLanguageOptions();
    if (this.isEditing && this.Dto.Birthdate && typeof this.Dto.Birthdate === 'string') {
      this.Dto.Birthdate = new Date(this.Dto.Birthdate);
    }
    if (!this.isEditing) {
      this.navigatorService.StartLoading();
      await this.navigatorService.Wait();
      this.Tab = tab;
      if (this.Tab === 2) {
        await this.LoadUO();
      }
      this.navigatorService.StopLoading();
    }
  }

  BindViewLanguageOptions() {
    if (this.navigatorService.Dictionary) {
      this.Languages = [
        new LanguageDto(1, this.navigatorService.Dictionary.Italian, Language.IT),
        new LanguageDto(2, this.navigatorService.Dictionary.English, Language.EN)
      ];

      this.LanguageDto = this.Languages.find(
        (q) => q.Value === this.Dto.Language
      );
    }
  }

  toggleEdit(tab) {
    if (this.isEditing) {
      this.Save();
    } else {
      if (this.Dto.Birthdate) {
        this.Dto.Birthdate = new Date(this.Dto.Birthdate);
      }
      this.Tab = tab;
      this.isEditing = true;
      this.Load(tab);
    }
  }


  async Save() {
    if (this.FormValidation()) {
      this.navigatorService.StartLoading();
      if (this.Dto.Birthdate) {
        const formattedDate = this.datepipe.transform(this.Dto.Birthdate, 'dd/MM/yyyy');
        if (formattedDate) {
          const splits = formattedDate.split('/');
          this.Dto.Birthdate = splits[2] + '/' + splits[1] + '/' + splits[0];
        }
      } else {
        this.Dto.Birthdate = null;
      }

    let model = new CurrentUserModel();
        model.Dto = this.Dto;
        model.UpdateProperties = this.GetUpdateProperties();
        model = await this.SettingController.CreateOrUpdateUser(model);

        if (model?.Performed) {
          this.Dto.Password=model.Entity.Password;
          this.Dto.EncryptionPassword=model.Entity.EncryptionPassword;
          this.authenticationService.CurrentUser = model.Dto;

          this.navigatorService.Dictionary = new Dictionary(
            this.authenticationService.CurrentUser.Language,
            this.whiteLabelService.AppName,
            this.authenticationService.CurrentUser?.Displayname
          ).Dictionary;

          const docApplicationSidebar = new DocApplicationSidebar(
            this.navigatorService.Dictionary,
            this.authenticationService.DocAccount.Dto
          );
          this.navigatorService.DashboardMenu = docApplicationSidebar.GetDashboardMenu();
          this.navigatorService.DocMenu = docApplicationSidebar.GetMenu();
          this.navigatorService.SettingsMenu = docApplicationSidebar.GetSettingsMenu();
          this.navigatorService.UserProfileMenu = docApplicationSidebar.GetUserProfileMenu();

          const message = this.navigatorService.Dictionary?.SaveSuccess;
          this.navigatorService.ShowSnackBar(message, this.Dto.Displayname);
          sessionStorage.setItem('user_language', model.Dto.Language);
          this.isEditing = false;
        } else {
          const message = this.navigatorService.Dictionary?.MessageGenericError;
          this.navigatorService.ShowSnackBar(message);
        }
      // } else {
      //   this.navigatorService.StopLoading();
      // }

      this.navigatorService.StopLoading();
    }
  }

  async CheckEncryptonPassword(encryptionPassword: string) {
    let performed = false;
    if (this.Dto.IsSetEncryptionPassword && encryptionPassword) {
      let model = new EncryptionModel();
      model.Dto = new EncryptionDto();
      model.Dto.Password = encryptionPassword;
      model = await this.SettingController.CheckEncryptionPassword(model);
      performed = model?.Performed;
    } else {
      performed = true;
    }
    return performed;
  }


  GetUpdateProperties() {
    let updateProperties = [
      'Name',
      'Surname',
      'Birthdate',
      'Displayname',
      'Language',
      'Password',
      'Phone',
      'Email',
      'EncryptionPassword'
    ];
    return updateProperties;
  }

  FormValidation() {
    let validation = true;
    if ( this.Dto.Password?.length <6 && this.Dto.Password?.length >0) {
      this.ErrorMessagePassword = this.navigatorService.Dictionary?.PasswordLength;
      validation = false;
    } else {
      this.ErrorMessagePassword = null;
    }

    if (this.Dto.EncryptionPassword?.length <6 && this.Dto.EncryptionPassword?.length >0) {
      this.ErrorMessageEncryptionPassword = this.navigatorService.Dictionary?.PasswordLength;
      validation = false;
    } else {
      this.ErrorMessageEncryptionPassword = null;
    }

    if (this.Dto.Email?.length <=0) {
      this.ErrorMessageEmail = this.navigatorService.Dictionary?.MissingMail;
      validation = false;
    }else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.Dto.Email)) {
        this.ErrorMessageEmail = this.navigatorService.Dictionary?.InvalidMail;
        validation = false;
    } else {
      this.ErrorMessageEmail = null;
    }
  }
    return validation;
  }

  SelectLanguage(id: number) {
    this.Dto.Language = this.Languages.find((q) => q.Id === id)?.Value;
  }

  cancelEdit(): void {

    this.isEditing = false;
  }

  //#region Role
  async LoadUO() {
    this.UOModel = new OrganizationUnitModel();
    this.UOModel.Filter = new OrganizationUnitFilter();
    this.UOModel.Filter.Trashed = false;
    this.UOModel.Filter = new OrganizationUnitFilter();
    this.UOModel.Filter.AccountId = this.authenticationService.DocAccount?.Dto?.Id;
    if (this.UOModel?.Filter?.AccountId > 0) {
      this.UOModel.Order = new BaseOrder();
      this.UOModel.Order.Name = 'Id';
      this.UOModel.Order.Direction = 'asc';
      this.UOModel = await this.UOController.Get(this.UOModel);
      if (this.UOModel.Performed) {
        this.UOModel.Dtos.sort((a, b) =>
          a.OfficeCode.toLowerCase() > b.OfficeCode.toLowerCase() ? 1 : -1
        );
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
    } else {
      this.UOModel.Performed = true;
      this.UOModel.Dtos = [];
    }
    this.UODataSource = new MatTableDataSource<OrganizationUnitDto>(
      this.UOModel?.Dtos
    );
    this.navigatorService.StopLoading();
  }
  //#endregion

}

export class LanguageDto {
  Id: number;
  DisplayValue: string;
  Value: string;

  constructor(id: number, displayValue: string, value: string) {
    this.Id = id;
    this.DisplayValue = displayValue;
    this.Value = value;
  }
}
