import { Component, Input } from '@angular/core';
import { PropertyDto } from '../../../../../../models/FieldsModel';
import { SelectComponent } from '../../../../../../custom-components/select/select.component';
import { MetadataDto } from '../../../../../../models/MetadataModel';
import { Column } from '../../Form';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent<TDto> extends SelectComponent<TDto> {
  @Input() Properties: PropertyDto;
  @Input() Data: any; 
  public DeleteComponent(){
    const component:MetadataDto =this.Data.item;
    const column:Column=this.Data.column;
    column.RemoveComponent(component);
    
  }
}
