import {
  SettingsFilter,
  SettingsModel,
} from '../documentary/models/SettingsModel';
import { DataService } from '../data.service';
import { UserGroupModel } from '../models/UserGroupModel';
import { UserModel } from '../models/UserModel';
import { GroupModel } from '../models/GroupModel';
import { NotificationModel } from '../models/NotificationModel';
import { UserAppsModel } from '../models/UserApps';
import { CurrentUserModel } from '../models/CurrentUserModel';

export class ItCoreController {
  constructor(private dataService: DataService) {}

  async IsAdmin(): Promise<boolean> {
    return await this.dataService.IsAdmin();
  }

  async GetUsersGroup(model: UserGroupModel) {
    const response = await this.dataService.GetUsersGroup(model);
    return response;
  }

  async CreateOrUpdateUsersGroup(model: UserGroupModel) {
    const response = await this.dataService.CreateOrUpdateUsersGroup(model);
    return response;
  }

  async DeleteUsersGroup(model: UserGroupModel) {
    const response = await this.dataService.DeleteUsersGroup(model);
    return response;
  }

  async SearchUser(model: UserModel): Promise<UserModel> {
    const response = await this.dataService.SearchUser(model);
    return response;
  }
  async SearchUserDoc(model: UserModel): Promise<UserModel> {
    const response = await this.dataService.SearchUserDoc(model);
    return response;
  }
  async ReadUser(model: UserModel): Promise<UserModel> {
    const response = await this.dataService.ReadUser(model);
    return response;
  }

  async GetGroup(model: GroupModel) {
    const response = await this.dataService.GetGroup(model);
    return response;
  }

  async GetOnlyOfficeUrl(): Promise<string> {
    let model = new SettingsModel();
    model.Filter = new SettingsFilter();
    model.Filter.Appid = 'itcore';
    model.Filter.Configkey = 'ONLYOFFICE_URL';
    model = await this.dataService.GetConfigKey(model);
    if (model.Performed && model.Dto) {
      return model.Dto?.Configvalue;
    }
    return null;
  }

  async GetNotification(model: NotificationModel) {
    const response = await this.dataService.GetNotification(model);
    return response;
  }

  async LoadNotificationHistory(model: NotificationModel) {
    const response = await this.dataService.LoadNotificationHistory(model);
    return response;
  }
  async MarkNotification(model: NotificationModel) {
    const response = await this.dataService.MarkNotification(model);
    return response;
  }

  async DeleteUserApps(model: UserAppsModel) {
    const response = await this.dataService.DeleteUserApps(model);
    return response;
  }

  async DisableUser(model: UserAppsModel) {
    const response = await this.dataService.CreateOrUpdateUserApps(model);
    return response;
  }

  async SearchUsersLdap(search: string): Promise<CurrentUserModel> {
    let model = new CurrentUserModel();
    model.Search = search;
    model = await this.dataService.SearchUsersLdap(model);
    return model;
  }
  async LdapEnabled(): Promise<boolean> {
    return await this.dataService.LdapEnabled();
  }
}
