<div class="component-container">
  <span class="material-icons icon-drag icon-align-center" cdkDragHandle>
    drag_indicator
  </span>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label class="placeholder">{{ Properties?.Placeholder }}</mat-label>
    <input
      [required]="Properties?.Required"
      matInput
      class="input-text"
      [matDatepicker]="picker"
      autocomplete="off"
      readonly
    />
    <mat-datepicker-toggle matSuffix [for]="picker">
      <img matDatepickerToggleIcon src="../../../../assets/icon/calendar.svg" />
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <span
    class="material-icons icon icon-align-center"
    (click)="DeleteComponent()"
  >
    delete
  </span>
</div>

<div class="toggle-container">
  <mat-slide-toggle
    [(ngModel)]="Properties.Required"
    labelPosition="before"
    class="required-toggle"
  >
    {{ navigatorService?.Dictionary?.Required }}
  </mat-slide-toggle>
</div>
