import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NewWorkflowComponent } from '../../dialog/new-workflow/new-workflow.component';
import {
  AccountRole,
  PageAction,
  WFUserType,
  WorkflowState,
} from '../../../../documentary/doc.configuration';
import {
  WorkflowDto,
  WorkflowFilter,
  WorkflowModel,
} from '../../../models/WorkflowModel';
import {
  WorkflowStepDto,
  WorkflowStepFilter,
  WorkflowStepModel,
} from '../../../models/WorkflowStepModel';
import {
  WorkflowUserDto,
  WorkflowUserFilter,
  WorkflowUserModel,
} from '../../../models/WorkflowUserModel';
import { RoleModel } from '../../../../documentary/models/RoleModel';
import {
  AccountFilter,
  AccountModel,
} from '../../../../documentary/models/AccountModel';
import { OrganizationUnitModel } from '../../../../documentary/models/OrganizationUnitModel';
import { WorkflowController } from '../../../controllers/WorkflowController';
import { WorkflowStepController } from '../../../controllers/WorkflowStepController';
import { WorkflowUserController } from '../../../controllers/WorkflowUserController';
import { RoleController } from '../../../../documentary/controllers/RoleController';
import { OrganizationUnitController } from '../../../../documentary/controllers/OrganizationUnitController';
import { AccountController } from '../../../../documentary/controllers/AccountController';
import { NotificationController } from '../../../../controllers/NotificationController';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { DataService } from '../../../../data.service';

@Component({
  selector: 'app-workflow-view',
  templateUrl: './workflow-view.component.html',
  styleUrls: ['./workflow-view.component.scss'],
})
export class WorkflowViewComponent implements OnInit {
  Tab = 1;
  AddBtnSrc: string;
  AdminRoleId: number;
  AccountRole = AccountRole;
  WorkflowState = WorkflowState;
  FilterWorkflowDtos: Array<WorkflowDto>;
  WorkFlowStepNameInput: WorkflowStepDto;
  WFTimeLimitData: Array<number>;
  UserToDeleteModel: WorkflowUserModel;
  StepToDeleteModel: WorkflowStepModel;
  FilterCount = 0;


  Model: WorkflowModel;
  RoleModel: RoleModel;
  WorkflowUserModel: WorkflowUserModel;
  AccountModel: AccountModel;
  OrganizationUnitModel: OrganizationUnitModel;

  Controller: WorkflowController;
  WorkflowStepController: WorkflowStepController;
  WorkflowUserController: WorkflowUserController;
  RoleController: RoleController;
  AccountController: AccountController;
  OrganizationUnitController: OrganizationUnitController;

  NotificationController: NotificationController;
  constructor(
    public navigatorService: NavigatorService,
    public dataService: DataService,
    public authservice: AuthService,
    commonService: CommonService
  ) {
    commonService.CurrentPageComponent = this;

    this.AddBtnSrc = '../../../../../assets/icon/add-circle.svg';
    this.AdminRoleId = null;
    this.FilterWorkflowDtos = new Array<WorkflowDto>();
    this.WorkFlowStepNameInput = new WorkflowStepDto();
    this.UserToDeleteModel = new WorkflowUserModel();
    this.StepToDeleteModel = new WorkflowStepModel();
    this.WFTimeLimitData = [3, 5, 10, 15, 20, 25, 30];

    this.Model = new WorkflowModel();
    this.RoleModel = new RoleModel();
    this.WorkflowUserModel = new WorkflowUserModel();
    this.AccountModel = new AccountModel();
    this.OrganizationUnitModel = new OrganizationUnitModel();

    this.Controller = new WorkflowController(dataService);
    this.WorkflowStepController = new WorkflowStepController(dataService);
    this.WorkflowUserController = new WorkflowUserController(dataService);
    this.RoleController = new RoleController(dataService);
    this.AccountController = new AccountController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(
      dataService
    );

    this.NotificationController = new NotificationController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1, workflowId = 0) {
    this.Tab = tab;
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Model = await this.Controller.Get(this.Model);
    if (this.Model && this.Model.Performed) {
      this.Model.Dtos.map((dto) => {
        (dto.IsExpanded = false), (dto.IsEditing = false);
      });
      workflowId =
        workflowId > 0
          ? workflowId
          : this.navigatorService.NotificationTargetId;
      this.navigatorService.NotificationTargetId = null;
      if (workflowId > 0) {
        this.Tab = 2;
        // keep workflow expanded when refresh view
        const find = this.Model.Dtos.find((w) => w.Id === workflowId);
        find.IsExpanded = true;
        find.IsEditing = false;
      }
      if (this.Tab === 1) {
        await this.GetYourWorkflow();
      } else {
        this.FilterWorkflowDtos = this.Model.Dtos;
      }
    } else {
      this.FilterWorkflowDtos = [];
    }
    // load users
    this.AccountModel = await this.AccountController.Get(this.AccountModel);
    if (
      this.AccountModel &&
      this.AccountModel.Dtos &&
      this.AccountModel.Dtos.length > 0
    ) {
      // remove duplicate users
      this.AccountModel.Dtos = this.AccountModel.Dtos.filter((a, i) => {
        return (
          this.AccountModel.Dtos.findIndex((x) => {
            return x.Id === a.Id;
          }) === i
        );
      });
    }
    // load account role
    this.RoleModel = await this.RoleController.Get(this.RoleModel);
    this.AdminRoleId = this.RoleModel?.Dtos?.find(
      (r) => r.Name === AccountRole.ADMIN
    )?.Id;
    // load uo
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(
      this.OrganizationUnitModel
    );
    this.BuildWFUsers();
    this.navigatorService.StopLoading();

    if (workflowId) {
      (
        document.getElementById('wf' + workflowId.toString()) as HTMLElement
      ).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  async GetYourWorkflow() {
    let currentAccountModel = new AccountModel();
    currentAccountModel.Filter = new AccountFilter();
    currentAccountModel.Filter.Username = this.authservice.CurrentUser.Username;
    currentAccountModel = await this.AccountController.Get(currentAccountModel);
    const uoIds = currentAccountModel?.Dtos?.map((a) => a.UoId);
    const workflowIds = new Array<number>();
    const workflows = !this.authservice?.DocAccount?.Dto?.IsAdmin
      ? this.Model?.Dtos?.filter((w) => w.State === WorkflowState.PUBLISHED)
      : this.Model?.Dtos;
    for (const workflow of workflows) {
      for (const step of workflow.WorkflowSteps) {
        let usersId = step.WorkflowStepUsers.map((u) => u.AccountId);
        const exist = usersId.includes(this.authservice.DocAccount?.Dto?.Id);
        if (exist) {
          workflowIds.push(workflow.Id);
          break;
        } else {
          if (uoIds?.length > 0) {
            usersId = step.WorkflowStepUsers.map((u) => u.UoId);
            for (const userId of usersId) {
              if (userId) {
                if (uoIds?.includes(userId)) {
                  workflowIds.push(workflow.Id);
                  break;
                }
              }
            }
          }
        }
      }
    }
    if (workflowIds?.length > 0) {
      this.FilterWorkflowDtos = this.Model.Dtos?.filter((w) =>
        workflowIds.includes(w.Id)
      );
    } else {
      this.FilterWorkflowDtos = [];
    }
  }

  BuildWFUsers() {
    const users = this.AccountModel?.Dtos?.map(
      ({ Id, DisplayName, RoleId, Avatar }) => ({
        Avatar: Avatar,
        AccountId: Id,
        Name: DisplayName,
        RoleId,
        Id: 0,
        Type: WFUserType.USER,
        UoId: null,
        WorkflowStepId: 0,
      })
    );

    const uos = this.OrganizationUnitModel?.Dtos?.map(({ Id, Name }) => ({
      Avatar: null,
      UoId: Id,
      Name,
      RoleId: null,
      Id: 0,
      Type: WFUserType.UO,
      AccountId: null,
      WorkflowStepId: 0,
    }));

    if (users && uos) {
      this.WorkflowUserModel.Dtos = [...users, ...uos];
    }
  }

  Search(search: string) {
    if (search) {
      this.FilterWorkflowDtos = this.Model.Dtos?.filter((w) =>
        w.Name?.includes(search)
      );
    } else {
      this.FilterWorkflowDtos = this.Model.Dtos;
    }
  }

  NewWorkflowModal() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(
      NewWorkflowComponent,
      null,
      '45%',
      'fit-content',
      '200px',
      async (result) => {
        if (result) {
          await this.Load(this.Tab);
        }
      }
    );
  }

  AddWFStep(dtoInput: WorkflowStepDto, workflowDto: WorkflowDto) {
    if (dtoInput && dtoInput.Name && workflowDto) {
      let lastStepAdminUsers = null;
      if (workflowDto.WorkflowSteps?.length > 0) {
        // tslint:disable-next-line:max-line-length
        lastStepAdminUsers = workflowDto.WorkflowSteps[
          workflowDto.WorkflowSteps?.length - 1
        ]?.WorkflowStepUsers.filter((u) => u.RoleId === this.AdminRoleId);
      }

      const newStepDto = new WorkflowStepDto();
      newStepDto.Name = dtoInput.Name;
      if (lastStepAdminUsers && lastStepAdminUsers.length > 0) {
        // if last step contains admin users the new step will be added to penultimate position
        workflowDto.WorkflowSteps?.splice(
          workflowDto.WorkflowSteps?.length - 1,
          0,
          newStepDto
        );
      } else {
        workflowDto.WorkflowSteps?.push(newStepDto);
      }
      this.WorkFlowStepNameInput.Name = '';
    }
  }

  SelectTimeLimit(timeLimitValue: number, workflowDto: WorkflowDto) {
    if (timeLimitValue && workflowDto) {
      workflowDto.TimeLimit = timeLimitValue;
    }
  }

  DropStep(event: CdkDragDrop<string[]>, workflowDto: WorkflowDto) {
    if (workflowDto) {
      let index = null;
      if (event.currentIndex === workflowDto.WorkflowSteps?.length - 1) {
        index = event.currentIndex;
      }
      if (event.previousIndex === workflowDto.WorkflowSteps?.length - 1) {
        index = event.previousIndex;
      }
      if (index && index >= 0) {
        let stepUsers = workflowDto.WorkflowSteps[index]?.WorkflowStepUsers;
        const adminUsers = stepUsers?.filter(
          (u) => u.RoleId === this.AdminRoleId
        );
        if (adminUsers && adminUsers.length > 0) {
          stepUsers = stepUsers?.filter((el) => {
            const indexUser = adminUsers.indexOf(el);
            if (indexUser < 0) {
              // if no admin present in stepUsers
              return true;
            } else if (indexUser >= 0) {
              if (adminUsers[indexUser]?.Id > 0) {
                this.UserToDeleteModel.Dtos.push(adminUsers[indexUser]);
              }
            }
            return false;
          });
          workflowDto.WorkflowSteps[index].WorkflowStepUsers = stepUsers;
        }
      }
      moveItemInArray(
        workflowDto.WorkflowSteps,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  FindUsersPerRole(stepUsers: Array<WorkflowUserDto>, roleName: string) {
    const roleId = this.RoleModel?.Dtos?.find((r) => r.Name === roleName)?.Id;
    return stepUsers.filter((item) => item.RoleId === roleId);
  }

  UoUsersChange(
    dto: WorkflowUserDto,
    stepDto: WorkflowStepDto,
    autocomplete: any,
    roleName: string
  ) {
    if (dto) {
      const find = stepDto.WorkflowStepUsers?.find((u) => u.Name === dto.Name);
      if (!find) {
        if (dto.Type === WFUserType.UO) {
          const roleId = this.RoleModel?.Dtos?.find(
            (r) => r.Name === roleName
          ).Id;
          dto.RoleId = roleId;
        }
        stepDto.WorkflowStepUsers?.push(dto);
        autocomplete.Clear();
      } else {
        if (dto.Type === WFUserType.UO) {
          // UO can add in multiple role sections
          const roleId = this.RoleModel?.Dtos?.find(
            (r) => r.Name === roleName
          ).Id;
          const newDto = Object.assign({}, dto);
          newDto.RoleId = roleId;
          stepDto.WorkflowStepUsers?.push(newDto);
          autocomplete.Clear();
        }
      }
    }
  }

  GetItems(roleName: string) {
    const roleId = this.RoleModel?.Dtos?.find((r) => r.Name === roleName)?.Id;
    return this.WorkflowUserModel?.Dtos?.filter(
      (item) => item.RoleId === roleId || item.Type === WFUserType.UO
    );
  }

  RemoveWFStepUser(userDto: WorkflowUserDto, stepDto: WorkflowStepDto) {
    if (userDto && stepDto) {
      const index = stepDto.WorkflowStepUsers?.findIndex((u) =>
        userDto.AccountId
          ? u.AccountId === userDto.AccountId && u.RoleId === userDto.RoleId
          : userDto.UoId
          ? u.UoId === userDto.UoId && u.RoleId === userDto.RoleId
          : null
      );
      if (index >= 0) {
        const userdelete = stepDto.WorkflowStepUsers?.splice(index, 1);
        if (userdelete && userdelete.length > 0) {
          if (userDto.Id > 0) {
            // for user already exist in DB
            this.UserToDeleteModel.Dtos.push(userDto);
          }
        }
      }
    }
  }

  ExpansionPanelOpenedEvent(workflowDto: WorkflowDto, index: number) {
    this.FilterWorkflowDtos?.map((dto) => (dto.IsExpanded = false));
    workflowDto.IsExpanded = true;
  }

  ExpansionPanelClosedEvent(workflowDto: WorkflowDto, index: number) {
    workflowDto.IsEditing = false;
    workflowDto.IsExpanded = false;
  }

  async DeleteStep(stepDto: WorkflowStepDto, workflowDto: WorkflowDto) {
    if (stepDto && workflowDto) {
      const index = workflowDto?.WorkflowSteps?.findIndex(
        (s) => s.Id === stepDto.Id
      );
      if (index >= 0) {
        const stepToDelete = workflowDto?.WorkflowSteps?.splice(index, 1);
        if (stepToDelete && stepToDelete.length > 0) {
          this.StepToDeleteModel.Dtos.push(stepDto);
        }
      }
    }
  }

  EditWorkflowBtn(workflowDto: WorkflowDto) {
    if (!workflowDto.IsEditing && !workflowDto.IsExpanded) {
      workflowDto.IsEditing = true;
      workflowDto.IsExpanded = true;
    } else if (!workflowDto.IsEditing && workflowDto.IsExpanded) {
      workflowDto.IsEditing = true;
    }

    const find = this.FilterWorkflowDtos?.find((dto) => dto.IsEditing);
    if (find) {
      this.FilterWorkflowDtos?.filter((dto) => !dto.IsEditing)?.map(
        (el) => (el.IsDisabled = true)
      );
    }
  }

  async Delete(workflowDto: WorkflowDto) {
    this.navigatorService.StartLoading();
    let model = new WorkflowModel();
    model.Filter = new WorkflowFilter();
    model.Filter.Id = workflowDto.Id;
    model = await this.Controller.Delete(model);
    if (model?.Performed) {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.WorkflowDeletedMessage,
        workflowDto.Name
      );
      await this.Load(this.Tab);
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.MessageGenericError,
        workflowDto.Name
      );
    }
    this.navigatorService.StopLoading();
  }

  async Discard(workflowDto: WorkflowDto) {
    workflowDto.IsEditing = false;
    await this.Load(this.Tab, workflowDto.Id);
  }

  Validate(workflowDto: WorkflowDto, publish: boolean) {
    if (publish) {
      // tslint:disable-next-line:max-line-length
      const adminIndex = workflowDto.WorkflowSteps[
        workflowDto.WorkflowSteps?.length - 1
      ]?.WorkflowStepUsers?.findIndex((u) => u.RoleId === this.AdminRoleId);
      if (adminIndex === -1) {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.RequiredAdminMessage
        );
        return false;
      }
    }
    return true;
  }

  async SaveOrPublish(workflowDto: WorkflowDto, publish = false) {
    if (workflowDto) {
      this.navigatorService.StartLoading();
      if (this.Validate(workflowDto, publish)) {
        let result = true;
        for (let i = 1; i <= workflowDto.WorkflowSteps?.length; i++) {
          workflowDto.WorkflowSteps[i - 1].Order = i;
        }
        // update workflow
        workflowDto.State = publish
          ? WorkflowState.PUBLISHED
          : WorkflowState.DRAFT;
        let workflowModel = new WorkflowModel();
        workflowModel.Dto = workflowDto;
        workflowModel = await this.Controller.CreateOrUpdate(workflowModel);
        if (
          workflowModel?.Performed &&
          workflowModel?.Entity &&
          workflowModel?.Entity?.Id > 0
        ) {
          // delete workflow steps
          if (
            this.StepToDeleteModel &&
            this.StepToDeleteModel.Dtos &&
            this.StepToDeleteModel.Dtos.length > 0
          ) {
            let stepToDeleteModel = new WorkflowStepModel();
            stepToDeleteModel.Filter = new WorkflowStepFilter();
            stepToDeleteModel.Filter.Id = this.StepToDeleteModel.Dtos.map(
              (s) => s.Id
            );
            stepToDeleteModel = await this.WorkflowStepController.Delete(
              stepToDeleteModel
            );
            if (!stepToDeleteModel?.Performed) {
              result = false;
            }
          }
          // update workflow steps
          workflowDto.WorkflowSteps?.map(
            (s) => (s.WorkflowId = workflowModel.Entity.Id)
          );
          let wfStepModel = new WorkflowStepModel();
          wfStepModel.Dtos = workflowDto.WorkflowSteps;
          wfStepModel = await this.WorkflowStepController.CreateOrUpdate(
            wfStepModel
          );
          if (
            wfStepModel?.Performed &&
            wfStepModel?.Dtos &&
            wfStepModel?.Dtos?.length > 0
          ) {
            for (const resultStepDto of wfStepModel?.Dtos) {
              const stepUsersToCreate = workflowDto.WorkflowSteps?.find(
                (s) => s.Order === resultStepDto.Order
              )?.WorkflowStepUsers;
              if (stepUsersToCreate && stepUsersToCreate.length > 0) {
                // delete workflow steps users
                if (
                  this.UserToDeleteModel &&
                  this.UserToDeleteModel.Dtos &&
                  this.UserToDeleteModel.Dtos.length > 0
                ) {
                  let userToDeleteModel = new WorkflowUserModel();
                  userToDeleteModel.Filter = new WorkflowUserFilter();
                  userToDeleteModel.Filter.Id = this.UserToDeleteModel.Dtos.map(
                    (u) => u.Id
                  );
                  userToDeleteModel = await this.WorkflowUserController.Delete(
                    userToDeleteModel
                  );
                  this.UserToDeleteModel.Dtos = [];
                }
                // update workflow step users
                stepUsersToCreate.map(
                  (u) => (u.WorkflowStepId = resultStepDto.Id)
                );
                let wfUserModel = new WorkflowUserModel();
                wfUserModel.Dtos = stepUsersToCreate;
                wfUserModel = await this.WorkflowUserController.CreateOrUpdate(
                  wfUserModel
                );
                if (!wfUserModel?.Performed) {
                  result = false;
                }
              }
            }
          }
          if (!wfStepModel?.Performed) {
            result = false;
          }
        }
        if (!workflowModel?.Performed) {
          result = false;
        }
        if (result) {
          // tslint:disable-next-line:max-line-length
          const message = publish
            ? this.navigatorService.Dictionary?.WorkflowPublishedMessage
            : this.navigatorService.Dictionary?.WorkflowSavedMessage;
          this.navigatorService.ShowSnackBar(message, workflowDto.Name);
        } else {
          // error message
          // tslint:disable-next-line:max-line-length
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.WorkflowSavedErrorMessage,
            workflowDto.Name
          );
        }
      }
      await this.Load(this.Tab, workflowDto.Id);
      this.navigatorService.StopLoading();
    }
  }

  OpenFiltersDialog() {}

}
