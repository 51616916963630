import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { PickerComponent } from '../../../custom-components/picker/picker.component';
import { ObjectFilter } from '../../../models/ObjectModel';
import { ChipsMultiselectComponent } from '../../../custom-components/chips-multiselect/chips-multiselect.component';
import { MimeTypeModel } from '../../../models/MimeTypeModel';
import { TipologyModel } from '../../../models/TipologyModel';
import {
  DocumentState,
  DocumentStateFilter,
  Pages,
} from '../../../doc.configuration';
import { OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { MimeTypeController } from '../../../controllers/MimeTypeController';
import { TipologyController } from '../../../controllers/TipologyController';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { DataService } from '../../../../data.service';

@Component({
  selector: 'app-filter-document',
  templateUrl: './filter-document.component.html',
  styleUrls: ['./filter-document.component.scss'],
})
export class FilterDocumentComponent implements OnInit {
  @ViewChild('creationDate') CreationDateControl: PickerComponent<ObjectFilter>;
  @ViewChild('uoSelect') UoSelect: ChipsMultiselectComponent;
  @ViewChild('statusSelect') StatusSelect: ChipsMultiselectComponent;
  @ViewChild('extensionSelect') ExtensionSelect: ChipsMultiselectComponent;
  @ViewChild('tipologySelect') TipologySelect: ChipsMultiselectComponent;

  View: string;
  Filter: ObjectFilter;
  DocumentStateFilter = DocumentStateFilter;
  Statuses = [];
  CreationDate: FormControl;
  MimeTypeModel: MimeTypeModel;
  TipologyModel: TipologyModel;
  OrganizationUnitModel: OrganizationUnitModel;
  MimeTypeController: MimeTypeController;
  TipologyController: TipologyController;
  OrganizationUnitController: OrganizationUnitController;


  ChipsUo = [];
  ChipsState = [];
  ChipsFileExtension = [];
  ChipsTipology = [];
  SelectedUo = [];
  SelectedState = [];
  SelectedFileExtension = [];
  SelectedTipology = [];
  Tab: number;
  StatusFilterDisabled: boolean = false;
  IsMyDocument: boolean;


  constructor(
    public navigatorService: NavigatorService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<FilterDocumentComponent>,
    private commonService: CommonService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.CreationDate = new FormControl();
    this.MimeTypeModel = new MimeTypeModel();
    this.TipologyModel = new TipologyModel();
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.MimeTypeController = new MimeTypeController(dataService);
    this.TipologyController = new TipologyController(dataService);
    this.OrganizationUnitController = new OrganizationUnitController(
      dataService
    );

    this.Tab = this.data?.Tab;
    this.IsMyDocument = data.IsMyDocument;


    // if (this.data?.View === Pages.Dossiers) {
    //   this.Statuses.push({
    //     Name: this.commonService.GetDisplayState(
    //       this.navigatorService.Dictionary,
    //       DocumentState.PROTOCOLLED
    //     ),
    //     Value: DocumentState.PROTOCOLLED,
    //   });
    //   this.Statuses.push({
    //     Name: this.commonService.GetDisplayState(
    //       this.navigatorService.Dictionary,
    //       DocumentState.ARCHIVED
    //     ),
    //     Value: DocumentState.ARCHIVED,
    //   });
    // }else 
    if (this.data?.View === Pages.ViewProtocols) {
      this.Statuses.push({
        Name: this.commonService.GetDisplayState(
          this.navigatorService.Dictionary,
          DocumentState.PROTOCOLLED
        ),
        Value: DocumentState.PROTOCOLLED,
      });
      this.ChipsState?.push(this.Statuses[0]);
      this.SelectedState?.push(this.Statuses[0].Value);
    } else {
      if (this.Tab == 4) {
        this.Statuses.push({
          Name: this.commonService.GetDisplayState(
            this.navigatorService.Dictionary,
            DocumentState.ARCHIVED
          ),
          Value: DocumentState.ARCHIVED,
        });
        this.ChipsState?.push(this.Statuses[0]);
        this.SelectedState?.push(this.Statuses[0].Value);
      } else {
        for (const status of Object.keys(DocumentStateFilter)) {
          this.Statuses.push({
            Name: this.commonService.GetDisplayState(
              this.navigatorService.Dictionary,
              status
            ),
            Value: status,
          });
        }
      }

    }
  }

  ngOnInit(): void {
    this.Load();
    this.StatusFilterDisabled = this.Tab === 3 || this.Tab === 4 || this.data?.View === Pages.ViewProtocols;
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.MimeTypeModel = await this.MimeTypeController.Get(this.MimeTypeModel);
    this.TipologyModel = await this.TipologyController.Get(this.TipologyModel);
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(
      this.OrganizationUnitModel
    );

    if (this.data?.Filter) {
      this.Filter = this.data.Filter;
      for (const uo of this.OrganizationUnitModel?.Dtos) {
        if (this.Filter?.SearchUo?.indexOf(uo.Id) !== -1) {
          this.SelectedUo?.push(uo.Id);
          this.ChipsUo?.push(uo);
        }
      }
      for (const status of this.Statuses) {
        if (this.Filter?.State?.indexOf(status.Value) !== -1) {
          if (this.Tab != 4 && this.data?.View !== Pages.ViewProtocols) {
            this.SelectedState?.push(status.Value);
            this.ChipsState?.push(status);
          }
        }
      }
      for (const mimetype of this.MimeTypeModel?.Dtos) {
        if (
          this.Filter?.SearchFileExtension?.indexOf(mimetype.Extension) !== -1
        ) {
          this.SelectedFileExtension?.push(mimetype.Extension);
          this.ChipsFileExtension?.push(mimetype);
        }
      }
      for (const tipology of this.TipologyModel?.Dtos) {
        if (this.Filter?.SearchTipology?.indexOf(tipology.Id) !== -1) {
          this.SelectedTipology?.push(tipology.Id);
          this.ChipsTipology?.push(tipology);
        }
      }
    } else {
      this.Filter = new ObjectFilter();
      this.Filter.SearchUo = [];
      this.Filter.State = [];
      this.Filter.SearchFileExtension = [];
      this.Filter.CreationDateString = null;
      this.Filter.SearchTipology = [];
      this.Filter.Title = null;
      this.Filter.Recipient = null;
      this.Filter.Sender = null;
    }

    this.navigatorService.StopLoading();
  }

  private GetFilterCount() {
    let filterCount = 0;
    if (this.SelectedUo?.length > 0) {
      filterCount += 1;
    }
    if (this.SelectedState?.length > 0) {
      filterCount += 1;
    }
    if (this.SelectedFileExtension?.length > 0) {
      filterCount += 1;
    }
    if (this.Filter.CreationDateString) {
      filterCount += 1;
    }
    if (this.SelectedTipology?.length > 0) {
      filterCount += 1;
    }
    if (this.Filter.Title) {
      filterCount += 1;
    }
    if (this.Filter.Recipient) {
      filterCount += 1;
    }
    if (this.Filter.Sender) {
      filterCount += 1;
    }
    return filterCount;
  }

  ApplyFilters() {
    const filterCount = this.GetFilterCount();
    this.Filter.SearchUo = this.SelectedUo;
    this.Filter.SearchFileExtension = this.SelectedFileExtension;
    this.Filter.SearchTipology = this.SelectedTipology;
    this.Filter.State = this.SelectedState;
    this.dialogRef.close({
      FilterCount: filterCount,
      Filter: filterCount <= 0 ? null : this.Filter,
      ReferralComponent: this.data.ReferralComponent,
      Search: this.data.Search,
    });
  }

  Close() {
    const filterCount = this.GetFilterCount();
    this.dialogRef.close({
      FilterCount: filterCount,
      Filter: filterCount <= 0 ? null : this.Filter,
      ReferralComponent: this.data.ReferralComponent,
    });
  }

  SelectUo(event) {
    if (event && event.Id > 0) {
      this.SelectedUo?.push(event.Id);
    }
  }

  RemoveUo(event) {
    if (event) {
      const index = this.Filter.SearchUo?.findIndex((x) => x === event.Id);
      this.SelectedUo?.splice(index, 1);
    }
  }

  SelectStatus(event) {
    if (event && event.Value) {
      this.SelectedState?.push(event.Value);
    }
  }

  RemoveStatus(event) {
    if (event && event.Value) {
      const index = this.Filter.State?.findIndex((x) => x === event.Value);
      this.SelectedState?.splice(index, 1);
    }
  }

  SelectExtension(event) {
    if (event && event.Id > 0) {
      this.SelectedFileExtension?.push(event.Extension);
    }
  }

  RemoveExtension(event) {
    if (event && event.Id > 0) {
      const index = this.Filter.SearchFileExtension?.findIndex(
        (x) => x === event.Id
      );
      this.SelectedFileExtension?.splice(index, 1);
    }
  }

  SelectTipology(event) {
    if (event && event.Id > 0) {
      this.SelectedTipology?.push(event.Id);
    }
  }

  RemoveTipology(event) {
    if (event && event.Id > 0) {
      const index = this.Filter.SearchTipology?.findIndex(
        (x) => x === event.Id
      );
      this.SelectedTipology?.splice(index, 1);
    }
  }

  InitializeFilters() {
    this.Filter = new ObjectFilter();
    this.Filter.SearchUo = [];
    this.Filter.State = [];
    this.Filter.SearchFileExtension = [];
    this.Filter.CreationDateString = null;
    this.Filter.SearchTipology = [];
    this.ChipsUo = [];
    this.ChipsFileExtension = [];
    this.ChipsState = [];
    this.ChipsTipology = [];
    this.SelectedUo = [];
    this.SelectedState = [];
    this.SelectedFileExtension = [];
    this.SelectedTipology = [];
    this.CreationDateControl?.Reset();
    this.UoSelect?.Reset();
    this.StatusSelect?.Reset();
    this.ExtensionSelect?.Reset();
    this.TipologySelect?.Reset();
  }
}
