import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationSidebar } from '../../doc.configuration';
import { NavigatorService } from '../../../navigator.services';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit {
  Sections: Steps;
  Step: number;
  SubStep: number;
  CurrentSubStep: SubStep;
  TotalSection: number;
  Menu: Array<any>;
  constructor(
    public navigatorService: NavigatorService,
    public dialogRef: MatDialogRef<WizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Step = 0;
    this.SubStep = 0;
    this.CurrentSubStep = null;
    this.TotalSection = data.IsAdmin ? 6 : 12;
  }

  ngOnInit(): void {
    this.Menu = new Array<any>();
    const docApplicationSidebar = new ApplicationSidebar(
      this.navigatorService.Dictionary,
      this.data.User
    );
    this.Menu.push(docApplicationSidebar.GetDashboardMenu());
    this.Menu.push(...docApplicationSidebar.GetMenu());
    this.Menu.push(docApplicationSidebar.GetSettingsMenu());
    this.Menu.push(...docApplicationSidebar.GetUserProfileMenu());
    this.Menu.map((q) => (q.Visible = false));
    const language = sessionStorage.getItem('user_language');
    this.Sections = new Steps(
      this.data.IsAdmin,
      language,
      this.TotalSection,
      this.Menu
    );
  }

  Start() {
    this.Step++;
    this.SubStep++;
    const step = this.Sections.Steps.find((q) => q.Step === this.Step);
    if (step) {
      step.Current = true;
      this.ShowMenuItem(step);
      this.CurrentSubStep = step.SubSteps.find(
        (q) => q.SubStep === this.SubStep
      );
    }
  }

  ShowMenuItem(step: Step) {
    for (const item of this.Sections.Steps) {
      if (!item.Parent) {
        item.ItemMenu.Visible = false;
      }
      item.ItemMenu.Selected = false;
      item.ItemMenu.Expanded = false;
      if (item.ItemMenu?.Childs?.length > 0) {
        item.ItemMenu.Childs.map((q) => (q.Selected = false));
      }
    }

    step.ItemMenu.Visible = true;
    step.ItemMenu.Selected = true;
    if (step.ItemMenu?.Childs?.length > 0 && step.ItemMenu.Visible) {
      step.ItemMenu.Expanded = true;
    } else if (step.Parent && step.Parent?.Childs?.length > 0) {
      step.Parent.Expanded = true;
      step.Parent.Visible = true;
      step.Parent.Childs.map((q) => (q.Visible = true));
    }
  }

  Next() {
    const step = this.Sections.Steps.find((q) => q.Step === this.Step);
    if (step) {
      this.ShowMenuItem(step);
      step.Current = true;
      this.SubStep++;
      const subStep = step.SubSteps.find((q) => q.SubStep === this.SubStep);
      if (!subStep && this.Sections.Steps?.length >= this.Step + 1) {
        this.Step++;
        this.SubStep = 0;
        this.Next();
      } else {
        this.CurrentSubStep = subStep;
      }
    }
  }

  GoToSection(section: Step) {
    this.Sections.Steps.filter((q) => q.Step > section.Step).map(
      (s) => (s.Current = false)
    );
    this.Sections.Steps.filter((q) => q.Step < section.Step).map(
      (s) => (s.Current = true)
    );
    this.Step = section.Step;
    this.SubStep = 1;
    section.Current = true;
    this.ShowMenuItem(section);
    this.CurrentSubStep = section.SubSteps.find(
      (q) => q.SubStep === this.SubStep
    );
  }

  SkipSection() {
    const length = this.Sections.Steps.filter((q) => q.Current === true).length;
    if (length < this.TotalSection) {
      const currentSection = this.Sections.Steps.filter(
        (q) => q.Current === true
      )[length - 1];
      const nextSection = this.GetNextSection(currentSection);
      this.GoToSection(nextSection);
    } else {
      this.dialogRef.close();
    }
  }
  GetNextSection(currentSection: Step) {
    let nextSection = this.Sections.Steps.find(
      (q) => q.Step === currentSection.Step + 1
    );
    if (nextSection.Parent) {
      nextSection = this.GetNextSection(nextSection);
    }
    return nextSection;
  }
}

export class Steps {
  Steps: Array<Step>;
  constructor(isAdmin, language, steps, menu) {
    const name = isAdmin ? '/Wizard_Admin' : '/Wizard_User';
    this.Steps = new Array<Step>();
    for (let i = 1; i <= steps; i++) {
      const step = new Step();
      step.Step = i;
      step.SetMenuItem(menu, isAdmin);
      step.Current = false;
      step.SubSteps = new Array<SubStep>();
      const subSteps = step.GetSubSteps(step, isAdmin);
      for (let j = 1; j <= subSteps; j++) {
        const substep = new SubStep();
        substep.SubStep = j;
        substep.Path =
          '../../../../assets/wizard/' + language + name + i + '.' + j + '.png';
        substep.CssClass = isAdmin
          ? 'admin-step' + i + '_' + j
          : 'user-step' + i + '_' + j;
        step.SubSteps.push(substep);
      }
      this.Steps.push(step);
    }
  }
}

export class Step {
  Step = 0;
  SubSteps: Array<SubStep>;
  Current = false;
  ItemMenu = null;
  Parent = null;

  GetSubSteps(step: Step, isAdmin: boolean) {
    if (isAdmin) {
      switch (step.Step) {
        case 1:
          return 7;
        case 2:
          return 5;
        case 3:
          return 5;
        case 4:
          return 6;
        case 5:
          return 3;
        case 6:
          return 1;
      }
    } else {
      switch (step.Step) {
        case 1:
          return 5;
        case 2:
          return 2;
        case 3:
          return 2;
        case 4:
          return 2;
        case 5:
          return 1;
        case 6:
          return 1;
        case 7:
          return 2;
        case 8:
          return 6;
        case 9:
          return 1;
        case 10:
          return 1;
        case 11:
          return 2;
        case 12:
          return 1;
      }
    }
    return null;
  }

  SetMenuItem(menu: any[], isAdmin: boolean) {
    if (isAdmin) {
      switch (this.Step) {
        case 1:
          this.ItemMenu = menu.find((q) => q.Name === 'dashboard');
          break;
        case 2:
          this.ItemMenu = menu.find((q) => q.Name === 'uo_view');
          break;
        case 3:
          this.ItemMenu = menu.find((q) => q.Name === 'tipology_view');
          break;
        case 4:
          this.ItemMenu = menu.find((q) => q.Name === 'workflow_view');
          break;
        case 5:
          this.ItemMenu = menu.find((q) => q.Name === 'settings');
          break;
        case 6:
          this.ItemMenu = menu.find((q) => q.Name === 'wizard');
          break;
      }
    } else {
      switch (this.Step) {
        case 1:
          this.ItemMenu = menu.find((q) => q.Name === 'dashboard');
          break;
        case 2:
          this.ItemMenu = menu.find((q) => q.Name === 'uo_view');
          break;
        case 3:
          this.ItemMenu = menu.find((q) => q.Name === 'tipology_view');
          break;
        case 4:
          this.ItemMenu = menu.find((q) => q.Name === 'workflow_view');
          break;
        case 5:
          this.ItemMenu = menu.find((q) => q.Name === 'dossiers');
          break;
        case 6:
          this.Parent = this.ItemMenu = menu.find((q) => q.Name === 'dossiers');
          this.ItemMenu = menu
            .find((q) => q.Name === 'dossiers')
            .Childs.find((q) => q.Name === 'dossier_close');
          break;
        case 7:
          this.ItemMenu = menu.find((q) => q.Name === 'protocols_view');
          break;
        case 8:
          this.ItemMenu = menu.find((q) => q.Name === 'documents');
          break;
        case 9:
          this.Parent = this.ItemMenu = menu.find(
            (q) => q.Name === 'documents'
          );
          this.ItemMenu = menu
            .find((q) => q.Name === 'documents')
            .Childs.find((q) => q.Name === 'files_archived');
          break;
        case 10:
          this.Parent = menu.find((q) => q.Name === 'documents');
          this.ItemMenu = menu
            .find((q) => q.Name === 'documents')
            .Childs.find((q) => q.Name === 'files_delete');
          break;
        case 11:
          this.ItemMenu = menu.find((q) => q.Name === 'settings');
          break;
        case 12:
          this.ItemMenu = menu.find((q) => q.Name === 'wizard');
          break;
      }
    }
  }
}

export class SubStep {
  SubStep: number;
  CssClass: string;
  Path: string;
}
