import { Component, Input } from '@angular/core';
import { InputComponent } from '../../../../../../custom-components/input/input.component';
import { MetadataDto } from '../../../../../../models/MetadataModel';
import { PropertyDto } from '../../../../../../models/FieldsModel';
import { Column } from '../../Form';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent<TDto> extends InputComponent<TDto> {
  @Input() Properties: PropertyDto;
  @Input() Data: any;
  public DeleteComponent() {
    const component: MetadataDto = this.Data.item;
    const column: Column = this.Data.column;
    column.RemoveComponent(component);
  }
}
