<mat-list-item *ngIf="MenuItem.Visible && MenuItem.Name!=='user-profile'" (click)="ClickItem(MenuItem)" class="item"
   (mouseover)='MouseOver($event,MenuItem)'
  (mouseleave)='MouseLeave($event,MenuItem)'>
  <div class="box align-items-center" [ngClass]="{'child-box': IsChild}">
    <div class="menu-item-container">
      <div style="display: flex;align-items: center;">
        <!-- <img *ngIf="MenuItem && !IsChild " class="icon" src="../../assets/icon/{{MenuItem.Icon}}.svg"
          [style]="MenuItem?.Selected || MenuItem.Hover?whiteLabel.SVGIconHover:whiteLabel.SVGIcon"> -->
        <!-- <img *ngIf="MenuItem && IsChild " class="icon-child" src="../../assets/icon/sidebar-item-selected.svg"
          [style]="MenuItem?.Selected || MenuItem.Hover?whiteLabel.SVGIconHover:whiteLabel.SVGIconChildNotSelect"> -->
        <div *ngIf="MenuItem && IsChild" [ngClass]="{' circle-selected': MenuItem?.Selected}" class="child-circle"></div>
        <span [ngClass]="{'selected': MenuItem?.Selected}" *ngIf="!isCollapsed" class="label ml-2">{{MenuItem.Label}}</span>
      </div>
      <div  *ngIf="ShowExpand() && !isCollapsed" class="expand-arrow-container">
        <span *ngIf="!MenuItem.Expanded && (MenuItem.Childs && MenuItem.Childs?.length > 0)"
          class="arrow material-icons" (click)="ClickExpand(MenuItem)">chevron_right</span>
        <span *ngIf="MenuItem.Expanded && (MenuItem.Childs && MenuItem.Childs?.length > 0)" class="arrow material-icons"
          (click)="ClickExpand(MenuItem)">expand_more</span>
      </div>
    </div>
  </div>
</mat-list-item>

<div *ngIf="MenuItem.Expanded">
  <app-sidebar-menu *ngFor="let child of MenuItem.Childs" [MenuItem]="child" [IsChild]="true"
    (ItemClick)="ClickItem(child)"></app-sidebar-menu>
</div>
