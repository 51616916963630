import { BaseEntity, BaseModel } from "../../models/BaseModel";

export class FormModel extends BaseModel<FormEntity, FormDto, FormFilter> {
    constructor() {
        super();
    }
}

export class FormEntity extends BaseEntity {
    Name: string;
    Form: string;
    constructor() {
        super();
    }
}

export class FormDto extends FormEntity {
    Creator: string;
    constructor() {
        super();
    }
}

export class FormFilter extends FormDto {
}
