<div id="WorkflowView" class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container d-flex">
            <div class="grid-column" (click)="Load(1)" [ngClass]="Tab === 1 ? 'tab-selected' : ''"
              [ngStyle]="{ cursor: Tab === 2 ? 'pointer' : 'default' }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.YourWorkflows }}
              </span>
            </div>
            <div class="grid-column" *ngIf="authservice.CurrentUser?.IsAdmin" (click)="Load(2)"
              [ngClass]="Tab === 2 ? 'tab-selected' : ''" [ngStyle]="{ cursor: Tab === 1 ? 'pointer' : 'default' }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.Workflows }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 m-0 p-0">
        <div class="search-create-container">
          <div class="p-0 d-flex align-items-center justify-content-center" style="width: 30px;margin-right: 15px;">
            <span class="filter-badge" [matBadgeHidden]="FilterCount === 0" matBadge="{{ FilterCount }}">
              <img class="clickable-icon" (click)="OpenFiltersDialog()"
                src="../../../../../assets/icon/filter-icon.svg" />
            </span>
          </div>
          <div class="flex-grow-1 p-0">
            <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder" [FiltersLabelBtn]="
                navigatorService.Dictionary?.SearchFiltersLabel
              " [HaveFilters]="false" [HaveActions]="false" (OnEnterPress)="Search($event)"
              (OnClearBtnPress)="Search($event)"></app-search>
          </div>

          <div class="p-0 d-flex justify-center" style="width: 60px;">
            <button *ngIf="authservice?.DocAccount?.Dto?.IsAdmin" mat-button class="it-doc-btn-primary-circle"
              (click)="NewWorkflowModal(); $event.stopPropagation()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="FilterWorkflowDtos && FilterWorkflowDtos.length > 0">
    <div class="container-view pb-5">
      <mat-accordion *ngFor="
          let dto of FilterWorkflowDtos;
          let i = index;
          let isFirst = first;
          let isLast = last
        " class="view-accordion">
        <a id="{{ 'wf' + dto.Id }}" href="{{ '#wf' + dto.Id }}"></a>
        <mat-expansion-panel id="panel{{ i }}" [ngClass]="{ 'mt-3': !isFirst }" hideToggle="true"
          (opened)="ExpansionPanelOpenedEvent(dto, i)" (closed)="ExpansionPanelClosedEvent(dto, i)"
          [expanded]="dto.IsExpanded" [disabled]="dto.IsDisabled">
          <mat-expansion-panel-header [ngClass]="{ 'border-radius-bottom': !dto.IsExpanded }">
            <mat-panel-title>
              <span>{{ dto.Name }}</span>
            </mat-panel-title>
            <mat-panel-description class="justify-content-between">
              <div class="draft-label">
                <span *ngIf="dto.State === WorkflowState.DRAFT">{{
                  navigatorService.Dictionary?.DraftLabel
                  }}</span>
              </div>
              <div class="d-flex">
                <ng-container *ngIf="dto.State === WorkflowState.DRAFT">
                  <img *ngIf="
                      !dto.IsEditing && authservice?.DocAccount?.Dto?.IsAdmin
                    " [matMenuTriggerFor]="editPublishMenu" src="../../../../../assets/icon/circle-more-blue.svg"
                    (click)="$event.stopPropagation()" />
                  <mat-menu #editPublishMenu="matMenu" class="more-action-menu">
                    <div mat-menu-item (click)="EditWorkflowBtn(dto); $event.stopPropagation()">
                      {{ navigatorService.Dictionary?.Update }}
                    </div>
                    <div mat-menu-item (click)="
                        SaveOrPublish(dto, true); $event.stopPropagation()
                      ">
                      {{ navigatorService.Dictionary?.Publish }}
                    </div>
                    <div mat-menu-item *ngIf="dto.State === 'DRAFT'" (click)="Delete(dto); $event.stopPropagation()">
                      {{ navigatorService.Dictionary?.Delete }}
                    </div>
                  </mat-menu>
                  <div *ngIf="dto.IsEditing" class="panel-action-container d-flex justify-content-between"
                    (click)="$event.stopPropagation()">
                    <div class="text-center" style="height: 100%"
                      (click)="$event.stopPropagation(); SaveOrPublish(dto)">
                      <div>
                        <span class="material-icons panel-action-icon" font->check</span>
                      </div>
                      <div class="action-text">
                        {{ navigatorService.Dictionary?.Save }}
                      </div>
                    </div>
                    <div class="text-center" style="height: 100%" (click)="$event.stopPropagation(); Discard(dto)">
                      <div>
                        <span class="material-icons panel-action-icon">close</span>
                      </div>
                      <div class="action-text">
                        {{ navigatorService.Dictionary?.Discard }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <img *ngIf="!dto.IsEditing && !dto.IsExpanded" class="ms-2"
                  src="../../../../../assets/icon/circle-arrow-right.svg" />
                <img *ngIf="dto.IsEditing || dto.IsExpanded" class="ms-2"
                  src="../../../../../assets/icon/circle-arrow-down-fill.svg" />
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- Content accordion -->
          <div class="content-accordion-container container-fluid p-0">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <app-input [DataValue]="WorkFlowStepNameInput" [Property]="'Name'" [Type]="'text-button'"
                  [Required]="false" [Maxlength]="50" [Placeholder]="
                    navigatorService.Dictionary?.WFStepNamePlaceholder
                  " [Disabled]="!dto.IsEditing" [Description]="navigatorService.Dictionary?.Maxlength50"
                  (Add)="AddWFStep($event, dto)"></app-input>
              </div>
              <div class="col-md-6 col-sm-6">
                <mat-form-field class="form-field" style="width: 100%">
                  <mat-label>{{
                    navigatorService.Dictionary?.WorkflowDurationPlaceholder
                    }}</mat-label>
                  <mat-select [value]="dto.TimeLimit" [disabled]="!dto.IsEditing"
                    (selectionChange)="SelectTimeLimit($event.value, dto)" required>
                    <mat-option *ngFor="let data of WFTimeLimitData" [value]="data">
                      {{ data }} {{ navigatorService.Dictionary?.Days }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!-- Draggable step -->
            <div cdkDropList class="step-list" (cdkDropListDropped)="DropStep($event, dto)">
              <div *ngFor="let step of dto.WorkflowSteps; let isLast = last" class="step-box"
                [cdkDragDisabled]="!dto.IsEditing" cdkDrag>
                <div class="container-fluid p-0">
                  <div class="row">
                    <div class="col-md-11 col-sm-11 p-0">
                      <div class="valign-icon-text">
                        <span *ngIf="dto.IsEditing" class="material-icons move" cdkDragHandle>drag_indicator</span>
                        <span class="step-name">{{ step.Name }}</span>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-1 text-end p-0">
                      <span *ngIf="dto.IsEditing" class="material-icons pointer"
                        [matMenuTriggerFor]="stepMenu">more_vert</span>
                      <mat-menu #stepMenu="matMenu" class="more-action-menu">
                        <div mat-menu-item (click)="DeleteStep(step, dto)">
                          {{ navigatorService.Dictionary?.Delete }}
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                  <!-- Step users -->
                  <div class="row all-users-box mt-2">
                    <div class="col-md-12">
                      <div class="row mt-3 mb-3">
                        <div class="col-md-6 col-sm-6">
                          <div>
                            <b>{{ navigatorService.Dictionary?.Revisor }}</b>
                          </div>
                          <div class="preview-user-box mt-2">
                            <app-mt-autocomplete *ngIf="dto.IsEditing" #autocompleteRevisorUser [appearance]="'fill'"
                              [Items]="GetItems(AccountRole.REVISOR)" [DisplayProperties]="['Name']" [Placeholder]="
                                navigatorService.Dictionary
                                  ?.WFUserOrUoPlaceholder
                              " [SearchProperties]="['Name']" [LoaderType]="'Spinner'" [DropDownMode]="true"
                              [DataValue]="" (SelectionChange)="
                                UoUsersChange(
                                  $event,
                                  step,
                                  autocompleteRevisorUser,
                                  AccountRole.REVISOR
                                )
                              " [Multiple]="false" (OnChange)="GetItems(AccountRole.REVISOR)"
                              [OptionIcon]="'add'"></app-mt-autocomplete>
                            <ng-container *ngIf="
                                FindUsersPerRole(
                                  step.WorkflowStepUsers,
                                  AccountRole.REVISOR
                                );
                                let Users
                              ">
                              <div *ngFor="
                                  let WFUser of Users;
                                  let isFirst = first
                                " [ngClass]="{ 'mt-3': !isFirst }">
                                <div class="d-flex justify-content-between">
                                  <div class="valign-icon-text">
                                    <img *ngIf="WFUser.Avatar" class="avatar-circle" [src]="
                                        'data:image/png;base64,' + WFUser.Avatar
                                      " style="margin-right: 10px" />
                                    <div *ngIf="!WFUser.Avatar" class="avatar-circle" style="margin-right: 10px">
                                      {{ WFUser.Name | acronym }}
                                    </div>
                                    <span>{{ WFUser.Name }}</span>
                                  </div>

                                  <span *ngIf="dto.IsEditing" class="material-icons pointer"
                                    (click)="RemoveWFStepUser(WFUser, step)">close</span>
                                </div>
                              </div>
                              <span *ngIf="!Users || Users?.length <= 0">-</span>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                          <div>
                            <b>{{ navigatorService.Dictionary?.Approver }}</b>
                          </div>
                          <div class="preview-user-box mt-2">
                            <app-mt-autocomplete *ngIf="dto.IsEditing" #autocompleteApproverUser [appearance]="'fill'"
                              [Items]="GetItems(AccountRole.APPROVER)" [DisplayProperties]="['Name']" [Placeholder]="
                                navigatorService.Dictionary
                                  ?.WFUserOrUoPlaceholder
                              " [SearchProperties]="['Name']" [LoaderType]="'Spinner'" [DropDownMode]="true"
                              [DataValue]="" (SelectionChange)="
                                UoUsersChange(
                                  $event,
                                  step,
                                  autocompleteApproverUser,
                                  AccountRole.APPROVER
                                )
                              " [Multiple]="false" (OnChange)="GetItems(AccountRole.APPROVER)"
                              [OptionIcon]="'add'"></app-mt-autocomplete>
                            <ng-container *ngIf="
                                FindUsersPerRole(
                                  step.WorkflowStepUsers,
                                  AccountRole.APPROVER
                                );
                                let Users
                              ">
                              <div *ngFor="
                                  let WFUser of Users;
                                  let isFirst = first
                                " [ngClass]="{ 'mt-3': !isFirst }">
                                <div class="d-flex justify-content-between">
                                  <div class="valign-icon-text">
                                    <img *ngIf="WFUser.Avatar" class="avatar-circle" [src]="
                                        'data:image/png;base64,' + WFUser.Avatar
                                      " style="margin-right: 10px" />
                                    <div *ngIf="!WFUser.Avatar" class="avatar-circle" style="margin-right: 10px">
                                      {{ WFUser.Name | acronym }}
                                    </div>
                                    <span>{{ WFUser.Name }}</span>
                                  </div>
                                  <span *ngIf="dto.IsEditing" class="material-icons pointer"
                                    (click)="RemoveWFStepUser(WFUser, step)">close</span>
                                </div>
                              </div>
                              <span *ngIf="!Users || Users?.length <= 0">-</span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="isLast" class="row mb-3">
                        <div class="col-md-6 col-sm-6">
                          <div>
                            <b>{{ navigatorService.Dictionary?.Admin }}</b><span class="required-color">*</span>
                          </div>
                          <div class="preview-user-box mt-2">
                            <app-mt-autocomplete *ngIf="dto.IsEditing" #autocompleteAdminUser [appearance]="'fill'"
                              [Items]="GetItems(AccountRole.ADMIN)" [DisplayProperties]="['Name']" [Placeholder]="
                                navigatorService.Dictionary
                                  ?.WFUserOrUoPlaceholder
                              " [SearchProperties]="['Name']" [LoaderType]="'Spinner'" [DropDownMode]="true"
                              [DataValue]="" (SelectionChange)="
                                UoUsersChange(
                                  $event,
                                  step,
                                  autocompleteAdminUser,
                                  AccountRole.ADMIN
                                )
                              " [Multiple]="false" (OnChange)="GetItems(AccountRole.ADMIN)"
                              [OptionIcon]="'add'"></app-mt-autocomplete>
                            <ng-container *ngIf="
                                FindUsersPerRole(
                                  step.WorkflowStepUsers,
                                  AccountRole.ADMIN
                                );
                                let Users
                              ">
                              <div *ngFor="
                                  let WFUser of Users;
                                  let isFirst = first
                                " [ngClass]="{ 'mt-3': !isFirst }">
                                <div class="d-flex justify-content-between">
                                  <div class="valign-icon-text">
                                    <img *ngIf="WFUser.Avatar" class="avatar-circle" [src]="
                                        'data:image/png;base64,' + WFUser.Avatar
                                      " style="margin-right: 10px" />
                                    <div *ngIf="!WFUser.Avatar" class="avatar-circle" style="margin-right: 10px">
                                      {{ WFUser.Name | acronym }}
                                    </div>
                                    <span>{{ WFUser.Name }}</span>
                                  </div>
                                  <span *ngIf="dto.IsEditing" class="material-icons pointer"
                                    (click)="RemoveWFStepUser(WFUser, step)">close</span>
                                </div>
                              </div>
                              <span *ngIf="!Users || Users?.length <= 0">-</span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End content accordion -->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
  <!-- Empty page -->
  <ng-container *ngIf="
      !navigatorService.Loading &&
      authservice?.DocAccount?.Dto?.IsAdmin &&
      (!FilterWorkflowDtos || FilterWorkflowDtos.length <= 0)
    ">
    <div class="container-fluid empty-container-workflow">
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-center">
          <img width="100%" height="518px" src="../../../../../assets/icon/your-workflow.svg" />
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-center">
          <div class="container-description-btn mt-5">
            <div class="title-empty mb-3">
              {{ navigatorService.Dictionary?.CreateWFTitle }}
            </div>
            <div class="description-empty">
              {{ navigatorService.Dictionary?.CreateWFPoint1 }}
            </div>
            <div class="description-empty">
              {{ navigatorService.Dictionary?.CreateWFPoint2 }}
            </div>
            <div class="description-empty">
              {{ navigatorService.Dictionary?.CreateWFPoint3 }}
            </div>
            <div class="text-center mt-5">
              <button class="create-first-wf-btn" (click)="NewWorkflowModal()">
                {{ navigatorService.Dictionary?.CreateWFButton }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="
      !navigatorService.Loading &&
      !authservice?.DocAccount?.Dto?.IsAdmin &&
      (!FilterWorkflowDtos || FilterWorkflowDtos.length <= 0)
    " class="empty-table-box" style="padding-bottom: 200px">
    <img src="../../../../../assets/icon/goal-empty.svg" />
    <div class="description">
      {{ navigatorService.Dictionary?.EmptyWorkflowMessage }}
    </div>
  </div>
</div>
