import {Component, Inject, Input} from '@angular/core';
import { RangepickerComponent } from '../../../../../../custom-components/rangepicker/rangepicker.component';
import { PropertyDto } from '../../../../../../models/FieldsModel';
import { NavigatorService } from '../../../../../../../navigator.services';
import { MetadataDto } from '../../../../../../models/MetadataModel';
import { Column } from '../../Form';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-form-rangepicker',
  templateUrl: './form-rangepicker.component.html',
  styleUrls: ['./form-rangepicker.component.scss'],
})
export class FormRangepickerComponent   {
  @Input() Properties: PropertyDto;
  @Input() Data: any;
  @Input() Minimize:boolean=false;
  constructor(public navigatorService: NavigatorService) {
   
  }

  public DeleteComponent() {
    const component: MetadataDto = this.Data.item;
    const column: Column = this.Data.column;
    column.RemoveComponent(component);
  }
}
