import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { PickerComponent } from '../../../custom-components/picker/picker.component';
import { ObjectFilter } from '../../../models/ObjectModel';
import { ChipsMultiselectComponent } from '../../../custom-components/chips-multiselect/chips-multiselect.component';
import { DossierFilter } from '../../../models/DossierModel';
import { OrganizationUnitModel } from '../../../models/OrganizationUnitModel';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { NavigatorService } from '../../../../navigator.services';
import { DataService } from '../../../../data.service';
import { Constants, DossierState } from '../../../doc.configuration';

@Component({
  selector: 'app-filter-dossier',
  templateUrl: './filter-dossier.component.html',
  styleUrls: ['./filter-dossier.component.scss'],
})
export class FilterDossierComponent implements OnInit {
  @ViewChild('creationDate') CreationDateControl: PickerComponent<ObjectFilter>;
  @ViewChild('uoSelect') UoSelect: ChipsMultiselectComponent;
  @ViewChild('statusSelect') StatusSelect: ChipsMultiselectComponent;

  Filter: DossierFilter;
  FilterCount = 0;
  Statuses = [];
  CreationDate: FormControl;
  OrganizationUnitModel: OrganizationUnitModel;
  OrganizationUnitController: OrganizationUnitController;
  ChipsUo = [];
  ChipsState = [];
  SelectedUo = [];
  SelectedState = [];
  constructor(
    public navigatorService: NavigatorService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<FilterDossierComponent>,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.CreationDate = new FormControl();
    this.OrganizationUnitModel = new OrganizationUnitModel();
    this.OrganizationUnitController = new OrganizationUnitController(
      dataService
    );

    for (const status of Object.keys(DossierState)) {
      this.Statuses.push({
        Name: this.GetDossierDisplayState(status),
        Value: status,
      });
    }
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.OrganizationUnitModel = await this.OrganizationUnitController.Get(
      this.OrganizationUnitModel
    );

    if (this.data) {
      this.Filter = this.data;
      for (const uo of this.OrganizationUnitModel?.Dtos) {
        if (this.Filter?.SearchUo?.indexOf(uo.Id) !== -1) {
          this.SelectedUo?.push(uo.Id);
          this.ChipsUo?.push(uo);
        }
      }
      for (const status of this.Statuses) {
        if (this.Filter?.State?.indexOf(status.Value) !== -1) {
          this.SelectedState?.push(status.Value);
          this.ChipsState?.push(status);
        }
      }
    } else {
      this.Filter = new DossierFilter();
      this.Filter.SearchUo = [];
      this.Filter.State = [];
      this.Filter.CreationDateString = null;
    }
    this.navigatorService.StopLoading();
  }

  private GetFilterCount() {
    let filterCount = 0;
    if (this.SelectedUo?.length > 0) {
      filterCount += 1;
    }
    if (this.SelectedState?.length > 0) {
      filterCount += 1;
    }
    if (this.Filter.CreationDateString) {
      filterCount += 1;
    }
    if (this.Filter.Label) {
      filterCount += 1;
    }
    return filterCount;
  }

  GetDossierDisplayState(state: string) {
    let displayState = Constants.ND.toString();
    if (state) {
      if (state === DossierState.OPEN) {
        displayState = this.navigatorService.Dictionary?.Open;
      } else if (state === DossierState.CLOSE) {
        displayState = this.navigatorService.Dictionary?.Archived;
      }
    }
    return displayState;
  }

  ApplyFilters() {
    const filterCount = this.GetFilterCount();
    this.Filter.SearchUo = this.SelectedUo;
    this.Filter.State = this.SelectedState;
    this.dialogRef.close({
      FilterCount: filterCount,
      Filter: filterCount <= 0 ? null : this.Filter,
    });
  }

  SelectUo(event) {
    if (event && event.Id > 0) {
      this.SelectedUo.push(event.Id);
    }
  }

  RemoveUo(event) {
    if (event) {
      const index = this.Filter.SearchUo?.findIndex((x) => x === event.Id);
      this.SelectedUo?.splice(index, 1);
    }
  }

  SelectStatus(event) {
    if (event && event.Value) {
      this.SelectedState?.push(event.Value);
    }
  }

  RemoveStatus(event) {
    if (event && event.Value) {
      const index = this.Filter.State?.findIndex((x) => x === event.Value);
      this.SelectedState?.splice(index, 1);
    }
  }

  InitializeFilters() {
    this.Filter = new DossierFilter();
    this.Filter.SearchUo = [];
    this.Filter.State = [];
    this.Filter.CreationDateString = null;
    this.ChipsUo = [];
    this.SelectedUo = [];
    this.ChipsState = [];
    this.SelectedState = [];
    this.CreationDateControl.Reset();
    this.UoSelect.Reset();
    this.StatusSelect.Reset();
  }
}
