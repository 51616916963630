import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../data.service';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { CommonService } from '../../../../common.service';
import { GenericDialogAction, PageAction } from '../../../doc.configuration';
import { GenericDialogModel } from '../../../models/GenericDialogModel';
import {
  MetadataDto,
  MetadataFilter,
  MetadataModel,
} from '../../../models/MetadataModel';
import { MetadataController } from '../../../controllers/MetadataController';
import { SelectionModel } from '@angular/cdk/collections';
import { MetadataComponent } from '../../dialog-menu/metadata/metadata.component';
import { FilterMetadataComponent } from '../../dialog-menu/filter-metadata/filter-metadata.component';
import { MetadataCategoryFilter } from '../../../models/MetadataCategoryModel';
import { FieldsFilter } from '../../../models/FieldsModel';

@Component({
  selector: 'app-metadata-view',
  templateUrl: './metadata-view.component.html',
  styleUrls: ['./metadata-view.component.scss'],
})
export class MetadataViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild('pager') Pager: PagerComponent;
  Model: MetadataModel;
  Controller: MetadataController;

  DataSource: MatTableDataSource<MetadataDto>;
  Pages: Array<number>;
  CurrentPage = 1;
  FilterCount = 0;
  Tab = 1;

  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authService: AuthService,
    commonService: CommonService
  ) {
    commonService.CurrentPageComponent = this;
    this.Model = new MetadataModel();
    this.Controller = new MetadataController(dataService);
  }

  ngOnInit() {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(page = 1, filter: MetadataFilter = null, tab = 1) {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();

    this.Model.Filter = filter ?? new MetadataFilter();
    this.Model.Search = this.SearchComponent.searchValue;
    this.Tab = tab;

    if (tab === 1) {
      this.Model.Filter.Disabled = null;
    } else if (tab === 2) {
      this.Model.Filter.Disabled = false;
    } else if (tab === 3) {
      this.Model.Filter.Disabled = true;
    }

    this.Model = await this.Controller.Load(this.Model);

    if (this.Model.Performed) {
      this.Paging(page);
    }

    this.navigatorService.StopLoading();
  }

  HeaderCheckbox(event: any) {
    if (event) {
      const dtos = this.Model.Dtos;
      dtos?.map((o) => (o.Checked = event.checked));
    }
  }

  IsAllSelected() {
    const dtosSelected = this.Model?.Dtos;
    const selection = new SelectionModel<MetadataDto>(
      true,
      this.Model?.Dtos?.filter((t) => t.Checked)
    );
    if (selection.selected.length > 0) {
      return selection.selected.length === dtosSelected?.length;
    }
    return false;
  }

  GetMenuMetadatas() {
    const selectedDtos = this.Model?.Dtos?.filter((dto) => dto.Checked);
    return selectedDtos?.length > 0;
  }

  CheckRow(event: any, dto: MetadataDto) {
    if (dto && dto.Id > 0) {
      dto.Checked = event.checked;
    }
  }

  Create() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(
      MetadataComponent,
      null,
      '45%',
      'fit-content',
      '200px',
      async (result) => {
        if (result) {
          this.Model.Skip = 0;
          await this.Load();
        }
      }
    );
  }

  Update(dto: MetadataDto) {
    this.navigatorService.PageAction = PageAction.Edit;
    this.navigatorService.ShowDialog(
      MetadataComponent,
      { Dto: dto },
      '45%',
      'fit-content',
      '200px',
      async (result) => {
        if (result) {
          await this.Load(this.Pager?.CurrentPage);
        }
      }
    );
  }

  async DisableMetadata(dto: MetadataDto) {
    if (dto) {
      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/code.svg';
      data.Name = null;
      data.Title = dto.Disabled
        ? this.navigatorService.Dictionary?.Enable
        : this.navigatorService.Dictionary?.Disable;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        (dto.Disabled
          ? this.navigatorService.Dictionary?.ToEnable.toLowerCase()
          : this.navigatorService.Dictionary?.ToDisable.toLowerCase()) +
        '<br>' +
        dto.Name +
        '?';
      data.ConfirmBtnLabel = data.Title;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();
            let model = new MetadataModel();
            dto.Disabled = !dto.Disabled;
            model.Dto = dto;
            model = await this.Controller.CreateOrUpdate(model);
            if (model?.Performed) {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.SaveSuccess,
                data.Name
              );
              await this.Load(this.Pager?.CurrentPage);
            } else {
              this.navigatorService.ShowSnackBar(
                this.navigatorService.Dictionary?.MessageGenericError
              );
            }
          }
          this.navigatorService.StopLoading();
        }
      );
    }
  }

  async ToggleSelectedMetadatas(action: 'disable' | 'enable') {
    const selectedDtos = this.Model?.Dtos?.filter((dto) => dto.Checked);

    if (selectedDtos?.length > 0) {
      const allDisabled = selectedDtos.every((dto) => dto.Disabled);

      const data = new GenericDialogModel();
      data.Icon = '../../../../../assets/icon/code.svg';
      data.Title =
        action === 'disable'
          ? this.navigatorService.Dictionary?.Disable
          : this.navigatorService.Dictionary?.Enable;
      data.Message =
        this.navigatorService.Dictionary?.AreYouSureWantMsg +
        ' ' +
        (action === 'disable'
          ? this.navigatorService.Dictionary?.ToDisable.toLowerCase()
          : this.navigatorService.Dictionary?.ToEnable.toLowerCase()) +
        ' ' +
        this.navigatorService.Dictionary?.AllSelectedMetadatas +
        '?';
      data.ConfirmBtnLabel = data.Title;
      data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;

      this.navigatorService.ShowDialog(
        GenericConfirmDialogComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (performed) => {
          if (performed === GenericDialogAction.CONFIRM) {
            this.navigatorService.StartLoading();

            for (const dto of selectedDtos) {
              dto.Disabled = action === 'disable' ? true : false;
              let model = new MetadataModel();
              model.Dto = dto;
              const updatedModel = await this.Controller.CreateOrUpdate(model);

              if (!updatedModel?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
                this.navigatorService.StopLoading();
                return;
              }
            }

            this.navigatorService.ShowSnackBar(
              this.navigatorService.Dictionary?.SaveSuccess
            );
            await this.Load(this.Pager?.CurrentPage);
            this.HeaderCheckbox({ checked: false });

            this.navigatorService.StopLoading();
          }
        }
      );
    }
  }

  async DisableSelectedMetadatas() {
    await this.ToggleSelectedMetadatas('disable');
  }

  async EnableSelectedMetadatas() {
    await this.ToggleSelectedMetadatas('enable');
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    await this.Load(1, this.Model.Filter, this.Tab);
    this.navigatorService.StopLoading();
  }

  OpenFiltersDialog() {
    this.navigatorService.ShowDialog(
      FilterMetadataComponent,
      {
        Filter: this.Model.Filter,
      },
      '45%',
      'fit-content',
      '200px',
      async (response: any) => {
        if (response) {
          this.navigatorService.StartLoading();
          this.FilterCount = response.FilterCount;
          this.Model.Skip = 0;
          if (this.FilterCount <= 0) {
            response.Filter = null;
          }
          await this.Load(1, response.Filter, this.Tab);
          this.navigatorService.StopLoading();
        }
      }
    );
  }

  // #region Paginazione
  RefreshTable(dtos: Array<MetadataDto>) {
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<MetadataDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.CurrentPage = page;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    this.CurrentPage = page;
    await this.Load(page, this.Model.Filter);
    this.navigatorService.StopLoading();
  }
  // #endregion Paginazione
}
