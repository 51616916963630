import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivityDto } from '../../../models/ActivityModel';
import { DateFormat } from '../../../doc.configuration';
import { NavigatorService } from '../../../../navigator.services';

@Component({
  selector: 'app-activities-card',
  templateUrl: './activities-card.component.html',
  styleUrls: ['./activities-card.component.scss'],
})
export class ActivitiesCardComponent implements OnInit {
  private dataValue: Array<ActivityDto> = null;
  get DataValue() {
    return this.dataValue;
  }

  @Input() set DataValue(value: Array<ActivityDto>) {
    if (value) {
      this.dataValue = value;
      if (this.dataValue) {
        const currentdate = new Date();
        for (const dto of this.dataValue) {
          const date = new Date(dto.DateString);
          const difference = this.GetDateDiffInDays(date, currentdate);
          if (difference === 0) {
            dto.DateString =
              this.navigatorService.Dictionary?.Today +
              ' ' +
              date.getHours() +
              ':' +
              date.getMinutes();
          } else if (difference === 1) {
            dto.DateString =
              this.navigatorService.Dictionary?.Yesterday +
              ' ' +
              date.getHours() +
              ':' +
              date.getMinutes();
          } else {
            dto.DateString = this.datePipe.transform(date, DateFormat.ddMMyyyy);
          }
        }
      }
    }
  }
  constructor(
    public navigatorService: NavigatorService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {}

  GetDateDiffInDays(date1: Date, date2: Date) {
    const t2 = date2.getTime();
    const t1 = date1.getTime();
    return Math.floor((t2 - t1) / (24 * 3600 * 1000));
  }
}
