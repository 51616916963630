import { DataService } from '../../data.service';
import { FormModel } from '../models/FormModel';

export class FormController {
    constructor(private dataService: DataService) { }

    async CreateOrUpdate(model: FormModel): Promise<FormModel> {
        let response = null;
        if (model && model.Dto) {
            response = await this.dataService.CreateOrUpdateForm(model);
            if (response) {
                model.Performed = response.Performed;
                if (response.Performed) {
                    model.Dto.Id = response.Entity.Id;
                }
            }
        }
        return response;
    }

    async Read(model: FormModel): Promise<FormModel> {
        const response = await this.dataService.ReadForm(model);
        if (response) {
            model.Performed = response.Performed;
            if (response.Performed) {
                model.Dto = response.Dto;
            }
        }
        return model;
    }

    async Get(model: FormModel): Promise<FormModel> {
        const response = await this.dataService.GetForm(model);
        if (response) {
            model.Performed = response.Performed;
            if (response.Performed) {
                model.Dtos = response.Dtos;
            }
        }
        return model;
    }    

    async Load(model: FormModel): Promise<FormModel> {
        const response = await this.dataService.LoadForm(model);
        if (response) {
            model.Performed = response.Performed;
            if (response.Performed) {
                model.Dtos = response.Dtos;
            }
        }
        return model;
    }

    async Delete(model: FormModel): Promise<FormModel> {
        let response = null;
        if (model) {
            response = await this.dataService.DeleteForm(model);
            if (response) {
                model.Performed = response.Performed;
            }
        }
        return response;
    } 

}
