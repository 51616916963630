import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChipsMultiselectComponent } from '../../../custom-components/chips-multiselect/chips-multiselect.component';
import { NavigatorService } from '../../../../navigator.services';
import { DataService } from '../../../../data.service';
import {
  MetadataDto,
  MetadataFilter,
  MetadataModel,
} from '../../../models/MetadataModel';
import {
  MetadataCategoryDto,
  MetadataCategoryFilter,
  MetadataCategoryModel,
} from '../../../models/MetadataCategoryModel';
import {
  FieldsDto,
  FieldsFilter,
  FieldsModel,
} from '../../../models/FieldsModel';
import { MetadataController } from '../../../controllers/MetadataController';
import { MetadataCategoryController } from '../../../controllers/MetadataCategoryController';
import { FieldsController } from '../../../controllers/FieldsController';
import { AuthService } from '../../../../auth.service';
import { UserFilter, UserModel } from '../../../../models/UserModel';
import { ItCoreController } from '../../../../controllers/ItCoreController';

@Component({
  selector: 'app-filter-metadata',
  templateUrl: './filter-metadata.component.html',
  styleUrls: ['./filter-metadata.component.scss'],
})
export class FilterMetadataComponent implements OnInit {
  @ViewChild('categorySelect') CategorySelect: ChipsMultiselectComponent;
  @ViewChild('fieldSelect') FieldSelect: ChipsMultiselectComponent;
  @ViewChild('autocompleteUser') CreatorSelect: any;

  Filter: MetadataFilter;
  FilterCount = 0;
  Controller: MetadataController;
  MetadataCategoryModel: MetadataCategoryModel;
  MetadataCategoryController: MetadataCategoryController;
  FieldsModel: FieldsModel;
  FieldsController: FieldsController;
  UserModel: UserModel;
  ItCoreController: ItCoreController;

  ChipsMetadataCategory: MetadataCategoryDto[] = [];
  ChipsField: FieldsDto[] = [];

  constructor(
    public navigatorService: NavigatorService,
    public authService: AuthService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<FilterMetadataComponent>,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Filter = data?.Filter ?? new MetadataFilter();
    this.Controller = new MetadataController(dataService);
    this.MetadataCategoryModel = new MetadataCategoryModel();
    this.MetadataCategoryController = new MetadataCategoryController(
      dataService
    );
    this.FieldsModel = new FieldsModel();
    this.UserModel = new UserModel();
    this.FieldsController = new FieldsController(dataService);
    this.Filter = data?.Filter || new MetadataFilter();
    this.ItCoreController = new ItCoreController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.MetadataCategoryModel = await this.MetadataCategoryController?.Get(
      this.MetadataCategoryModel
    );
    this.FieldsModel = await this.FieldsController?.Get(this.FieldsModel);
    this.Filter = this.data?.Filter || new MetadataFilter();
    this.BindCategoryChips();
    this.BindFieldChips();
    this.BindCreator();
    this.navigatorService.StopLoading();
  }

  private GetFilterCount() {
    let filterCount = 0;
    if (this.Filter?.SelectedCategoryIds?.length > 0) {
      filterCount += 1;
    }
    if (this.Filter?.SelectedFieldIds?.length > 0) {
      filterCount += 1;
    }
    if (this.Filter?.UserId) {
      filterCount += 1;
    }
    return filterCount;
  }

  ApplyFilters() {
    const filterCount = this.GetFilterCount();
    this.dialogRef.close({
      FilterCount: filterCount,
      Filter: filterCount <= 0 ? null : this.Filter,
    });
  }

  SelectCategory(metadataCategoryDto: MetadataCategoryDto) {
    if (metadataCategoryDto && metadataCategoryDto.Id > 0) {
      this.Filter.SelectedCategoryIds?.push(metadataCategoryDto.Id);
      const dto = this.MetadataCategoryModel.Dtos.find(
        (q) => q.Id == metadataCategoryDto.Id
      );
      if (dto) {
        this.ChipsMetadataCategory?.push(dto);
      }
    }
  }

  RemoveCategory(metadataCategoryDto: MetadataCategoryDto) {
    if (metadataCategoryDto && metadataCategoryDto.Id) {
      const index = this.Filter?.SelectedCategoryIds?.findIndex(
        (x) => x === metadataCategoryDto.Id
      );
      this.Filter?.SelectedCategoryIds?.splice(index, 1);
    }
  }

  SelectField(fieldDto: FieldsDto) {
    if (fieldDto && fieldDto.Id) {
      this.Filter?.SelectedFieldIds?.push(fieldDto.Id);
      const dto = this.FieldsModel.Dtos.find((q) => q.Id == fieldDto.Id);
      if (dto) {
        this.ChipsField?.push(dto);
      }
    }
  }

  RemoveField(fieldDto: FieldsDto) {
    if (fieldDto && fieldDto.Id) {
      const index = this.Filter.SelectedFieldIds?.findIndex(
        (x) => x === fieldDto.Id
      );
      this.Filter?.SelectedFieldIds?.splice(index, 1);
    }
  }

  BindCategoryChips() {
    if (this.Filter) {
      for (const categoryId of this.Filter?.SelectedCategoryIds) {
        const dto = this.MetadataCategoryModel.Dtos.find(
          (q) => q.Id == categoryId
        );
        if (dto) {
          this.ChipsMetadataCategory?.push(dto);
        }
      }
    }
  }

  BindFieldChips() {
    if (this.Filter) {
      for (const fieldId of this.Filter?.SelectedFieldIds) {
        const dto = this.FieldsModel.Dtos.find((q) => q.Id == fieldId);
        if (dto) {
          this.ChipsField?.push(dto);
        }
      }
    }
  }

  async BindCreator() {
    if (this.Filter) {
      this.UserModel.Dtos = [];
      if (this.Filter.UserId > 0) {
        this.UserModel.Filter = new UserFilter();
        this.UserModel.Filter.Id = this.Filter.UserId;
        const response = await this.ItCoreController.ReadUser(this.UserModel);
      }
    }
  }

  async SearchUser($event) {
    const search = $event.Search;
    if (search) {
      this.UserModel.Search = encodeURI(search.trim());
      this.UserModel.Dtos = [];
      const response = await this.ItCoreController.SearchUserDoc(
        this.UserModel
      );
      if ($event.Callback) {
        $event.Callback(response.Dtos, $event.Istance);
      }
    }
  }

  SelectUserChange($event) {
    if ($event) {
      this.Filter.UserId = $event.Id;
    }
  }

  InitializeFilters() {
    this.Filter = new MetadataFilter();
    this.Filter.SelectedCategoryIds = [];
    this.Filter.SelectedFieldIds = [];
    this.ChipsMetadataCategory = [];
    this.ChipsField = [];
    this.CategorySelect?.Reset();
    this.FieldSelect?.Reset();
    this.CreatorSelect?.Clear();
  }
}
