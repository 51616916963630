<div class="itdoc-page itdoc-app-container-box">
  <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container">
          <div class="grid-container">
            <div class="grid-column" (click)="Load(1)" [ngClass]="Tab === 1 ? 'tab-selected' : ''"
              [ngStyle]="{ cursor: Tab === 2 ? 'pointer' : 'default' }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.YourUo }}
              </span>
            </div>
            <div class="grid-column" *ngIf="authenticationService.CurrentUser?.IsAdmin" (click)="Load(2)"
              [ngClass]="Tab === 2 ? 'tab-selected' : 'default'" [ngStyle]="{ cursor: Tab === 1 ? 'pointer' : '' }">
              <span class="grid-column-title">
                {{ navigatorService.Dictionary?.AllUo }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mx-0 p-0">
    <div class="search-create-container">
      <div class="p-0 d-flex align-items-center justify-content-center" style="width: 30px;margin-right: 15px;">
        <span class="filter-badge" [matBadgeHidden]="FilterCount === 0" matBadge="{{ FilterCount }}">
          <img class="clickable-icon" (click)="OpenFiltersDialog()" src="../../../../../assets/icon/filter-icon.svg" />
        </span>
      </div>
      <div class="flex-grow-1 p-0">
        <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
          [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
          [HaveActions]="false" [Interactive]="true" (OnEnterPress)="Search($event, Model.Dtos)"
          (OnClearBtnPress)="ClearSearchInput($event)"></app-search>
      </div>

      <div class="p-0" style="width: 120px;">
        <button *ngIf="authenticationService.CurrentUser?.IsAdmin" (click)="Create()" mat-fab extended
          class="it-doc-btn-create create-button">
          <img class="clickable-icon" src="../../../../../assets/icon/add.svg" />
          <span class="text">{{ navigatorService.Dictionary?.Create }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row" style="height: 100%">
    <div class="col-md-12">
      <div class="form mt-3">
        <div *ngIf="Model?.Dtos?.length > 0" class="row w-100 table-uo-header">
          <div class="col-md-3 uo-col-1">
            <div class="valign-icon-text">
              <div>{{ navigatorService.Dictionary?.Name }}</div>
            </div>
          </div>
          <div class="col-md-2 uo-col-2">
            <div class="valign-icon-text">
              <div>
                {{ navigatorService.Dictionary?.OfficeCodePlaceHolder }}
              </div>
            </div>
          </div>

          <div class="col-md-3 uo-col-3 no-sort-column">
            {{ navigatorService.Dictionary?.Description }}
          </div>
          <div class="col-md-2 uo-col-4 no-sort-column">
            {{ navigatorService.Dictionary?.Admin }}
          </div>
          <div class="col-md-1 uo-col-5 no-sort-column">
            {{ navigatorService.Dictionary?.Users }}
          </div>
          <div class="col-md-1 uo-col-6"></div>
        </div>
        <app-tree #uotree [DataSource]="FilteredUo" [Tab]="Tab" [Index]="0"></app-tree>
        <div *ngIf="Model?.Dtos?.length <= 0 && !navigatorService.Loading" class="empty-table-box">
          <img src="../../../../../assets/icon/uo-empty-icon.svg" />
          <div class="description">
            {{ navigatorService.Dictionary?.EmptyUoViewMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
