<div class="itdoc-page itdoc-app-container-box">
  <!-- <div class="row m-0">
    <div class="col-md-12 p-0">
      <div class="title-header-container">
        <div class="title-container ">
          <div class="grid-container">
            <div class="grid-column" (click)='Load(1)' [ngClass]="Tab===1?'tab-selected':''"
              [ngStyle]="{'cursor':Tab===2?'pointer':'default'}">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.YourDeleteDocument}}
              </span>
            </div>
            <div class="grid-column" *ngIf=" this.CountAllObject>0" (click)='Load(2)'
              [ngClass]="Tab===2?'tab-selected':''" [ngStyle]="{'cursor':Tab===1?'pointer':'default'}">
              <span class="grid-column-title">
                {{navigatorService.Dictionary?.AllDeleteDocument}}
              </span>
            </div>
          </div>
        </div>
        <div class="search-create-container">
          <app-search #search [Placeholder]="navigatorService.Dictionary?.SearchPlaceholder"
            [FiltersLabelBtn]="navigatorService.Dictionary?.SearchFiltersLabel" [HaveFilters]="false"
            [HaveActions]="false" (OnEnterPress)="Search($event)" (OnClearBtnPress)="Search($event)">
          </app-search>
        </div>
      </div>
    </div>
  </div> -->
  <div class="form mt-3">
    <div class="row breadcrumb-lite" *ngIf="this.CurrentFolder && CurrentFolder.Deep > 0">
      <div class="col-md-4">
        <button mat-icon-button (click)="BreadcrumbBack()" [disabled]="!this.CurrentFolder">
          <mat-icon class="icon-text-right"> keyboard_arrow_left</mat-icon>
          <span *ngIf="!CurrentFolder.IsRedirect">{{navigatorService.Dictionary?.Back}}
          </span>
          <span *ngIf="CurrentFolder.IsRedirect">{{navigatorService.Dictionary?.AllDocument}}
          </span>
        </button>
      </div>
      <div class="col-md-4 text-center current-folder">
        <span>{{CurrentFolder?.Name}}</span>
      </div>
      <div class="col-md-4"></div>
    </div>

    <div class="it-doc-table-container">
      <table mat-table [dataSource]="DataSource" matSort *ngIf="navigatorService.Dictionary && DataSource!=null">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef class="checkbox-column">
            <mat-checkbox (change)="HeaderCheckbox($event)" [checked]="IsAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox *ngIf="element.PhysicalName && element.Parent" (change)="CheckRow($event, element)"
              [(ngModel)]="element.Checked">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnName}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnName}} </th>
          <td mat-cell *matCellDef="let element">
            <span title="{{element.Name + (element.Type==='file'?'.' +
                        commonService.GetExtension(element.PhysicalName):'')}}">
              <div class="d-flex">
                <span class="icon-text-right"><img src="{{element.Icon}}"></span>
                <div class="ellipsis-text">{{element.Name + (element.Type==='file'?'.' +
                  commonService.GetExtension(element.PhysicalName):'')}}</div>
              </div>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnVersion}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnVersion}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.Version && element.Version>0?'v.'+element.Version: ND}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnState}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnState}} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Type==='file'" class="material-icons"
              [ngClass]="'icon-circle-'+ element.State?.toLowerCase()">
              circle</span>
            <span>
              {{element.State?element.State: ND}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.Admin}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.Admin}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{element.DisplayName}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnTeam}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnTeam}} </th>
          <td mat-cell *matCellDef="let element">
            <span>
              <ng-container *ngIf="element?.Team?.length > 0 && element?.Team?.length <= 3">
                <span *ngFor="let user of element?.Team; let i=index">
                  <img *ngIf="user.Avatar" class="profile-picture" [ngClass]="i>0?'profile-picture-over':''"
                    matTooltip="{{user.Name}}"
                    [ngStyle]="{'left.px': (i>0 && i<=3 && (i>0 && element?.Team[i-1].Avatar)?i*(-8):0)}"
                    src="data:image/png;base64,{{user.Avatar}}"></span>
              </ng-container>
              <ng-container *ngIf="element?.Team?.length > 0 && element?.Team?.length > 3">
                <span *ngFor="let user of element?.Team; let i=index;">
                  <img *ngIf="i<=3 && user.Avatar" class="profile-picture"
                    [ngStyle]="{'left.px': (i>0 && i<=3 && (i>0 && element?.Team[i-1].Avatar)?i*(-8):0)}"
                    matTooltip="{{user.Name}}" src="data:image/png;base64,{{user.Avatar}}">
                </span>
                <div *ngIf="(element?.Team?.length - 4)>0" class="profile-picture-circle"
                  [ngStyle]="{'left.px': 4*(-8)}">
                  +{{element?.Team?.length - 4}}</div>
              </ng-container>
              <ng-container *ngIf="!element?.Team || element?.Team?.length === 0">-</ng-container>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{navigatorService.Dictionary?.TableColumnUpdate}}">
          <th mat-header-cell *matHeaderCellDef>
            {{navigatorService.Dictionary?.TableColumnUpdate}} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.Deep>=0">
              {{element.ModificationDateString}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="more-action">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <button mat-icon-button (click)="GetMenuFiles()" class="w-auto"  [disabled]="GetMoreActionFilesDisabled()"
            #m="matMenuTrigger" [matMenuTriggerFor]="moreAction">
            <img src="../../../../../assets/icon/more-vert.svg" [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
          </button>
          <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
            <button mat-menu-item (click)="ActionMenu(itemMenu.Name)" *ngFor="let itemMenu of FilesMenu"
              [disabled]="!itemMenu.Enabled">{{itemMenu.Label}}</button>
          </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element">
            <img #m="matMenuTrigger" [matMenuTriggerFor]="moreAction" class="clickable-icon"
              (click)="GetMenuFile(element)" src="../../../../../assets/icon/more-vert.svg"
              [ngClass]="{'open-menu-trigger-color': m.menuOpen}">
            <mat-menu #moreAction="matMenu" xPosition="before" class="more-action-menu">
              <button mat-menu-item (click)="ActionMenu(itemMenu.Name,element)"
                *ngFor="let itemMenu of FileMenu">{{itemMenu.Label}}</button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef class="icon-column">
            <!-- <img class="clickable-icon" src="../../../../../assets/icon/filter-icon.svg"> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <!-- <img *ngIf="element.Type==='folder'"
                            class="clickable-icon" src="../../../../../assets/icon/forward.svg"> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['checkbox',
                    navigatorService.Dictionary?.TableColumnName,
                    navigatorService.Dictionary?.TableColumnVersion,
                    navigatorService.Dictionary?.TableColumnState,
                    navigatorService.Dictionary?.Admin,
                    navigatorService.Dictionary?.TableColumnTeam,
                    navigatorService.Dictionary?.TableColumnUpdate,
                    'more-action',
                    'filter']">
        </tr>
        <tr mat-row (click)="SelectRow(row)" [ngClass]="{'selected':row.Selected}" *matRowDef="let row;  columns: ['checkbox',
                     navigatorService.Dictionary?.TableColumnName,
                     navigatorService.Dictionary?.TableColumnVersion,
                     navigatorService.Dictionary?.TableColumnState,
                     navigatorService.Dictionary?.Admin,
                     navigatorService.Dictionary?.TableColumnTeam,
                     navigatorService.Dictionary?.TableColumnUpdate,
                     'more-action',
                    'filter']">
        </tr>

      </table>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <app-pager #pager (onPaging)="PagingChange($event)"></app-pager>
      </div>
    </div>

    <div *ngIf="DataSource?.data?.length <= 0 && !navigatorService.Loading" class="m-0 empty-table-box"
      style="padding-bottom: 200px;">
      <img src="../../../../../assets/icon/trashed-empty.svg">
      <div class="description">{{navigatorService.Dictionary?.NoTrashedFileMsg}}</div>
    </div>
  </div>

</div>
